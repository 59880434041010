import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Divider from "@mui/material/Divider";

// Default button values
const BUTTON_SIZE = 24;
const BUTTON_PADDING = 8;

const HackyMoreButton = () => {
  return (
    <Box sx={{ position: "relative", width: `${BUTTON_SIZE}px`, height: `${BUTTON_SIZE}px` }}>
      <IconButton
        aria-label="settings"
        // Hack in order to not shift section header down because of padding
        // and to maintain button click area big enough to click
        sx={{ position: "absolute", left: `-${BUTTON_PADDING}px`, top: `-${BUTTON_PADDING}px` }}
      >
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
};

export const Section = ({ title, children }) => {
  return (
    <Card sx={{ p: "24px", mb: "24px" }}>
      <Stack gap={"16px"}>
        <Stack direction={"row"} justifyContent="space-between" alignItems="center">
          <Typography variant="h2" fontSize={22} fontWeight={700}>
            {title}
          </Typography>
          <HackyMoreButton />
        </Stack>
        <Divider flexItem sx={{ borderColor: "#BDBDBD", mb: "16px" }} />
        {children}
      </Stack>
    </Card>
  );
};
