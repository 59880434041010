import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

import { BasePropsType } from "../../types/common/EmmsysTypes";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: '100%';
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

type LookupValues<Values> = Values[keyof Values];
const CLOSE_REASON = {
  BUTTON: "closeButtonClick",
  BACKDROP: "backdropClick",
  ESCAPE: "escapeKeyDown",
} as const;

type CloseReason = LookupValues<typeof CLOSE_REASON>;
const IGNORED_REASONS: CloseReason[] = [CLOSE_REASON.BACKDROP, CLOSE_REASON.ESCAPE];

interface ErrorDialogProps extends Pick<BasePropsType, "translate"> {
  show: boolean;
  err: any;
  onClose: (errcode: string, errmessage: string) => void;
}

const ErrorDialog = memo(function ErrorDialog(props: ErrorDialogProps) {
  const { translate, show, err, onClose } = props;
  const [open, setOpen] = useState(show ? true : false);
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const theme = useTheme();

  const matchessmbreakpoints = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = (event: object, reason: CloseReason) => {
    if (IGNORED_REASONS.includes(reason)) {
      return;
    }
    setOpen(false);
    if (onClose != undefined) onClose(err.Code, err.Message);
  };

  const handleSeeDetails = (event) => {
    setShowErrorDetails(!showErrorDetails);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        maxWidth={matchessmbreakpoints ? "md" : "sm"}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: matchessmbreakpoints ? "400px" : "250px",
            minHeight: "200px",
          },
        }}
      >
        <DialogTitle sx={{ alignItems: "center", display: "flex", padding: "10px" }}>
          <ErrorIcon sx={{ margin: "0px 8px", color: "red" }} />
          <span style={{ flex: "1 0 auto" }}>{translate("Error")}</span>
          <IconButton aria-label="close" onClick={(e) => handleClose(e, CLOSE_REASON.BUTTON)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers={true}>
          <DialogContentText>
            {err.Message}
            {err.Details && (
              <Button
                sx={{ textTransform: "none" }}
                size="small"
                variant="text"
                onClick={handleSeeDetails}
              >
                ...{translate("See error details")}
              </Button>
            )}
          </DialogContentText>
          {showErrorDetails && (
            <Box sx={{ flexGrow: 1 }}>
              <Textarea
                aria-label="exception details"
                disabled
                minRows={5}
                defaultValue={err.Details}
                style={{ width: "100%" }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={(e) => handleClose(e, CLOSE_REASON.BUTTON)}>
            {translate("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export { ErrorDialog };
