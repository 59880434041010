import CircleIcon from "@mui/icons-material/Circle";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";

import { CustomAreaChart } from "../common/CustomAreaChart";
import { CustomGrid } from "../common/customgrid";
import { CustomSimpleGrid } from "../common/CustomSimpleGrid";
import { HorizontalBarChart } from "../common/horizontalbarchart";
import { StatusPieChart } from "../common/StatusPieChart";
import { VeeStatistics } from "./veestatistics";

const columns = [
  {
    field: "DMA",
    headerName: "DMA",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
  {
    field: "VolumeIn",
    headerName: "Volume In",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
  {
    field: "VolumeOut",
    headerName: "Volume Out",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
  {
    field: "Losses",
    headerName: "Losses",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
  {
    field: "LossesPercent",
    headerName: "Losses%",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
];

const rows = [
  {
    id: 1,
    DMA: "Sector 1",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 2,
    DMA: "Sector 2",
    VolumeIn: 2882.92,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 19.6,
  },
  {
    id: 3,
    DMA: "Sector 3",
    VolumeIn: 6913.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 3.4,
  },
  {
    id: 4,
    DMA: "Sector 4",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 5,
    DMA: "Sector 5",
    VolumeIn: 2882.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 19.6,
  },
  {
    id: 6,
    DMA: "Sector 6",
    VolumeIn: 6913.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 3.4,
  },
  {
    id: 7,
    DMA: "Sector 7",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 8,
    DMA: "Sector 8",
    VolumeIn: 6913.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 9,
    DMA: "Sector 9",
    VolumeIn: 2882.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 10,
    DMA: "Sector 10",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 11,
    DMA: "Sector 11",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 12,
    DMA: "Sector 12",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 13,
    DMA: "Sector 13",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 14,
    DMA: "Sector 14",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 15,
    DMA: "Sector 15",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 16,
    DMA: "Sector 16",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 17,
    DMA: "Sector 17",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 18,
    DMA: "Sector 18",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 19,
    DMA: "Sector 19",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 20,
    DMA: "Sector 20",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 21,
    DMA: "Sector 21",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 22,
    DMA: "Sector 22",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 23,
    DMA: "Sector 23",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
  {
    id: 24,
    DMA: "Sector 24",
    VolumeIn: 3955.28,
    VolumeOut: 3017.09,
    Losses: 937.896,
    LossesPercent: 23.7,
  },
];

const columns1 = [
  {
    field: "Status",
    headerName: "Status",
    flex: 0.3,
    minWidth: 150,
    editable: false,
    renderCell: (params) => (
      <>
        <CircleIcon
          sx={{
            color: (params.value === "Meter Oversized" ? "orange" : "red") + " !important",
          }}
        />
        {params.value}
      </>
    ),
  },
  {
    field: "MeterCount",
    headerName: "Meter Count",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
  {
    field: "Percent",
    headerName: "%",
    flex: 0.3,
    minWidth: 150,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return params.value;
      }
      return params.value * 100;
    },
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toLocaleString()} %`;
    },
  },
];

const rows1 = [
  { id: 1, Status: "Meter Oversized", MeterCount: 1918, Percent: 97.36 },
  { id: 2, Status: "Meter Undersized", MeterCount: 52, Percent: 2.64 },
];

const wodataset = [
  {
    id: 1,
    statusName: "Forced Finish",
    woCount: 1,
    percent: 38.61,
  },
  {
    id: 2,
    statusName: "Execution",
    woCount: 2,
    percent: 35.63,
  },
  {
    id: 3,
    statusName: "Fished",
    woCount: 4,
    percent: 11.48,
  },
  {
    id: 4,
    statusName: "Creation",
    woCount: 8,
    percent: 7.7,
  },
];

// const veedataset = [
//   {
//     id: 1,
//     statusName: "Open",
//     metersCount: 1,
//     percent: 38.61,
//   },
//   {
//     id: 2,
//     statusName: "Closed",
//     metersCount: 2,
//     percent: 35.63,
//   },
// ];

// const veedataset1 = [
//   {
//     id: 1,
//     statusName: "Initial State",
//     metersCount: 1,
//     percent: 38.61,
//   },
//   {
//     id: 2,
//     statusName: "Validated",
//     metersCount: 2,
//     percent: 35.63,
//   },
//   {
//     id: 3,
//     statusName: "Invalidated",
//     metersCount: 2,
//     percent: 35.63,
//   },
//   {
//     id: 4,
//     statusName: "Recheck",
//     metersCount: 2,
//     percent: 35.63,
//   },
// ];

// const veedataset2 = [
//   {
//     id: 1,
//     statusName: "Missing",
//     metersCount: 1,
//     percent: 38.61,
//   },
//   {
//     id: 2,
//     statusName: "Read",
//     metersCount: 2,
//     percent: 35.63,
//   },
//   {
//     id: 3,
//     statusName: "Manual Read",
//     metersCount: 2,
//     percent: 35.63,
//   },
//   {
//     id: 4,
//     statusName: "Estimated",
//     metersCount: 2,
//     percent: 35.63,
//   },
// ];

// const veedataset3 = [
//   {
//     id: 1,
//     statusName: "Exported",
//     metersCount: 1,
//     percent: 38.61,
//   },
// ];

const valueFormatter = (value) => `${value}%`;

export default function Dashboard(props) {
  const { translate, language, globalRefresh, onGeneralError } = props;

  const currentDate = new Date();

  const [month, setMonth] = useState(dayjs(currentDate));

  //    const [weekvalue, setWeekvalue] = useState([dayjs(firstdayofcurrentweek), dayjs(lastdayofcurrentweek)]);
  const [alignment, setAlignment] = useState("alarmdistribution");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChangeMonth = (value) => {
    setMonth(value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Typography
            noWrap
            component="div"
            sx={{
              fontWeight: 700,
              fontSize: "1.75rem",
              flex: "auto",
            }}
          >
            {translate(" Dashboard")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={"Select date"}
              views={["month"]}
              format={"MMMM YYYY"}
              value={month}
              onChange={handleChangeMonth}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "1.38rem",
                    fontWeight: 700,
                  }}
                >
                  {translate("DMA Overview")}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "1.5rem",
                }}
              >
                <CustomGrid
                  translate={translate}
                  dataSet={rows}
                  columns={columns}
                  allowAdd={false}
                  filterable={true}
                  addButtonText={translate("Add")}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "1.38rem",
                    fontWeight: 700,
                  }}
                >
                  {translate("Meter Sizing")}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    width: "40%",
                    justifyContent: "center",
                    padding: "2rem 0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1rem", padding: "5px 10px" }}
                      component="span"
                      noWrap
                    >
                      {translate("Replacement Performance")}
                    </Typography>
                    <Chip
                      label="10%"
                      variant="filled"
                      sx={{
                        backgroundColor: "green !important",
                        color: "white !important",
                      }}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ margin: "3px 10px !important" }}
                    />
                    <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                      Var:+4%
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1rem", padding: "5px 10px" }}
                      component="span"
                      noWrap
                    >
                      {translate("Replacement Performance")}
                    </Typography>
                    <Chip
                      label="+$5.500"
                      variant="filled"
                      sx={{
                        backgroundColor: "green !important",
                        color: "white !important",
                      }}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ margin: "3px 10px !important" }}
                    />
                    <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                      Var:-2%
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1rem", padding: "5px 10px" }}
                      component="span"
                      noWrap
                    >
                      {translate("Total Consumption Outside Q1-Q3:") + "7.6%"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1rem", padding: "5px 10px" }}
                      component="span"
                      noWrap
                    >
                      {translate("Total Meter Gain/Losses")}
                    </Typography>
                    <Chip
                      label="1.2%"
                      variant="filled"
                      sx={{
                        backgroundColor: "green !important",
                        color: "white !important",
                      }}
                    />
                  </Box>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ margin: "3rem 0px !important" }} />
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    width: "60%",
                    justifyContent: "center",
                    padding: "30px",
                  }}
                >
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    sx={{ justifyContent: "center" }}
                  >
                    <ToggleButton
                      value="alarmdistribution"
                      aria-label="left aligned"
                      sx={{
                        maxHeight: "40px",
                        backgroundColor:
                          alignment == "alarmdistribution" ? "#0051C1 !important" : "inherit",
                        color: alignment == "alarmdistribution" ? "white !important" : "inherit",
                        borderRadius: "4rem 0 0 4rem !important",
                      }}
                    >
                      {alignment == "alarmdistribution" && <DoneOutlinedIcon />}
                      <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                        {translate("Alarm Distribution")}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value="alarmages"
                      aria-label="right aligned"
                      sx={{
                        maxHeight: "40px",
                        backgroundColor:
                          alignment == "alarmages" ? "#0051C1 !important" : "inherit",
                        color: alignment == "alarmages" ? "white !important" : "inherit",
                        borderRadius: "0 4rem 4rem 0 !important",
                      }}
                    >
                      <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                        {translate("Alarm Ages")}
                      </Typography>
                      {alignment == "alarmages" && <DoneOutlinedIcon />}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <CustomSimpleGrid dataSet={rows1} columns={columns1} />
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "1.38rem",
                    fontWeight: 700,
                  }}
                >
                  {translate("Work Orders")}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ flex: "1" }}>
                    <HorizontalBarChart
                      translate={translate}
                      language={language}
                      chartConfig={{
                        dataset: wodataset,
                        seriesValueFormatter: valueFormatter,
                        title: translate("Work Order Status"),
                        height: 250,
                        xAxisField: "woCount",
                        yAxisField: "statusName",
                        colors: ["#1976D2"],
                      }}
                    />
                  </Box>

                  <Divider orientation="vertical" flexItem sx={{ margin: "3rem 0px !important" }} />
                  <Box sx={{ flex: "1 0" }}>
                    <CustomAreaChart
                      language={language}
                      chartConfig={{
                        title: "Efficiency",
                        lineColor: "#1976D2",
                        height: 250,
                        xAxis: [
                          {
                            id: "yAxis_id",
                            scaleType: "point",
                            data: ["v_1", "v_2", "v_3", "v_4", "v_5"],
                          },
                        ],
                        yAxis: [
                          {
                            valueFormatter: (value) => value + " %",
                          },
                        ],
                        series: [
                          {
                            id: "Efficiency",
                            data: [60, 20, 18, 10, 50],
                            area: true,
                            showMark: false,
                          },
                        ],
                        gradientColors: ["#0051C17A", "#0051C10F"],
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "1.38rem",
                    fontWeight: 700,
                  }}
                >
                  {translate("Asset Management")}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ flex: "1" }}>
                    <StatusPieChart
                      translate={translate}
                      language={language}
                      chartConfig={{
                        dataset: [
                          {
                            value: 9.09,
                            color: "#1976D2",
                            text: "In Stock (1)",
                            percent: "9.09%",
                          },
                          {
                            value: 4.55,
                            color: "#2196F3",
                            text: "In Field (1)",
                            percent: "4.55%",
                          },
                          {
                            value: 86.36,
                            color: "#BBDEFB",
                            text: "Under Installation (19)",
                            percent: "86.36%",
                          },
                        ],
                        title: translate("Meters Overview"),
                      }}
                    />
                  </Box>

                  <Divider orientation="vertical" flexItem sx={{ margin: "3rem 0px !important" }} />
                  <Box sx={{ flex: "1 0" }}>
                    <StatusPieChart
                      translate={translate}
                      language={language}
                      chartConfig={{
                        dataset: [
                          {
                            value: 9.09,
                            color: "#2196F3",
                            text: "In Stock (2)",
                            percent: "9.09%",
                          },
                          {
                            value: 4.55,
                            color: "#1976D2",
                            text: "In Field (1)",
                            percent: "4.55%",
                          },
                          {
                            value: 86.36,
                            color: "#BBDEFB",
                            text: "Under Installation (19)",
                            percent: "86.36%",
                          },
                        ],
                        title: translate("Device Overview"),
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <VeeStatistics
            translate={translate}
            language={language}
            globalRefresh={globalRefresh}
            selectedMonth={month}
            onGeneralError={onGeneralError}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
