import { useAppStore } from "../store/store";
import { config } from "../config/config";
import * as API_Types from "../types/api/ApiContracts";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const authUrl = config.WS_AUTH_URL.startsWith("http")
  ? config.WS_AUTH_URL
  : window.location.origin + config.WS_AUTH_URL;

const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IAPI {
  GetEmsNodeMeterPerspectives: (nodeId, nodeType, mediumId) => any;
  GetEmsNodeMediums: (nodeId, nodeType) => any;
  GetAdministrativeGroupConsumptionEvolution: (
    timeline,
    nodeId,
    mediumId,
    nodeType,
    perspectiveValue,
    mediumValue,
    mediumFilter?
  ) => any;
  GetAdministrativeGroupConsumption: (
    nodeId,
    mediumId,
    nodeType,
    startDate,
    endDate,
    periodicity,
    perspectiveValue,
    mediumValue,
    mediumFilter?
  ) => any;
  GetEmsNodes: (nodeType, nodeId, parentNodeId, quickSearch) => any;
  SearchBuildings: () => any;
  GetBuildings: (regions?, cities?, cityZones?) => any;
  GetTenants: (username: string, userPassword: string) => Promise<Array<API_Types.Tennant>>;
}

const API: IAPI = {
  GetEmsNodeMeterPerspectives: async (nodeId, nodeType, mediumId) => {
    return await fetch(
      `${apiUrl}GetEmsNodeMeterPerspectives?idNode=${nodeId}&nodeType=${nodeType}&idMedium=${mediumId}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetEmsNodeMediums: async (nodeId, nodeType) => {
    return await fetch(`${apiUrl}GetEmsNodeMediums?idNode=${nodeId}&nodeType=${nodeType}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetAdministrativeGroupConsumptionEvolution: async (
    timeline,
    nodeId,
    mediumId,
    nodeType,
    perspectiveValue,
    mediumValue,
    mediumFilter = ""
  ) => {
    return await fetch(
      `${apiUrl}GetAdministrativeGroupConsumptionEvolution${timeline}?idNode=${nodeId}&idMedium=${mediumId}&nodeType=${nodeType}&idMediuFiltruContoare=${mediumFilter}&codPerspectivaFiltruContoare=${perspectiveValue}&tipConversie=${mediumValue}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetAdministrativeGroupConsumption: async (
    nodeId,
    mediumId,
    nodeType,
    startDate,
    endDate,
    periodicity,
    perspectiveValue,
    mediumValue,
    mediumFilter = ""
  ) => {
    return await fetch(
      `${apiUrl}GetAdministrativeGroupConsumption?groupId=${nodeId}&mediumId=${mediumId}&nodeType=${nodeType}&start=${startDate.format(
        "YYYYMMDD"
      )}&stop=${endDate.format(
        "YYYYMMDD"
      )}&periodicity=${periodicity}&idMediuFiltruContoare=${mediumFilter}&codPerspectivaFiltruContoare=${perspectiveValue}&tipConversie=${mediumValue}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetEmsNodes: async (nodeType, nodeId, parentNodeId, quickSearch) => {
    return await fetch(
      `${apiUrl}GetEmsNodes?nodeType=${nodeType}&idNode=${nodeId}&idParentNode=${parentNodeId}&quickSearch=${quickSearch}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  SearchBuildings: async () => {
    return await fetch(`${apiUrl}GetSearchResult?searchBy=buildings`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetBuildings: async (regions = "", cities = "", cityZones = "") => {
    return await fetch(
      `${apiUrl}GetBuildings?regionIds=${regions}&cityIds=${cities}&cityZoneIds=${cityZones}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetTenants: async (username, userPassword) => {
    return await fetch(`${authUrl}GetTenants?userName=${username}&userPwd=${userPassword}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<API_Types.Tennant>) => {
        return data;
      });
  },
};

export { API };
