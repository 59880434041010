//@ts-nocheck

import { useEffect } from "react";
import { config } from "../../config/config";

var repParameters = {};
var SELECT_A_VALUE = { value: -1, name: "<select a value>" };

function pushParam(param, options, widget) {
  if (param.hasChildParameters === true) {
    var childParameters = param.childParameters;
    for (var i = 0, leni = childParameters.length; i < leni; i++) {
      var childParamName = childParameters[i];
      var childRepParam = repParameters[childParamName];
      if (childRepParam) {
        childRepParam.hasParrent = true;
        childRepParam.parrentName = param.name;
      } else {
        repParameters[childParamName] = {
          hasParrent: true,
          parrentName: param.name,
        };
      }
    }
  }
  var repParam = repParameters[param.name];
  if (repParam) {
    repParam.param = param;
    repParam.options = options;
    repParam.widget = widget;
  } else {
    repParameters[param.name] = {
      param: param,
      options: options,
      widget: widget,
    };
  }
}

var paramUpdatedCount = -1;
function countParamUpdated() {
  if (paramUpdatedCount === -1) paramUpdatedCount = 0;
  paramUpdatedCount++;
  if (Object.keys(repParameters).length <= paramUpdatedCount) {
    paramUpdatedCount = -1;
    applyParamPreset();
  }
}

function applyParamPreset() {
  if (paramsParrents.length > 0) {
    overrideParams(paramsParrents, "parrents");
    return;
  } else if (paramsWithChildrenAndParrents.length > 0) {
    overrideParams(paramsWithChildrenAndParrents, "parrents_childrens");
    return;
  } else if (paramsChildren.length > 0) {
    overrideParams(paramsChildren, "childrens");
    return;
  }
}

let paramsChildren = [] as any;
let paramsParrents = [] as any;
let paramsWithChildrenAndParrents = [] as any;

function overrideParams(oParams, type) {
  if (type) {
    if (type === "parrents") {
      var oParam = oParams.shift();
      var repParam = repParameters[oParam.name];
      repParam.widget.value(oParam.value);
      repParam.widget.trigger("change");
      return;
    }
    if (type === "parrents_childrens") {
      var oParam = oParams.shift();
      var repParam = repParameters[oParam.name];
      repParam.widget.value(oParam.value);
      repParam.widget.trigger("change");
      return;
    }

    if (type === "childrens") {
      while ((oParam = oParams.shift())) {
        var repParam = repParameters[oParam.name];
        repParam.widget.value(oParam.value);
        repParam.widget.trigger("change");
      }
    }

    return;
  }

  paramsChildren = [];
  paramsParrents = [];
  paramsWithChildrenAndParrents = [];

  for (var i = 0, leni = oParams.length; i < leni; i++) {
    var oParam = oParams[i];
    var repParam = repParameters[oParam.name];
    if (repParam === undefined) continue;

    if (repParam.hasParrent !== true && repParam.param.hasChildParameters === false) {
      repParam.widget.value(oParam.value);
      repParam.widget.trigger("change");
      continue;
    }

    if (repParam.param.hasChildParameters === true && repParam.hasParrent !== true) {
      paramsParrents.push(oParam);
      continue;
    }

    if (repParam.hasParrent === true && repParam.param.hasChildParameters === true) {
      paramsWithChildrenAndParrents.push(oParam);
      continue;
    }

    if (repParam.hasParrent === true && repParam.param.hasChildParameters !== true) {
      paramsChildren.push(oParam);
      continue;
    }
  }

  do {
    var swapped = false;
    for (var j = 0, lenj = paramsWithChildrenAndParrents.length - 1; j < lenj; j++) {
      var first = repParameters[paramsWithChildrenAndParrents[j].name];
      for (var k = j + 1, lenk = paramsWithChildrenAndParrents.length; k < lenk; k++) {
        var second = paramsWithChildrenAndParrents[k];
        if (first.parrentName === second.name) {
          paramsWithChildrenAndParrents[j] = second;
          paramsWithChildrenAndParrents[k] = paramsWithChildrenAndParrents[j];
          swapped = true;
        }
      }
    }
  } while (swapped === true);

  if (paramsParrents.length > 0) {
    overrideParams(paramsParrents, "parrents");
  } else if (paramsWithChildrenAndParrents.length > 0) {
    overrideParams(paramsWithChildrenAndParrents, "parrents_childrens");
  } else if (paramsChildren.length > 0) {
    overrideParams(paramsChildren, "childrens");
  }
}

export default function TelerikReportViewer(props) {
  const { options } = props;

  const repparams = options.rParams ?? {};
  const repname = options.rName ?? "";
  const reportsurl = config.WS_REPORTING_URL;

  function createViewer() {
    window.jQuery("#reportViewer1").telerik_ReportViewer({
      serviceUrl: reportsurl + "api/reports",
      reportSource: {
        report: null,
        parameters: {},
      },
      scale: 1.0,
      viewMode: "INTERACTIVE",
      printMode: "FIT_PAGE_WIDTH",
      sendEmail: { enabled: true },
      parameterEditors: [
        {
          match: function (parameter) {
            // Here you can use all of the parameter properties in order to
            // create a more specific editor
            return parameter.allowNull === true && parameter.type === "System.DateTime";
          },
          createEditor: function (placeholder, options) {
            var DatePickerElement = window.jQuery(placeholder).html('<input type="datetime"/>');
            var parameter,
              valueChangedCallback = options.parameterChanged,
              myDatePicker;
            //function onChange() {
            //    var val = myDatePicker.value();
            //    valueChangedCallback(parameter, val);
            //}
            function onChange() {
              var val = myDatePicker.value();
              if (val === "") {
                myDatePicker.value(null);
              }
              valueChangedCallback(parameter, val);
            }
            return {
              beginEdit: function (param) {
                parameter = param;
                myDatePicker = window
                  .jQuery(DatePickerElement)
                  .find("input")
                  .kendoDatePicker({
                    dataBound: function () {
                      countParamUpdated();
                    },
                    dataTextField: "name",
                    dataValueField: "value",
                    value: parameter.value,
                    dataSource: parameter.availableValues,
                    change: onChange,
                  })
                  .getKendoMyDatePicker();
                //myDatePicker = window.jQuery(DatePickerElement).find("input").data("kendoDatePicker");
                //console.log(myDatePicker);
                myDatePicker.value(parameter.value);
                pushParam(parameter, options, myDatePicker);
              },
            };
          },
        },
        {
          //TEXTBOX
          match: function (parameter) {
            return false;
            //var p = parameter;
            //if (p.isVisible === false)
            //    return false;
            //if (p.type !== "System.String" && p.type !== "System.Double")
            //    return false;
            //if (p.availableValues !== null)
            //    return false;
            //if (p.multivalue !== false)
            //    return false;
            //console.log("MATCH TEXTBOX", p);
            //return true;
          },
          createEditor: function (placeholder, options) {
            var textBoxElem = window
                .jQuery(placeholder)
                .html('<input type="text" class="k-textbox trv-parameter-editor-text"/>'),
              parameter,
              valueChangedCallback = options.parameterChanged,
              textBox;

            function onChange(val) {
              //var val = textBox.value();
              valueChangedCallback(parameter, val);
            }

            return {
              beginEdit: function (param) {
                parameter = param;

                var timerid;
                textBox = textBoxElem.find("input");
                textBox.val(parameter.value);
                textBox.on("input", function (e) {
                  var value = window.jQuery(this).val();
                  if (window.jQuery(this).data("lastval") != value) {
                    window.jQuery(this).data("lastval", value);
                    clearTimeout(timerid);

                    timerid = setTimeout(function () {
                      //change action
                      onChange(value);
                    }, 400);
                  }
                });
                textBox.value = function (v) {
                  this.val(v);
                  onChange(v);
                };
                pushParam(parameter, options, textBox);
              },
            };
          },
        },
        {
          //DROPDOWNLIST NULL
          match: function (parameter) {
            var p = parameter;
            if (p.isVisible === false) return false;
            if (Boolean(p.availableValues) === false) return false;
            if (p.multivalue === true) return false;
            if (p.allowNull === false) return false;
            //console.log("MATCH DROPDOWNLIST NULL", p);
            return true;
          },

          createEditor: function (placeholder, options) {
            var myDropDownListElem = window
                .jQuery(placeholder)
                .html('<div style="width:100%"></div>'),
              parameter,
              valueChangedCallback = options.parameterChanged,
              myDropDownList;

            function onChange() {
              //debugger;
              var val = myDropDownList.value();
              if (val === "") {
                var data = myDropDownList.dataSource.data();
                if (data.length === 0) val = null;
                else val = data[0].value;
                myDropDownList.value(val);
              }
              valueChangedCallback(parameter, val);
            }

            return {
              beginEdit: function (param) {
                parameter = param;

                myDropDownList = window
                  .jQuery(myDropDownListElem)
                  .kendoDropDownList({
                    dataBound: function () {
                      countParamUpdated();
                    },
                    dataTextField: "name",
                    dataValueField: "value",
                    value: parameter.value,
                    dataSource: parameter.availableValues,
                    change: onChange,
                  })
                  .getKendoMyDropDownList();

                myDropDownList.value(parameter.value);

                pushParam(parameter, options, myDropDownList);
              },
            };
          },
        },
        {
          //MULTISELECT NULL
          match: function (parameter) {
            var p = parameter;
            if (p.isVisible === false) return false;

            if (p.multivalue === false) return false;

            if (p.allowNull === false) return false;

            //console.log("MATCH MULTISELECT NULL", p);
            return true;
          },
          createEditor: function (placeholder, options) {
            //console.log("createEditor", placeholder, options);
            var multiSelectElement = window
                .jQuery(placeholder)
                .html('<div style="width:100%"></div>'),
              parameter,
              valueChangedCallback = options.parameterChanged,
              multiSelect;

            function onChange() {
              var val = multiSelect.value(undefined, false, true);
              //console.log("VALOARE MS ", val);
              valueChangedCallback(parameter, val);
            }

            return {
              beginEdit: function (param) {
                parameter = param;

                parameter.availableValues.unshift({ name: "<Select All>", value: "-2" });
                multiSelect = window
                  .jQuery(multiSelectElement)
                  .kendoMultiSelect({
                    dataBound: function () {
                      countParamUpdated();
                    },
                    dataValueField: "value",
                    dataTextField: "name",
                    change: onChange,
                    dataSource: parameter.availableValues,
                  })
                  .getKendoMyMultiSelect();

                multiSelect.value(parameter.value);
                pushParam(parameter, options, multiSelect);
              },
            };
          },
        },
        {
          //MULTISELECT NOT NULL
          match: function (parameter) {
            var p = parameter;
            if (p.isVisible === false) return false;

            if (p.multivalue === false) return false;

            if (p.allowNull === true) return false;

            //console.log("MATCH MULTISELECT NULL", p);
            return true;
          },
          createEditor: function (placeholder, options) {
            //console.log("createEditor", placeholder, options);
            var multiSelectElement = window
                .jQuery(placeholder)
                .html('<div style="width:100%"></div>'),
              parameter,
              valueChangedCallback = options.parameterChanged,
              multiSelect;

            function onChange() {
              //console.log("ONCHANGES");
              var val = multiSelect.value();
              //console.log("VALOARE MS ", val);
              valueChangedCallback(parameter, val);
            }

            return {
              beginEdit: function (param) {
                parameter = param;

                parameter.availableValues.unshift({ name: "<Select All>", value: "-2" });
                //console.log("PARAMETRU", parameter);
                multiSelect = window
                  .jQuery(multiSelectElement)
                  .kendoMultiSelect({
                    dataBound: function () {
                      countParamUpdated();
                    },
                    dataValueField: "value",
                    dataTextField: "name",
                    change: onChange,
                    dataSource: parameter.availableValues,
                    select: function (e) {
                      //console.log(e);
                      if (e.dataItem.value == -2) {
                        e.preventDefault();
                        //if (this.allSelected === true) {
                        //    this.allSelected = false;
                        //    this.value([]);
                        //    this.trigger("change");
                        //} else {
                        //    this.allSelected = true;
                        //    this.selectAll();
                        //    this.trigger("change");
                        //}
                        this.selectAll();
                        this.trigger("change");
                      } //else {
                      //this.allSelected = false;
                      //}
                    },
                  })
                  .getKendoMultiSelect();
                multiSelect.selectAll = function () {
                  var data = this.dataSource.data();
                  var select = [];
                  for (var i = 1, leni = data.length; i < leni; i++) {
                    select.push(data[i].value);
                  }
                  this.value(select);
                };

                multiSelect.value(parameter.value);

                pushParam(parameter, options, multiSelect);
              },
            };
          },
        },
        {
          //DROPDOWNLIST NOT NULL
          match: function (parameter) {
            var p = parameter;
            if (p.isVisible === false) return false;
            if (Boolean(p.availableValues) === false) return false;
            if (p.multivalue === true) return false;
            if (p.allowNull === true) return false;

            //console.log("MATCH DROPDOWNLIST NOT NULL", p);
            return true;
          },

          createEditor: function (placeholder, options) {
            //console.log(placeholder);
            var dropDownElement = window.jQuery(placeholder).html('<div style="width:100%"></div>');
            var parameter,
              valueChangedCallback = options.parameterChanged,
              dropDownList;

            function onChange() {
              var newVal = dropDownList.value();
              if (newVal == -1) {
                return;
              }
              if (newVal != -1 && this.options.minunu == false) {
                this.options.minunu = true;
                this.dataSource.remove(this.dataSource.get(-1));
              }
              valueChangedCallback(parameter, newVal);
            }

            return {
              beginEdit: function (param) {
                parameter = param;
                //var defValue = defLabel = null;

                //if ((!parameter.value && parameter.value != 0) && parameter.availableValues && parameter.availableValues.length > 0) {
                //    defValue = parameter.availableValues[0].value;
                //    defLabel = parameter.availableValues[0].name;
                //} else {
                //    defValue = parameter.value;
                //    defLabel = parameter.label;
                //}

                //parameter.value = defValue;
                //parameter.label = defLabel;

                parameter.availableValues.unshift(SELECT_A_VALUE);
                window.jQuery(dropDownElement).kendoDropDownList({
                  dataBound: function () {
                    countParamUpdated();
                  },
                  dataTextField: "name",
                  dataValueField: "value",
                  value: parameter.value, //parameter.value,
                  dataSource: {
                    data: parameter.availableValues,
                    schema: {
                      model: {
                        id: "value",
                      },
                    },
                  },
                  change: onChange,
                  minunu: false,
                });

                dropDownList = window.jQuery(dropDownElement).data("kendoDropDownList");
                //dropDownList.value(defValue);
                //valueChangedCallback(parameter, defValue);
              },
            };
          },
        },
      ],
    });
  }

  useEffect(() => {
    if (reportsurl == undefined || reportsurl == "") {
      if (repname != undefined && repname != "") {
        fetch(pdfrepurl + "Service1.svc/SetReportParameters", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          //credentials: "same-origin",
          redirect: "follow",
          referrerPolicy: "no-referrer",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            parameters: repparams,
            reportname: repname,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              return res.text().then((text) => {
                throw new Error(text);
              });
            } else {
              return res.json();
            }
          })
          .then((result) => {})
          .catch((error) => {});
      }
    }
  }, []);

  useEffect(() => {
    if (options != undefined && options.rName != "") {
      let viewer = window.jQuery("#reportViewer1").data("telerik_ReportViewer");

      if (viewer != undefined) {
        let xparams = {};
        let xparamsstr = "";
        if (options.rParams != undefined) {
          for (let i = 0; i < options.rParams.length; i++) {
            xparamsstr =
              xparamsstr +
              options.rParams[i].Name +
              '":' +
              (options.rParams[i].Value == undefined ||
              options.rParams[i].Value == "" ||
              options.rParams[i].Value == null
                ? "null"
                : options.rParams[i].Value) +
              ',"';
          }
          xparamsstr = '{"' + xparamsstr.substring(0, xparamsstr.length - 2) + "}";
          xparams = JSON.parse(xparamsstr);
        }

        viewer.reportSource({
          report: options.rName,
          parameters: xparams,
        });
      } else {
        if (typeof window.jQuery("#reportViewer1").telerik_ReportViewer === "function") {
          createViewer();
        }
      }
    }
  }, [options]);

  useEffect(() => {
    if (window.jQuery("#reportViewer1") != undefined) {
      if (window.jQuery("#reportViewer1").data("telerik_ReportViewer") == undefined) {
        if (typeof window.jQuery("#reportViewer1").telerik_ReportViewer === "function") {
          createViewer();
        }
      }
    }
  }, []);

  return <div id="reportViewer1"></div>;
}
