import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { memo, useEffect, useState } from "react";

import { BasePropsType } from "../../../types/common/EmmsysTypes";
import { Warehouse_API } from "../../../api/warehouseapi";
import { StatusPieChart } from "../../common/StatusPieChart";

export const AssetManagementWidget = memo(function AssetManagementWidget(props: BasePropsType) {
  const { translate, language } = props;

  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const [isWarehouseMetersStatusLoaded, setIsWarehouseMetersStatusLoaded] = useState(false);
  const [warehouseMetersStatusItems, setWarehouseMetersStatusItems] = useState<any>([]);

  const [isWarehouseDevicesStatusLoaded, setIsWarehouseDevicesStatusLoaded] = useState(false);
  const [warehouseDevicesStatusItems, setWarehouseDevicesStatusItems] = useState<any>([]);

  useEffect(() => {
    setIsWarehouseMetersStatusLoaded(false);
    setIsWarehouseDevicesStatusLoaded(false);

    Warehouse_API.GetWarehouseOverviewStatus([], []).then(
      (result) => {
        setIsWarehouseMetersStatusLoaded(true);

        const warehousemetersstatus = result.map((ms) => {
          const msname = ms.status + " (" + ms.metersCount.toString() + ")";

          return {
            value: Math.floor(ms.percent * 10000) / 100,
            color: ms.color,
            text: msname,
            label: msname,
            percent: Math.floor(ms.percent * 10000) / 100,
          };
        });
        setWarehouseMetersStatusItems(warehousemetersstatus);
      },
      (error) => {
        setIsWarehouseMetersStatusLoaded(true);
        setWarehouseMetersStatusItems([]);
      }
    );

    Warehouse_API.GetWarehouseOverviewDevicesStatus(5, [], []).then(
      (result) => {
        setIsWarehouseDevicesStatusLoaded(true);

        const warehousedevicesstatus = result.map((ms) => {
          const msname = ms.status + " (" + ms.deviceCount.toString() + ")";

          return {
            value: Math.floor(ms.percent * 10000) / 100,
            color: ms.color,
            text: msname,
            label: msname,
            percent: Math.floor(ms.percent * 10000) / 100,
          };
        });
        setWarehouseDevicesStatusItems(warehousedevicesstatus);
      },
      () => {
        setIsWarehouseDevicesStatusLoaded(true);
        setWarehouseDevicesStatusItems([]);
      }
    );
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
          <Grid item xs={12} md={13} lg={13}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
              }}
            >
              <Typography
                noWrap
                component="span"
                sx={{
                  fontSize: "1.38rem",
                  fontWeight: 700,
                }}
              >
                {translate("Asset Management")}
              </Typography>
              <Tooltip title={translate("Description:")} arrow>
                <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {!isWarehouseMetersStatusLoaded && (
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress />
              </Box>
            )}
            <StatusPieChart
              translate={translate}
              language={language}
              chartConfig={{
                dataset: warehouseMetersStatusItems,
                title: translate("Meters Overview"),
              }}
            />
          </Grid>
          <Grid
            item
            md={1}
            lg={1}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              width: "1rem",
              justifyContent: "center",
            }}
          >
            <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "fnone",
              },
              justifyContent: "center",
            }}
          >
            <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {!isWarehouseDevicesStatusLoaded && (
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress />
              </Box>
            )}
            <StatusPieChart
              translate={translate}
              language={language}
              chartConfig={{
                dataset: warehouseDevicesStatusItems,
                title: translate("Device Overview"),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
