import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MuiPagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import {
  DataGridPro,
  GridPagination,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import React, { memo, useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import Cookies from "universal-cookie";
import { config } from "../../config/config";

import { AddressFilter } from "../common/AddressFilter";
import { useAppStore } from "../../store/store";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "left-start",
        border: "1px solid  rgb(224, 224, 224)",
        borderRadius: "4px",
        padding: "0px",
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          p: "3px",
          flexGrow: 1,
        }}
      />
    </Box>
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <Box sx={{ flexGrow: 1, paddingBottom: "20px", display: "flex" }}>
        <QuickSearchToolbar />
        <GridToolbarFilterButton sx={{ color: "#212121", flex: "0 0 auto", margin: "0px 1rem" }} />
      </Box>
    </GridToolbarContainer>
  );
}

const groupingColDef = {
  field: "hierarchy",
  headerName: "Code",
  minWidth: 150,
  valueGetter: (params) => {
    if (params.row.nodeType == 3) {
      return params.row.buildingName;
    } else {
      return params.row.consumptionPointName;
    }
  },
};

const getTreeDataPath = (row) => row.hierarchy;

interface BuildingsTableV1Props {
  translate: (params: string) => string;
  language: string;
  showAppartments: boolean;
  globalRefresh: boolean;
  onChange: (params: any) => void;
}

const BuildingsTableV1 = memo(function BuildingsTableV1({
  translate,
  language,
  showAppartments,
  onChange,
}: BuildingsTableV1Props) {
  const { appConfig } = useAppStore();
  const cookies = new Cookies();

  const [buildingsItems, setBuildingsItems] = useState<any>([]);
  const [buildingsFilter, setBuildingsFilter] = useState<any>({});

  const [rowModesModel, setRowModesModel] = useState({});
  const [gridColumns, setGridColumns] = useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    if (onChange !== undefined) {
      if (newRowSelectionModel[0] !== undefined) {
        const ids = newRowSelectionModel[0].split("_");
        onChange({
          idBuilding: ids[0],
          idConsumptionPoint: ids[1],
          nodeType: ids[1] === "" ? "3" : "2",
        });
      }
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleAddressChange = (value) => {
    if (!isEqual(buildingsFilter, value)) {
      setBuildingsFilter(value);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setBuildingsItems(buildingsItems.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.2,
      valueGetter: (params) => {
        const nume =
          params.row.nodeType === 3 ? params.row.buildingName : params.row.consumptionPointName;
        return nume;
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "consumptionPointsNo",
      headerName: "Consumption Poinst No",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "metersNo",
      headerName: "Meters No",
      minWidth: 150,
      flex: 0.2,
    },
  ];

  const tid = cookies.get("loginTenant") ?? appConfig?.tid;
  const uid = cookies.get("loginUserId") ?? appConfig?.uid;
  const mid = cookies.get("mid") ?? appConfig?.mid;

  useEffect(() => {
    const tmpcolumns = columns.map((col) => {
      col.headerName = translate(col.headerName);
      return col;
    });

    setGridColumns(tmpcolumns);
  }, [language]);

  useEffect(() => {
    const rids = buildingsFilter.regions ?? "";
    const cids = buildingsFilter.cities ?? "";
    const czids = buildingsFilter.cityZones ?? "";

    fetch(
      apiUrl + "GetBuildings?regionIds=" + rids + "&cityIds=" + cids + "&cityZoneIds=" + czids,
      {
        mode: "cors",
        headers: {
          tid: tid,
          uid: uid,
          mid: mid,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result != undefined && result.length > 0) {
            const dstmp = result.flatMap((item) => {
              if (showAppartments === false && item.nodeType == 2) return [];

              item.id =
                item.idBuilding + "_" + (item.idPunctConsum == null ? "" : item.idPunctConsum);
              item.hierarchy =
                item.nodeType == 3 ? [item.idBuilding] : [item.idBuilding, item.idPunctConsum];
              return item;
            });
            setBuildingsItems(dstmp);
          } else setBuildingsItems([]);
        },
        () => {
          setBuildingsItems([]);
        }
      );
  }, [buildingsFilter]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
              flex: "0 1 auto",
            }}
          >
            {translate("Buildings")}
          </Typography>
          <Box sx={{ p: 0, m: 0, flex: "0 1 auto" }}>
            <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
          </Box>
          <Box sx={{ flex: "1 0 auto" }}>
            <AddressFilter translate={translate} onChange={handleAddressChange} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1.5rem",
          }}
        >
          <DataGridPro
            treeData
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            rows={buildingsItems}
            editMode="row"
            columns={gridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            slots={{
              toolbar: (props) => CustomToolbar(props),
              pagination: CustomPagination,
            }}
            sx={{
              backgroundColor: "white",
              height: "500px",
              border: "none",
              "& .MuiTablePagination-spacer": { flex: "0" },
              "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
              "& .MuiInputBase-root.MuiInput-root:before": {
                borderBottom: "none",
              },
              "& .MuiTablePagination-root": { width: "100%" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-selectedRowCount": {
                flex: "1 0 auto",
              },
            }}
            localeText={{
              toolbarFilters: translate("Filter List"),
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
});

export { BuildingsTableV1 };
