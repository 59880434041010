import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import MuiPagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  DataGridPro,
  GridPagination,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useContext, useEffect, useState } from "react";
import isEqual from "react-fast-compare";

import { AddressFilter } from "../common/AddressFilter";
import { CustomDropDown } from "../common/CustomDropDown";
import { EMS_API } from "../../api/emsapi";
import { COMMON_API } from "../../api/commonapi";
import { EnergyManagementContext } from "../ethoutcomes/EnergyManagement/EnergyManagementContext";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        flex: "1 0 auto",
        display: "flex",
        justifyContent: "left-start",
        border: "1px solid  rgb(224, 224, 224)",
        borderRadius: "4px",
        padding: "0px",
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          p: "3px",
        }}
      />
    </Box>
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar(
  props,
  addBuildingText,
  addAppartmentText,
  handleAddBuilding,
  showAppartments
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick1 = () => {
    //if (buildingsItems.find(i => i.id == -1) == undefined) {
    //    const id = -1;
    //    setBuildingsItems((oldRows) => [...oldRows, { id: id, isNew: true }]);
    //    setRowModesModel((oldModel) => ({
    //        ...oldModel,
    //        [id]: { mode: GridRowModes.Edit },
    //    }));
    //}
  };

  return (
    <GridToolbarContainer {...props}>
      <Box
        sx={{
          flexGrow: 1,
          paddingBottom: "20px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {/*<Button*/}
        {/*  endIcon={<AddIcon />}*/}
        {/*  onClick={handleAddBuilding}*/}
        {/*  sx={{*/}
        {/*    maxHeight: "40px",*/}
        {/*    backgroundColor: "#0051C1",*/}
        {/*    color: "white",*/}
        {/*    minWidth: "200px",*/}
        {/*    borderRadius: "7px",*/}
        {/*    marginRight: showAppartments ? "0px" : "10px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {addBuildingText}*/}
        {/*</Button>*/}
        {/*{showAppartments && (*/}
        {/*  <Button*/}
        {/*    endIcon={<AddIcon />}*/}
        {/*    onClick={handleClick1}*/}
        {/*    sx={{*/}
        {/*      maxHeight: "40px",*/}
        {/*      backgroundColor: "#0051C1",*/}
        {/*      color: "white",*/}
        {/*      minWidth: "200px",*/}
        {/*      borderRadius: "7px",*/}
        {/*      margin: "0px 10px",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {addAppartmentText}*/}
        {/*  </Button>*/}
        {/*)}*/}
        <QuickSearchToolbar />
        <GridToolbarFilterButton sx={{ color: "#212121", flex: "0 0 auto", margin: "0px 1rem" }} />
      </Box>
    </GridToolbarContainer>
  );
}

// function SelectEditInputCell(props) {
//   const { id, value, field, translate } = props;
//   const apiRef = useGridApiContext();

//   const handleChange = async (event) => {
//     await apiRef.current.setEditCellValue({
//       id,
//       field,
//       value: event.target.value,
//     });
//     apiRef.current.stopCellEditMode({ id, field });
//   };

//   return (
//     <Select
//       value={value}
//       onChange={handleChange}
//       size="small"
//       sx={{ height: 1, flexGrow: 1 }}
//       native
//       autoFocus
//     >
//       <option value="0">{translate("instant email")}</option>
//       <option value="1">{translate("hourly")}</option>
//       <option value="2">{translate("on each 2 hours")}</option>
//       <option value="3">{translate("on each 3 hours")}</option>
//       <option value="4">{translate("on each 4 hours")}</option>
//       <option value="6">{translate("on each 6 hours")}</option>
//       <option value="8">{translate("on each 8 hours")}</option>
//       <option value="12">{translate("on each 12 hours")}</option>
//       <option value="24">{translate("daily")}</option>
//     </Select>
//   );
// }

// const renderSelectEditInputCell = (params, translate) => {
//   return <SelectEditInputCell {...params} translate={translate} />;
// };

const groupingColDef = {
  field: "hierarchy",
  headerName: "Code",
  minWidth: 150,
  valueGetter: (params) => {
    if (params.row.nodeType == 3) {
      return params.row.buildingName;
    } else {
      return params.row.consumptionPointName;
    }
  },
};

const getTreeDataPath = (row) => row.hierarchy;

interface BuildingsTableProps {
  translate: (params: string) => string;
  language: string;
  showAppartments?: boolean;
  globalRefresh: boolean;
  onFilterChange: (params: any) => void;
  onGeneralError: (error: any) => void;
}

const BuildingsTable = memo(function BuildingsTable({
  translate,
  language,
  showAppartments,
  onFilterChange,
  onGeneralError,
}: BuildingsTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { changeNode } = useContext<any>(EnergyManagementContext);

  const [buildingTreeData, setBuildingTreeData] = useState<any>([]);
  const [buildingsFilter, setBuildingsFilter] = useState<any>({});

  const [newBuildingName, setNewBuildingName] = useState("");
  const [newZipCode, setNewZipCode] = useState("");
  const [newBuildingCode, setNewBuildingCode] = useState("");
  const [newBuildingCode1, setNewBuildingCode1] = useState("");
  const [newStreet, setNewStreet] = useState("");
  const [newStreetNumber, setNewStreetNumber] = useState("");
  const [newBuildingType, setNewBuildingType] = useState("");
  const [newComments, setNewComments] = useState("");

  const [isRegionsLoaded, setIsRegionsLoaded] = useState(false);
  const [isCitiesLoaded, setIsCitiesLoaded] = useState(false);
  const [isCityZonesLoaded, setIsCityZonesLoaded] = useState(false);
  const [regionsItems, setRegionsItems] = useState<any>([]);
  const [citiesItems, setCitiesItems] = useState<any>([]);
  const [cityZonesItems, setCityZonesItems] = useState<any>([]);

  const [regionsValues, setRegionsValues] = useState("");
  const [citiesValues, setCitiesValues] = useState("");
  // const [cityZonesValues, setCityZonesValues] = useState("");

  const [rowModesModel, setRowModesModel] = useState({});
  const [gridColumns, setGridColumns] = useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);

  const [openAddBuilding, setOpenAddBuilding] = useState(false);

  const handleRegionValueChange = (value) => {
    setRegionsValues(value);
  };

  const handleCitiesValueChange = (value) => {
    setCitiesValues(value);
  };

  // const handleCityZonesValueChange = (value) => {
  //   setCityZonesValues(value);
  // };

  const handleAddBuildingClose = () => {
    setOpenAddBuilding(false);
  };

  const handleAddBuilding = () => {
    setOpenAddBuilding(true);
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    if (!isEqual(rowSelectionModel, newRowSelectionModel)) {
      setRowSelectionModel(newRowSelectionModel);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // const handleEditClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleAlarmsClick = (id) => () => {
  //   //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };

  // const handleDeleteClick = (id) => () => {
  //   setBuildingsItems(buildingsItems.filter((row) => row.id !== id));
  // };

  // const handleCancelClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });

  //   setBuildingsItems(buildingsItems);
  // };

  const handleAddressChange = (value) => {
    //console.log("buildingsFilter", buildingsFilter);
    setBuildingsFilter(value);
    onFilterChange(value);
  };

  // const processRowUpdate = (newRow) => {
  //   const updatedRow = { ...newRow, isNew: false };
  //   setBuildings(buildings.map((row) => (row.id === newRow.id ? updatedRow : row)));
  //   return updatedRow;
  // };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.2,
      valueGetter: (params) => {
        const nume =
          params.row.nodeType === 3 ? params.row.buildingName : params.row.consumptionPointName;
        return nume;
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "consumptionPointsNo",
      headerName: "Consumption Poinst No",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "metersNo",
      headerName: "Meters No",
      minWidth: 150,
      flex: 0.2,
    },
  ];

  useEffect(() => {
    if (changeNode !== undefined) {
      if (rowSelectionModel[0] !== undefined) {
        const ids = rowSelectionModel[0].split("_");
        changeNode({
          idBuilding: ids[0],
          idConsumptionPoint: ids[1],
          nodeType: ids[1] === "" ? "3" : "2",
        });
      } else {
        changeNode({
          idBuilding: 0,
          idConsumptionPoint: 0,
          nodeType: "3",
        });
      }
    }
  }, [rowSelectionModel]);

  useEffect(() => {
    const tmpcolumns = columns.map((col) => {
      col.headerName = translate(col.headerName);
      return col;
    });

    setGridColumns(tmpcolumns);
  }, [language]);

  useEffect(() => {
    const rids = buildingsFilter.regions ?? "";
    const cids = buildingsFilter.cities ?? "";
    const czids = buildingsFilter.cityZones ?? "";

    EMS_API.GetBuildings(rids, cids, czids).then(
      (result) => {
        if (result != undefined && result.length > 0) {
          const dstmp = result.flatMap((item) => {
            if (showAppartments === false && item.nodeType == 2) return [];

            item.id =
              item.idBuilding + "_" + (item.idPunctConsum == null ? "" : item.idPunctConsum);
            item.hierarchy =
              item.nodeType == 3 ? [item.idBuilding] : [item.idBuilding, item.idPunctConsum];
            return item;
          });
          setBuildingTreeData(dstmp);
          setRowSelectionModel([dstmp[0].id]);
        } else {
          setBuildingTreeData([]);
          setRowSelectionModel([]);
        }
      },
      (error) => {
        setBuildingTreeData([]);
        setRowSelectionModel([]);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [buildingsFilter]);

  useEffect(() => {
    setIsCitiesLoaded(false);
    if (regionsValues != "" && regionsValues != undefined) {
      COMMON_API.GetCatalog("Cities").then(
        (result) => {
          if (result.length > 0) {
            const cities = result.map((reg: any) => {
              reg.id = reg.code;
              reg.value = reg.code;
              reg.text = reg.name;
              return reg;
            });
            setCitiesItems(cities);
          } else {
            setCitiesItems([]);
            setCityZonesItems([]);
            setCitiesValues("");
            // setCityZonesValues("");
          }
          setIsCitiesLoaded(true);
        },
        (error) => {
          setCitiesItems([]);
          setCityZonesItems([]);
          setCitiesValues("");
          // setCityZonesValues("");
          setIsCitiesLoaded(true);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    } else {
      setCitiesItems([]);
      setCityZonesItems([]);
      setCitiesValues("");
      // setCityZonesValues("");
      setIsCitiesLoaded(true);
    }
  }, [regionsValues]);

  useEffect(() => {
    setIsCityZonesLoaded(false);
    if (citiesValues != "" && citiesValues != undefined) {
      COMMON_API.GetCatalog("CityZones").then(
        (result) => {
          if (result.length > 0) {
            const cityzones = result.map((reg: any) => {
              reg.id = reg.code;
              reg.value = reg.code;
              reg.text = reg.name;
              return reg;
            });
            setCityZonesItems(cityzones);
          } else {
            setCityZonesItems([]);
            // setCityZonesValues("");
          }
          setIsCityZonesLoaded(true);
        },
        (error) => {
          setCityZonesItems([]);
          // setCityZonesValues("");
          setIsCityZonesLoaded(true);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    } else {
      setCityZonesItems([]);
      // setCityZonesValues("");
      setIsCityZonesLoaded(true);
    }
  }, [citiesValues]);

  useEffect(() => {
    if (openAddBuilding) {
      setIsRegionsLoaded(false);

      COMMON_API.GetCatalog("Regions").then(
        (result) => {
          if (result.length > 0) {
            const regionsitems = result.map((reg: any) => {
              reg.id = reg.code;
              reg.value = reg.code;
              reg.text = reg.name;
              return reg;
            });
            setRegionsItems(regionsitems);
          } else {
            setRegionsItems([]);
          }
          setIsRegionsLoaded(true);
        },
        (error) => {
          setRegionsItems([]);
          setIsRegionsLoaded(true);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    }
  }, [openAddBuilding]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingBottom: "10px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{
              flex: isMobile ? "1" : "0 1 auto",
              display: "flex",
              justifyContent: "flex-start",
              paddingTop: "10px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              noWrap
              component="span"
              sx={{
                fontSize: "1.38rem",
                fontWeight: 700,
                flex: "0 1 auto",
              }}
            >
              {translate("Buildings")}
            </Typography>
            <Box sx={{ p: 0, m: 0, flex: "0 1 auto" }}>
              <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
            </Box>
          </Box>

          <Box sx={{ flex: isMobile ? "1" : "1 0 auto", width: isMobile ? "100%" : "auto" }}>
            <AddressFilter translate={translate} onChange={handleAddressChange} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1.5rem",
          }}
        >
          <DataGridPro
            treeData
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            rows={buildingTreeData}
            editMode="row"
            columns={gridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            //rowModesModel={rowModesModel}
            //onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            // processRowUpdate={processRowUpdate}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            slots={{
              toolbar: (props) =>
                CustomToolbar(
                  props,
                  translate("Add Building"),
                  translate("Add Appartment"),
                  handleAddBuilding,
                  showAppartments ?? true
                ),
              pagination: CustomPagination,
            }}
            sx={{
              maxWidth: { xs: 250, sm: 570, md: "100%", lg: "100%", xl: "100%" },
              backgroundColor: "white",
              height: "500px",
              border: "none",
              "& .MuiTablePagination-spacer": { flex: "0" },
              "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
              "& .MuiInputBase-root.MuiInput-root:before": {
                borderBottom: "none",
              },
              "& .MuiTablePagination-root": { width: "100%" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-selectedRowCount": {
                flex: "1 0 auto",
              },
            }}
            localeText={{
              toolbarFilters: translate("Filter List"),
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
              },
            }}
          />
        </Box>
        <Dialog open={openAddBuilding} onClose={handleAddBuildingClose}>
          <DialogTitle>{translate("Add Building")}</DialogTitle>
          <IconButton
            onClick={handleAddBuildingClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  label={translate("Name")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newBuildingName}
                  variant="outlined"
                  onChange={(e) => {
                    setNewBuildingName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label={translate("Zip Code")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newZipCode}
                  variant="outlined"
                  onChange={(e) => {
                    setNewZipCode(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label={translate("Building Code")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newBuildingCode}
                  variant="outlined"
                  onChange={(e) => {
                    setNewBuildingCode(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label={translate("Building Code (additional)")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newBuildingCode1}
                  variant="outlined"
                  onChange={(e) => {
                    setNewBuildingCode1(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomDropDown
                  translate={translate}
                  dataSet={regionsItems}
                  dropDownLabel={"Region"}
                  onValueChange={handleRegionValueChange}
                />
                {!isRegionsLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomDropDown
                  translate={translate}
                  dataSet={citiesItems}
                  dropDownLabel={"Cities"}
                  onValueChange={handleCitiesValueChange}
                />
                {!isCitiesLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomDropDown
                  translate={translate}
                  dataSet={cityZonesItems}
                  dropDownLabel={"City Zones"}
                />
                {!isCityZonesLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  label={translate("Street")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newStreet}
                  variant="outlined"
                  onChange={(e) => {
                    setNewStreet(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label={translate("Street Number")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newStreetNumber}
                  variant="outlined"
                  onChange={(e) => {
                    setNewStreetNumber(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label={translate("Building Type")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  value={newBuildingType}
                  variant="outlined"
                  onChange={(e) => {
                    setNewBuildingType(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={translate("Comments")}
                  sx={{ display: "flex", flexGrow: 1 }}
                  size="small"
                  multiline
                  rows={4}
                  value={newComments}
                  variant="outlined"
                  onChange={(e) => {
                    setNewComments(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleAddBuildingClose}>
              {translate("Close")}
            </Button>
            <Button variant="contained" onClick={handleAddBuildingClose}>
              {translate("Add Building")}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
});

export { BuildingsTable };
