import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { LineChart, axisClasses, useDrawingArea } from "@mui/x-charts";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { memo, useState } from "react";

import { CustomSimpleGrid } from "../common/CustomSimpleGrid";
import { GetFirstDateOfWeek } from "../utils/utils";

const chartSetting = {
  legend: { hidden: true },
};

const viewbylist = [
  {
    text: "Week",
    id: 1,
  },
  {
    text: "Month",
    id: 2,
  },
  {
    text: "Year",
    id: 3,
  },
];

const typelist = [
  {
    text: "Chart",
    id: 1,
  },
  {
    text: "Table",
    id: 2,
  },
];

const monthslist = [
  {
    text: "January",
    id: 1,
  },
  {
    text: "February",
    id: 2,
  },
  {
    text: "March",
    id: 3,
  },
  {
    text: "April",
    id: 4,
  },
  {
    text: "May",
    id: 5,
  },
  {
    text: "June",
    id: 6,
  },
  {
    text: "July",
    id: 7,
  },
  {
    text: "August",
    id: 8,
  },
  {
    text: "September",
    id: 9,
  },
  {
    text: "October",
    id: 10,
  },
  {
    text: "November",
    id: 11,
  },
  {
    text: "December",
    id: 12,
  },
];

const StyledTickLine = styled("line")(({ x1, y1 }) => ({
  //stroke: theme.palette.text[color],
  stroke: "#757575",
  strokeOpacity: "0.3",
  strokeDasharray: "3px",
  shapeRendering: "crispEdges",
  strokeWidth: 1,
  x1: x1,
  y1: y1,
}));

function CustomXYAxisTick(props) {
  const { width, height } = useDrawingArea();
  const { x2, y2 } = props;

  return (
    <StyledTickLine
      {...props}
      x1={x2 == undefined ? null : width}
      y1={y2 == undefined ? null : -1 * height}
    />
  );
}

// const defaultValueFormatter = (value) => `${value}`;

interface EmsEfficiencyChartProps {
  translate: (params: string) => string;
  language: string;
  chartConfig: any;
  viewByMode: number;
}

const EmsEfficiencyChart = memo(function EmsEfficiencyChart({
  translate,
  chartConfig,
  viewByMode,
}: EmsEfficiencyChartProps) {
  const serieId =
    chartConfig.series.length > 0 ? chartConfig.series[0].id ?? "seriesId" : "seriesId";

  const useGetFirstDateOfWeek = GetFirstDateOfWeek;

  const [viewby, setViewby] = useState(viewByMode);

  const currentDate = new Date();

  const firstdayofcurrentweek = useGetFirstDateOfWeek(currentDate);
  const lastdayofcurrentweek = new Date(
    firstdayofcurrentweek.getFullYear(),
    firstdayofcurrentweek.getMonth(),
    firstdayofcurrentweek.getDate() + 6
  );

  const [type, setType] = useState(1);

  const [month, setMonth] = useState(currentDate.getMonth() + 1);

  const [year, setYear] = useState(dayjs(currentDate));

  const [weekvalue, setWeekvalue] = useState<any>([
    dayjs(firstdayofcurrentweek),
    dayjs(lastdayofcurrentweek),
  ]);

  const [dataView, setDataView] = React.useState({
    solar: true,
    production: false,
    distribution: false,
  });

  const handleChangeViewby = (event) => {
    setViewby(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (value) => {
    setYear(dayjs(value));
  };

  const handleChangeWeekValue = (value) => {
    setWeekvalue(value);
  };

  const handleDataViewChange = (event) => {
    setDataView({
      ...dataView,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={2} sx={{ width: "100%", margin: "0 !important" }}>
      <Grid item xs={12} md={12} sx={{ width: "100%", paddingTop: "0px !important" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
            paddingTop: "0px",
          }}
        >
          <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
            <Box sx={{ flex: "1 0 auto" }}>
              <InputLabel id="viewby-label" disableAnimation={true}>
                {translate("View by")}
              </InputLabel>
              <Select
                labelId="viewby-label"
                id="viewby-select"
                value={viewby}
                onChange={handleChangeViewby}
                sx={{ width: "100%" }}
                size="small"
              >
                {viewbylist.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {translate(item.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ flex: "1 0 auto" }}>
              {viewby == 1 && (
                <>
                  <InputLabel id="interval-label" disableAnimation={true}>
                    {translate("Select week")}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      slots={{ field: SingleInputDateRangeField }}
                      sx={{ width: "100%" }}
                      displayWeekNumber={true}
                      format={"MM/DD"}
                      value={weekvalue}
                      onAccept={handleChangeWeekValue}
                    />
                  </LocalizationProvider>
                </>
              )}
              {viewby == 2 && (
                <>
                  <InputLabel id="month-label" disableAnimation={true}>
                    {translate("Select month")}
                  </InputLabel>
                  <Select
                    labelId="month-label"
                    id="month-select"
                    value={month}
                    onChange={handleChangeMonth}
                    sx={{ width: "100%" }}
                    size="small"
                  >
                    {monthslist.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {translate(item.text)}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {viewby == 3 && (
                <>
                  <InputLabel id="year-label" disableAnimation={true}>
                    {translate("Select year")}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      sx={{ width: "100%" }}
                      format={"YYYY"}
                      value={year}
                      onAccept={handleChangeYear}
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
            <Box sx={{ flex: "1 0 auto" }}>
              <InputLabel id="type-label" disableAnimation={true}>
                {translate("Type")}
              </InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                value={type}
                onChange={handleChangeType}
                sx={{ width: "100%" }}
                size="small"
              >
                {typelist.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {translate(item.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ flex: "1 0 auto", display: "flex", justifyContent: "end" }}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup sx={{ flexDirection: "row", flexGrow: 1, alignItems: "end" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataView.solar}
                        onChange={handleDataViewChange}
                        name="solar"
                      />
                    }
                    label={translate("Solar Coverage")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataView.production}
                        onChange={handleDataViewChange}
                        name="production"
                      />
                    }
                    label={translate("Production Efficiency")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataView.distribution}
                        onChange={handleDataViewChange}
                        name="distribution"
                      />
                    }
                    label={translate("Distribution Efficiency")}
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          {type === 1 && (
            <LineChart
              sx={{
                "& .MuiLineElement-root": {
                  strokeWidth: 2,
                  stroke: chartConfig.lineColor,
                },
                ["& .MuiAreaElement-series-" + serieId]: {
                  fill: "url('#customAreaChartGradient')",
                },
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translate(0px, -25px)",
                },
                [`.${axisClasses.right} .${axisClasses.label}`]: {
                  transform: "translate(5px, 30)",
                },
              }}
              margin={{ top: 30, left: 80, right: 30, bottom: 30 }}
              xAxis={chartConfig.xAxis}
              yAxis={chartConfig.yAxis}
              series={chartConfig.series}
              height={chartConfig.height ?? 300}
              slots={{
                axisTick: (props) => CustomXYAxisTick(props),
              }}
              {...chartSetting}
            >
              {chartConfig.gradientColors != undefined &&
                chartConfig.gradientColors.length === 2 && (
                  <defs>
                    <linearGradient
                      id="customAreaChartGradient"
                      gradientTransform="rotate(90)"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="6%" stopColor={chartConfig.gradientColors[0]} />
                      <stop offset="48%" stopColor={chartConfig.gradientColors[1]} />
                    </linearGradient>
                  </defs>
                )}
            </LineChart>
          )}
          {type === 2 && (
            <CustomSimpleGrid
              dataSet={chartConfig.dataset}
              columns={chartConfig.tableColumns}
              maxHeight={350}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
});

export { EmsEfficiencyChart };
