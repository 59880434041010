import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { useState } from "react";

import { IconConverter } from "../utils/utils";
import { BasePropsType } from "../../types/common/EmmsysTypes";

interface MobileNavigationBarProps extends Pick<BasePropsType, "translate"> {
  menuItems: any;
  sidebarSelectedItem: any;
  handleExpandClick: React.MouseEventHandler;
  handleDrawerClose: React.MouseEventHandler;
  sidebarExpandedItems: Array<any>;
  installationType: string;
}

export const MobileNavigationBar = (props: MobileNavigationBarProps) => {
  const {
    translate,
    menuItems,
    sidebarSelectedItem,
    handleExpandClick,
    handleDrawerClose,
    sidebarExpandedItems,
    installationType,
  } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openmobilemenu = Boolean(anchorMenuEl);

  const handleDrawerToggle = (event) => {
    setMobileOpen(!mobileOpen);
    setAnchorMenuEl(event.currentTarget);
  };

  const handleNotifMenuClose = () => {
    setAnchorMenuEl(null);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="mobile-menu"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorMenuEl}
        id="mobile-menu"
        open={openmobilemenu}
        onClose={handleNotifMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 5,
            ml: -2,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <List
          disablePadding
          id="ml1"
          sx={{
            padding: "0px 10px",
            "& .MuiListItemButton-root.Mui-selected": {
              backgroundColor: "#0051C1 !important",
              borderRadius: "1.2rem",
              color: "white",
            },
          }}
        >
          {menuItems != undefined &&
            menuItems.map((item, index) => {
              const isSelected =
                sidebarSelectedItem.id === item.id || sidebarSelectedItem.parentId === item.id;
              const isExpanded =
                sidebarExpandedItems.find((sidebarItem) => sidebarItem.id === item.id) !==
                undefined;

              if (item.type === "header") {
                return (
                  <ListSubheader
                    key={index}
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5,
                      display: "flex",
                      alignItems: "center",
                      margin: "1rem 0px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                      onClick={handleDrawerClose}
                    >
                      <img src="/img/itron1.png" height="24px" alt="missing" />
                    </ListItemIcon>
                  </ListSubheader>
                );
              } else {
                if (item.type === "divider") {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        display: "block",
                      }}
                    >
                      <Divider sx={{ margin: "5px 0px" }} />
                    </ListItem>
                  );
                } else {
                  if (item.installationType && item.installationType !== installationType)
                    return null;

                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        display: "block",
                        border: isExpanded && isSelected ? "1px solid #0051C1" : "",
                        borderRadius: isExpanded && isSelected ? "1.2rem" : "",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: "initial",
                          px: 2.5,
                        }}
                        onClick={() => {
                          handleExpandClick(item.id);
                          if (!item.items) setAnchorMenuEl(null);
                        }}
                        selected={isSelected}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: "center",
                          }}
                        >
                          {item.icon != "" && (
                            <IconConverter name={isSelected ? item.iconselected : item.icon} />
                          )}
                        </ListItemIcon>

                        <ListItemText primary={translate(item.text)} />
                        {!item.items ? "" : isExpanded ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      {item.items != null && (
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <List disablePadding sx={{ margin: "0px 20px" }}>
                            {item.items.map((subitem, index) => {
                              const isSelected = sidebarSelectedItem.id === subitem.id;
                              return (
                                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                                  <ListItemButton
                                    sx={{
                                      justifyContent: "initial",
                                      px: 2.5,
                                    }}
                                    onClick={() => {
                                      handleExpandClick(subitem.id);
                                      if (!subitem.items) setAnchorMenuEl(null);
                                    }}
                                  >
                                    <ListItemText
                                      primary={translate(subitem.text)}
                                      sx={{
                                        margin: "0px 10px",
                                        "& .MuiTypography-root": {
                                          fontWeight: isSelected ? 700 : 400,
                                        },
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Collapse>
                      )}
                    </ListItem>
                  );
                }
              }
            })}
        </List>
      </Menu>
    </>
  );
};
