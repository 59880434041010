import SearchIcon from "@mui/icons-material/Search";
// import SettingsIcon from "@mui/icons-material/Settings";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { createContext, memo, useEffect, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import Cookies from "universal-cookie";
import { config } from "../../config/config";

import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";
import { useAppStore } from "../../store/store";
import { BasePropsType } from "../../types/common/EmmsysTypes";

const LISTBOX_PADDING = 8; // px

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];

  if (dataSet[1].text == undefined) return "";

  const { inputValue } = dataSet[3];

  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  const matches = match(dataSet[1].text, inputValue, { insideWords: true });
  const parts = parse(dataSet[1].text, matches);

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].type === "building" && <ApartmentIcon />}
      {dataSet[1].type === "apart" && <HomeIcon />}
      <div>
        {parts.map((part, index) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400,
              backgroundColor: part.highlight ? "orange" : "inherit",
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </Typography>
  );
}

const OuterElementContext = createContext({});

// TODO: Fix eslint issue
// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

interface ListboxComponentProps {
  children?: any;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  { children, ...other }: ListboxComponentProps,
  ref
) {
  const itemData: any = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    // TODO: Fix eslint issue
    // eslint-disable-next-line no-prototype-builtins
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <Box ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});

//ListboxComponent.propTypes = {
//    children: PropTypes.node
//};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

interface CustomSearchBarProps extends Pick<BasePropsType, "translate"> {
  onValueChange: (params: any) => void;
}

const CustomSearchBar = memo(function CustomSearchBar(props: CustomSearchBarProps) {
  const { translate, onValueChange } = props;
  const { appConfig } = useAppStore();
  const cookies = new Cookies();
  const [searchItems, setSearchItems] = useState<any>(null);
  // const [apiError, setApiError] = useState(null);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const opensettingsmenu = Boolean(anchorEl);

  const [inputValue, setInputValue] = useState("");

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleValueChange = (event, value) => {
    if (value != "" && value != undefined) {
      const optionvalue: any = [];
      optionvalue.push(value.id);
      if (value.tag0 != null && value.tag0 != "") optionvalue.push(parseInt(value.tag0));
      onValueChange(optionvalue);
    } else {
      onValueChange(null);
    }
  };

  useEffect(() => {
    const tid = cookies.get("loginTenant") ?? appConfig?.tid;
    const uid = cookies.get("loginUserId") ?? appConfig?.uid;
    const mid = cookies.get("mid") ?? appConfig?.mid;

    fetch(apiUrl + "GetSearchResult?searchBy=buildings", {
      mode: "cors",
      headers: {
        tid: tid,
        uid: uid,
        mid: mid,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result != undefined && result.length > 0) {
            const dstmp = result.flatMap((item) => {
              item.text = item.name + " - " + item.description;
              return item;
            });
            setSearchItems(dstmp);
          } else setSearchItems([]);
        },
        () => {
          setSearchItems([]);
        }
      );
  }, []);

  return (
    <Autocomplete
      sx={{ backgroundColor: "white", flex: 1 }}
      size="small"
      fullWidth={true}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      onChange={handleValueChange}
      options={searchItems || []}
      getOptionLabel={(option) => option.text}
      renderOption={(props, option, state) => [props, option, state.index, { inputValue }]}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={inputValue}
            placeholder={translate("Search") + " " + translate("Buildings")}
            onChange={(ev) => {
              setInputValue(ev.target.value);
            }}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment position="end">
              //     <IconButton
              //       onClick={handleClick}
              //       size="small"
              //       sx={{ ml: 2, borderRadius: "unset" }}
              //       aria-controls={opensettingsmenu ? "searchsettings-menu" : undefined}
              //       aria-haspopup="true"
              //       aria-expanded={opensettingsmenu ? "true" : undefined}
              //     >
              //       <SettingsIcon />
              //     </IconButton>
              //   </InputAdornment>
              // ),
            }}
          />
        );
      }}
    />
  );
});

export { CustomSearchBar };
