import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import * as L from "leaflet";
import { Icon } from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { memo, useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, Tooltip, useMap } from "react-leaflet";

import { CustomClusterIcon } from "../utils/utils";
import { BasePropsType } from "../../types/common/EmmsysTypes";
import { MAP_API } from "../../api/mapapi";
import isEqual from "react-fast-compare";

const myIcon = new Icon({
  iconUrl: markerIconPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const InitMapCenter = (props) => {
  const map = useMap();
  const { coord } = props;

  useEffect(() => {
    if (coord.length === 2) map.panTo({ lat: coord[0], lng: coord[1] });
  }, [coord]);
  return null;
};

function MapPins(props: Pick<BasePropsType, "translate">) {
  const { translate } = props;
  const [mapPins, setMapPins] = useState<any>([]);
  const map = useMap();

  const [svnParams, setSvnParams] = useState<any>([]);

  function mapMoveEndEvent() {
    let newCoord = {
      topLeftLat: map.getBounds().getNorth(),
      topLeftLong: map.getBounds().getWest(),
      bottomRightLat: map.getBounds().getSouth(),
      bottomRightLong: map.getBounds().getEast(),
      zoomLevel: Math.round(map.getZoom()),
      width: map.getSize().x,
      height: map.getSize().y,
    };

    if (!isEqual(svnParams, newCoord)) {
      setSvnParams(newCoord);
    }
  }

  useEffect(() => {
    MAP_API.GetMapPinsV2(
      svnParams.topLeftLat,
      svnParams.topLeftLong,
      svnParams.bottomRightLat,
      svnParams.bottomRightLong,
      svnParams.zoomLevel,
      svnParams.width,
      svnParams.height
    ).then(
      (mappins) => {
        setMapPins(mappins);
      },
      () => {
        setMapPins([]);
      }
    );
  }, [svnParams]);

  useEffect(() => {
    map.on("moveend", mapMoveEndEvent);

    return () => {
      map.off("moveend", mapMoveEndEvent);
    };
  }, []);

  return (
    <>
      {mapPins.flatMap((pin, index) => {
        if (pin.isCluster) {
          return (
            <Marker
              key={index}
              position={[pin.gpsLatitude, pin.gpsLongitude]}
              icon={CustomClusterIcon(pin.meters.length)}
            >
              <Tooltip>{pin.meters.length}</Tooltip>
            </Marker>
          );
        } else {
          return (
            <Marker key={index} position={[pin.gpsLatitude, pin.gpsLongitude]} icon={myIcon}>
              <Tooltip>
                <b>{pin.meterName}</b>
                <br />
                {translate("Last index:")}
                <br /> {pin.lastPrimaryIndex ?? ""} {pin.primaryIndexUnit ?? ""} /{" "}
                {dayjs(pin.lastPrimaryIndexDate).format("YYYY-MM-DD")}
                <br />
              </Tooltip>
            </Marker>
          );
        }
      })}
    </>
  );
}

interface LeafletMapProps extends Pick<BasePropsType, "translate"> {
  globalRefresh: boolean;
  mapFilters: any;
}

const LeafletMap = memo(function LeafletMap(props: LeafletMapProps) {
  const { translate, mapFilters } = props;
  const mapCenter = new L.LatLng(48.8307449, 2.2660506);
  const [initCenter, setInitCenter] = useState<any>([]);

  const [isMarkersLoaded, setIsMarkersLoaded] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);

  const rids = mapFilters.regions ?? null;
  const cids = mapFilters.cities ?? null;
  const czids = mapFilters.cityZones ?? null;

  const GestureHandlingSetter = () => {
    /* eslint-disable */
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addHandler("gestureHandling", GestureHandling);
    map.gestureHandlingOptions = {
      text: {
        touch: "Use two fingers to move the map",
        scroll: "Use ctrl + scroll to zoom the map",
        scrollMac: "Use \u2318 + scroll to zoom the map",
      },
      duration: 1500,
    };
    setInit(false);
    /* eslint-enable */
    return null;
  };

  useEffect(() => {
    setIsMarkersLoaded(false);

    MAP_API.GetMapPins(rids, cids, czids).then(
      (mapbounds) => {
        setIsMarkersLoaded(true);
        setInitCenter([mapbounds.latitude, mapbounds.longitude]);
      },
      () => {
        setIsMarkersLoaded(true);
      }
    );
  }, [mapFilters]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          {!isMarkersLoaded && (
            <CircularProgress
              sx={{
                width: "1.2rem !important",
                height: "1.2rem !important",
                margin: "6px",
              }}
            />
          )}
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
            }}
          >
            {translate("MAP")}
          </Typography>
          <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
        </Box>
        <Box sx={{ width: "100%", display: "flex", height: "400px" }}>
          <MapContainer center={mapCenter} zoom={10} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {init && <GestureHandlingSetter />}
            {initCenter.length > 0 && <InitMapCenter coord={initCenter} />}
            <MapPins translate={translate} />
          </MapContainer>
        </Box>
      </CardContent>
    </Card>
  );
});

export { LeafletMap };
