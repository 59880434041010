import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useNavigate } from "react-router-dom";

import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IconConverter } from "../utils/utils";
import { BasePropsType } from "../../types/common/EmmsysTypes";

const operationalvisibilityitems = [
  {
    name: "DMA Monitoring Global Losses",
    bgColor: "#1976D2",
    icon: "water_line",
  },
  {
    name: "Alarm & Event Management",
    bgColor: "#1976D2",
    icon: "notifications_line",
  },
];
const operationalefficiencyitems = [
  {
    name: "Replacement & Recommendations",
    bgColor: "#1976D2",
    icon: "replace_line",
  },
  {
    name: "Flow & Pressure Monitoring",
    bgColor: "#1976D2",
    icon: "meter_line",
  },
];
const hcoperationalvisibilityitems = [
  {
    name: "District Heating & Cooling",
    bgColor: "#D32F2F",
    icon: "temperature_line",
    route: "/outcomes/districtheatandcooling",
  },
  {
    name: "Energy Management",
    bgColor: "#D32F2F",
    icon: "energy_line",
    route: "/outcomes/energymanagement",
  },
  //{
  //  name: "Building Allocation",
  //  bgColor: "#D32F2F",
  //  icon: "chart_line",
  //  route: "/outcomes/energymanagement",
  //},
  //{
  //  name: "Consummer Awareness",
  //  bgColor: "#D32F2F",
  //  icon: "chart_line",
  //  route: "/outcomes/energymanagement",
  //},
];
const assetmanagementitems = [
  { name: "Meter Management", bgColor: "#F57C00", icon: "management_line" },
  { name: "Device Management", bgColor: "#F57C00", icon: "devices_line" },
];
const datavalidationitems = [
  {
    name: "Data Validation",
    bgColor: "#F57C00",
    icon: "data_validation_line",
  },
  /*{ name: "VEE", bgColor: "#F57C00", icon: "validation_line" },*/
];
const womanagementitems = [
  { name: "Field Service", bgColor: "#F57C00", icon: "engineer_line" },
  /*{ name: "Android App", bgColor: "#F57C00", icon: "android_line" },*/
];

interface HomeProps extends Pick<BasePropsType, "translate"> {
  installationType: string;
}

export default function Home(props: HomeProps) {
  const { translate, installationType } = props;

  const navigate = useNavigate();

  const handleMenuItemClick = (ev, route) => {
    if (route != null && route !== "") navigate(route);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {installationType === "water" && (
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <CardContent>
                <CardHeader
                  avatar={
                    <Avatar
                      variant="square"
                      sx={{ bgcolor: "#1976D2", width: "3px", height: "25px" }}
                    ></Avatar>
                  }
                  title={translate("Water Outcomes")}
                  sx={{
                    "& .MuiCardHeader-title": {
                      fontSize: "1rem",
                      fontWeight: 700,
                    },
                  }}
                />
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    borderTop: "1px solid lightgray",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      sx={{ fontSize: "0.75rem" }}
                    >
                      {translate("Operational Visibility")}
                    </ListSubheader>
                  }
                >
                  {operationalvisibilityitems.map((item, index) => (
                    <ListItemButton key={index}>
                      <Avatar
                        alt={item.name}
                        sx={{
                          backgroundColor: item.bgColor,
                          width: "32px",
                          height: "32px",
                        }}
                      >
                        <IconConverter name={item.icon} />
                      </Avatar>
                      <ListItemText
                        primary={translate(item.name)}
                        sx={{ margin: "0px 10px" }}
                        primaryTypographyProps={{ fontSize: "14px" }}
                      />
                    </ListItemButton>
                  ))}
                </List>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    borderTop: "1px solid lightgray",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader1"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader1"
                      sx={{ fontSize: "0.75rem" }}
                    >
                      {translate("Operational Efficiency")}
                    </ListSubheader>
                  }
                >
                  {operationalefficiencyitems.map((item, index) => (
                    <ListItemButton key={index}>
                      <Avatar
                        alt={item.name}
                        src={item.icon}
                        sx={{
                          backgroundColor: item.bgColor,
                          width: "32px",
                          height: "32px",
                        }}
                      >
                        <IconConverter name={item.icon} />
                      </Avatar>
                      <ListItemText
                        primary={translate(item.name)}
                        sx={{ margin: "0px 10px" }}
                        primaryTypographyProps={{ fontSize: "14px" }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <CardContent>
              <CardHeader
                avatar={
                  <Avatar
                    variant="square"
                    sx={{ bgcolor: "#D32F2F", width: "3px", height: "25px" }}
                  ></Avatar>
                }
                title={translate("Heating & Cooling Outcomes")}
                sx={{
                  "& .MuiCardHeader-title": {
                    fontSize: "1rem",
                    fontWeight: 700,
                  },
                }}
              />
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  borderTop: "1px solid lightgray",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader5"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader5"
                    sx={{ fontSize: "0.75rem" }}
                  >
                    {translate("Operational Visibility")}
                  </ListSubheader>
                }
              >
                {hcoperationalvisibilityitems.map((item, index) => (
                  <ListItemButton
                    key={index}
                    onClick={(event) => handleMenuItemClick(event, item.route)}
                  >
                    <Avatar
                      alt={item.name}
                      src={item.icon}
                      sx={{
                        backgroundColor: item.bgColor,
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <IconConverter name={item.icon} />
                    </Avatar>
                    <Link
                      component={RouterLink}
                      to={item.route}
                      sx={{ color: "#000000DE", textDecoration: "none" }}
                    >
                      <ListItemText
                        primary={translate(item.name)}
                        sx={{ margin: "0px 10px" }}
                        primaryTypographyProps={{ fontSize: "14px" }}
                      />
                    </Link>
                  </ListItemButton>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <CardContent>
              <CardHeader
                avatar={
                  <Avatar
                    variant="square"
                    sx={{ bgcolor: "#F57C00", width: "3px", height: "25px" }}
                  ></Avatar>
                }
                title="Apps"
                sx={{
                  "& .MuiCardHeader-title": {
                    fontSize: "1rem",
                    fontWeight: 700,
                  },
                }}
              />
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  borderTop: "1px solid lightgray",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader2"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader2"
                    sx={{ fontSize: "0.75rem" }}
                  >
                    {translate("Asset Management")}
                  </ListSubheader>
                }
              >
                {assetmanagementitems.map((item, index) => (
                  <ListItemButton key={index}>
                    <Avatar
                      alt={item.name}
                      src={item.icon}
                      sx={{
                        backgroundColor: item.bgColor,
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <IconConverter name={item.icon} />
                    </Avatar>
                    <ListItemText
                      primary={translate(item.name)}
                      sx={{ margin: "0px 10px" }}
                      primaryTypographyProps={{ fontSize: "14px" }}
                    />
                  </ListItemButton>
                ))}
              </List>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  borderTop: "1px solid lightgray",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader3"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader3"
                    sx={{ fontSize: "0.75rem" }}
                  >
                    {translate("Data Validation")}
                  </ListSubheader>
                }
              >
                {datavalidationitems.map((item, index) => (
                  <ListItemButton key={index}>
                    <Avatar
                      alt={item.name}
                      src={item.icon}
                      sx={{
                        backgroundColor: item.bgColor,
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <IconConverter name={item.icon} />
                    </Avatar>
                    <ListItemText
                      primary={translate(item.name)}
                      sx={{ margin: "0px 10px" }}
                      primaryTypographyProps={{ fontSize: "14px" }}
                    />
                  </ListItemButton>
                ))}
              </List>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  borderTop: "1px solid lightgray",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader4"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader4"
                    sx={{ fontSize: "0.75rem" }}
                  >
                    {translate("Work Order Management")}
                  </ListSubheader>
                }
              >
                {womanagementitems.map((item, index) => (
                  <ListItemButton key={index}>
                    <Avatar
                      alt={item.name}
                      src={item.icon}
                      sx={{
                        backgroundColor: item.bgColor,
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <IconConverter name={item.icon} />
                    </Avatar>
                    <ListItemText
                      primary={translate(item.name)}
                      sx={{ margin: "0px 10px" }}
                      primaryTypographyProps={{ fontSize: "14px" }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
