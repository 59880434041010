import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { API } from "../../../api/api";
import { useAppStore } from "../../../store/store";

const viewIntervalList = [
  {
    text: "Days",
    id: 1,
  },
  {
    text: "Months",
    id: 3,
  },
  {
    text: "Years",
    id: 4,
  },
  {
    text: "Hours",
    id: 5,
  },
  {
    text: "15 Minutes",
    id: 6,
  },
  {
    text: "Custom",
    id: 7,
  },
];

const viewIntervalCustomList = [
  "Current Day",
  "Yesterday",
  "This week",
  "Previous Week",
  "This Month",
  "Last 3 Months",
  "Previous Month",
  "Previous 3 Months",
  "This Year",
  "Previous Year",
];

const customIntervalConfigList = [
  // Current Day - Hours
  {
    viewBy: 5,
    dataStart: dayjs().subtract(1, "day"),
    dataStop: dayjs(),
    periodicity: 7,
  },
  // Yesterday - Hours
  {
    viewBy: 5,
    dataStart: dayjs().subtract(2, "day"),
    dataStop: dayjs().subtract(2, "day"),
    periodicity: 7,
  },
  // This week - Days
  {
    viewBy: 1,
    dataStart: dayjs().subtract(1, "week"),
    dataStop: dayjs(),
    periodicity: 14,
  },
  // Previous Week - Days
  {
    viewBy: 1,
    dataStart: dayjs().subtract(2, "week"),
    dataStop: dayjs().subtract(1, "week"),
    periodicity: 14,
  },
  // This Month - Days
  {
    viewBy: 1,
    dataStart: dayjs().subtract(1, "month"),
    dataStop: dayjs(),
    periodicity: 14,
  },
  // Last 3 Months - Days
  {
    viewBy: 1,
    dataStart: dayjs().subtract(3, "month"),
    dataStop: dayjs(),
    periodicity: 14,
  },
  // Previous Month - Days
  {
    viewBy: 1,
    dataStart: dayjs().subtract(2, "month"),
    dataStop: dayjs().subtract(1, "month"),
    periodicity: 14,
  },
  // Previous 3 Months - Days
  {
    viewBy: 1,
    dataStart: dayjs().subtract(6, "month"),
    dataStop: dayjs().subtract(3, "month"),
    periodicity: 14,
  },
  // This Year - Months
  {
    viewBy: 3,
    dataStart: dayjs().subtract(1, "year"),
    dataStop: dayjs(),
    periodicity: 16,
  },
  // Previous Year - Months
  {
    viewBy: 3,
    dataStart: dayjs().subtract(2, "year"),
    dataStop: dayjs().subtract(1, "year"),
    periodicity: 16,
  },
];

const typelist = [
  {
    text: "Chart",
    id: 1,
  },
  {
    text: "Table",
    id: 2,
  },
];

export const DetailsInputs = (props) => {
  const { appConfig } = useAppStore();
  const mid = appConfig?.mid;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { translate, chartConfig, chartType, setChartType, setViewFormat, setData } = props;

  const [viewInterval, setViewInterval] = useState(1);
  const [customInterval, setCustomInterval] = useState(2);

  const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
  const [endDate, setEndDate] = useState(dayjs());

  const [nodePerspectives, setNodePerspectives] = useState<any>([]);
  const [nodeMediums, setNodeMediums] = useState<any>([]);

  const [perspectiveValue, setPerspectiveValue] = useState(-1);
  const [mediumValue, setMediumValue] = useState(-1);

  const handleChangeStartDate = (value) => {
    setStartDate(value);
  };

  const handleChangeEndDate = (value) => {
    setEndDate(value);
  };

  const handleChangeViewInterval = (event) => {
    const value = event.target.value;

    switch (value) {
      case 1: {
        setStartDate(dayjs().subtract(7, "day"));
        break;
      }
      case 3: {
        setStartDate(dayjs().subtract(1, "year"));
        break;
      }
      case 4: {
        setStartDate(dayjs().subtract(5, "year"));
        break;
      }
      case 5: {
        setStartDate(dayjs().subtract(1, "day"));
        setEndDate(dayjs());
        break;
      }
      case 6: {
        setStartDate(dayjs().subtract(6, "hour"));
        setEndDate(dayjs());
        break;
      }
      default:
        break;
    }

    setViewInterval(value);
  };

  const handleChangeCustomInterval = (event) => {
    setCustomInterval(event.target.value);

    setViewFormat(customIntervalConfigList[event.target.value]);
  };

  const handleNodePerspectiveChange = (event) => {
    setPerspectiveValue(event.target.value);
  };

  const handleNodeMediumChange = (event) => {
    setMediumValue(event.target.value);
  };

  useEffect(() => {
    const nodeId = chartConfig.nodeId;
    const nodeType = chartConfig.nodeType;
    if (nodeId === undefined || nodeId === "") return;

    API.GetEmsNodeMeterPerspectives(nodeId, nodeType, mid).then((result) => {
      setNodePerspectives(result);
      if (result.length > 0) setPerspectiveValue(result[0].code);
    });

    API.GetEmsNodeMediums(nodeId, nodeType).then((result) => {
      setNodeMediums(result);
      if (result.length > 0) setMediumValue(result[0].idMedium);
    });
  }, [chartConfig]);

  useEffect(() => {
    const nodeId = chartConfig.nodeId;
    const nodeType = chartConfig.nodeType;
    if (nodeId === undefined || nodeId === "" || mediumValue === -1 || perspectiveValue === -1)
      return;

    let dataStart, dataStop, periodicity;
    dataStart = startDate;
    dataStop = endDate;

    let finalViewBy = viewInterval;
    switch (viewInterval) {
      case 1: {
        periodicity = 14;
        break;
      }
      case 2: {
        // periodicity = 15;
        break;
      }
      case 3: {
        periodicity = 16;
        break;
      }
      case 4: {
        dataStart = startDate.dayOfYear(1);
        dataStop = endDate.add(1, "year").dayOfYear(1);
        periodicity = 17;
        break;
      }
      case 5: {
        periodicity = 7;
        break;
      }
      case 6: {
        periodicity = 4;
        break;
      }
      case 7: {
        dataStart = customIntervalConfigList[customInterval].dataStart;
        dataStop = customIntervalConfigList[customInterval].dataStop;
        periodicity = customIntervalConfigList[customInterval].periodicity;
        finalViewBy = customIntervalConfigList[customInterval].viewBy;
        break;
      }
      default:
        break;
    }

    API.GetAdministrativeGroupConsumption(
      nodeId,
      mid,
      nodeType,
      dataStart,
      dataStop,
      periodicity,
      perspectiveValue,
      mediumValue
    ).then(
      (result) => {
        setData(result);
        setViewFormat(finalViewBy);
      },
      () => {
        setData({});
        setViewFormat(finalViewBy);
      }
    );
  }, [
    chartConfig,
    viewInterval,
    customInterval,
    startDate,
    endDate,
    mediumValue,
    perspectiveValue,
  ]);
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: "10px",
      }}
    >
      <Stack direction={isMobile ? "column" : "row"} spacing={1} sx={{ width: "100%" }}>
        <Box sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}>
          <InputLabel id="viewby-label" disableAnimation={true}>
            {translate("View by")}
          </InputLabel>
          <Select
            labelId="viewby-label"
            id="viewby-select"
            value={viewInterval}
            onChange={handleChangeViewInterval}
            sx={{ width: "100%" }}
            size="small"
          >
            {viewIntervalList.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {translate(item.text)}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {viewInterval === 7 ? (
          <Box sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}>
            <InputLabel id="customDate-label" disableAnimation={true}>
              {translate("Interval")}
            </InputLabel>
            <Select
              labelId="customDate-label"
              id="customDate-select"
              value={customInterval}
              onChange={handleChangeCustomInterval}
              size="small"
            >
              {viewIntervalCustomList.map((item, index) => (
                <MenuItem key={index} value={index}>
                  {translate(item)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <>
            <Box
              sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}
            >
              <InputLabel id="startDate-label" disableAnimation={true}>
                {translate("Start Date")}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  displayWeekNumber={true}
                  format={"DD/MM/YYYY"}
                  value={startDate}
                  onAccept={handleChangeStartDate}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Box>

            <Box
              sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}
            >
              <InputLabel id="endDate-label" disableAnimation={true}>
                {translate("End Date")}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  displayWeekNumber={true}
                  format={"DD/MM/YYYY"}
                  value={endDate}
                  onAccept={handleChangeEndDate}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Box>
          </>
        )}

        <Box sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}>
          <InputLabel id="chartType-label" disableAnimation={true}>
            {translate("Chart Type")}
          </InputLabel>
          <Select
            labelId="chartType-label"
            id="chartType-select"
            value={chartType}
            onChange={(event) => {
              setChartType(event.target.value);
            }}
            sx={{ width: "100%" }}
            size="small"
          >
            {typelist.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {translate(item.text)}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}>
          <InputLabel id="perspective-label" disableAnimation={true}>
            {translate("Perspective")}
          </InputLabel>
          <Select
            labelId="perspective-label"
            id="perspective-select"
            value={perspectiveValue}
            onChange={handleNodePerspectiveChange}
            sx={{ width: "100%" }}
            size="small"
          >
            {nodePerspectives.map((item, index) => (
              <MenuItem key={index} value={item.code}>
                {translate(item.name)}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ width: isMobile ? "100%" : "auto", ml: isMobile ? "0px !important" : "8px" }}>
          <InputLabel id="medium-label" disableAnimation={true}>
            {translate("Medium")}
          </InputLabel>
          <Select
            labelId="medium-label"
            id="medium-select"
            value={mediumValue}
            onChange={handleNodeMediumChange}
            sx={{ width: "100%" }}
            size="small"
          >
            {nodeMediums.map((item, index) => (
              <MenuItem key={index} value={item.idMedium}>
                {translate(item.name)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>
    </Box>
  );
};
