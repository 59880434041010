import { BuildingFilters } from "./BuildingFilters";
import { BuildingTableX } from "./BuildingTableX";
import { Section } from "./Section";
import { Stack } from "@mui/material";

export const BuildingsWidget = () => {
  return (
    <Section title="Buildings">
      <Stack gap="24px">
        <BuildingFilters />
        <BuildingTableX />
      </Stack>
    </Section>
  );
};
