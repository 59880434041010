import { useAppStore } from "../store/store";
import { config } from "../config/config";
import * as API_Types from "../types/api/ApiContracts";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface ICOMMON_API {
  GetCatalog: (catalogName: string) => Promise<Array<API_Types.CatalogItem>>;
  GetWidgetCatalog: (
    objectId: number | string,
    objectType: number | never
  ) => Promise<Array<API_Types.CatalogItem>>;
  GetWGaugeStatistics: (
    objectType: number | null,
    ageCode: number | null,
    idMedium: number | null,
    idRegion: number | null,
    idCity: number | null,
    idCityZone: number | null,
    idBillingGroup: number | null
  ) => Promise<Array<API_Types.GougeStatisticItem>>;
  GetNetworkLength: () => Promise<any>;
  GetSupplyPointsNo: () => Promise<any>;
  GetUsageData: () => Promise<Array<any>>;
  GetExportFileTaskProgress: (id: string) => Promise<any>;
}

const COMMON_API: ICOMMON_API = {
  GetCatalog: async (catalogName) => {
    return await fetch(
      `${apiUrl}GetCatalog?catalogName=${catalogName}&catW=${""}&catO=${""}&catWP=${""}&catPg=${""}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<API_Types.CatalogItem>) => {
        return data;
      });
  },
  GetWidgetCatalog: async (objectId, objectType) => {
    return await fetch(`${apiUrl}GetWidgetCatalog?objectId=${objectId}&objectType=${objectType}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<API_Types.CatalogItem>) => {
        return data;
      });
  },
  GetWGaugeStatistics: async (
    objectType,
    ageCode,
    idMedium,
    idRegion,
    idCity,
    idCityZone,
    idBillingGroup
  ) => {
    return await fetch(
      `${apiUrl}GetWGaugeStatistics?objectType=${objectType}&ageCode=${ageCode}&idMedium=${idMedium}&idRegion=${idRegion}&idCity=${idCity}&idCityZone=${idCityZone}&idBillingGroup=${idBillingGroup}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetNetworkLength: async () => {
    return await fetch(`${apiUrl}GetNetworkLength`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetSupplyPointsNo: async () => {
    return await fetch(`${apiUrl}GetSupplyPointsNo`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetUsageData: async () => {
    return await fetch(`${apiUrl}GetUsageData`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetExportFileTaskProgress: async (id) => {
    return await fetch(`${apiUrl}GetExportFileTaskProgress?id=${id}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
};

export { COMMON_API };
