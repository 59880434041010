import AddIcon from "@mui/icons-material/Add";
import EastIcon from "@mui/icons-material/East";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import React, { memo, useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { BasePropsType } from "../../types/common/EmmsysTypes";
import { config } from "../../config/config";
import { useAppStore } from "../../store/store";
import FetchResult from "../../api/fetchresult";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, readingStatus, theme) {
  return {
    fontWeight:
      readingStatus.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

let filterstmp: any = [];
let filterId = 0;

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;

function CustomFilter(props) {
  const { translate, multiSelect, filter, onGeneralError } = props;

  const theme = useTheme();
  const { appConfig } = useAppStore();
  const cookies = new Cookies();

  const isTextBoxFilter = Boolean(filter.type === 8 || filter.type === 9);

  const [value, setValue] = useState(filter.value || (multiSelect ? [] : ""));
  const [filterItems, setFilterItems] = useState<any>([]);

  const handleValueChange = (event) => {
    let findex = filterstmp.findIndex((f) => f.id === filter.id);
    if (findex !== -1) {
      filterstmp[findex].value = event.target.value;
    }
    if (multiSelect) {
      const {
        target: { value },
      } = event;
      setValue(typeof value === "string" ? value.split(",") : value);
    } else setValue(event.target.value);
  };

  const tid = cookies.get("loginTenant") ?? appConfig?.tid;
  const uid = cookies.get("loginUserId") ?? appConfig?.uid;
  const mid = cookies.get("mid") ?? appConfig?.mid;

  useEffect(() => {
    let fetchUrl = "";
    let filterItemsTmp: any = [];

    switch (filter.type) {
      case 1:
        filterItemsTmp = [
          { id: 1, value: null, text: "" },
          { id: 2, value: 1, text: translate("connected") },
          { id: 3, value: 2, text: translate("disconnected") },
        ];
        break;
      case 2:
        filterItemsTmp = [
          { id: 1, value: 1, text: translate("Blank meters") },
          { id: 2, value: 2, text: translate("Meters without alarms") },
          { id: 3, value: 3, text: translate("Meters with alarms") },
          { id: 4, value: 4, text: translate("Unreachable meters") },
          { id: 5, value: 5, text: translate("Meters with warnings") },
        ];
        break;
      case 3:
        fetchUrl = apiUrl + "GetCatalog?catalogName=Alarms&catW=!!ActiveAlarms&catO=&catWP=&catPg=";
        break;
      case 4:
        fetchUrl =
          apiUrl +
          "GetCatalog?catalogName=AlarmCategories&catW=!!AlarmCategories&catO=&catWP=&catPg=";
        break;
      case 5:
        fetchUrl = apiUrl + "GetCatalog?catalogName=Regions&catW=&catO=&catWP=&catPg=";
        break;
      case 6:
        fetchUrl = apiUrl + "GetCatalog?catalogName=Cities&catW=&catO=&catWP=&catPg=";
        break;
      case 7:
        fetchUrl = apiUrl + "GetCatalog?catalogName=Streets&catW=&catO=&catWP=&catPg=";
        break;
      case 10:
        fetchUrl = apiUrl + "GetCatalog?catalogName=Mediums&catW=!!Mediums&catO=&catWP=&catPg=";
        break;
      case 11:
        fetchUrl =
          apiUrl + "GetCatalog?catalogName=DeviceModel&catW=!!IsVisible&catO=&catWP=&catPg=";
        break;
      case 12:
        fetchUrl = apiUrl + "GetCatalog?catalogName=DN&catW=&catO=OrderDns&catWP=&catPg=";
        break;
    }

    if (!isTextBoxFilter) {
      if (fetchUrl !== "") {
        fetch(fetchUrl, {
          mode: "cors",
          headers: {
            tid: tid,
            uid: uid,
            mid: mid,
          },
        })
          .then((res) => FetchResult(res))
          .then(
            (result) => {
              if (result.length > 0) {
                let resultitems = result.map((resultitem) => {
                  resultitem.id = resultitem.code;
                  resultitem.value = resultitem.code;
                  resultitem.text = resultitem.name;
                  return resultitem;
                });
                setFilterItems(resultitems);
              } else {
                setFilterItems([]);
              }
            },
            (error) => {
              if (onGeneralError != undefined) onGeneralError(error);
            }
          );
      } else {
        setFilterItems(filterItemsTmp);
      }
    }
  }, []);

  return (
    <>
      {isTextBoxFilter && (
        <TextField
          hiddenLabel
          size="small"
          id="customfilter-outlined"
          value={value}
          onChange={handleValueChange}
        />
      )}
      {!isTextBoxFilter && multiSelect && (
        <Select
          size="small"
          sx={{ minWidth: 120 }}
          id="customfilter-select"
          multiple
          value={value}
          onChange={handleValueChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          {filterItems.map((item) => (
            <MenuItem key={item.id} value={item.value} style={getStyles(item.value, value, theme)}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      )}
      {!isTextBoxFilter && !multiSelect && (
        <Select
          size="small"
          sx={{ minWidth: 120 }}
          id="customfilter-select"
          value={value}
          onChange={handleValueChange}
        >
          {filterItems.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}

interface BasicFilterProps extends Pick<BasePropsType, "translate"> {
  filter: any;
  onRemoveFilter: (params: any) => void;
}

const BasicFilter = memo(function BasicFilter(props: BasicFilterProps) {
  const { translate, filter, onRemoveFilter } = props;
  const [filterType, setFilterType] = React.useState(filter.type || "");

  const handleFilterTypeChange = (event) => {
    const findex = filterstmp.findIndex((f) => f.id === filter.id);
    if (findex !== -1) {
      filterstmp[findex].type = event.target.value;
      filterstmp[findex].value = null;
    }

    setFilterType(event.target.value);
  };

  const handleDeteleFilter = () => {
    if (onRemoveFilter !== undefined) onRemoveFilter(filter.id);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box sx={{ flex: "1" }}>
        <FormControl sx={{ m: 1, minWidth: 120, flexDirection: "row" }} size="small">
          <InputLabel id="filtertype-select-label">Filter by</InputLabel>
          <Select
            sx={{ minWidth: 120 }}
            id="filtertype-select"
            value={filterType}
            label="Filter by"
            onChange={handleFilterTypeChange}
          >
            <MenuItem value="">(none)</MenuItem>
            <MenuItem value={1}>Installation status</MenuItem>
            <MenuItem value={2}>Reading status</MenuItem>
            <MenuItem value={3}>Alarm type</MenuItem>
            <MenuItem value={4}>Alarm category</MenuItem>
            <ListSubheader
              sx={{
                textAlign: "end",
                fontWeight: "bold",
                fontStyle: "italic",
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              Meter Address
            </ListSubheader>
            <MenuItem value={5}>Region</MenuItem>
            <MenuItem value={6}>City</MenuItem>
            <MenuItem value={7}>Street</MenuItem>
            <MenuItem value={8}>Zip Code</MenuItem>
            <ListSubheader
              sx={{
                textAlign: "end",
                fontWeight: "bold",
                fontStyle: "italic",
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              Work Orders
            </ListSubheader>
            <MenuItem value={9}>Work order id</MenuItem>
            <ListSubheader
              sx={{
                textAlign: "end",
                fontWeight: "bold",
                fontStyle: "italic",
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              Meter Specific
            </ListSubheader>
            <MenuItem value={10}>Medium</MenuItem>
            <MenuItem value={11}>Device type</MenuItem>
            <MenuItem value={12}>DN size</MenuItem>
          </Select>
          {filterType !== "" && <EastIcon sx={{ marginTop: "0.5rem" }} />}
          {filterType === 1 && (
            <CustomFilter filter={filter} multiSelect={false} translate={translate} />
          )}
          {filterType === 2 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 3 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 4 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 5 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 6 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 7 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 8 && (
            <CustomFilter filter={filter} multiSelect={false} translate={translate} />
          )}
          {filterType === 9 && (
            <CustomFilter filter={filter} multiSelect={false} translate={translate} />
          )}
          {filterType === 10 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 11 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType === 12 && (
            <CustomFilter filter={filter} multiSelect={true} translate={translate} />
          )}
          {filterType !== "" && (
            <Tooltip title="Remove filter">
              <IconButton onClick={handleDeteleFilter}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </FormControl>
      </Box>
    </Stack>
  );
});

interface BasicFiltersProps {
  translate: (params: string) => string;
  onFiltersChange: (params: any) => void;
}

const BasicFilters = memo(function BasicFilters({ translate, onFiltersChange }: BasicFiltersProps) {
  const [filters, setFilters] = React.useState(filterstmp || []);

  const handleAddFilter = () => {
    filterId = filterId + 1;
    filterstmp.push({ id: filterId, type: -1, value: null });
    const f = filterstmp.map((item) => item);
    setFilters(f);
  };

  const handleRemoveFilter = (value) => {
    const findex = filterstmp.findIndex((item) => item.id === value);

    if (findex !== -1) {
      const flist = filterstmp.flatMap((item) => {
        if (item.id === value) return [];
        else return item;
      });
      filterstmp = flist;
      setFilters(flist);
    }
  };

  const handleApplyfilters = () => {
    if (onFiltersChange != undefined) onFiltersChange(filterstmp);
  };

  const handleClearfilters = () => {
    filterstmp = [];
    setFilters([]);
    if (onFiltersChange != undefined) onFiltersChange(filterstmp);
  };

  return (
    <Box sx={{ flex: "1" }}>
      <Grid container spacing={2}>
        {filters.map((filter, index) => {
          return (
            <Grid item key={index} xs={12} md={12}>
              <BasicFilter
                onRemoveFilter={handleRemoveFilter}
                filter={filter}
                translate={translate}
              />
            </Grid>
          );
        })}

        <Grid item xs={12} md={12}>
          <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddFilter}>
            Add filter
          </Button>
          <Button variant="outlined" onClick={handleApplyfilters}>
            Apply filters
          </Button>
          <Button variant="outlined" onClick={handleClearfilters}>
            Clear filters
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
});

export { BasicFilters };
