import DoneIcon from "@mui/icons-material/Done";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createContext, forwardRef, memo, useContext, useEffect, useRef, useState } from "react";
import { VariableSizeList, ListChildComponentProps } from "react-window";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <li {...dataSet[0]} style={inlineStyle}>
      <Box
        component={DoneIcon}
        sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
        style={{
          visibility: dataSet[0]["aria-selected"] ? "visible" : "hidden",
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {dataSet[1].text}
      </Box>
    </li>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactElement) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

function renderTags(props, label, count) {
  return (
    <Typography noWrap>
      {label} ({count == 0 ? 0 : props.length}/{count})
    </Typography>
  );
}

interface CustomMultiselectDropDownProps {
  dataSet: any;
  dropDownLabel: string;
  onValueChange: (params: any) => void;
}

const CustomMultiselectDropDown = memo(function CustomMultiselectDropDown(
  props: CustomMultiselectDropDownProps
) {
  const { dataSet, dropDownLabel, onValueChange } = props;
  const [value, setValue] = useState([]);

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
    if (onValueChange != undefined) {
      const values = newValue.map((v) => v.value);
      onValueChange(values);
    }
  };

  useEffect(() => {
    if (dataSet != null && dataSet.length == 0) {
      setValue([]);
    }
  }, []);

  return (
    <Autocomplete
      size="small"
      sx={{ flex: "1 1 max-content" }}
      multiple
      fullWidth
      limitTags={1}
      disableCloseOnSelect
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      value={value}
      options={dataSet}
      renderTags={(props) => renderTags(props, dropDownLabel, dataSet.length)}
      onChange={handleValueChange}
      getOptionLabel={(option: any) => option.text}
      renderInput={(params) => <TextField {...params} label={dropDownLabel} />}
      renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
    />
  );
});

export { CustomMultiselectDropDown };
