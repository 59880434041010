import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CloseIcon from "@mui/icons-material/Close";
import SpeedIcon from "@mui/icons-material/Speed";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

import { COMMON_API } from "../../api/commonapi";
import { CustomDropDown } from "../common/CustomDropDown";
import { GaugeIndicatorWidget } from "../shared/Widgets/WGaugeIndicator";
import { EnergyManagementWidget } from "../shared/Widgets/WEnergyManagement";
import { AllocationWidget } from "../shared/Widgets/WAllocation";
import { WorkOrdersWidget } from "../shared/Widgets/WWorkOrders";
import { AssetManagementWidget } from "../shared/Widgets/WAssetManagement";
import { VeeStatisticsWidget } from "../shared/Widgets/WVeeStatistics";

interface IDashboardContext {
  idRegion: number | string;
  month: dayjs.Dayjs | null;
  changeRegion: (region: number | string) => void;
}

export const DashboardContext = createContext<IDashboardContext | null>(null);

export const UserDashboard = ({ translate, language, globalRefresh, onGeneralError }) => {
  console.log("render dashboard");
  const cookies = new Cookies();

  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [idRegion, setIdRegion] = useState<any>("");
  const [isRegionsLoaded, setIsRegionsLoaded] = useState<boolean>(false);
  const [openAddWidget, setOpenAddWidget] = useState(false);
  const [regionsItems, setRegionsItems] = useState<any>([]);

  const currentDate = new Date();
  const [month, setMonth] = useState(dayjs(currentDate));

  const [anchorCustomizeViewEl, setAnchorCustomizeViewEl] = useState(null);
  const openCustomizeview = Boolean(anchorCustomizeViewEl);
  const idCustomizeview = openCustomizeview ? "customize-view-popover" : undefined;

  const [checkedWidgets, setCheckedWidgets] = useState(cookies.get("ethDashboardWidgets") ?? []);
  const [selectedWidgetIndex, setSelectedWidgetIndex] = useState(0);

  const [checkedView, setCheckedView] = useState(
    cookies.get("ethDashboardViews") ?? [
      "energymgmt",
      "allocation",
      "workorders",
      "assetmgmt",
      "veestatistics",
    ]
  );

  const changeRegion = (region: number | string) => {
    setIdRegion(region);
  };

  const handleRegionChange = (value) => {
    if (value === undefined || value === null) setIdRegion("");
    else setIdRegion(value.value ?? "");
  };

  const handleChangeMonth = (value) => {
    setMonth(value);
  };

  const handleCustomizeWiewClick = (event) => {
    setAnchorCustomizeViewEl(event.currentTarget);
  };

  const handleCustomizeWiewClose = () => {
    setAnchorCustomizeViewEl(null);
  };

  const handleToggleView = (value) => () => {
    const currentIndex = checkedView.indexOf(value);
    const newChecked = [...checkedView];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedView(newChecked);
  };

  const handleMoreWClick = () => {
    setAnchorCustomizeViewEl(null);
    setOpenAddWidget(true);
  };

  const handleBackClick = () => {
    setAnchorCustomizeViewEl(null);
  };

  const handleSaveViewClick = () => {
    setAnchorCustomizeViewEl(null);

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);

    const newChecked = [...checkedView];
    cookies.set("ethDashboardViews", newChecked, { path: "/", expires: d });
  };

  const handleAddWidget = () => {
    const newCheckedW = [...checkedWidgets];

    //wType: 0-meters read; 1-active alarm; 2-delayed wo
    let wtitle = "";
    switch (selectedWidgetIndex) {
      case 0:
        wtitle = "Meters Read";
        break;
      case 1:
        wtitle = "Active Alarms";
        break;
      case 2:
        wtitle = "Delayed Work Orders";
        break;
      default:
        wtitle = "Widget";
        break;
    }
    const wguid = uuidv4();
    const newW = {
      wType: selectedWidgetIndex,
      wGuid: wguid,
      wTitle: wtitle,
      wSettings: {},
    };

    newCheckedW.push(newW);

    setCheckedWidgets(newCheckedW);

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);

    cookies.set("ethDashboardWidgets", newCheckedW, { path: "/", expires: d });

    setOpenAddWidget(false);
  };

  const removeWidget = (guid) => {
    const newCheckedW = checkedWidgets.flatMap((w) => {
      if (w.wGuid === undefined || w.wGuid !== guid) return w;
      else return [];
    });

    setCheckedWidgets(newCheckedW);

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);

    cookies.set("ethDashboardWidgets", newCheckedW, { path: "/", expires: d });
  };

  const handleAddWidgetClose = () => {
    setOpenAddWidget(false);
  };

  const handleWidgetListItemClick = (event, index) => {
    setSelectedWidgetIndex(index);
  };

  useEffect(() => {
    setIsRegionsLoaded(false);

    COMMON_API.GetCatalog("Regions").then((regions) => {
      regions.unshift({ code: 0, name: "All" });
      const regionsitems = regions.map((reg) => {
        return {
          id: reg.code,
          value: reg.code == 0 ? "" : reg.code.toString(),
          text: reg.name,
        };
      });
      setRegionsItems(regionsitems);
      setIsRegionsLoaded(true);
      if (regionsitems.length > 0) {
        setIdRegion(regionsitems[0].value);
      }
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          alignItems: isMobile ? "flex-start" : "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{ justifyContent: "flex-end", flexGrow: 1, display: isMobile ? "none" : "flex" }}
        ></Box>
        <CustomDropDown
          dropDownStyle={true}
          selectFirstValue={true}
          translate={translate}
          dataSet={regionsItems}
          onValueChange={handleRegionChange}
          dropDownLabel={translate("Region")}
          sx={{
            padding: isMobile ? "1rem 0rem" : "0rem 1rem",
            minWidth: "250px",
            width: isMobile ? "100%" : "auto",
          }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={translate("Select date")}
            views={["year", "month"]}
            openTo="month"
            format={"MMMM YYYY"}
            value={month}
            onChange={handleChangeMonth}
            sx={{ width: isMobile ? "100%" : "auto" }}
          />
        </LocalizationProvider>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ margin: "0px 20px !important", display: isMobile ? "none" : "flex" }}
        />
        <Tooltip title={translate("Customize dashboard")} arrow>
          <IconButton onClick={handleCustomizeWiewClick}>
            <DashboardCustomizeIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={idCustomizeview}
          open={openCustomizeview}
          anchorEl={anchorCustomizeViewEl}
          onClose={handleCustomizeWiewClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              padding: "1rem",
              bgcolor: "background.paper",
              "& .MuiListItem-root": {
                border: "1px solid #BDBDBD",
                borderRadius: "8px",
                margin: "0.5rem 0px",
              },
              "& .MuiListItemIcon-root": {
                minWidth: "34px",
              },
            }}
            subheader={
              <ListSubheader sx={{ fontWeight: 700, fontSize: "1.5rem", padding: "0px" }}>
                {translate("Customize view")}
              </ListSubheader>
            }
          >
            <ListItem key="0">
              <ListItemIcon>
                <DragIndicatorIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-energymgmt"
                primary={translate("Energy Management")}
              />
              <Switch
                edge="end"
                onChange={handleToggleView("energymgmt")}
                checked={checkedView.indexOf("energymgmt") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-energymgmt",
                }}
              />
            </ListItem>
            <ListItem key="1">
              <ListItemIcon>
                <DragIndicatorIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-allocation" primary={translate("Allocation")} />
              <Switch
                edge="end"
                onChange={handleToggleView("allocation")}
                checked={checkedView.indexOf("allocation") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-allocation",
                }}
              />
            </ListItem>
            <ListItem key="2">
              <ListItemIcon>
                <DragIndicatorIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-workorders" primary={translate("Work Orders")} />
              <Switch
                edge="end"
                onChange={handleToggleView("workorders")}
                checked={checkedView.indexOf("workorders") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-workorders",
                }}
              />
            </ListItem>
            <ListItem key="3">
              <ListItemIcon>
                <DragIndicatorIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-assetmgmt"
                primary={translate("Asset Management")}
              />
              <Switch
                edge="end"
                onChange={handleToggleView("assetmgmt")}
                checked={checkedView.indexOf("assetmgmt") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-assetmgmt",
                }}
              />
            </ListItem>
            <ListItem key="4">
              <ListItemIcon>
                <DragIndicatorIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-veestatistics" primary={translate("VEE")} />
              <Switch
                edge="end"
                onChange={handleToggleView("veestatistics")}
                checked={checkedView.indexOf("veestatistics") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-veestatistics",
                }}
              />
            </ListItem>
            <ListItem key="5" sx={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <WidgetsIcon />
              </ListItemIcon>
              <Button variant="outlined" onClick={handleMoreWClick}>
                {translate("More widgets...")}
              </Button>
            </ListItem>
          </List>
          <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
          <Stack
            direction={matchessmbreackpoint ? "row" : "column"}
            spacing={1}
            sx={{ justifyContent: "space-between", padding: "1rem" }}
          >
            <Button variant="outlined" onClick={handleBackClick}>
              {translate("Back")}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveViewClick}>
              {translate("Save and close")}
            </Button>
          </Stack>
        </Popover>
      </Grid>
      <DashboardContext.Provider value={{ idRegion, month, changeRegion }}>
        {checkedView.indexOf("energymgmt") !== -1 && (
          <Grid item xs={12} md={12}>
            <EnergyManagementWidget translate={translate} />
          </Grid>
        )}
        {checkedView.indexOf("allocation") !== -1 && (
          <Grid item xs={12} md={12}>
            <AllocationWidget
              translate={translate}
              language={language}
              onGeneralError={onGeneralError}
            />
          </Grid>
        )}
        {checkedView.indexOf("workorders") !== -1 && (
          <Grid item xs={12} md={12}>
            <WorkOrdersWidget
              translate={translate}
              language={language}
              onGeneralError={onGeneralError}
            />
          </Grid>
        )}
        {checkedView.indexOf("veestatistics") !== -1 && (
          <Grid item xs={12} md={12}>
            <AssetManagementWidget
              translate={translate}
              language={language}
              onGeneralError={onGeneralError}
            />
          </Grid>
        )}
        {checkedView.indexOf("assetmgmt") !== -1 && (
          <Grid item xs={12} md={12}>
            <VeeStatisticsWidget
              translate={translate}
              language={language}
              globalRefresh={globalRefresh}
              onGeneralError={onGeneralError}
            />
          </Grid>
        )}
        {checkedWidgets.flatMap((w, index) => {
          if (w.wType === undefined) return [];

          switch (w.wType) {
            case 0:
            case 1:
            case 2:
              return (
                <Grid key={"w-" + index} item xs={12} md={12}>
                  <GaugeIndicatorWidget
                    translate={translate}
                    language={language}
                    chartConfig={{ title: w.wTitle, objectType: w.wType, wGuid: w.wGuid }}
                    onDelete={removeWidget}
                    onGeneralError={onGeneralError}
                  />
                </Grid>
              );
          }
        })}
      </DashboardContext.Provider>

      <Dialog open={openAddWidget} onClose={handleAddWidgetClose}>
        <DialogTitle>{translate("Widgets")}</DialogTitle>
        <IconButton
          onClick={handleAddWidgetClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <List
            sx={{
              width: "100%",
              minWidth: isMobile ? 200 : 460,
              padding: "1rem",
              bgcolor: "background.paper",
              "& .MuiListItem-root": {
                border: "1px solid #BDBDBD",
                borderRadius: "8px",
                margin: "0.5rem 0px",
              },
              "& .MuiListItemIcon-root": {
                minWidth: "34px",
              },
            }}
          >
            <ListItemButton
              key="0"
              selected={selectedWidgetIndex === 0}
              onClick={(event) => handleWidgetListItemClick(event, 0)}
            >
              <ListItemIcon>
                <SpeedIcon />
              </ListItemIcon>
              <ListItemText id="widget-list-label-wmetersread" primary={translate("Meters Read")} />
            </ListItemButton>
            <ListItemButton
              key="1"
              selected={selectedWidgetIndex === 1}
              onClick={(event) => handleWidgetListItemClick(event, 1)}
            >
              <ListItemIcon>
                <SpeedIcon />
              </ListItemIcon>
              <ListItemText
                id="widget-list-label-wactalappear"
                primary={translate("Active Alarm Appearence")}
              />
            </ListItemButton>
            <ListItemButton
              key="2"
              selected={selectedWidgetIndex === 2}
              onClick={(event) => handleWidgetListItemClick(event, 2)}
            >
              <ListItemIcon>
                <SpeedIcon />
              </ListItemIcon>
              <ListItemText
                id="widget-list-label-wdelayedwo"
                primary={translate("Delayed Work Orders")}
              />
            </ListItemButton>
          </List>
        </DialogContent>
        <DialogActions sx={{ mt: 5 }}>
          <Button variant="outlined" onClick={handleAddWidgetClose}>
            {translate("Close")}
          </Button>
          <Button variant="contained" onClick={handleAddWidget}>
            {translate("Add Widget")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
