import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getPostHeaders = () => {
  return {
    "Content-Type": "application/json",
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IWarehouse_API {
  GetWarehouseOverviewStatus: (idDNs: Array<any>, idFlowmeterModels: Array<any>) => Promise<any>;
  GetWarehouseOverviewDevicesStatus: (
    categoryCode: number,
    idProductTypeArray: Array<any>,
    idProductModelArray: Array<any>
  ) => Promise<Array<any>>;
}

const Warehouse_API: IWarehouse_API = {
  GetWarehouseOverviewStatus: async (idDNs, idFlowmeterModels) => {
    return await fetch(`${apiUrl}GetWarehouseOverviewStatus`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: getPostHeaders(),
      body: JSON.stringify({
        idDNs: idDNs,
        idFlowmeterModels: idFlowmeterModels,
      }),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetWarehouseOverviewDevicesStatus: async (
    categoryCode,
    idProductTypeArray,
    idProductModelArray
  ) => {
    return await fetch(`${apiUrl}GetWarehouseOverviewDevicesStatus`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: getPostHeaders(),
      body: JSON.stringify({
        categoryCode: categoryCode,
        idProductTypeArray: idProductTypeArray,
        idProductModelArray: idProductModelArray,
      }),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
};

export { Warehouse_API };
