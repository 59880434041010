const LoginSteps = [
  {
    icon: "k-i-lock",
    label: "Account Info",
    index: 0,
  },
  {
    icon: "k-i-myspace",
    label: "Tenant",
    index: 1,
  },
  {
    icon: "k-i-user",
    label: "Login",
    index: 2,
  },
];

const LoginRegions = [
  {
    text: "Europe",
    id: 1,
  },
  {
    text: "Australia",
    id: 2,
  },
  {
    text: "Asia",
    id: 3,
  },
];

const LoginFlags: any = [
  {
    text: "English (UK)",
    icon: "en-GB",
  },
  {
    text: "العربية (Arabic)",
    icon: "ar-SA",
  },
  {
    text: "Czech",
    icon: "cs-CZ",
  },
  {
    text: "Deutsch (German)",
    icon: "de-DE",
  },
  {
    text: "Ελληνικά (Greek)",
    icon: "el-GR",
  },
  {
    text: "Español (CL)",
    icon: "es-CL",
  },
  {
    text: "Español (ES)",
    icon: "es-ES",
  },
  {
    text: "Français",
    icon: "fr-FR",
  },
  {
    text: "Hrvatski",
    icon: "hr-HR",
  },
  {
    text: "Italiano",
    icon: "it-IT",
  },
  {
    text: "Nederlands (Dutch)",
    icon: "nl-NL",
  },
  {
    text: "Polski",
    icon: "pl-PL",
  },
  {
    text: "Português (BR)",
    icon: "pt-BR",
  },
  {
    text: "Português (PT)",
    icon: "pt-PT",
  },
  {
    text: "Pусский (Russian)",
    icon: "ru-RU",
  },
  {
    text: "Română",
    icon: "ro-RO",
  },
  {
    text: "Slovenščina (Slovene)",
    icon: "sl-SI",
  },
  {
    text: "Svenska (Swedish)",
    icon: "sv-SE",
  },
  {
    text: "Türkçe",
    icon: "tr-TR",
  },
  {
    text: "中文 (繁体) Chinese Traditional",
    icon: "zh-HK",
  },
  {
    text: "中文 (简体) Chinese Simplified",
    icon: "zh-CN",
  },
];

export { LoginSteps, LoginRegions, LoginFlags };
