import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { LoginFlags, LoginRegions, LoginSteps } from "./components/resources/arrayconstants";
import { useAppStore } from "./store/store";
import { AUTHAPI } from "./api/auth";

const flagsItemRender = (props) => {
  const result = LoginFlags.find(({ icon }) => icon === props);

  return (
    <>
      <Avatar
        alt="En"
        src={"/img/flags/" + result.icon + ".png"}
        variant="rounded"
        sx={{
          "& .MuiAvatar-img": {
            width: "16px",
            height: "16px",
          },
        }}
      />
      <ListItemText
        sx={{
          flex: "0 1 auto",
          padding: "5px 10px",
          alignItems: "center",
          display: "flex",
        }}
      >
        {result.text}
      </ListItemText>
    </>
  );
};

export default function LoginLayout(props) {
  const { translate, culture, language, onChangeInstallationType, onGeneralError } = props;

  const theme = useTheme();

  const matchessmbreakpoints = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesmddownbreakpoints = useMediaQuery(theme.breakpoints.down("md"));

  const cookies = new Cookies();
  const navigate = useNavigate();

  const { authToken, setAuthToken, setRefreshToken, setAppConfig } = useAppStore();

  const [user, setUser] = useState<any>({});

  const [isTenantsLoading, setIsTenantsLoading] = useState(false);
  const [tenants, setTenants] = useState<any>([]);

  const [isAuthenticathing, setIsAuthenticathing] = useState(false);

  const [step, setStep] = useState(0);
  const [selectedFlag, setSelectedFlag] = useState(language);

  const [region, setRegion] = useState(cookies.get("loginRegion") || "");

  const [tenant, setTenant] = useState("");

  const [userName, setUserName] = useState(cookies.get("loginUser") || "");
  const [userPassword, setUserPassword] = useState(cookies.get("loginUPwd") || "");

  const [rememberMe, setRememberMe] = useState(cookies.get("loginRememberMe") || false);

  const handleChangeRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
  };

  const handleChangeTenant = (event) => {
    setTenant(event.target.value);

    const findtenant = tenants.find((element) => element.id === event.target.value);
    if (findtenant !== undefined) {
      setAppConfig({
        mid: findtenant.mid,
        mname: findtenant.mname,
        munit: findtenant.munit,
        instType: findtenant.instType === undefined ? "water" : findtenant.instType,
      });
      onChangeInstallationType(findtenant.instType === undefined ? "water" : findtenant.instType);
    }

    setStep(2);
  };

  const handleBtnStep1Click = () => {
    setIsAuthenticathing(true);
  };

  const handleBtnStep2Click = () => {
    setIsTenantsLoading(true);

    if (step === 2) {
      if (user.token !== null) {
        setAuthToken(user.token.accessToken);
        setRefreshToken(user.token.refreshToken);
      }
    }
  };

  const handleBtnStep2BackClick = () => {
    setStep(0);
  };

  const handleLanguageChange = (event) => {
    setSelectedFlag(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (authToken != null) setIsTenantsLoading(true);
  }, [authToken]);

  useEffect(() => {
    culture(selectedFlag);
  }, [selectedFlag]);

  useEffect(() => {
    if (user != null && user.user != null) {
      const d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);

      if (rememberMe) {
        cookies.set("loginRegion", region, { path: "/", expires: d });
        cookies.set("loginUser", userName, { path: "/", expires: d });
        cookies.set("loginUserId", user.user.userId, {
          path: "/",
          expires: d,
        });

        cookies.set("loginUPwd", userPassword, { path: "/", expires: d });
        cookies.set("loginRememberMe", true, { path: "/", expires: d });
      } else {
        cookies.remove("loginRegion", { path: "/" });
        cookies.remove("loginUser", { path: "/" });
        cookies.remove("loginUserId", { path: "/" });
        cookies.remove("mid", { path: "/" });
        cookies.remove("loginTenant", { path: "/" });
        cookies.remove("loginUPwd", { path: "/" });
        cookies.remove("loginRememberMe", { path: "/" });
      }

      cookies.set("tokenMin", user.token.tokenValidityInMinutes, { path: "/", expires: d });
      cookies.set("loginUserFullName", user.user.firstName + " " + user.user.lastName, {
        path: "/",
        expires: d,
      });

      setRefreshToken(user.token.refreshToken);
      setAuthToken(user.token.accessToken);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticathing) {
      AUTHAPI.Authentificate(
        userName,
        userPassword,
        false,
        "",
        false,
        tenant === "" ? "0" : tenant
      ).then(
        (result) => {
          if (result.user != null) {
            setUser(result);
            setIsAuthenticathing(false);
          } else {
            setUser({});
            setIsAuthenticathing(false);
            if (onGeneralError != undefined)
              onGeneralError({ cause: 200, message: "Login failed." });
          }
        },
        (error) => {
          setUser({});
          setIsAuthenticathing(false);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    }
  }, [isAuthenticathing]);

  useEffect(() => {
    if (isTenantsLoading && authToken != null) {
      AUTHAPI.GetTenants(userName, userPassword).then(
        (result) => {
          const xtenants = result.flatMap((t) => {
            return {
              id: t.id,
              text: t.name,
              instType: t.installationType,
              mid: 11,
              mname: "Cooling",
              munit: "KWh",
            };
          });

          setTenants(xtenants);

          if (xtenants.length === 1) {
            const xtenant = xtenants[0];
            setTenant(xtenant.id.toString());
            setAppConfig({
              mid: xtenant.mid,
              mname: xtenant.mname,
              munit: xtenant.munit,
              instType: xtenant.instType === undefined ? "water" : xtenant.instType,
            });
            const d = new Date();
            d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
            cookies.set("mid", xtenant.mid, {
              path: "/",
              expires: d,
            });
            cookies.set("loginTenant", xtenant.id, { path: "/", expires: d });
            onChangeInstallationType(xtenant.instType === undefined ? "water" : xtenant.instType);

            if (user.token !== null) {
              navigate("/", { replace: true });
            }
          } else {
            setStep(1);
            setIsTenantsLoading(false);
          }
        },
        (error) => {
          setTenants([]);
          setIsTenantsLoading(false);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    }
  }, [isTenantsLoading]);

  return (
    <Box sx={{ flexGrow: 1 }} style={{ height: "100%" }}>
      <CssBaseline />
      <Grid container spacing={0} sx={{ height: "100%", overflowY: "auto" }}>
        {matchessmbreakpoints && !isMobile && (
          <Grid
            item
            sx={{
              display: matchesmddownbreakpoints ? "none" : "inherit",
              height: "100%",
              backgroundImage: 'url("../img/bg2.png")',
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              padding: "0px 32px",
            }}
            md={5}
            lg={5}
          >
            <img
              src="../img/Group1.png"
              alt="missing"
              style={{ position: "absolute", top: "0px" }}
            ></img>
            <img
              src="../img/Group5.png"
              alt="missing"
              style={{ position: "absolute", top: "320px" }}
            ></img>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={matchessmbreakpoints && !isMobile ? 7 : 12}
          lg={matchessmbreakpoints && !isMobile ? 7 : 12}
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "top",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
            <FormControl sx={{ m: 1, width: 250, right: "10px", top: "10px" }}>
              <InputLabel
                id="language-label"
                sx={{ backgroundColor: "white", padding: "0px 6px 0px 6px" }}
              >
                {translate("Language")}
              </InputLabel>
              <Select
                id="language-select"
                labelId="language-label"
                value={selectedFlag}
                onChange={handleLanguageChange}
                renderValue={flagsItemRender}
                sx={{
                  "& .MuiSelect-select": {
                    display: "flex",
                    padding: "5px",
                  },
                }}
              >
                {LoginFlags.map((item, index) => (
                  <MenuItem key={index} value={item.icon}>
                    <Avatar
                      alt="En"
                      src={"/img/flags/" + item.icon + ".png"}
                      variant="rounded"
                      sx={{
                        width: "32px",
                        height: "32px",
                        "& .MuiAvatar-img": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                    />
                    <ListItemText
                      sx={{
                        flex: "0 1 auto",
                        padding: "5px 10px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {item.text}
                    </ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              overscrollBehaviorY: "auto",
            }}
          >
            <form
              noValidate
              autoComplete="off"
              style={{
                width: matchessmbreakpoints ? "400px" : "100%",
                padding: "8px",
                marginTop: matchesmddownbreakpoints || isMobile ? "80px" : "0px",
              }}
            >
              {matchessmbreakpoints && !isMobile && (
                <div style={{ paddingBottom: "40px", textAlign: "center" }}>
                  <img
                    src="/img/ENDUSER_logo_login.png"
                    alt="missing"
                    style={{ margin: "0 auto" }}
                  ></img>
                </div>
              )}
              {step === 0 && (
                <>
                  <FormControl sx={{ margin: "8px 0px", width: "100%" }} error={region == ""}>
                    <InputLabel id="region-label">{translate("Select Region")}</InputLabel>
                    <Select
                      labelId="region-label"
                      id="region-select"
                      value={region}
                      label={translate("Select Region")}
                      onChange={handleChangeRegion}
                    >
                      {LoginRegions.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {region == "" && <FormHelperText>{translate("Required")}</FormHelperText>}
                  </FormControl>
                  <FormControl
                    sx={{ margin: "8px 0px", width: "100%" }}
                    error={userName !== null && !(userName !== "") && userName != undefined}
                  >
                    <InputLabel htmlFor="outlined-username">{translate("ID / Email")}</InputLabel>
                    <OutlinedInput
                      id="outlined-username"
                      label={translate("ID / Email")}
                      value={userName}
                      onChange={(event) => {
                        setUserName(event.target.value);
                      }}
                    />
                    {userName !== null && !(userName !== "") && userName !== undefined && (
                      <FormHelperText>{translate("Username is required")}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    sx={{ margin: "8px 0px", width: "100%" }}
                    error={
                      userPassword !== null && !(userPassword !== "") && userPassword !== undefined
                    }
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      {translate("Password")}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          ></IconButton>
                        </InputAdornment>
                      }
                      value={userPassword}
                      onChange={(event) => {
                        setUserPassword(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key == "Enter") setIsAuthenticathing(true);
                      }}
                      label={translate("Password")}
                    />
                    {userPassword !== null &&
                      !(userPassword !== "") &&
                      userPassword !== undefined && (
                        <FormHelperText>{translate("Password is required")}</FormHelperText>
                      )}
                  </FormControl>
                  <FormGroup sx={{ margin: "8px 0px", width: "100%" }}>
                    <Stack direction="row" spacing={1} sx={{ margin: "0px", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberMe}
                            onChange={handleChangeRememberMe}
                            sx={{ padding: "9px 0px" }}
                          />
                        }
                        label={translate("Remember me")}
                        sx={{
                          flex: "auto",
                          "& .MuiFormControlLabel-label": {
                            margin: "0px 9px",
                          },
                        }}
                      />
                      <Link
                        href="/"
                        sx={{
                          display: "flex",
                        }}
                      >
                        {translate("Forgot password?")}
                      </Link>
                    </Stack>
                  </FormGroup>
                  <Button
                    variant="contained"
                    size="large"
                    style={{ width: "100%", marginTop: "40px" }}
                    color="error"
                    onClick={handleBtnStep1Click}
                    disabled={
                      region === "" || userName === "" || userPassword === "" || isTenantsLoading
                    }
                  >
                    {translate("Next")}
                    {isTenantsLoading && (
                      <CircularProgress
                        sx={{
                          width: "1.2rem !important",
                          height: "1.2rem !important",
                          margin: "6px",
                        }}
                      />
                    )}
                  </Button>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      margin: "0px",
                      padding: "30px 0px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      noWrap
                      component="span"
                      sx={{
                        fontSize: "1rem",
                      }}
                    >
                      {translate("Don't have an account?")}
                    </Typography>
                    <Link href="/">{translate("Register here")}</Link>
                  </Stack>
                </>
              )}
              {(step === 1 || step === 2) && (
                <>
                  <FormControl
                    sx={{ margin: "8px 0px", width: "100%" }}
                    error={tenant !== null && !(tenant !== "") && tenant !== undefined}
                  >
                    <InputLabel id="tenant-label">{translate("Select Tenant")}</InputLabel>
                    <Select
                      labelId="tenant-label"
                      id="tenant-select"
                      value={tenant}
                      label={translate("Select Tenent")}
                      onChange={handleChangeTenant}
                    >
                      {tenants.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {tenant !== null && !(tenant !== "") && tenant !== undefined && (
                      <FormHelperText>{translate("Required")}</FormHelperText>
                    )}
                    <Button
                      variant="contained"
                      size="large"
                      style={{ width: "100%", marginTop: "40px" }}
                      color="error"
                      onClick={handleBtnStep2Click}
                      disabled={tenant == "" || isAuthenticathing}
                    >
                      {translate("Login")}
                      {isAuthenticathing && (
                        <CircularProgress
                          sx={{
                            width: "1.2rem !important",
                            height: "1.2rem !important",
                            margin: "6px",
                          }}
                        />
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      style={{ width: "100%", marginTop: "40px" }}
                      onClick={handleBtnStep2BackClick}
                      disabled={isAuthenticathing}
                    >
                      {translate("Back")}
                    </Button>
                  </FormControl>
                </>
              )}
              <div
                style={{
                  paddingTop: matchesmddownbreakpoints || isMobile ? "10px" : "60px",
                }}
              >
                <Stepper activeStep={step} alternativeLabel>
                  {LoginSteps.map((item, index) => (
                    <Step key={index}>
                      <StepLabel>{translate(item.label)}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
