import { memo, useContext } from "react";

import { BasePropsType } from "../../../types/common/EmmsysTypes";
import { DashboardContext } from "../../dashboard/DashboardContext";
import { VeeStatistics } from "../../dashboard/veestatistics";

export const VeeStatisticsWidget = memo(function VeeStatisticsWidget(props: BasePropsType) {
  const { translate, language, globalRefresh, onGeneralError } = props;

  const { month } = useContext<any>(DashboardContext);

  return (
    <VeeStatistics
      translate={translate}
      language={language}
      globalRefresh={globalRefresh === undefined ? false : globalRefresh}
      selectedMonth={month}
      onGeneralError={onGeneralError}
    />
  );
});
