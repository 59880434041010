import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getPostHeaders = () => {
  return {
    "Content-Type": "application/json",
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IMAP_API {
  GetMapPins: (regions: string, cities: string, cityzones: string) => Promise<any>;
  GetMapPinsV2: (
    topLeftLat: number,
    topLeftLong: number,
    bottomRightLat: number,
    bottomRightLong: number,
    zoomLevel: number,
    width: number,
    height: number
  ) => Promise<Array<any>>;
}

const MAP_API: IMAP_API = {
  GetMapPins: async (regions, cities, cityzones) => {
    return await fetch(`${apiUrl}GetMapPins`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: getPostHeaders(),
      body: JSON.stringify({
        quickSearch: null,
        readingStatusCodes: null,
        onlyUnassigned: null,
        alarmCodes: null,
        alarmCategoryCodes: null,
        medium: null,
        submediumCodes: null,
        deviceCodes: null,
        region: regions,
        city: cities,
        street: null,
        cityZone: cityzones,
        tariff: null,
        activityType: null,
        customerType: null,
        manufacturerCodes: null,
        deviceModelCodes: null,
        dnCodes: null,
        accuracyLossRiskIds: null,
        workOrderAssignment: null,
        metersIds: null,
        groupsIds: null,
        dmaInletMetersIds: null,
        dmaOutletMainMetersIds: null,
        dmaOutletEndMetersIds: null,
        routeNames: null,
        routeSequences: null,
        idDisReplaceRecommType: null,
        collectionAreaIds: null,
        billingGroupIds: null,
        customerCustomerTypeIds: null,
        customerCustActivityTypeIds: null,
        soldToIds: null,
        billToIds: null,
        shipToIds: null,
        quickSearchConnectionID: null,
        quickSearchMeterName: null,
        quickSearchMeterSN: null,
        quickSearchElectronicSN: null,
        quickSearchConsumptionPointCode: null,
        quickSearchCustomerAccountNumber: null,
        quickSearchCustomerName: null,
        quickSearchCustomerAddress: null,
        quickSearchMatchWholeWord: null,
        installationStatusCodeArray: null,
        quickSearchMethod: null,
        illegalUsage: null,
        specificTaskStatus: null,
        quickSearchConsumptionPointName: null,
        quickSearchConsumptionPointAddress: null,
        quickSearchConsumptionPointAccountNumber: null,
        meterTags: null,
        meterAttributeId: null,
        meterAttributeValue: null,
        zipCode: null,
        workOrderId: null,
        workOrderIdTaskStatus: null,
        workOrderIdIncludeReassignment: null,
        compoundPartCodeArray: null,
        idWorkCenterArray: null,
        isVirtualMeter: null,
      }),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetMapPinsV2: async (
    topLeftLat,
    topLeftLong,
    bottomRightLat,
    bottomRightLong,
    zoomLevel,
    width,
    height
  ) => {
    return await fetch(`${apiUrl}GetMapPinsV2`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: getPostHeaders(),
      body: JSON.stringify({
        topLeftLat: topLeftLat,
        topLeftLong: topLeftLong,
        bottomRightLat: bottomRightLat,
        bottomRightLong: bottomRightLong,
        zoomLevel: Math.round(zoomLevel),
        width: width,
        height: height,
      }),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
};

export { MAP_API };
