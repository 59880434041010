import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { memo } from "react";
import { ReactComponent as RoundedBox } from "../../assets/icons/RoundedBox.svg";

import { WordWrap } from "../utils/utils";

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  dominantBaseline: "central",
}));

function PieCenterLabel({ children }) {
  const { left, top } = useDrawingArea();
  const lines = WordWrap(children, 10);

  const yfirstline = top + 120 - ((lines.length - 1) / 2) * 20;

  return (
    <StyledText
      x={left + 120}
      y={top + 120}
      sx={{
        textAnchor: "middle",
        fontSize: "1rem",
        fontWeight: 700,
      }}
    >
      {lines.map((line, index) => {
        const xx = left + 120;
        const yy = yfirstline + index * 20;
        return (
          <tspan key={index} x={xx} y={yy}>
            {line}
          </tspan>
        );
      })}
    </StyledText>
  );
}

interface StatusPieChartProps {
  translate: (params: string) => string;
  language: string;
  chartConfig: any;
}

const StatusPieChart = memo(function StatusPieChart(props: StatusPieChartProps) {
  const { chartConfig } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0px 20px",
        overflowX: "auto",
        flexWrap: "wrap",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
      }}
    >
      <Stack
        direction="column"
        spacing={1}
        sx={{
          alignItems: "flex-end",
          justifyContent: "center",
          maxWidth: "250px",
        }}
      >
        <PieChart
          series={[
            {
              data: chartConfig.dataset,
              startAngle: -90,
              innerRadius: 70,
              outerRadius: 100,
              cx: 120,
              cy: 120,
            },
          ]}
          width={240}
          height={chartConfig.height ?? 240}
          legend={{ hidden: true }}
        >
          <PieCenterLabel>{chartConfig.title}</PieCenterLabel>
        </PieChart>
      </Stack>
      <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "0px 0.5rem",
          }}
        >
          {chartConfig.dataset.map((item, index) => {
            return (
              <Box key={index} sx={{ display: "flex", justifyContent: "flex-start" }}>
                <RoundedBox style={{ marginRight: "16px", fill: item.color ?? "#FB8C00" }} />
                <Typography sx={{ fontSize: "0.9rem", paddingTop: "2px" }} noWrap component="span">
                  {item.text}
                </Typography>
              </Box>
            );
          })}
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            alignItems: "flex-end",
            justifyContent: "center",
            width: "100%",
            padding: "0px 0.5rem",
          }}
        >
          {chartConfig.dataset.map((item, index) => {
            return (
              <Box key={index} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography sx={{ fontSize: "0.9rem", paddingTop: "2px" }} noWrap component="span">
                  {item.percent}%
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
});

export { StatusPieChart };
