import { memo, useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridPageCountSelector,
  GridPagination,
  GridToolbarQuickFilter,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import MuiPagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

interface QuickSearchToolbarProps {
  allowAdd: boolean;
}

function QuickSearchToolbar({ allowAdd }: QuickSearchToolbarProps) {
  return (
    <Box
      sx={{
        flex: "1 0 auto",
        display: "flex",
        justifyContent: allowAdd ? "center" : "flex-start",
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          p: 0.5,
          pb: 0,
          border: "1px solid rgb(224, 224, 224)",
          borderRadius: "5px",
          padding: "4px",
        }}
      />
    </Box>
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar(
  props,
  allowAdd,
  filterable,
  addButtonText,
  setDataSetWithId,
  setRowModesModel,
  dataSetWithId
) {
  const handleClick = () => {
    if (dataSetWithId.find((i) => i.id == -1) == undefined) {
      const id = -1;
      setDataSetWithId((oldRows) => [...oldRows, { id, isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit },
      }));
    }
  };

  return (
    <>
      {(allowAdd || filterable) && (
        <GridToolbarContainer {...props}>
          <Box sx={{ flexGrow: 1, paddingBottom: "20px", display: "flex" }}>
            <div></div>
            {allowAdd && (
              <Button
                endIcon={<AddIcon />}
                onClick={handleClick}
                sx={{
                  maxHeight: "40px",
                  backgroundColor: "#0051C1",
                  color: "white",
                  minWidth: "200px",
                  borderRadius: "7px",
                }}
              >
                {addButtonText}
              </Button>
            )}
            <QuickSearchToolbar allowAdd={allowAdd} />
            {filterable && <GridToolbarFilterButton sx={{ color: "#212121", flex: "0 0 auto" }} />}
          </Box>
        </GridToolbarContainer>
      )}
    </>
  );
}

interface CustomGridProps {
  translate: (params: string) => string;
  dataSet: any;
  columns: any;
  allowAdd: boolean;
  filterable: boolean;
  addButtonText: string;
  withCheckBoxSelection?: boolean;
}

const CustomGrid = memo(function CustomGrid(props: CustomGridProps) {
  const {
    translate,
    dataSet,
    columns,
    allowAdd,
    filterable,
    addButtonText,
    withCheckBoxSelection,
  } = props;
  const [dataSetWithId, setDataSetWithId] = useState<any>([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [gridColumns, setGridColumns] = useState([]);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setDataSetWithId(dataSetWithId.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = dataSetWithId.find((row) => row.id === id);
    if (editedRow.isNew) {
      setDataSetWithId(dataSetWithId.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setDataSetWithId(dataSetWithId.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  useEffect(() => {
    const tmpcolumns = columns.map((col) => {
      return col;
    });
    if (allowAdd) {
      tmpcolumns.push({
        field: "actions",
        type: "actions",
        headerName: translate("Actions"),
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label={translate("Save")}
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
                key={0}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label={translate("Cancel")}
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                key={1}
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label={translate("Edit")}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              key={0}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label={translate("Delete")}
              onClick={handleDeleteClick(id)}
              color="inherit"
              key={1}
            />,
          ];
        },
      });
    }
    setGridColumns(tmpcolumns);

    if (dataSet != undefined && dataSet.length > 0) {
      const dstmp = dataSet.map((item, index) => {
        if (item.id === undefined) item.id = index;
        return item;
      });
      setDataSetWithId(dstmp);
    }
  }, []);

  return (
    <DataGrid
      rows={dataSetWithId}
      editMode="row"
      columns={gridColumns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      pageSizeOptions={[5]}
      checkboxSelection={withCheckBoxSelection ?? false}
      disableRowSelectionOnClick
      slots={{
        toolbar: (props) =>
          CustomToolbar(
            props,
            allowAdd,
            filterable,
            addButtonText,
            setDataSetWithId,
            setRowModesModel,
            dataSetWithId
          ),
        loadingOverlay: LinearProgress,
        pagination: CustomPagination,
      }}
      sx={{
        maxWidth: { xs: 250, sm: 570, md: "100%", lg: "100%", xl: "100%" },
        backgroundColor: "white",
        height: "500px",
        border: "none",
        "& .MuiTablePagination-spacer": { flex: "0" },
        "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
        "& .MuiInputBase-root.MuiInput-root:before": { borderBottom: "none" },
        "& .MuiTablePagination-root": { width: "100%" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#F5F5F5",
          fontWeight: "bold",
        },
        "& .MuiDataGrid-selectedRowCount": {
          flex: "1 0 auto",
        },
      }}
      localeText={{
        toolbarFilters: translate("Filter List"),
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
        },
      }}
    />
  );
});

export { CustomGrid };
