import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useContext, useEffect, useState } from "react";

import { Allocation_API } from "../../api/allocationapi";

interface DMACountersProps {
  translate: (params: string) => string;
  globalRefresh: boolean;
  language: string;
  context: any;
}

const DMACounters = memo(function DMACounters(props: DMACountersProps) {
  const { translate, context } = props;

  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const [isCountersLoaded, setIsCountersLoaded] = useState(false);
  const [counters, setCounters] = useState<any>({});

  const { nodeItem } = useContext<any>(context);

  useEffect(() => {
    setIsCountersLoaded(false);

    const nodetype = nodeItem.nodeType ?? "";
    const idnode = nodeItem.nodeId ?? "";

    Allocation_API.GetAllocationSummaryCounters(nodetype, idnode).then(
      (result) => {
        setCounters(result);
        setIsCountersLoaded(true);
      },
      () => {
        setIsCountersLoaded(true);
      }
    );
  }, [nodeItem]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
              }}
            >
              {!isCountersLoaded && (
                <CircularProgress
                  sx={{
                    width: "1.2rem !important",
                    height: "1.2rem !important",
                    margin: "6px",
                  }}
                />
              )}
              <Typography
                noWrap
                component="span"
                sx={{
                  fontSize: "1.38rem",
                  fontWeight: 700,
                }}
              >
                {translate("Counters")}
              </Typography>
              <Tooltip title={translate("Description:")} arrow>
                <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ flexGrow: 1, display: "flex", overflowX: "auto" }}>
              <Stack
                direction={matchessmbreackpoint ? "row" : "column"}
                spacing={2}
                sx={{ flexGrow: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                    {translate("Regions")}
                  </Typography>
                  <Chip
                    label={counters.region === null || counters.region === undefined ? "n/a" : "1"}
                    variant="filled"
                    sx={{
                      backgroundColor: "#1976D2 !important",
                      color: "white !important",
                    }}
                  />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                    {translate("Cities")}
                  </Typography>
                  <Chip
                    label={
                      counters.cities === null || counters.cities === undefined
                        ? counters.city === null || counters.city === undefined
                          ? "n/a"
                          : "1"
                        : counters.cities
                    }
                    variant="filled"
                    sx={{
                      backgroundColor: "#1976D2 !important",
                      color: "white !important",
                    }}
                  />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                    {translate("City Zones")}
                  </Typography>
                  <Chip
                    label={
                      counters.cityZones === null || counters.cityZones === undefined
                        ? counters.cityZone === null || counters.cityZone === undefined
                          ? "n/a"
                          : "1"
                        : counters.cityZones
                    }
                    variant="filled"
                    sx={{
                      backgroundColor: "#1976D2 !important",
                      color: "white !important",
                    }}
                  />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                    {translate("Buildings")}
                  </Typography>
                  <Chip
                    label={
                      counters.buildings === null || counters.buildings === undefined
                        ? counters.building === null || counters.building === undefined
                          ? "n/a"
                          : "1"
                        : counters.buildings
                    }
                    variant="filled"
                    sx={{
                      backgroundColor: "#1976D2 !important",
                      color: "white !important",
                    }}
                  />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                    {translate("Consumption Points")}
                  </Typography>
                  <Chip
                    label={
                      counters.consumptionPoints === null ||
                      counters.consumptionPoints === undefined
                        ? counters.consumptionPoint === null ||
                          counters.consumptionPoint === undefined
                          ? "n/a"
                          : "1"
                        : counters.consumptionPoints
                    }
                    variant="filled"
                    sx={{
                      backgroundColor: "#1976D2 !important",
                      color: "white !important",
                    }}
                  />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", padding: "5px 10px" }} component="span">
                    {translate("Meters")}
                  </Typography>
                  <Chip
                    label={counters.meters ?? "n/a"}
                    variant="filled"
                    sx={{
                      backgroundColor: "#1976D2 !important",
                      color: "white !important",
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});

export { DMACounters };
