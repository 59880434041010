import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { BarChart, useDrawingArea } from "@mui/x-charts";
import { memo } from "react";
import { RoundedBar } from "./RoundedBar";

const xAxisValueFormatter = (value) => `${value}%`;

const chartSetting = {
  sx: {
    flex: "0 1 auto",
  },
};

const StyledTickLine = styled("line")(({ y1 }) => ({
  stroke: "#757575",
  strokeOpacity: "0.3",
  strokeDasharray: "3px",
  shapeRendering: "crispEdges",
  strokeWidth: 1,
  y1: y1,
}));

function CustomXAxisTick(props) {
  const { height } = useDrawingArea();
  const { y2 } = props;

  return <StyledTickLine {...props} y1={y2 == undefined ? null : -1 * height} />;
}

interface HorizontalBarChartProps {
  translate: (params: string) => string;
  language: string;
  chartConfig: any;
}

const HorizontalBarChart = memo(function HorizontalBarChart(props: HorizontalBarChartProps) {
  const { chartConfig } = props;
  let margin = 0;
  if (chartConfig.dataset.length > 0) {
    chartConfig.dataset.forEach((element) => {
      if (element[chartConfig.yAxisLabelField ?? chartConfig.yAxisField] != undefined)
        margin =
          element[chartConfig.yAxisLabelField ?? chartConfig.yAxisField].length > margin
            ? element[chartConfig.yAxisLabelField ?? chartConfig.yAxisField].length
            : margin;
    });
  }

  margin = margin * 7;
  const title = chartConfig.title ?? "";

  return (
    <Box sx={{ flexGrow: 1 }}>
      {title != "" && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "5px",
          }}
        >
          <Typography noWrap component="span" sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {title}
          </Typography>
        </Box>
      )}
      <BarChart
        {...chartSetting}
        height={
          chartConfig.height == undefined || chartConfig.height == 0 ? 500 : chartConfig.height
        }
        margin={{
          top: 30,
          left: margin == 0 ? 30 : margin,
          right: 30,
          bottom: 30,
        }}
        dataset={chartConfig.dataset}
        //disableLine={true}
        yAxis={
          chartConfig.dataset.length > 0
            ? [
                {
                  scaleType: "band",
                  dataKey: chartConfig.yAxisField,
                  //categoryGapRatio: 0.2,
                  //barGapRatio: 0.2,
                },
              ]
            : []
        }
        xAxis={
          chartConfig.dataset.length > 0
            ? [
                {
                  valueFormatter: xAxisValueFormatter,
                },
              ]
            : []
        }
        series={
          chartConfig.dataset.length > 0 ? [{ dataKey: chartConfig.xAxisField, type: "bar" }] : []
        }
        colors={chartConfig.colors}
        layout="horizontal"
        slots={{
          bar: (props) =>
            RoundedBar({
              ...props,
              data: chartConfig.dataset,
              dataKey: chartConfig.xAxisField,
              orientation: "horizontal",
            }),
          axisTick: (props) => CustomXAxisTick(props),
        }}
      ></BarChart>
    </Box>
  );
});

export { HorizontalBarChart };
