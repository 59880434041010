import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IAllocation_API {
  GetAllocationSummaryCounters: (nodeType: number | null, nodeId: string) => Promise<any>;
  GetNodeAllocationDetails: (
    idNode: string,
    tipGrupare: number | null,
    idUtility: number | null,
    referenceMonth: string
  ) => Promise<any>;
}

const Allocation_API: IAllocation_API = {
  GetAllocationSummaryCounters: async (nodeType, nodeId) => {
    return await fetch(
      `${apiUrl}GetAllocationSummaryCounters?nodeType=${nodeType}&idNode=${nodeId}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetNodeAllocationDetails: async (idNode, tipGrupare, idUtility, referenceMonth) => {
    return await fetch(
      `${apiUrl}GetNodeAllocationDetails?idNode=${idNode}&tipGrupare=${tipGrupare}&idUtility=${idUtility}&referenceMonth=${referenceMonth}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
};

export { Allocation_API };
