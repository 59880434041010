import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { memo, useEffect, useState } from "react";

import EmmsysReportViewer from "../reporting/reportviewer";

const allocationReports = [
  {
    id: 0,
    text: "Energy Allocation",
    reportType3: "Building_EnergyAllocation.trdx",
  },
  {
    id: 1,
    text: "HCA Allocation (based on months)",
    reportType3: "Building_HcaAllocationPeriodMonths.trdx",
  },
  {
    id: 2,
    text: "HCA Key Indicator (based on months)",
    reportType3: "Building_HcaAllocationKeyPeriodMonths.trdx",
  },
  {
    id: 3,
    text: "HCA Allocation (based on days)",
    reportType3: "Building_HcaAllocationPeriodDays.trdx",
  },
  {
    id: 4,
    text: "HCA Key Indicator(based on days)",
    reportType3: "Building_HcaAllocationKeyPeriodDays.trdx",
  },
];

interface EmsBuildingAllocationReportsProps {
  translate: (params: string) => string;
  language: string;
  globalRefresh: boolean;
  reportsParams: any;
}

const EmsBuildingAllocationReports = memo(function EmsBuildingAllocationReports({
  translate,
  reportsParams,
}: EmsBuildingAllocationReportsProps) {
  const [selectedReport, setSelectedReport] = useState<any>(0);

  const [reportConfig, setReportConfig] = useState({});

  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
  };

  useEffect(() => {
    if (reportsParams.nodeType != undefined) {
      const rparameters: any = [];
      const findreport = allocationReports.find((r) => r.id === selectedReport) as any;
      let rname = "";
      switch (reportsParams.nodeType) {
        case "3":
          rparameters.push({
            Name: "idBuilding",
            Value: reportsParams.nodeId ?? 0,
            Visible: false,
          });
          rname = findreport.reportType3;
          break;
        case "2":
          rparameters.push(
            { Name: "idBuilding", Value: "", Visible: false },
            {
              Name: "idEndPointExtern",
              Value: reportsParams.nodeId ?? 0,
              Visible: false,
            },
            { Name: "idEndPoint", Value: "", Visible: false }
          );
          rname = findreport.reportType2;
          break;
        default:
          rparameters.push({
            Name: "idBuilding",
            Value: reportsParams.nodeId ?? 0,
            Visible: false,
          });
          rname = findreport.reportType3;
          break;
      }

      setReportConfig({
        rName: rname,
        rParams: rparameters,
      });
    }
  }, [reportsParams.nodeId, selectedReport]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
            }}
          >
            {translate("Reports")}
          </Typography>
          <Tooltip title={translate("Description:")} arrow>
            <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
          </Tooltip>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              p: 0,
            }}
          >
            <FormControl sx={{ m: "0px 1em", minWidth: 250 }}>
              <InputLabel id="select-report-label">{translate("Report")}</InputLabel>
              <Select
                labelId="select-report-label"
                id="select-report"
                value={selectedReport}
                onChange={handleReportChange}
                autoWidth
                label={translate("Report")}
                size="small"
              >
                {allocationReports.map((b, index) => {
                  return (
                    <MenuItem key={index} value={b.id}>
                      {b.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            p: 0,
          }}
        >
          <EmmsysReportViewer width="100%" height="500px" reportOptions={reportConfig} />
        </Box>
      </CardContent>
    </Card>
  );
});

export { EmsBuildingAllocationReports };
