import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import ReactSpeedometer from "react-d3-speedometer";
import { memo, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

import * as Common_Types from "../../../types/common/EmmsysTypes";
import { CustomDropDown } from "../../common/CustomDropDown";
import { COMMON_API } from "../../../api/commonapi";
import { DashboardContext } from "../../dashboard/DashboardContext";

const periodValues = [
  {
    text: "today",
    id: 1,
    value: 1,
  },
  {
    text: "this week",
    id: 2,
    value: 2,
  },
  {
    text: "this month",
    id: 3,
    value: 3,
  },
];

interface GaugeIndicatorWidgetProps extends Common_Types.BasePropsType {
  chartConfig: any;
  onDelete: (params: any) => void;
}

export const GaugeIndicatorWidget = memo(function GaugeIndicatorWidget(
  props: GaugeIndicatorWidgetProps
) {
  const { chartConfig, translate, language, onDelete } = props;

  const { idRegion } = useContext<any>(DashboardContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const cookies = new Cookies();

  const cookiew = cookies.get("ethDashboardWidgets") ?? [];
  const cookiewdata = cookiew.filter((w) => w.wGuid === chartConfig.wGuid)[0];

  const [anchorEl, setAnchorEl] = useState(null);
  const opensettingsmenu = Boolean(anchorEl);

  const [selectedSettingsItem, setSelectedSettingsItem] = useState(
    cookiewdata.wSettings !== undefined ? cookiewdata.wSettings.allowExternalEvents : 0
  );

  const [gaugeValue, setGaugeValue] = useState<any>({});
  const [periodItems, setPeriodItems] = useState<any>([]);
  const [period, setPeriod] = useState(
    cookiewdata.wSettings !== undefined
      ? cookiewdata.wSettings.period !== undefined
        ? cookiewdata.wSettings.period
        : 1
      : 1
  );

  const [refreshData, setRefreshData] = useState(false);
  const [openSettingsWindow, setOpenSettingsWindow] = useState(false);
  const [isRegionsLoaded, setIsRegionsLoaded] = useState(true);
  const [isCitiesLoaded, setIsCitiesLoaded] = useState(true);
  const [isCityZonesLoaded, setIsCityZonesLoaded] = useState(true);
  const [isBillingGroupsLoaded, setIsBillingGroupsLoaded] = useState(true);
  const [regionsItems, setRegionsItems] = useState<Common_Types.CatalogItemType[]>([]);
  const [citiesItems, setCitiesItems] = useState<Common_Types.CatalogItemType[]>([]);
  const [cityZonesItems, setCityZonesItems] = useState<Common_Types.CatalogItemType[]>([]);
  const [billingGroupsItems, setBillingGroupsItems] = useState<Common_Types.CatalogItemType[]>([]);

  const [wSummaryText, setWSummaryText] = useState("");

  const [regionsValues, setRegionsValues] = useState<any>("");
  const [citiesValues, setCitiesValues] = useState<any>("");
  const [cityZonesValues, setCityZonesValues] = useState<any>("");
  const [billingGroupsValues, setBillingGroupsValues] = useState<any>("");

  const [wSummary, setWSummary] = useState<any>({});

  const handleSettingsWindowClose = () => {
    setOpenSettingsWindow(false);
  };

  const handleSaveSettings = () => {
    const newCookieData = cookiew.flatMap((w) => {
      if (w.wGuid === chartConfig.wGuid) {
        w.wSettings = {
          allowExternalEvents: selectedSettingsItem,
          period: period,
          regionId: regionsValues,
          cityId: citiesValues,
          cityZoneId: cityZonesValues,
          billingGroupId: billingGroupsValues,
          wSummary: wSummary,
        };
        return w;
      } else return w;
    });

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    cookies.set("ethDashboardWidgets", newCookieData, { path: "/", expires: d });

    setOpenSettingsWindow(false);
  };

  const handleChangePeriod = (event: SelectChangeEvent) => {
    setPeriod(event.target.value);
  };

  const handleRegionValueChange = (value) => {
    setRegionsValues(value === null ? "" : value.value);
    setWSummary({ ...wSummary, regionName: value === null ? "" : value.text });
  };

  const handleCitiesValueChange = (value) => {
    setCitiesValues(value === null ? "" : value.value);
    setWSummary({ ...wSummary, cityName: value === null ? "" : value.text });
  };

  const handleCityZonesValueChange = (value) => {
    setCityZonesValues(value === null ? "" : value.value);
    setWSummary({ ...wSummary, cityZoneName: value === null ? "" : value.text });
  };

  const handleBillingGroupsValueChange = (value) => {
    setBillingGroupsValues(value === null ? "" : value.value);
    setWSummary({ ...wSummary, billingGroupName: value === null ? "" : value.text });
  };

  const handleSettingsMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuItemClick = (event, mode) => {
    const value = selectedSettingsItem === mode ? null : mode;

    setSelectedSettingsItem(value);
    setAnchorEl(null);
  };

  const handleRemoveMenuItemClick = () => {
    if (onDelete !== undefined) onDelete(chartConfig.wGuid);
  };

  const handleSettingsMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const rids = regionsValues ?? "";
    const cids = citiesValues ?? "";
    const czids = cityZonesValues ?? "";
    const bgids = billingGroupsValues ?? "";
    const objtype = chartConfig.objectType ?? "";

    COMMON_API.GetWGaugeStatistics(objtype, period, 11, rids, cids, czids, bgids).then(
      (statistics) => {
        setGaugeValue(statistics);
      }
    );
  }, [
    period,
    regionsValues,
    citiesValues,
    cityZonesValues,
    billingGroupsValues,
    chartConfig.objectType,
    refreshData,
  ]);

  useEffect(() => {
    const periodvaluestmp = periodValues.map((opt) => {
      opt.text = translate(opt.text);
      return opt;
    });

    setPeriodItems(periodvaluestmp);
  }, [language]);

  useEffect(() => {
    if (regionsValues !== "" && regionsValues !== undefined) {
      setIsCitiesLoaded(false);

      COMMON_API.GetWidgetCatalog(regionsValues, 1).then((cities) => {
        const citiesresult = cities.map((city) => {
          return {
            id: city.code,
            value: city.code,
            text: city.name,
          };
        });
        setCitiesItems(citiesresult);
        setCityZonesItems([]);
        setBillingGroupsItems([]);
        setCitiesValues("");
        setCityZonesValues("");
        setBillingGroupsValues("");
        setIsCitiesLoaded(true);
      });
    } else {
      setCitiesItems([]);
      setCitiesValues("");
      setIsCitiesLoaded(true);
    }
  }, [regionsValues]);

  useEffect(() => {
    if (citiesValues !== "" && citiesValues !== undefined) {
      setIsCityZonesLoaded(false);

      COMMON_API.GetWidgetCatalog(citiesValues, 2).then((cityzones) => {
        const cityzonesresult = cityzones.map((cityzone) => {
          return {
            id: cityzone.code,
            value: cityzone.code,
            text: cityzone.name,
          };
        });
        setCityZonesItems(cityzonesresult);
        setBillingGroupsItems([]);
        setCityZonesValues("");
        setBillingGroupsValues("");
        setIsCityZonesLoaded(true);
      });
    } else {
      setCityZonesItems([]);
      setCityZonesValues("");
      setIsCityZonesLoaded(true);
    }
  }, [citiesValues]);

  useEffect(() => {
    if (cityZonesValues !== "" && cityZonesValues !== undefined) {
      setIsBillingGroupsLoaded(false);
      COMMON_API.GetWidgetCatalog(cityZonesValues, 3).then((billinggroups) => {
        const billinggroupsresult = billinggroups.map((billinggroup) => {
          return {
            id: billinggroup.code,
            value: billinggroup.code,
            text: billinggroup.name,
          };
        });
        setBillingGroupsItems(billinggroupsresult);
        setBillingGroupsValues("");
        setIsBillingGroupsLoaded(true);
      });
    } else {
      setBillingGroupsItems([]);
      setBillingGroupsValues("");
      setIsBillingGroupsLoaded(true);
    }
  }, [cityZonesValues]);

  useEffect(() => {
    if (selectedSettingsItem === 0) {
      if (idRegion !== undefined && regionsItems.length > 0) {
        setRegionsValues(idRegion);
        const rn = regionsItems.filter((r) => r.value?.toString() === idRegion.toString())[0];
        if (rn !== undefined) {
          setWSummary({
            regionName: rn.text,
            cityName: "",
            cityZoneName: "",
            billingGroupName: "",
          });
        }
      }
    }
  }, [idRegion]);

  useEffect(() => {
    if (wSummary !== undefined) {
      setRegionsValues(idRegion);
      const summaytext =
        (wSummary.regionName !== undefined ? wSummary.regionName : "") +
        (wSummary.cityName !== undefined && wSummary.cityName !== ""
          ? " / " + wSummary.cityName
          : "") +
        (wSummary.cityZoneName !== undefined && wSummary.cityZoneName !== ""
          ? " / " + wSummary.cityZoneName
          : "") +
        (wSummary.billingGroupName !== undefined && wSummary.billingGroupName !== ""
          ? " / " + wSummary.billingGroupName
          : "");
      setWSummaryText(summaytext);
    }
  }, [wSummary]);

  useEffect(() => {
    setIsRegionsLoaded(false);

    COMMON_API.GetWidgetCatalog("", 0).then((regions) => {
      const regionsresult = regions.map((region) => {
        return {
          id: region.code,
          value: region.code,
          text: region.name,
        };
      });
      setRegionsItems(regionsresult);

      setRegionsValues(cookiewdata.wSettings !== undefined ? cookiewdata.wSettings.regionId : "");
      setCitiesValues(cookiewdata.wSettings !== undefined ? cookiewdata.wSettings.cityId : "");
      setCityZonesValues(
        cookiewdata.wSettings !== undefined ? cookiewdata.wSettings.cityZoneId : ""
      );
      setBillingGroupsValues(
        cookiewdata.wSettings !== undefined ? cookiewdata.wSettings.billingGroupId : ""
      );
      setWSummary(cookiewdata.wSettings !== undefined ? cookiewdata.wSettings.wSummary : {});
      setIsRegionsLoaded(true);
    });
  }, []);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
              flex: "1 0 auto",
            }}
          >
            {translate(chartConfig.title)}
          </Typography>
          <IconButton
            onClick={() => {
              setRefreshData(!refreshData);
            }}
            size="small"
            sx={{ ml: 2, borderRadius: "unset" }}
          >
            <RefreshIcon />
          </IconButton>
          <IconButton
            onClick={handleSettingsMenuClick}
            size="small"
            sx={{ ml: 2, borderRadius: "unset" }}
            aria-controls={opensettingsmenu ? "settings-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={opensettingsmenu ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="settings-menu"
            open={opensettingsmenu}
            onClose={handleSettingsMenuClose}
            onClick={handleSettingsMenuClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem key="0">
              <ListItemIcon>
                <Switch
                  size="small"
                  edge="end"
                  onChange={(event) => handleSettingsMenuItemClick(event, 0)}
                  checked={selectedSettingsItem === 0}
                />
              </ListItemIcon>
              <ListItemText>{translate("Allow external events")}</ListItemText>
            </MenuItem>
            <MenuItem key="1" onClick={() => setOpenSettingsWindow(true)}>
              <ListItemIcon>
                <SettingsRoundedIcon />
              </ListItemIcon>
              <ListItemText>{translate("Settings")}</ListItemText>
            </MenuItem>
            <MenuItem key="2" onClick={handleRemoveMenuItemClick}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>{translate("Remove from dashboard")}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "0.8rem",
            paddingTop: "0.8rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Select size="small" id="period-select" value={period} onChange={handleChangePeriod}>
                {periodItems.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {translate(item.text)}
                  </MenuItem>
                ))}
              </Select>
              {wSummary !== undefined && (
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 700,
                    padding: "0px 10px",
                  }}
                >
                  {wSummaryText}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "1.5rem",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ReactSpeedometer
            maxValue={100}
            minValue={0}
            height={isMobile ? 150 : 190}
            width={isMobile ? 230 : 290}
            value={gaugeValue.percent ?? 0}
            currentValueText="${value}%"
            needleTransitionDuration={1000}
            needleColor="red"
            startColor="blue"
            segments={10}
            endColor="green"
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "1.5rem",
            }}
          >
            {chartConfig.title}: {gaugeValue.count ?? "n/a"}
          </Box>
        </Box>

        <Dialog open={openSettingsWindow} onClose={handleSettingsWindowClose}>
          <DialogTitle>{translate("Settings")}</DialogTitle>
          <IconButton
            onClick={handleSettingsWindowClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <CustomDropDown
                  translate={translate}
                  dataSet={regionsItems}
                  selectedValue={selectedSettingsItem === 0 ? idRegion : regionsValues}
                  dropDownLabel={"Region"}
                  onValueChange={handleRegionValueChange}
                />
                {!isRegionsLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomDropDown
                  translate={translate}
                  dataSet={citiesItems}
                  selectedValue={citiesValues}
                  dropDownLabel={"Cities"}
                  onValueChange={handleCitiesValueChange}
                />
                {!isCitiesLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomDropDown
                  translate={translate}
                  dataSet={cityZonesItems}
                  selectedValue={cityZonesValues}
                  dropDownLabel={"City Zones"}
                  onValueChange={handleCityZonesValueChange}
                />
                {!isCityZonesLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomDropDown
                  translate={translate}
                  dataSet={billingGroupsItems}
                  selectedValue={billingGroupsValues}
                  dropDownLabel={"Billing Groups"}
                  onValueChange={handleBillingGroupsValueChange}
                />
                {!isBillingGroupsLoaded && (
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ mt: 5 }}>
            <Button variant="outlined" onClick={handleSettingsWindowClose}>
              {translate("Close")}
            </Button>
            <Button variant="contained" onClick={handleSaveSettings}>
              {translate("Save settings")}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
});
