import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState } from "react";

import { CustomSearchBar } from "../common/CustomSearchBar";
import { BuildingsTableV1 } from "../shared/BuildingsTableV1";
import { EmsBuildingAllocationReports } from "./emsallocationreports";

export default function BuildingsAllocation(props) {
  const { translate, language, globalRefresh } = props;

  const [selectedBuildingItem, setSelectedBuildingItem] = useState<any>({});

  const onBuildingChange = (value) => {
    if (value != undefined) {
      setSelectedBuildingItem(value);
    }
  };

  const onSearchValueChange = (value) => {
    if (value != undefined) {
      if (value.length > 1) {
        if (value[1] == "0" || value[1] == "")
          setSelectedBuildingItem({
            idBuilding: value[0],
            idConsumptionPoint: null,
            nodeType: 3,
          });
        else
          setSelectedBuildingItem({
            idBuilding: value[1],
            idConsumptionPoint: value[0],
            nodeType: 2,
          });
      } else
        setSelectedBuildingItem({
          idBuilding: value[0],
          idConsumptionPoint: null,
          nodeType: 3,
        });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            position: "sticky",
            top: "-12px",
            zIndex: "1001",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <CustomSearchBar translate={translate} onValueChange={onSearchValueChange} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BuildingsTableV1
            translate={translate}
            globalRefresh={globalRefresh}
            language={language}
            showAppartments={false}
            onChange={onBuildingChange}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <EmsBuildingAllocationReports
            translate={translate}
            globalRefresh={globalRefresh}
            language={language}
            reportsParams={{
              nodeId:
                selectedBuildingItem.nodeType == 3
                  ? selectedBuildingItem.idBuilding
                  : selectedBuildingItem.idConsumptionPoint,
              nodeType: selectedBuildingItem.nodeType,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
