import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiPagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { memo, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config/config";

import EmmsysReportViewer from "../reporting/reportviewer";
import { useAppStore } from "../../store/store";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;

const columns = [
  {
    field: "property",
    headerName: "Property",
    editable: false,
    flex: 0.2,
  },
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 0.2,
  },
  {
    field: "energy",
    headerName: "Energy (kWh)",
    editable: false,
    flex: 0.2,
  },
  {
    field: "eco",
    headerName: "Eco (kWh/m2)",
    editable: false,
    flex: 0.2,
  },
  {
    field: "cO2",
    headerName: "CO2 (kg)",
    editable: false,
    flex: 0.2,
  },
];

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

interface HeatingReportsProps {
  translate: (params: string) => string;
  language: string;
  globalRefresh: boolean;
  reportsParams: any;
}

const HeatingReports = memo(function HeatingReports({
  translate,
  language,
  reportsParams,
}: HeatingReportsProps) {
  const { appConfig } = useAppStore();
  const cookies = new Cookies();
  // const theme = useTheme();
  // const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  // const [apiError, setApiError] = useState(null);

  const [openReport, setOpenReport] = useState(false);

  const [gridColumns, setGridColumns] = useState<any>([]);
  const [selectedReportId, setSelectedReportId] = useState<any>(0);

  const [eedReports, setEedReports] = useState<any>([]);
  const [eedReportsFlats, setEedReportsFlats] = useState([]);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [reportConfig, setReportConfig] = useState({});

  //const apifilesurl = config.WS_APIFILES_URL;

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleChangeEedReport = (event) => {
    setSelectedReportId(event.target.value);
    if (reportsParams.nodeType == 3) {
      const selreport = eedReports.find((r) => r.id == event.target.value);
      if (selreport != undefined) {
        const pdfurl =
          "GetEMSEEDPdf/" +
          selreport.id +
          "/" +
          dayjs(selreport.reportMonth).format("YYYYMMDD") +
          "/" +
          selreport.idConsumptionPoint +
          "/" +
          selreport.isWinter;
        setReportConfig({
          pdfReportUrl: pdfurl,
        });
      }
    }
  };

  const handleRegenerateReportClick = () => {
    const selreport = eedReports.find((r) => r.id == selectedReportId);
    fetch(apiUrl + "EmsDEERegenerateReport", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        tid: "1",
      },
      body: JSON.stringify({
        idBuilding: reportsParams.nodeId,
        reportMonth: selreport == null ? "" : dayjs(selreport.reportMonth).format("YYYYMMDD"),
      }),
    }).then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          throw new Error(text);
        });
      } else {
        return res.json();
      }
    });
  };

  const handleReportClose = () => {
    setOpenReport(false);
  };

  // const handleOpenReport = (ev) => {
  //   if (reportsParams.nodeType == 3) {
  //     let selreport = eedReports.find((r) => r.id == selectedReportId);
  //     if (selreport != undefined) {
  //       let pdfurl =
  //         "GetEMSEEDPdf/" +
  //         selreport.id +
  //         "/" +
  //         dayjs(selreport.reportMonth).format("YYYYMMDD") +
  //         "/" +
  //         selreport.idConsumptionPoint +
  //         "/" +
  //         selreport.isWinter;

  //       setReportConfig({
  //         pdfReportUrl: pdfurl,
  //       });
  //     }
  //   }
  //   setOpenReport(true);
  // };

  const tid = cookies.get("loginTenant") ?? appConfig?.tid;
  const uid = cookies.get("loginUserId") ?? appConfig?.uid;
  const mid = cookies.get("mid") ?? appConfig?.mid;

  useEffect(() => {
    const tmpcolumns = columns.map((col) => {
      col.headerName = translate(col.headerName);
      return col;
    });

    setGridColumns(tmpcolumns);
  }, [language]);

  useEffect(() => {
    const idnode = reportsParams.nodeId ?? "";
    const nodetype = reportsParams.nodeType ?? "";

    fetch(apiUrl + "GetEmsEEDReports?nodeId=" + idnode + "&nodeType=" + nodetype, {
      mode: "cors",
      headers: {
        tid: tid,
        uid: uid,
        mid: mid,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result != undefined) {
            if (result.length > 0) {
              const resulttmp = result.map((item) => {
                item.date = dayjs(item.reportMonth).format("MMM YYYY");
                return item;
              });

              setEedReports(resulttmp);
              setSelectedReportId(result[0].id);
            } else {
              setEedReports([]);
              setSelectedReportId(0);
            }
          } else setEedReports([]);
        },
        () => {
          setEedReports([]);
        }
      );
  }, [reportsParams]);

  useEffect(() => {
    if (reportsParams.nodeType == 3) {
      fetch(apiUrl + "GetEmsEEDReportFlats?idReport=" + selectedReportId, {
        mode: "cors",
        headers: {
          tid: tid,
          uid: uid,
          mid: mid,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result != undefined && result.length > 0) {
              setEedReportsFlats(result);
            } else setEedReportsFlats([]);
          },
          () => {
            setEedReportsFlats([]);
          }
        );
    }

    if (reportsParams.nodeType == 2) {
      const selreport = eedReports.find((r) => r.id == selectedReportId);
      if (selreport != undefined) {
        const pdfurl =
          "GetEMSEEDPdf/" +
          selreport.id +
          "/" +
          dayjs(selreport.reportMonth).format("YYYYMMDD") +
          "/" +
          selreport.idConsumptionPoint +
          "/" +
          selreport.isWinter;
        setReportConfig({
          pdfReportUrl: pdfurl,
        });
      }
    }
  }, [selectedReportId]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
              flex: "0 1 auto",
            }}
          >
            {translate("Heating Reports")}
          </Typography>
          <Box sx={{ p: 0, m: 0, flex: "0 1 auto" }}>
            <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
          </Box>
          <Divider orientation="vertical" flexItem sx={{ margin: "0px 20px !important" }} />
          <Box sx={{ flex: "0 1 auto", minWidth: "200px" }}>
            <FormControl sx={{ m: "0px 1em", minWidth: 250 }}>
              <InputLabel id="select-eedreportsmonth-label">
                {translate("Reporting month")}
              </InputLabel>
              <Select
                labelId="select-eedreportsmonth-label"
                id="eedreportsmonth-select"
                value={selectedReportId}
                onChange={handleChangeEedReport}
                sx={{ width: "100%" }}
                size="small"
                label={translate("Reporting month")}
              >
                {eedReports.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flex: "0 1 auto", padding: "2px 1rem" }}>
            <Button
              endIcon={<RefreshOutlinedIcon />}
              onClick={handleRegenerateReportClick}
              sx={{
                backgroundColor: "#0051C1",
                color: "white",
                minWidth: "200px",
                borderRadius: "7px",
              }}
            >
              {translate("Regenerate report")}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0.5rem 1.5rem",
          }}
        >
          {reportsParams.nodeType == 3 && (
            <DataGrid
              rows={eedReportsFlats}
              editMode="row"
              columns={gridColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={rowSelectionModel}
              pageSizeOptions={[5]}
              checkboxSelection={false}
              slots={{
                pagination: CustomPagination,
              }}
              sx={{
                backgroundColor: "white",
                height: "400px",
                border: "none",
                "& .MuiTablePagination-spacer": { flex: "0" },
                "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
                "& .MuiInputBase-root.MuiInput-root:before": {
                  borderBottom: "none",
                },
                "& .MuiTablePagination-root": { width: "100%" },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F5F5F5",
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  flex: "1 0 auto",
                },
              }}
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
                },
              }}
            />
          )}
          {reportsParams.nodeType == 2 && (
            <EmmsysReportViewer width="100%" height="500px" reportOptions={reportConfig} />
          )}
        </Box>

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          scroll={"paper"}
          onClose={handleReportClose}
          aria-labelledby="customized-dialog-title"
          open={openReport}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {translate("Report")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleReportClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: 0, minHeight: "500px" }}>
            <EmmsysReportViewer width="100%" height="500px" reportOptions={reportConfig} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleReportClose}>
              {translate("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
});

export { HeatingReports };
