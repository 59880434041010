import { COMMON_API } from "../../../api/commonapi";
import { API } from "../../../api/api";
import { CATALOG_TYPE, PROP_NODE_TYPE } from "../../../components/utils/constants";
import { createContext, useEffect, useState } from "react";
import { BuildingsWidget } from "./BuildingsWidget";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Select,
  Typography,
} from "@mui/material";
import { Section } from "./Section";
import { Card } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const BuildingsContext = createContext({
  buildings: [],
  filters: {},
});

const sectionDictionary = [
  {
    id: "0",
    name: "BuildingsWidget",
    component: BuildingsWidget,
  },
  {
    id: "1",
    name: "Test",
    component: Section,
  },
];

export const TestPage = () => {
  const [buildings, setBuildings] = useState<any>([]);
  const [apartments, setApartments] = useState([]);
  const [filters, setFilters] = useState<any>({});
  const [sections, setSections] = useState<any>([]);

  useEffect(() => {
    const getFilters = async () => {
      const regions = await COMMON_API.GetCatalog(CATALOG_TYPE.REGIONS);
      const cities = await COMMON_API.GetCatalog(CATALOG_TYPE.CITIES);
      const cityZones = await COMMON_API.GetCatalog(CATALOG_TYPE.CITY_ZONES);

      setFilters({
        regions,
        cities,
        cityZones,
      });
    };

    const getBuildings = async () => {
      const nodes = await API.GetBuildings("", "", "");
      const newBuildings = nodes
        .filter((node) => node.nodeType === PROP_NODE_TYPE.BUILDING)
        .map((building) => {
          return {
            id: building.idBuilding,
            name: building.buildingName,
            code: building.buildingCode,
            consumptionPointsNo: building.consumptionPointsNo,
            metersNo: building.metersNo,
          };
        });
      setBuildings(newBuildings);

      const newApartments = nodes
        .filter((node) => node.nodeType === PROP_NODE_TYPE.APARTMENT)
        .map((apartment) => {
          return {
            id: apartment.idBuilding,
            name: apartment.consumptionPointName,
            code: apartment.consumptionPointCode,
            consumptionPointsNo: apartment.consumptionPointsNo,
            metersNo: apartment.metersNo,
          };
        });
      setApartments(newApartments);
    };

    getBuildings();
    getFilters();
  }, []);

  console.log(sections);

  return (
    <>
      <Card sx={{ p: "24px", mb: "24px" }}>
        <Typography variant="h6" sx={{ mb: "16px" }}>
          Section Manager
        </Typography>

        <Select label="Add Section" sx={{ width: "200px" }}>
          {sectionDictionary.map((section) => (
            <ListItemButton
              key={sections.length}
              onClick={() => {
                section.id = crypto.randomUUID();
                setSections([...sections, section]);
              }}
            >
              <ListItemText>{section.name}</ListItemText>
            </ListItemButton>
          ))}
        </Select>
        <List>
          {sections.map((section) => (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => setSections(sections.filter((s) => s.id !== section.id))}
                >
                  <DeleteIcon />
                </IconButton>
              }
              key={section.id}
            >
              {section.name}
            </ListItem>
          ))}
        </List>
      </Card>
      <BuildingsContext.Provider value={{ buildings, filters }}>
        {sections.map((component) => {
          const Component = component.component;
          console.log(Component);
          return <Component key={component.id} />;
        })}
      </BuildingsContext.Provider>
    </>
  );
};
