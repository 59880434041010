import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, SxProps, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {
  createContext,
  memo,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useContext,
} from "react";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";

import { BasePropsType } from "../../types/common/EmmsysTypes";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].text}
    </Typography>
  );
}

const OuterElementContext = createContext({});

// TODO: Fix eslint issue
// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

interface ListboxComponentProps {
  children?: any;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(
  { children, ...other }: ListboxComponentProps,
  ref
) {
  const itemData: any = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    // TODO: Fix eslint issue
    // eslint-disable-next-line no-prototype-builtins
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <Box ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

interface CustomDropDownProps extends Pick<BasePropsType, "translate"> {
  dataSet: any;
  selectFirstValue?: boolean;
  selectedValue?: number;
  dropDownLabel: string;
  dropDownStyle?: boolean;
  onValueChange?: (params: any) => void;
  sx?: SxProps;
}

const CustomDropDown = memo(function CustomDropDown(props: CustomDropDownProps) {
  const {
    translate,
    dataSet,
    selectFirstValue,
    selectedValue,
    dropDownLabel,
    dropDownStyle,
    onValueChange,
    sx,
  } = props;

  const [value, setValue] = useState<any>({});

  const handleValueChange = (event, value) => {
    setValue(value);
    if (onValueChange !== undefined) onValueChange(value);
  };

  const handleIsOptionEqualToValue = (option, value) => {
    if (value === undefined || option === undefined) return false;
    return option.value === value.value;
  };

  useEffect(() => {
    if (selectFirstValue === true) {
      const initialValue = dataSet.length > 0 ? dataSet[0] : undefined;
      if (initialValue !== undefined) {
        setValue(initialValue);
      }
    }
  }, [dataSet]);

  useEffect(() => {
    if (selectedValue !== undefined) {
      const xval = dataSet.find((item) => item.id === selectedValue);
      setValue(xval);
    }
  }, []);

  return (
    <Autocomplete
      sx={sx}
      value={value}
      disableClearable={dropDownStyle == true ? true : false}
      disableListWrap={true}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={dataSet}
      onChange={handleValueChange}
      isOptionEqualToValue={(option: any, value) => handleIsOptionEqualToValue(option, value)}
      getOptionLabel={(option: any) => option.text ?? ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate(dropDownLabel)}
          disabled={dropDownStyle == true ? true : false}
        />
      )}
      renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
    />
  );
});

export { CustomDropDown };
