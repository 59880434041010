import ApartmentIcon from "@mui/icons-material/Apartment";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { BuildingsTable } from "../../shared/BuildingsTable";
import { LeafletMap } from "../../shared/LeafletMap";
import { DMACounters } from "../../shared/Counters";
import { DateValueFormatter } from "../../utils/utils";
import { EmsConsumptionChart } from "../EnergyConsumption/EmsConsumptionChart";
import { CheckSheets } from "./checksheets";
import { EmsCheckReports } from "./emscheckreports";
import { EmsEfficiencyChart } from "../emsefficiencychart";
import { SynopticDiagram } from "./synoptic";
import { DCS_API } from "../../../api/dcsapi";

const nullValueFormatter = (value) => {
  if (value == null) return "n/a";
  else return `Reading rate: ${value}%`;
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        flex: "1 0 auto",
        display: "flex",
        justifyContent: "left-start",
        border: "1px solid  rgb(224, 224, 224)",
        borderRadius: "4px",
        padding: "0px",
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          p: "3px",
        }}
      />
    </Box>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <Box sx={{ flexGrow: 1, paddingBottom: "20px", display: "flex" }}>
        <QuickSearchToolbar />
      </Box>
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: "250px",
    flex: 1,
    valueGetter: (params) => {
      const nume =
        params.row.nodeType == 3 ? params.row.buildingName : params.row.consumptionPointName;
      return nume;
    },
    renderCell: (params) => {
      const { row } = params;
      if (row.nodeType == 3)
        return (
          <div className="building-item">
            <ApartmentIcon />
            <Typography
              noWrap
              component="span"
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                flex: "1 0 auto",
                padding: "0rem 0.4rem",
              }}
            >
              {row.buildingName}
            </Typography>
          </div>
        );
      else
        return (
          <div className="apart-item">
            <HomeIcon />
            <Typography
              noWrap
              component="span"
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                flex: "1 0 auto",
                padding: "0rem 0.4rem",
              }}
            >
              {row.consumptionPointName}
            </Typography>
          </div>
        );
    },
  },
];

interface IEnergyManagementContext {
  nodeItem: any;
  changeNode: (node: any) => void;
}

export const EnergyManagementContext = createContext<IEnergyManagementContext | null>(null);

export const EnergyManagement = (props) => {
  const { translate, language, globalRefresh, onGeneralError } = props;
  const cookies = new Cookies();
  console.log("render emg");
  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const dateValueFormatter = DateValueFormatter;

  const [buildingsSelectorOpen, setBuildingsSelectorOpen] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});
  const [gridColumns, setGridColumns] = useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [isPerformanceLoaded, setIsPerformanceLoaded] = useState(false);
  const [performanceItems, setPerformanceItems] = useState<any>({
    xAxisData: [],
    seriesData: [],
  });

  const selectedEquipment = 0;

  const [buildingsFilter, setBuildingsFilter] = useState<any>([]);
  const [selectedBuildingItem, setSelectedBuildingItem] = useState<any>();
  const [nodeItem, setNodeItem] = useState<any>({});
  const [buildings, setBuildings] = useState<any>([]);

  const [anchorCustomizeViewEl, setAnchorCustomizeViewEl] = useState(null);
  const openCustomizeview = Boolean(anchorCustomizeViewEl);
  const idCustomizeview = openCustomizeview ? "customize-view-popover" : undefined;

  //const [checkedView, setCheckedView] = useState(cookies.get('energymanagementViews') ?? ['emgmap', 'emgbuildings', 'emgconsumption', 'emgcounters', 'emgefficiency', 'emgsynoptic', 'emgchecksheets','emgcheckreports']);

  //temporar scoatem  'emgefficiency', 'emgsynoptic', pentru testul Tasleem
  const [checkedView, setCheckedView] = useState(
    cookies.get("energymanagementViews") ?? [
      "emgmap",
      "emgbuildings",
      //"emgbuildingmeters",
      "emgconsumption",
      "emgcounters",
      "emgchecksheets",
      "emgcheckreports",
    ]
  );

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    const ids = newRowSelectionModel[0].split("_");
    setSelectedBuildingItem({
      idBuilding: ids[0],
      idConsumptionPoint: ids[1],
      nodeType: ids[1] == "" ? "3" : "2",
    });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleFabClick = () => {
    setBuildingsSelectorOpen(!buildingsSelectorOpen);
  };

  const handleToggleView = (value) => () => {
    const currentIndex = checkedView.indexOf(value);
    const newChecked = [...checkedView];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedView(newChecked);
  };

  const handleCustomizeWiewClick = (event) => {
    setAnchorCustomizeViewEl(event.currentTarget);
  };

  const handleCustomizeWiewClose = () => {
    setAnchorCustomizeViewEl(null);
  };

  const handleBackClick = () => {
    setAnchorCustomizeViewEl(null);
  };

  const handleSaveViewClick = () => {
    setAnchorCustomizeViewEl(null);

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);

    const newChecked = [...checkedView];
    cookies.set("energymanagementViews", newChecked, { path: "/", expires: d });
  };

  // const handleDataViewChange = (event, newDataView) => {
  //   setDataView(newDataView);
  // };

  const changeNode = (value) => {
    if (value != undefined) {
      setNodeItem({
        nodeId: value.nodeType === "3" ? value.idBuilding : value.idConsumptionPoint,
        nodeType: value.nodeType,
      });
    }
  };

  const onSearchValueChange = (value) => {
    if (value != undefined) {
      if (value.length > 1) {
        if (value[1] == "0" || value[1] == "")
          setSelectedBuildingItem({
            idBuilding: value[0],
            idConsumptionPoint: null,
            nodeType: "3",
          });
        else
          setSelectedBuildingItem({
            idBuilding: value[1],
            idConsumptionPoint: value[0],
            nodeType: "2",
          });
      } else
        setSelectedBuildingItem({
          idBuilding: value[0],
          idConsumptionPoint: null,
          nodeType: "3",
        });
    }
  };

  const onBuildingAddressFilterChange = (value) => {
    setBuildingsFilter(value);
  };

  useEffect(() => {
    const tmpcolumns = columns.map((col) => {
      col.headerName = translate(col.headerName);
      return col;
    });

    setGridColumns(tmpcolumns);
  }, [language]);

  useEffect(() => {
    setIsPerformanceLoaded(false);

    const currentDate = new Date();
    const dataStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const dataStop = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate() - 1
    );

    DCS_API.GetAnalyseReadingRates(
      0,
      1,
      selectedEquipment,
      "",
      dayjs(dataStart).format("YYYYMMDD"),
      dayjs(dataStop).format("YYYYMMDD"),
      "daily",
      "0"
    ).then(
      (result) => {
        setIsPerformanceLoaded(true);
        const rratesxaxisdata = result.flatMap((rs) => {
          return dayjs(rs.day).toDate();
        });
        const rratesseriesdata = result.flatMap((rs) => {
          return rs.readingRate;
        });
        setPerformanceItems({
          xAxisData: rratesxaxisdata,
          seriesData: rratesseriesdata,
        });
      },
      (error) => {
        setIsPerformanceLoaded(true);
        setPerformanceItems({ xAxisData: [], seriesData: [] });
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [globalRefresh]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {/*<CustomSearchBar translate={translate} onValueChange={onSearchValueChange} />*/}
          </Box>
          <Tooltip title={translate("Customize dashboard")} arrow>
            <IconButton onClick={handleCustomizeWiewClick}>
              <DashboardCustomizeIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={idCustomizeview}
            open={openCustomizeview}
            anchorEl={anchorCustomizeViewEl}
            onClose={handleCustomizeWiewClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                padding: "1rem",
                bgcolor: "background.paper",
                "& .MuiListItem-root": {
                  border: "1px solid #BDBDBD",
                  borderRadius: "8px",
                  margin: "0.5rem 0px",
                },
                "& .MuiListItemIcon-root": {
                  minWidth: "34px",
                },
              }}
              subheader={
                <ListSubheader sx={{ fontWeight: 700, fontSize: "1.5rem", padding: "0px" }}>
                  {translate("Customize view")}
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-emgmap" primary={translate("MAP")} />
                <Switch
                  edge="end"
                  onChange={handleToggleView("emgmap")}
                  checked={checkedView.indexOf("emgmap") !== -1}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-emgmap",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-emgbuildings"
                  primary={translate("Buildings")}
                />
                <Switch
                  edge="end"
                  onChange={handleToggleView("emgbuildings")}
                  checked={checkedView.indexOf("emgbuildings") !== -1}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-emgbuildings",
                  }}
                />
              </ListItem>
              {checkedView.indexOf("emgbuildingmeters") !== -1 && (
                <ListItem>
                  <ListItemIcon>
                    <DragIndicatorIcon />
                  </ListItemIcon>
                  <ListItemText
                    id="switch-list-label-emgbuildingmeters"
                    primary={translate("Meters")}
                  />
                  <Switch
                    edge="end"
                    onChange={handleToggleView("emgbuildingmeters")}
                    checked={checkedView.indexOf("emgbuildingmeters") !== -1}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-emgbuildingmeters",
                    }}
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-emgconsumption"
                  primary={translate("Energy Supplied & Consumed")}
                />
                <Switch
                  edge="end"
                  onChange={handleToggleView("emgconsumption")}
                  checked={checkedView.indexOf("emgconsumption") !== -1}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-emgconsumption",
                  }}
                />
              </ListItem>
              {checkedView.indexOf("emgcounters") !== -1 && (
                <ListItem>
                  <ListItemIcon>
                    <DragIndicatorIcon />
                  </ListItemIcon>
                  <ListItemText
                    id="switch-list-label-emgcounters"
                    primary={translate("Counters")}
                  />
                  <Switch
                    edge="end"
                    onChange={handleToggleView("emgcounters")}
                    checked={checkedView.indexOf("emgcounters") !== -1}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-emgcounters",
                    }}
                  />
                </ListItem>
              )}
              {checkedView.indexOf("emgefficiency") !== -1 && (
                <ListItem>
                  <ListItemIcon>
                    <DragIndicatorIcon />
                  </ListItemIcon>
                  <ListItemText
                    id="switch-list-label-emgefficiency"
                    primary={translate("Efficiency")}
                  />
                  <Switch
                    edge="end"
                    onChange={handleToggleView("emgefficiency")}
                    checked={checkedView.indexOf("emgefficiency") !== -1}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-emgefficiency",
                    }}
                  />
                </ListItem>
              )}
              {checkedView.indexOf("emgsynoptic") !== -1 && (
                <ListItem>
                  <ListItemIcon>
                    <DragIndicatorIcon />
                  </ListItemIcon>
                  <ListItemText
                    id="switch-list-label-emgsynoptic"
                    primary={translate("Synoptic")}
                  />
                  <Switch
                    edge="end"
                    onChange={handleToggleView("emgsynoptic")}
                    checked={checkedView.indexOf("emgsynoptic") !== -1}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-emgsynoptic",
                    }}
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-emgchecksheets"
                  primary={translate("Check Sheets")}
                />
                <Switch
                  edge="end"
                  onChange={handleToggleView("emgchecksheets")}
                  checked={checkedView.indexOf("emgchecksheets") !== -1}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-emgchecksheets",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-emgcheckreports"
                  primary={translate("Reports")}
                />
                <Switch
                  edge="end"
                  onChange={handleToggleView("emgcheckreports")}
                  checked={checkedView.indexOf("emgcheckreports") !== -1}
                  inputProps={{
                    "aria-labelledby": "switch-list-label-emgcheckreports",
                  }}
                />
              </ListItem>
            </List>
            <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
            <Stack
              direction={matchessmbreackpoint ? "row" : "column"}
              spacing={1}
              sx={{ justifyContent: "space-between", padding: "1rem" }}
            >
              <Button variant="outlined" onClick={handleBackClick}>
                {translate("Back")}
              </Button>
              <Button variant="contained" color="primary" onClick={handleSaveViewClick}>
                {translate("Save and close")}
              </Button>
            </Stack>
          </Popover>
        </Grid>
        <EnergyManagementContext.Provider value={{ nodeItem, changeNode }}>
          {checkedView.indexOf("emgmap") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LeafletMap
                translate={translate}
                globalRefresh={globalRefresh}
                mapFilters={buildingsFilter}
              />
            </Grid>
          )}

          {checkedView.indexOf("emgbuildings") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BuildingsTable
                translate={translate}
                globalRefresh={globalRefresh}
                language={language}
                onFilterChange={onBuildingAddressFilterChange}
                onGeneralError={onGeneralError}
              />
            </Grid>
          )}

          {/*{checkedView.indexOf("emgbuildingmeters") !== -1 && (*/}
          {/*  <Grid item xs={12} sm={12} md={12} lg={12}>*/}
          {/*    <BuildingMeters*/}
          {/*      translate={translate}*/}
          {/*      globalRefresh={globalRefresh}*/}
          {/*      language={language}*/}
          {/*      filterParams={{*/}
          {/*        nodeId:*/}
          {/*          selectedBuildingItem.nodeType === "3"*/}
          {/*            ? selectedBuildingItem.idBuilding*/}
          {/*            : selectedBuildingItem.idConsumptionPoint,*/}
          {/*        nodeType: selectedBuildingItem.nodeType,*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*)}*/}

          {checkedView.indexOf("emgconsumption") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <EmsConsumptionChart
                translate={translate}
                language={language}
                context={EnergyManagementContext}
              />
            </Grid>
          )}

          {checkedView.indexOf("emgcounters") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DMACounters
                translate={translate}
                globalRefresh={globalRefresh}
                language={language}
                context={EnergyManagementContext}
              />
            </Grid>
          )}

          {checkedView.indexOf("emgefficiency") !== -1 && (
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                          alignItems: "center",
                        }}
                      >
                        {!isPerformanceLoaded && (
                          <CircularProgress
                            sx={{
                              width: "1.2rem !important",
                              height: "1.2rem !important",
                              margin: "6px",
                            }}
                          />
                        )}
                        <Typography
                          variant="h5"
                          noWrap
                          component="span"
                          sx={{
                            fontSize: "1.38rem",
                            flex: "0 1 auto",
                            fontWeight: 700,
                          }}
                        >
                          {translate("Efficiency")}
                        </Typography>
                        <Tooltip title={translate("Description:")} arrow>
                          <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
                        </Tooltip>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "0px 20px !important" }}
                        />
                        <Stack direction="row" spacing={1} sx={{ margin: "0px 10px", flex: "1" }}>
                          <Chip
                            sx={{
                              padding: "4px 14px !important",
                              borderRadius: "8px",
                            }}
                            icon={<WarningOutlinedIcon sx={{ color: "gray !important" }} />}
                            label={"0 " + translate("Critical Alarms")}
                            variant="outlined"
                          />
                          <Chip
                            sx={{
                              padding: "4px 14px !important",
                              borderRadius: "8px",
                            }}
                            icon={<ErrorOutlinedIcon sx={{ color: "gray !important" }} />}
                            label={"0 " + translate("Alarm Warning")}
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <EmsEfficiencyChart
                        translate={translate}
                        language={language}
                        chartConfig={{
                          lineColor: "#1976D2",
                          xAxis:
                            performanceItems.xAxisData.length > 0
                              ? [
                                  {
                                    id: "yAxis_id",
                                    scaleType: "time",
                                    data: performanceItems.xAxisData,
                                    min: performanceItems.xAxisData[0].getTime(),
                                    max: performanceItems.xAxisData[
                                      performanceItems.xAxisData.length - 1
                                    ].getTime(),
                                    valueFormatter: (params) => dateValueFormatter(params, 1),
                                    tickNumber: 6,
                                  },
                                ]
                              : [],
                          yAxis: [
                            {
                              valueFormatter: (value) => value + " %",
                              label: translate("Performance"),
                            },
                          ],
                          series:
                            performanceItems.seriesData.length > 0
                              ? [
                                  {
                                    id: "EqipmentReadingRates",
                                    data: performanceItems.seriesData,
                                    area: true,
                                    showMark: false,
                                    valueFormatter: nullValueFormatter,
                                  },
                                ]
                              : [],
                          gradientColors: ["#0051C17A", "#0051C10F"],
                        }}
                        viewByMode={2}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {checkedView.indexOf("emgsynoptic") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SynopticDiagram
                translate={translate}
                globalRefresh={globalRefresh}
                language={language}
                viewByMode={1}
              />
            </Grid>
          )}

          {checkedView.indexOf("emgchecksheets") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CheckSheets
                translate={translate}
                globalRefresh={globalRefresh}
                language={language}
                context={EnergyManagementContext}
                onGeneralError={onGeneralError}
              />
            </Grid>
          )}

          {checkedView.indexOf("emgcheckreports") !== -1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <EmsCheckReports
                translate={translate}
                globalRefresh={globalRefresh}
                language={language}
                context={EnergyManagementContext}
              />
            </Grid>
          )}
        </EnergyManagementContext.Provider>
      </Grid>
      {/*<Fab*/}
      {/*  size="medium"*/}
      {/*  color="primary"*/}
      {/*  sx={{*/}
      {/*    position: "fixed",*/}
      {/*    bottom: 16,*/}
      {/*    right: 16,*/}
      {/*  }}*/}
      {/*  onClick={handleFabClick}*/}
      {/*>*/}
      {/*  <ApartmentIcon sx={{ color: "white" }} />*/}
      {/*</Fab>*/}
      {/*<TrapFocus open disableAutoFocus disableEnforceFocus>*/}
      {/*  <Fade appear={false} in={buildingsSelectorOpen}>*/}
      {/*    <Paper*/}
      {/*      role="dialog"*/}
      {/*      aria-modal="false"*/}
      {/*      aria-label="Cookie banner"*/}
      {/*      square*/}
      {/*      variant="outlined"*/}
      {/*      tabIndex={-1}*/}
      {/*      sx={{*/}
      {/*        position: "fixed",*/}
      {/*        bottom: 65,*/}
      {/*        right: 16,*/}
      {/*        p: 0,*/}
      {/*        borderWidth: 1,*/}
      {/*        zIndex: 10000,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          width: "100%",*/}
      {/*          display: "flex",*/}
      {/*          justifyContent: "flex-start",*/}
      {/*          padding: "1rem",*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <DataGrid*/}
      {/*          rows={buildings}*/}
      {/*          columns={gridColumns}*/}
      {/*          autoPageSize*/}
      {/*          rowModesModel={rowModesModel}*/}
      {/*          onRowModesModelChange={handleRowModesModelChange}*/}
      {/*          onRowSelectionModelChange={handleRowSelectionModelChange}*/}
      {/*          rowSelectionModel={rowSelectionModel}*/}
      {/*          pageSizeOptions={[10]}*/}
      {/*          checkboxSelection={false}*/}
      {/*          getCellClassName={(params) => {*/}
      {/*            if (params.field === "city" || params.value == null) {*/}
      {/*              return "";*/}
      {/*            }*/}
      {/*            return params.value >= 15 ? "hot" : "cold";*/}
      {/*          }}*/}
      {/*          slots={{*/}
      {/*            toolbar: (props) => CustomToolbar(props),*/}
      {/*          }}*/}
      {/*          sx={{*/}
      {/*            backgroundColor: "white",*/}
      {/*            height: "500px",*/}
      {/*            border: "none",*/}
      {/*            maxWidth: "280px",*/}
      {/*            "& .MuiTablePagination-spacer": { flex: "0" },*/}
      {/*            "& .MuiInputBase-root.MuiInput-root:before": {*/}
      {/*              borderBottom: "none",*/}
      {/*            },*/}
      {/*            "& .MuiTablePagination-root": {*/}
      {/*              width: "100%",*/}
      {/*              display: "flex",*/}
      {/*              justifyContent: "flex-end",*/}
      {/*            },*/}
      {/*            "& .MuiDataGrid-columnHeaders": {*/}
      {/*              display: "none",*/}
      {/*            },*/}
      {/*            "& .MuiDataGrid-selectedRowCount ": {*/}
      {/*              display: "none",*/}
      {/*            },*/}
      {/*            "& .building-item": {*/}
      {/*              display: "flex",*/}
      {/*            },*/}
      {/*            "& .apart-item": {*/}
      {/*              padding: "0rem 0.9rem",*/}
      {/*              display: "flex",*/}
      {/*            },*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </Box>*/}
      {/*    </Paper>*/}
      {/*  </Fade>*/}
      {/*</TrapFocus>*/}
    </>
  );
};
