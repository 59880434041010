import Check from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BarChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { memo, useEffect, useState } from "react";
import { ReactComponent as RoundedBox } from "../../assets/icons/RoundedBox.svg";

import { HorizontalBarChart } from "../common/horizontalbarchart";
import { VEE_API } from "../../api/veeapi";

const valueFormatter = (value) => `${value}%`;

const dateValueFormatter = (value) => {
  return dayjs(value).format("MMM");
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 32,
  borderRadius: "2rem",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "2rem",
    backgroundColor: theme.palette.mode === "light" ? "#66BB6A" : "#308fe8",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: 100, padding: "0px 0.5rem" }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: "right" }}
        >{`${props.value}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

interface VeeStatisticsProps {
  translate: (params: string) => string;
  language: string;
  selectedMonth: dayjs.Dayjs;
  globalRefresh: boolean;
  onGeneralError: (error: any) => void;
}

const VeeStatistics = memo(function VeeStatistics({
  translate,
  language,
  selectedMonth,
  globalRefresh,
  onGeneralError,
}: VeeStatisticsProps) {
  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  // const [apiError, setApiError] = useState(null);

  const [isBillingGroupsLoaded, setIsBillingGroupsLoaded] = useState(false);
  const [billingGroupsItems, setBillingGroupsItems] = useState<any>([]);
  const [selectedBillingGroup, setSelectedBillingGroup] = useState(0);

  const [selectedViewMode, setSelectedViewMode] = useState(1);

  const [isVeeStatisticsLoaded, setIsVeeStatisticsLoaded] = useState(false);
  const [veeStatistics, setVeeStatistics] = useState<any>({});
  const [veeStatistics1Items, setVeeStatistics1Items] = useState([]);
  const [veeStatistics2Items, setVeeStatistics2Items] = useState<any>([]);
  const [veeStatistics3Items, setVeeStatistics3Items] = useState([]);
  const [veeStatistics4Items, setVeeStatistics4Items] = useState([]);
  const [veeStatistics5Items, setVeeStatistics5Items] = useState([]);

  const handleBillingGroupChange = (event) => {
    setSelectedBillingGroup(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openaviewmodemenu = Boolean(anchorEl);

  const handleViewModeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewModeItemClick = (event, mode) => {
    setSelectedViewMode(mode);
    setAnchorEl(null);
  };

  const handleViewModeClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsVeeStatisticsLoaded(false);
    setIsBillingGroupsLoaded(false);

    const dataStart = new Date(selectedMonth.year(), selectedMonth.month(), 1);
    // let dataStop = new Date(
    //   dataStart.getFullYear(),
    //   dataStart.getMonth() + 1,
    //   dataStart.getDate() - 1,
    // );

    VEE_API.GetVeeBillingGroups(dayjs(dataStart).format("YYYYMMDD"), "").then(
      (result) => {
        if (result != null) {
          result.splice(0, 0, {
            idBillingGroup: 0,
            billingGroupName: "All",
            lunaInchisa: null,
          });
          setBillingGroupsItems(result);
        } else {
          setBillingGroupsItems([
            { idBillingGroup: 0, billingGroupName: "All", lunaInchisa: null },
          ]);
        }

        setIsBillingGroupsLoaded(true);
      },
      (error) => {
        setBillingGroupsItems([{ idBillingGroup: 0, billingGroupName: "All", lunaInchisa: null }]);
        setSelectedBillingGroup(0);
        setIsBillingGroupsLoaded(true);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [globalRefresh]);

  useEffect(() => {
    setIsVeeStatisticsLoaded(false);

    const dataStart = new Date(selectedMonth.year(), selectedMonth.month(), 1);
    const billingDateStart = new Date(dataStart.getFullYear(), 0, 1);

    VEE_API.GetVeeStatistics(
      dayjs(dataStart).format("YYYYMMDD"),
      "",
      dayjs(billingDateStart).format("YYYYMMDD")
    ).then(
      (result) => {
        setIsVeeStatisticsLoaded(true);

        const ch1data: any = [];
        const ch2data: any = [];
        const ch3data: any = [];
        const ch4data: any = [];

        const ch5data: any = [];

        if (result != null) {
          ch1data.push(
            {
              statusName: "Open",
              metersCount: result.grupuriFacturare,
              percent:
                result.grupuriFacturare == 0
                  ? 0
                  : Math.floor(
                      parseFloat(
                        (result.grupuriFacturareLunaDeschisa / result.grupuriFacturare).toString()
                      ) * 10000
                    ) / 100,
              color: "red",
            },
            {
              statusName: "Closed",
              metersCount: result.grupuriFacturare,
              percent:
                result.grupuriFacturare == 0
                  ? 0
                  : Math.floor(
                      parseFloat(
                        (result.grupuriFacturareLunaInchisa / result.grupuriFacturare).toString()
                      ) * 10000
                    ) / 100,
              color: "green",
            }
          );

          ch2data.push(
            {
              statusName: "Initial state",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.contoareStareInitiala / result.contoare).toString()) *
                        10000
                    ) / 100,
              color: "gray",
            },
            {
              statusName: "Validated",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.contoareStareValidat / result.contoare).toString()) * 10000
                    ) / 100,
              color: "green",
            },
            {
              statusName: "Invalidated",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.contoareStareInvalidat / result.contoare).toString()) *
                        10000
                    ) / 100,
              color: "red",
            },
            {
              statusName: "Recheck",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.contoareStareRecheck / result.contoare).toString()) * 10000
                    ) / 100,
              color: "yellow",
            }
          );

          ch3data.push(
            {
              statusName: "Missing",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.indexLipsa / result.contoare).toString()) * 10000
                    ) / 100,
              color: "gray",
            },
            {
              statusName: "Read",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.indexStareCitit / result.contoare).toString()) * 10000
                    ) / 100,
              color: "green",
            },
            {
              statusName: "Manual read",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.indexStareEditat / result.contoare).toString()) * 10000
                    ) / 100,
              color: "yellow",
            },
            {
              statusName: "Estimated",
              metersCount: result.contoare,
              percent:
                result.contoare == 0
                  ? 0
                  : Math.floor(
                      parseFloat((result.indexStareEstimat / result.contoare).toString()) * 10000
                    ) / 100,
              color: "orange",
            }
          );

          ch4data.push({
            statusName: "Exported",
            metersCount: result.contoare,
            percent:
              result.contoare == 0
                ? 0
                : Math.floor(
                    parseFloat((result.contoareExportare / result.contoare).toString()) * 10000
                  ) / 100,
            color: "green",
          });

          if (result.previousData.length > 0) {
            result.previousData.map((item) => {
              ch5data.push({
                date: item.date,
                statusName1: "Initial state",
                metersCount1: item.contoare,
                percent1:
                  item.contoare == 0
                    ? 0
                    : Math.floor(
                        parseFloat((item.contoareStareInitiala / item.contoare).toString()) * 10000
                      ) / 100,
                statusName2: "Validated",
                metersCount2: item.contoare,
                percent2:
                  item.contoare == 0
                    ? 0
                    : Math.floor(
                        parseFloat((item.contoareStareValidat / item.contoare).toString()) * 10000
                      ) / 100,
                statusName3: "Invalidated",
                metersCount3: item.contoare,
                percent3:
                  item.contoare == 0
                    ? 0
                    : Math.floor(
                        parseFloat((item.contoareStareInvalidat / item.contoare).toString()) * 10000
                      ) / 100,
                statusName4: "Recheck",
                metersCount4: item.contoare,
                percent4:
                  item.contoare == 0
                    ? 0
                    : Math.floor(
                        parseFloat((item.contoareStareRecheck / item.contoare).toString()) * 10000
                      ) / 100,
              });
            });
          }
        }

        setVeeStatistics(result);
        setVeeStatistics1Items(ch1data);
        setVeeStatistics2Items(ch2data);
        setVeeStatistics3Items(ch3data);
        setVeeStatistics4Items(ch4data);
        setVeeStatistics5Items(ch5data);
      },
      (error) => {
        setIsVeeStatisticsLoaded(true);
        setVeeStatistics1Items([]);
        setVeeStatistics2Items([]);
        setVeeStatistics3Items([]);
        setVeeStatistics4Items([]);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [globalRefresh, selectedMonth, selectedBillingGroup]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
          <Grid item xs={12} md={13} lg={13}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
                alignItems: "center",
              }}
            >
              {(!isVeeStatisticsLoaded || !isBillingGroupsLoaded) && (
                <CircularProgress
                  sx={{
                    width: "1.2rem !important",
                    height: "1.2rem !important",
                    margin: "6px",
                  }}
                />
              )}
              <Typography
                noWrap
                component="span"
                sx={{
                  fontSize: "1.38rem",
                  fontWeight: 700,
                  flex: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {translate("VEE")}
                <Tooltip title={translate("Description:")} arrow>
                  <InfoIcon sx={{ margin: "0px 0.9rem", color: "#0051C1" }} />
                </Tooltip>
              </Typography>

              {selectedViewMode === 2 && (
                <FormControl sx={{ m: "0px 1em", minWidth: 200 }}>
                  <InputLabel id="select-billinggroup-label">
                    {translate("Billing group")}
                  </InputLabel>
                  <Select
                    labelId="select-billinggroup-label"
                    id="select-billinggroup"
                    value={selectedBillingGroup}
                    onChange={handleBillingGroupChange}
                    autoWidth
                    label={translate("Billing group")}
                  >
                    {billingGroupsItems.map((b, index) => {
                      return (
                        <MenuItem key={index} value={b.idBillingGroup}>
                          {b.billingGroupName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <IconButton
                onClick={handleViewModeClick}
                size="small"
                sx={{ ml: 2, borderRadius: "unset" }}
                aria-controls={openaviewmodemenu ? "view-mode-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openaviewmodemenu ? "true" : undefined}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {selectedViewMode === 0 && (
          <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
            <Grid item xs={12} md={13} lg={13}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "flex-start",
                  padding: "3rem 0.5rem",
                  margin: 0,
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }} component="span">
                  {translate("Validated") + " (" + veeStatistics.contoareStareValidat + ")"}
                </Typography>
                <Box sx={{ flexGrow: 1, mr: 1 }}>
                  <LinearProgressWithLabel
                    variant="determinate"
                    value={
                      veeStatistics.contoare == 0
                        ? 0
                        : Math.floor(
                            parseFloat(
                              (
                                veeStatistics.contoareStareValidat / veeStatistics.contoare
                              ).toString()
                            ) * 10000
                          ) / 100
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        )}

        {selectedViewMode === 1 && (
          <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
            <Grid item xs={12} md={4} lg={4}>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: "0px 0.5rem",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    justifyContent: "flex-start",
                    padding: "2rem 0.5rem",
                    margin: 0,
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <Chip
                      label={
                        (veeStatistics.contoare === 0
                          ? 0
                          : Math.floor(
                              parseFloat(
                                (
                                  veeStatistics.contoareStareValidat / veeStatistics.contoare
                                ).toString()
                              ) * 10000
                            ) / 100) + " %"
                      }
                      color="success"
                    />
                  </Box>
                  <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }} component="span">
                    {translate("Validated") + " (" + veeStatistics.contoareStareValidat + ")"}
                  </Typography>
                </Stack>
                {veeStatistics2Items.map((item, index) => {
                  return (
                    <Box key={index} sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <RoundedBox
                        style={{
                          fill: item.color ?? "#FB8C00",
                          margin: "0px 10px",
                        }}
                      />
                      <Typography
                        sx={{ fontSize: "0.9rem", paddingTop: "3px" }}
                        noWrap
                        component="span"
                      >
                        {item.statusName}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Grid>

            <Grid
              item
              md={1}
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                width: "1rem",
                justifyContent: "center",
              }}
            >
              <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "fnone",
                },
                justifyContent: "center",
              }}
            >
              <Divider orientation="horizontal" flexItem sx={{ margin: "0 2rem", flexGrow: 1 }} />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <BarChart
                height={350}
                dataset={veeStatistics5Items}
                xAxis={
                  veeStatistics5Items.length > 0
                    ? [
                        {
                          scaleType: "band",
                          dataKey: "date",
                          valueFormatter: dateValueFormatter,
                        },
                      ]
                    : []
                }
                yAxis={[
                  {
                    tickSize: 6,
                    tickFontSize: 12,
                    valueFormatter: valueFormatter,
                  },
                ]}
                series={
                  veeStatistics5Items.length > 0
                    ? [
                        {
                          dataKey: "percent1",
                          valueFormatter: valueFormatter,
                          stack: "total",
                        },
                        {
                          dataKey: "percent2",
                          valueFormatter: valueFormatter,
                          stack: "total",
                        },
                        {
                          dataKey: "percent3",
                          valueFormatter: valueFormatter,
                          stack: "total",
                        },
                        {
                          dataKey: "percent4",
                          valueFormatter: valueFormatter,
                          stack: "total",
                        },
                      ]
                    : []
                }
                colors={["gray", "green", "red", "yellow"]}
              ></BarChart>
            </Grid>
          </Grid>
        )}

        {selectedViewMode === 2 && (
          <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "1rem 5px",
                    }}
                  >
                    <Typography noWrap component="span" sx={{ fontSize: "1rem", fontWeight: 700 }}>
                      {translate("Billing Groups") +
                        (veeStatistics === null ? "" : " " + veeStatistics.grupuriFacturare)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <HorizontalBarChart
                    translate={translate}
                    language={language}
                    chartConfig={{
                      dataset: veeStatistics1Items,
                      seriesValueFormatter: valueFormatter,
                      title: "",
                      xAxisField: "percent",
                      yAxisField: "statusName",
                      yAxisLabelField: "statusName",
                      yAxisExtField: "metersCount",
                      colors: ["#1976D2"],
                      height: 250,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={1}
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                width: "1rem",
                justifyContent: "center",
              }}
            >
              <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "fnone",
                },
                justifyContent: "center",
              }}
            >
              <Divider orientation="horizontal" flexItem sx={{ margin: "0 2rem", flexGrow: 1 }} />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "1rem 5px",
                    }}
                  >
                    <Typography noWrap component="span" sx={{ fontSize: "1rem", fontWeight: 700 }}>
                      {translate("Meters") +
                        (veeStatistics === null ? "" : " " + veeStatistics.contoare)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <HorizontalBarChart
                    translate={translate}
                    language={language}
                    chartConfig={{
                      dataset: veeStatistics2Items,
                      seriesValueFormatter: valueFormatter,
                      title: "",
                      xAxisField: "percent",
                      yAxisField: "statusName",
                      yAxisLabelField: "statusName",
                      yAxisExtField: "metersCount",
                      colors: ["#1976D2"],
                      height: 250,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "1rem 5px",
                    }}
                  >
                    <Typography noWrap component="span" sx={{ fontSize: "1rem", fontWeight: 700 }}>
                      {translate("Indexes") +
                        (veeStatistics === null ? "" : " " + veeStatistics.contoare)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <HorizontalBarChart
                    translate={translate}
                    language={language}
                    chartConfig={{
                      dataset: veeStatistics3Items,
                      seriesValueFormatter: valueFormatter,
                      title: "",
                      xAxisField: "percent",
                      yAxisField: "statusName",
                      yAxisLabelField: "statusName",
                      yAxisExtField: "metersCount",
                      colors: ["#1976D2"],
                      height: 250,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={1}
              lg={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                width: "1rem",
                justifyContent: "center",
              }}
            >
              <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "fnone",
                },
                justifyContent: "center",
              }}
            >
              <Divider orientation="horizontal" flexItem sx={{ margin: "0 2rem", flexGrow: 1 }} />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "1rem 5px",
                    }}
                  >
                    <Typography noWrap component="span" sx={{ fontSize: "1rem", fontWeight: 700 }}>
                      {translate("Billing Export") +
                        (veeStatistics === null ? "" : " " + veeStatistics.contoare)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <HorizontalBarChart
                    translate={translate}
                    language={language}
                    chartConfig={{
                      dataset: veeStatistics4Items,
                      seriesValueFormatter: valueFormatter,
                      title: "",
                      xAxisField: "percent",
                      yAxisField: "statusName",
                      yAxisLabelField: "statusName",
                      yAxisExtField: "metersCount",
                      colors: ["#1976D2"],
                      height: 250,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Menu
          anchorEl={anchorEl}
          id="view-mode-menu"
          open={openaviewmodemenu}
          onClose={handleViewModeClose}
          onClick={handleViewModeClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem key="0" onClick={(event) => handleViewModeItemClick(event, 0)}>
            <ListItemIcon>{selectedViewMode === 0 && <Check />}</ListItemIcon>
            <ListItemText>{translate("Compact")}</ListItemText>
          </MenuItem>
          <MenuItem key="1" onClick={(event) => handleViewModeItemClick(event, 1)}>
            <ListItemIcon>{selectedViewMode === 1 && <Check />}</ListItemIcon>
            <ListItemText>{translate("Standard")}</ListItemText>
          </MenuItem>
          <MenuItem key="2" onClick={(event) => handleViewModeItemClick(event, 2)}>
            <ListItemIcon>{selectedViewMode === 2 && <Check />}</ListItemIcon>
            <ListItemText>{translate("Large")}</ListItemText>
          </MenuItem>
        </Menu>
      </CardContent>
    </Card>
  );
});

export { VeeStatistics };
