import {
  RouterProvider,
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import { ProtectedRoute } from "./protectedroute";

import LoginLayout from "../../login";
import { useAppStore } from "../../store/store";
import Dashboard from "../dashboard/dashboard";
import BuildingsAllocation from "../ethoutcomes/buildingsallocation";
import CustomerAwareness from "../ethoutcomes/customerawareness";
import DistrictHeatAndCooling from "../ethoutcomes/heatandcooling";
import Home from "../home/home";
import OperationalVisibility from "../wateroutcomes/operationalvisibility";
//import EnergyManagement from "../../components/ethoutcomes/EnergyManagement";
import { TestPage } from "../../components/shared/Section/TestPage";
import Meters from "../../components/meters/Meters";

import { UserDashboard } from "../dashboard/DashboardContext";
import { EnergyManagement } from "../ethoutcomes/EnergyManagement/EnergyManagementContext";

function RootBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>{"This page doesn't exist!"}</div>;
    }

    if (error.status === 401) {
      return <div>{"You aren't authorized to see this"}</div>;
    }

    if (error.status === 503) {
      return <div>{"Looks like our API is down"}</div>;
    }
  }

  return <div>{"Something went wrong"}</div>;
}

const EmmsysRoutes = (props) => {
  const { authToken } = useAppStore();
  const { installationType } = props;

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      id: 0,
      path: "/service",
      element: <div>Service Page</div>,
    },
    {
      id: 1,
      path: "/about-us",
      element: <div>About Us</div>,
    },
  ];

  const ethRoutesForAuthenticatedOnly = [
    {
      id: 0,
      path: "/",
      element: <ProtectedRoute {...props} />,
      children: [
        {
          id: 0,
          path: "/",
          element: <Home {...props} />,
        },
        {
          id: 1,
          path: "/dashboard",
          element: <UserDashboard {...props} />,
        },
        {
          id: 2,
          path: "/outcomes/districtheatandcooling",
          element: <DistrictHeatAndCooling {...props} />,
        },
        {
          id: 3,
          path: "/outcomes/energymanagement",
          element: <EnergyManagement {...props} />,
        },
        {
          id: 4,
          path: "/outcomes/buildingallocation",
          element: <BuildingsAllocation {...props} />,
        },
        {
          id: 5,
          path: "/outcomes/customerawareness",
          element: <CustomerAwareness {...props} />,
        },
        {
          id: 6,
          path: "/login",
          element: <LoginLayout {...props} />,
        },
        {
          id: 8,

          path: "/meters",
          element: <Meters {...props} />,
        },
        {
          id: 9,

          path: "/test",
          element: <TestPage {...props} />,
        },
      ],
      errorElement: <RootBoundary />,
    },
  ];

  const waterRoutesForAuthenticatedOnly = [
    {
      id: 0,
      path: "/",
      element: <ProtectedRoute {...props} />,
      children: [
        {
          id: 0,
          path: "/",
          element: <Home {...props} />,
        },
        {
          id: 1,
          path: "/dashboard",
          element: <Dashboard {...props} />,
        },
        {
          id: 2,
          path: "/outcomes/operationalvisibility",
          element: <OperationalVisibility {...props} />,
        },
        {
          id: 3,
          path: "/login",
          element: <LoginLayout {...props} />,
        },
      ],
      errorElement: <RootBoundary />,
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      id: 0,
      path: "/login",
      element: <LoginLayout {...props} />,
      errorElement: <RootBoundary />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  // @ts-ignore
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(authToken == null ? routesForNotAuthenticatedOnly : []),
    ...(installationType === "eth"
      ? ethRoutesForAuthenticatedOnly
      : waterRoutesForAuthenticatedOnly),
  ]);

  return <RouterProvider router={router} />;
};

export default EmmsysRoutes;
