import { Navigate, Outlet } from "react-router-dom";
import MiniDrawer from "../../mainlayoutcontent";
import { useAppStore } from "../../store/store";

export const ProtectedRoute = (props) => {
  const { authToken } = useAppStore();

  if (authToken == null) {
    return <Navigate to="/login" />;
  }

  return (
    <MiniDrawer {...props}>
      <Outlet />
    </MiniDrawer>
  );
};
