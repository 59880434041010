import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IWO_API {
  GetWorkOrdersStatus: (start: string, stop: string, region: string) => Promise<Array<any>>;
  GetFieldWorkerEfficiencyOverall: (
    start: string,
    stop: string,
    fieldWorkerId: string,
    dbSuffix: string,
    woReferenceDay: number,
    woReferenceStatus: number,
    taskReasonArray: string
  ) => Promise<Array<any>>;
}

const WO_API: IWO_API = {
  GetWorkOrdersStatus: async (start, stop, region) => {
    return await fetch(
      `${apiUrl}GetWorkOrdersStatus?dateStart=${start}&dateStop=${stop}&region=${region}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetFieldWorkerEfficiencyOverall: async (
    start,
    stop,
    fieldWorkerId,
    dbSuffix,
    woReferenceDay,
    woReferenceStatus,
    taskReasonArray
  ) => {
    return await fetch(
      `${apiUrl}GetFieldWorkerEfficiencyOverall?dateStart=${start}&dateStop=${stop}&fieldWorkerId=${fieldWorkerId}&dbSuffix=${dbSuffix}&woReferenceDay=${woReferenceDay}&woReferenceStatus=${woReferenceStatus}&taskReasonArray=${taskReasonArray}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
};

export { WO_API };
