import AddIcon from "@mui/icons-material/Add";
import { default as CancelIcon, default as CloseIcon } from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MuiPagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridPagination,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";

import { useAppStore } from "../../store/store";

const coverageValues = {
  "all meters": "all meters",
  meter: "meter",
};

function AlarmsQuickSearchToolbar() {
  return (
    <Box sx={{ flex: "1 0 auto", display: "flex", justifyContent: "center" }}>
      <GridToolbarQuickFilter
        sx={{
          p: 0.5,
          pb: 0,
          border: "1px solid rgb(224, 224, 224)",
          borderRadius: "5px",
          padding: "4px",
        }}
      />
    </Box>
  );
}

function AlarmsPagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function AlarmsCustomPagination(props) {
  return <GridPagination ActionsComponent={AlarmsPagination} {...props} />;
}

function AlarmsCustomToolbar(props) {
  const { addButtonText, setAlarmsRows, setAlarmsRowModesModel, alarmsRows } = props;

  const handleAlarmsClick = () => {
    if (alarmsRows.find((i) => i.id === -1) === undefined) {
      const id = -1;
      setAlarmsRows((oldRows) => [
        ...oldRows,
        { id: id, isNew: true, alarm: "", coverage: "", identifier: "" },
      ]);
      setAlarmsRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "description" },
      }));
    }
  };

  return (
    <GridToolbarContainer {...props}>
      <Box sx={{ flexGrow: 1, paddingBottom: "20px", display: "flex" }}>
        <div style={{ flex: "0 0 auto" }}></div>
        <Button
          endIcon={<AddIcon />}
          onClick={handleAlarmsClick}
          sx={{
            maxHeight: "40px",
            backgroundColor: "#0051C1",
            color: "white",
            minWidth: "200px",
            borderRadius: "7px",
          }}
        >
          {addButtonText}
        </Button>
        <AlarmsQuickSearchToolbar />
        <GridToolbarFilterButton sx={{ color: "#212121", flex: "0 0 auto" }} />
      </Box>
    </GridToolbarContainer>
  );
}

export function AlarmsForSubscription(props) {
  const {
    apiurl,
    tid,
    uid,
    mid,
    translate,
    selectedId,
    selectedDescr,
    selectedEmailTo,
    selectedFrequency,
    openAlarms,
    handleAlarmsClose,
    alarmValues,
    ...otherProps
  } = props;
  console.log("[CVT1]", "AlarmsForSubscription - otherProps", otherProps);
  const [alarmsRows, setAlarmsRows] = useState<any>([]);
  const [alarmsRowModesModel, setAlarmsRowModesModel] = useState<any>({});
  const [alarmsSnackbar, setAlarmsSnackbar] = useState<any>(null);
  // const [isAlarmsRowsLoaded, setIsAlarmsRowsLoaded] = useState(false);

  const handleAlarmsCloseSnackbar = () => setAlarmsSnackbar(null);

  const handleAlarmsRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    console.log("[CVT]", "handleAlarmsRowEditStop");
  };

  const handleAlarmsEditClick = (id) => () => {
    console.log("[CVT]", "handleAlarmsEditClick");
    setAlarmsRowModesModel({
      ...alarmsRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleAlarmsSaveClick = (id) => () => {
    console.log("[CVT]", "handleAlarmsSaveClick");
    setAlarmsRowModesModel({
      ...alarmsRowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };
  //Confirmation when deleting dialog
  const [alarmsPromiseArguments, setAlarmsPromiseArguments] = React.useState<any>(null);
  const noAlarmsButtonRef = useRef(null);
  const handleAlarmsNo = () => {
    //const { id } = promiseArguments;
    //resolve(oldRow); // Resolve with the old row to not update the internal state
    setAlarmsPromiseArguments(null);
  };

  const handleAlarmsYes = async () => {
    const { id } = alarmsPromiseArguments;

    try {
      // Make the HTTP request to delete in the backend

      fetch(apiurl + "DeleteAlarmFromSubscription?idSubscriptionAlarm=" + id, {
        mode: "cors",
        method: "PUT",
        headers: {
          tid: tid,
          uid: uid,
          mid: mid,
          Authorization: "bearer " + useAppStore.getState().authToken,
          RefreshToken: useAppStore.getState().refreshToken,
        },
      })
        .then(
          (res) => res.json(),
          (error) => {
            console.log("[CVT]", "[API] DeleteAlarmFromSubscription ERR1", error);

            setAlarmsSnackbar({
              children: translate("Alarm deletion failed"),
              severity: "error",
            });
            setAlarmsPromiseArguments(null);
          }
        )
        .then(
          (result) => {
            console.log("[CVT]", "[API] DeleteAlarmFromSubscription", result);
            if (result.id === null) {
              console.log("[CVT]", "[API] DeleteAlarmFromSubscription ERR2.1", result.error);

              setAlarmsSnackbar({
                children: translate("Subscription deletion failed"),
                severity: "error",
              });
              setAlarmsPromiseArguments(null);
            }

            setAlarmsRows(alarmsRows.filter((row) => row.id !== id));
            setAlarmsSnackbar({
              children: translate("Alarm successfully deleted"),
              severity: "success",
            });
            setAlarmsPromiseArguments(null);
          },
          (error) => {
            console.log("[CVT]", "[API] DeleteAlarmFromSubscription ERR2.2", error);

            setAlarmsSnackbar({
              children: translate("Alarm deletion failed"),
              severity: "error",
            });
            setAlarmsPromiseArguments(null);
          }
        );

      //const response = await mutateRow(newRow);
      //setSnackbar({ children: 'User successfully saved', severity: 'success' });
      //resolve(response);
      //setPromiseArguments(null);
    } catch (error) {
      setAlarmsSnackbar({
        children: translate("Alarm deletion failed"),
        severity: "error",
      });
      setAlarmsPromiseArguments(null);
    }
  };

  const handleAlarmsEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };
  const renderAlarmsConfirmDialog = () => {
    if (!alarmsPromiseArguments) {
      return null;
    }

    //const { id } = promiseArguments;
    //const mutation = (id > 0) ? translate("Are you sure you want to delete this subscription?") : null;

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleAlarmsEntered }}
        open={!!alarmsPromiseArguments}
      >
        <DialogTitle>{translate("Warning!")}</DialogTitle>
        <DialogContent dividers>
          {translate("Are you sure you want to delete this alarm?")}
        </DialogContent>
        <DialogActions>
          <Button ref={noAlarmsButtonRef} onClick={handleAlarmsNo}>
            {translate("No")}
          </Button>
          <Button onClick={handleAlarmsYes}>{translate("Yes")}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleAlarmsDeleteClick = (id) => () => {
    console.log("[CVT]", "handleAlarmsDeleteClick");

    setAlarmsPromiseArguments({ id });
  };

  const handleAlarmsCancelClick = (id) => () => {
    console.log("[CVT]", "handleAlarmsCancelClick");
    setAlarmsRowModesModel({
      ...alarmsRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = alarmsRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setAlarmsRows(alarmsRows.filter((row) => row.id !== id));
    }
  };

  const processAlarmsRowUpdate = (newRow, originalRow) => {
    const newId = newRow.id;
    let newAlarmCode = newRow.alarmCode;
    if (alarmValues[newAlarmCode] === undefined) {
      for (const [key, val] of Object.entries(alarmValues)) {
        if (translate(val) === newAlarmCode) {
          newAlarmCode = key;
          break;
        }
      }
    }
    if (newRow.isNew || newRow.id === -1) {
      //Este inregistrare noua; adaug in baza de date
      console.log("[CVT]", "processAlarmsRowUpdate", "INSERT IN DATABASE");
      return fetch(
        apiurl +
          "AddAlarmToSubscription?idSubscription=" +
          selectedId +
          "&tipGrupareAdministrativa=" +
          newRow.tipGrupareAdministrativa +
          "&idReferinta=" +
          newRow.idReferinta +
          "&alarmCode=" +
          newAlarmCode,
        {
          mode: "cors",
          method: "POST",
          headers: {
            tid: tid,
            uid: uid,
            mid: mid,
            Authorization: "bearer " + useAppStore.getState().authToken,
            RefreshToken: useAppStore.getState().refreshToken,
          },
        }
      )
        .then(
          (res) => res.json(),
          (error) => {
            console.log("[CVT]", "[API] AddAlarmToSubscription ERR1", error);

            setAlarmsSnackbar({
              children: translate("Alarm creation failed"),
              severity: "error",
            });
            return originalRow;
          }
        )
        .then(
          (result) => {
            console.log("[CVT]", "[API] AddAlarmToSubscription", result);
            if (result.id === null) {
              console.log("[CVT]", "[API] AddAlarmToSubscription ERR2.1", result.error);

              setAlarmsSnackbar({
                children: "Alarm creation failed",
                severity: "error",
              });
              return originalRow;
            }
            const updatedRow = { ...newRow, id: newId, isNew: false };
            setAlarmsRows(alarmsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            setAlarmsSnackbar({
              children: translate("Alarm successfully added to subscription"),
              severity: "success",
            });
            return updatedRow;
          },
          (error) => {
            console.log("[CVT]", "[API] AddAlarmToSubscription ERR2.2", error);

            setAlarmsSnackbar({
              children: translate("Alarm creation failed"),
              severity: "error",
            });
            return originalRow;
          }
        );
    } else {
      //Exista deja, ii fac update in baza de date, daca s-a schimbat ceva
      const editedRow = alarmsRows.find((row) => row.id === newId);
      let editedRowAlarmCode = editedRow.emailFrequency;
      if (alarmValues[editedRowAlarmCode] === undefined) {
        for (const [key, val] of Object.entries(alarmValues)) {
          if (translate(val) === editedRowAlarmCode) {
            editedRowAlarmCode = key;
            break;
          }
        }
      }
      if (
        editedRow.description !== newRow.description ||
        editedRow.emailTo !== newRow.emailTo ||
        editedRow.emailCC !== newRow.emailCC ||
        editedRow.emailSubject !== newRow.emailSubject ||
        editedRowAlarmCode !== newAlarmCode
      ) {
        //s-a modificat; fac update in baza de date
        console.log("[CVT]", "processAlarmsRowUpdate", "UPDATE IN DATABASE");
        return fetch(
          apiurl +
            "UpdateAlarmOfSubscription?idSubscriptionAlarm=" +
            newId +
            "&tipGrupareAdministrativa=" +
            newRow.tipGrupareAdministrativa +
            "&idReferinta=" +
            newRow.idReferinta +
            "&alarmCode=" +
            newAlarmCode,
          {
            mode: "cors",
            method: "PUT",
            headers: {
              tid: tid,
              uid: uid,
              mid: mid,
              Authorization: "bearer " + useAppStore.getState().authToken,
              RefreshToken: useAppStore.getState().refreshToken,
            },
          }
        )
          .then(
            (res) => res.json(),
            (error) => {
              console.log("[CVT]", "[API] UpdateAlarmOfSubscription ERR1", error);

              setAlarmsSnackbar({
                children: translate("Alarm change failed"),
                severity: "error",
              });
              return originalRow;
            }
          )
          .then(
            (result) => {
              console.log("[CVT]", "[API] UpdateAlarmOfSubscription", result);
              if (result.id === null) {
                console.log("[CVT]", "[API] UpdateAlarmOfSubscription ERR2.1", result.error);

                setAlarmsSnackbar({
                  children: translate("Alarm change failed"),
                  severity: "error",
                });
                return originalRow;
              }
              const updatedRow = { ...newRow, id: newId, isNew: false };
              setAlarmsRows(alarmsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
              setAlarmsSnackbar({
                children: translate("Alarm successfully changed"),
                severity: "success",
              });
              return updatedRow;
            },
            (error) => {
              console.log("[CVT]", "[API] UpdateAlarmOfSubscription ERR2.2", error);

              setAlarmsSnackbar({
                children: translate("Alarm change failed"),
                severity: "error",
              });
              return originalRow;
            }
          );
      } else {
        //nu s-a modificat
        console.log("[CVT]", "processAlarmsRowUpdate", "DO NOTHING");
        return originalRow;
      }
    }
  };

  const handleAlarmsRowModesModelChange = (newRowModesModel) => {
    setAlarmsRowModesModel(newRowModesModel);
  };
  function AlarmsSelectEditInputCellAlarm(props) {
    const { id, value, field, translate } = props;
    const apiRef = useGridApiContext();
    const [myValue, setMyValue] = useState(0);

    const handleChange = async (event) => {
      console.log("[CVT]", "SelectEditInputCellAlarm/handleChange", id, value, field, event);
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    useEffect(() => {
      if (alarmValues[value] === undefined) {
        for (const [key, val] of Object.entries(alarmValues)) {
          console.log("[CVT]", "alarms/useEffect", value, key, val);
          if (translate(val) === value) {
            setMyValue(parseInt(key));
          }
        }
      } else setMyValue(value);
    }, [value, translate]);
    return (
      <Select
        value={myValue}
        onChange={handleChange}
        size="small"
        sx={{ height: 1, flexGrow: 1 }}
        native
        autoFocus
      >
        {alarmValues.map((item) => (
          <option key={item.id} value={item.id}>
            {translate(item.name)}
          </option>
        ))}
      </Select>
    );
  }

  const renderAlarmsSelectEditInputCellAlarm = (params, translate) => {
    return <AlarmsSelectEditInputCellAlarm {...params} translate={translate} />;
  };
  function AlarmsSelectEditInputCellCoverage(props) {
    const { id, value, field, translate } = props;
    const apiRef = useGridApiContext();
    const [myValue, setMyValue] = useState(0);

    const handleCoverageChange = async (event) => {
      console.log("[CVT]", "SelectEditInputCellCoverage/handleChange", id, value, field, event);
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    useEffect(() => {
      if (coverageValues[value] === undefined) {
        for (const [key, val] of Object.entries(coverageValues)) {
          console.log("[CVT]", "coverage/useEffect", value, key, val);
          if (translate(val) === value) {
            setMyValue(parseInt(key));
          }
        }
      } else setMyValue(value);
    }, [value, translate]);
    return (
      <Select
        value={myValue}
        onChange={handleCoverageChange}
        size="small"
        sx={{ height: 1, flexGrow: 1 }}
        native
        autoFocus
      >
        <option value="all meters">{translate("all meters")}</option>
        <option value="meter">{translate("meter")}</option>
      </Select>
    );
  }
  const renderAlarmsSelectEditInputCellCoverage = (params, translate) => {
    return <AlarmsSelectEditInputCellCoverage {...params} translate={translate} />;
  };
  const alarmsColumns = [
    {
      field: "name",
      headerName: "Alarm",
      flex: 0.16,
      //minWidth: 150,
      editable: true,
      type: "singleSelect",
      valueParser: (value) => {
        if (alarmValues[value] !== undefined) return translate(alarmValues[value]);
        else return value;
      },
      renderEditCell: (params) => renderAlarmsSelectEditInputCellAlarm(params, translate),
    },
    {
      field: "object",
      headerName: "Coverage",
      flex: 0.16,
      //minWidth: 150,
      editable: true,
      type: "singleSelect",
      valueParser: (value) => {
        if (coverageValues[value] !== undefined) return translate(coverageValues[value]);
        else return value;
      },
      renderEditCell: (params) => renderAlarmsSelectEditInputCellCoverage(params, translate),
    },
    {
      field: "objectReference",
      headerName: "Identifier",
      flex: 0.16,
      minWidth: 150,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: translate("Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = alarmsRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode || id === -1) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label={translate("Save")}
              sx={{
                color: "primary.main",
              }}
              onClick={handleAlarmsSaveClick(id)}
              key={0}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label={translate("Cancel")}
              className="textPrimary"
              onClick={handleAlarmsCancelClick(id)}
              color="inherit"
              key={1}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={translate("Edit")}
            className="textPrimary"
            onClick={handleAlarmsEditClick(id)}
            color="inherit"
            key={0}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={translate("Delete")}
            onClick={handleAlarmsDeleteClick(id)}
            color="inherit"
            key={1}
          />,
        ];
      },
    },
  ];

  const DivColumn = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
  }));
  const DivRow = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid lightgray",
    paddingBottom: "12px",
  }));

  useEffect(() => {
    console.log("[CVT]", "alarmsforsubscription.useEffect", selectedId, selectedId > 0);
    if (selectedId > 0) {
      //   setIsAlarmsRowsLoaded(false);

      fetch(apiurl + "GetSubscriptionAlarms?idSubscription=" + selectedId, {
        mode: "cors",
        headers: {
          tid: tid,
          uid: uid,
          mid: mid,
          Authorization: "bearer " + useAppStore.getState().authToken,
          RefreshToken: useAppStore.getState().refreshToken,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log("[CVT]", "GetSubscriptionAlarms", result);
            // setIsAlarmsRowsLoaded(true);
            if (result !== undefined && result.length > 0) {
              const dstmp = result.map((item, index) => {
                if (item.id === undefined) item.id = index;
                item.alarmCode =
                  alarmValues[item.alarmCode] !== undefined
                    ? translate(alarmValues[item.alarmCode])
                    : item.alarmCode;
                return item;
              });

              setAlarmsRows(dstmp);
            }
          },
          () => {
            // setIsAlarmsRowsLoaded(true);
            setAlarmsRows([]);
          }
        );
    } else {
      //   setIsAlarmsRowsLoaded(true);
      setAlarmsRows([]);
    }
  }, [apiurl, tid, uid, mid, translate, selectedId, alarmValues]);
  return (
    <Dialog open={openAlarms} onClose={handleAlarmsClose} fullWidth={true} maxWidth={"lg"}>
      <DialogTitle>{translate("Alarms")}</DialogTitle>
      <IconButton
        onClick={handleAlarmsClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DivColumn>
          <DivRow>
            <DivColumn>
              <Typography variant="caption" display="block" gutterBottom>
                {translate("Description")}
              </Typography>
              <Typography variant="subtitle2" display="block" gutterBottom>
                {selectedDescr}
              </Typography>
            </DivColumn>
            <DivColumn>
              <Typography variant="caption" display="block" gutterBottom>
                {translate("Email to")}
              </Typography>
              <Typography variant="subtitle2" display="block" gutterBottom>
                {selectedEmailTo}
              </Typography>
            </DivColumn>
            <DivColumn>
              <Typography variant="caption" display="block" gutterBottom>
                {translate("Frequency")}
              </Typography>
              <Typography variant="subtitle2" display="block" gutterBottom>
                {selectedFrequency}
              </Typography>
            </DivColumn>
          </DivRow>
        </DivColumn>
        <Box>
          {renderAlarmsConfirmDialog()}
          <DataGrid
            sx={{
              backgroundColor: "white",
              height: "400px",
              border: "none",
              "& .MuiTablePagination-spacer": { flex: "0" },
              "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
              "& .MuiInputBase-root.MuiInput-root:before": {
                borderBottom: "none",
              },
              "& .MuiTablePagination-root": { width: "100%" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-selectedRowCount": {
                flex: "1 0 auto",
              },
            }}
            rows={alarmsRows}
            columns={alarmsColumns}
            editMode="row"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            rowModesModel={alarmsRowModesModel}
            onRowModesModelChange={handleAlarmsRowModesModelChange}
            onRowEditStop={handleAlarmsRowEditStop}
            processRowUpdate={processAlarmsRowUpdate}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            slots={{
              toolbar: (props) =>
                AlarmsCustomToolbar({
                  addButtonText: translate("Add Alarm"),
                  setAlarmsRows,
                  setAlarmsRowModesModel,
                  alarmsRows,
                  props,
                }),
              pagination: AlarmsCustomPagination,
            }}
            slotProps={{
              toolbar: { setAlarmsRows, setAlarmsRowModesModel },
            }}
            localeText={{
              toolbarFilters: translate("Filter List"),
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
              },
            }}
          />
          {!!alarmsSnackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleAlarmsCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...alarmsSnackbar} onClose={handleAlarmsCloseSnackbar} />
            </Snackbar>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAlarmsClose}>{translate("Close")}</Button>
        {/*<Button onClick={handleAddBuildingClose}>{translate('Add Building')}</Button>*/}
      </DialogActions>
    </Dialog>
  );
}
