//export const OldschoolEmmsysMenu = [
//  {
//    text: "Home",
//    icon: "page_home",
//    iconselected: "page_home",
//    route: "/",
//    level: 1,
//    id: 1,
//    installationType: "eth",
//  },
//  {
//    text: "Analyse",
//    icon: "page_analyse",
//    route: "/",
//    level: 1,
//    id: 2,
//  },
//  {
//    text: "Compare",
//    icon: "page_compare",
//    iconselected: "page_compare",
//    route: "/",
//    level: 1,
//    id: 3,
//  },
//  {
//    text: "Alarms",
//    icon: "page_alarm",
//    iconselected: "page_alarm",
//    route: "/",
//    level: 1,
//    id: 4,
//  },
//  {
//    text: "Meters",
//    icon: "page_meters",
//    iconselected: "page_meters",
//    route: "/meters",
//    level: 1,
//    id: 5,
//  },
//  {
//    text: "Diagrams",
//    icon: "page_diagrams",
//    iconselected: "page_diagrams",
//    route: "/",
//    level: 1,
//    id: 6,
//  },
//  {
//    text: "Work Orders",
//    icon: "page_workorders",
//    iconselected: "page_workorders",
//    route: "/",
//    level: 1,
//    id: 7,
//  },
//  {
//    text: "Map",
//    icon: "page_map",
//    iconselected: "page_map",
//    route: "/",
//    level: 1,
//    id: 8,
//  },
//  {
//    text: "Custom Reports",
//    icon: "page_custom_reports",
//    iconselected: "page_custom_reports",
//    route: "/",
//    level: 1,
//    id: 9,
//  },
//  {
//    text: "Balance",
//    icon: "page_balance",
//    iconselected: "page_balance",
//    route: "/",
//    level: 1,
//    id: 11,
//  },
//  {
//    text: "Energy Management",
//    icon: "page_energy_management",
//    iconselected: "page_energy_management",
//    route: "outcomes/energymanagement",
//    level: 1,
//    id: 12,
//  },
//  {
//    text: "Meter Park",
//    icon: "page_meterspark",
//    iconselected: "page_meterspark",
//    route: "/",
//    level: 1,
//    id: 13,
//  },
//  {
//    text: "Data Collect System",
//    icon: "page_datacollect",
//    iconselected: "page_datacollect",
//    route: "/",
//    level: 1,
//    id: 14,
//  },
//  {
//    text: "User Dashboard",
//    icon: "page_userdashboards",
//    iconselected: "page_userdashboards",
//    route: "/dashboard",
//    level: 1,
//    id: 15,
//    installationType: "water",
//  },
//  {
//    text: "Scheduled Reports",
//    icon: "page_scheduled_reports",
//    iconselected: "page_scheduled_reports",
//    route: "/",
//    level: 1,
//    id: 16,
//  },
//];

export const EmmsysMenu = [
  {
    id: 0,
    type: "header",
  },
  {
    text: "Home",
    icon: "home_line",
    iconselected: "home_fill",
    route: "/",
    level: 1,
    id: 1,
  },
  {
    text: "Dashboard",
    icon: "dashboard_line",
    iconselected: "dashboard_full",
    route: "/dashboard",
    level: 1,
    id: 2,
    installationType: "water",
  },
  {
    text: "Dashboard",
    icon: "dashboard_line",
    iconselected: "dashboard_full",
    route: "/dashboard",
    level: 1,
    id: 3,
    installationType: "eth",
  },
  {
    text: "Water Outcomes",
    icon: "water_line",
    iconselected: "water_fill",
    route: "",
    level: 1,
    id: 4,
    dataExpanded: false,
    installationType: "water",
    items: [
      {
        text: "Operational Vizibility",
        icon: "",
        route: "/outcomes/operationalvisibility",
        level: 2,
        id: 5,
        parentId: 4,
      },
      {
        text: "Operational Efficiency",
        icon: "",
        route: "/outcomes/operationalefficiency",
        level: 2,
        id: 6,
        parentId: 4,
      },
    ],
  },
  {
    text: "Heat & Cooling Outcomes",
    icon: "temperature_line",
    iconselected: "temperature_fill",
    route: "",
    level: 1,
    id: 7,
    installationType: "eth",
    dataExpanded: false,
    items: [
      {
        text: "District Heat & Cooling",
        icon: "temperature_line",
        route: "/outcomes/districtheatandcooling",
        level: 2,
        id: 8,
        parentId: 7,
      },
      {
        text: "Energy Management",
        icon: "energy_line",
        route: "/outcomes/energymanagement",
        level: 2,
        id: 9,
        parentId: 7,
      },
      //{
      //  text: "Building Allocation",
      //    icon: "chart_line",
      //  route: "/outcomes/buildingallocation",
      //  level: 2,
      //  id: 10,
      //  parentId: 7,
      //},
      //{
      //  text: "Consummer Awareness",
      //    icon: "chart_line",
      //  route: "/outcomes/customerawareness",
      //  level: 2,
      //  id: 11,
      //  parentId: 7,
      //},
    ],
  },
  {
    text: "Water Reports & Tools",
    icon: "chart_line",
    iconselected: "chart_fill",
    installationType: "water",
    route: "",
    level: 1,
    id: 12,
  },
  {
    id: 13,
    type: "divider",
  },
  {
    text: "Assistance",
    icon: "tool_fill",
    iconselected: "tool_line",
    route: "",
    level: 1,
    id: 14,
  },
];
