import { useAppStore } from "../store/store";
import { config } from "../config/config";
import * as API_Types from "../types/api/ApiContracts";
import FetchResult from "./fetchresult";

const authUrl = config.WS_AUTH_URL.startsWith("http")
  ? config.WS_AUTH_URL
  : window.location.origin + config.WS_AUTH_URL;

const appConfig = useAppStore.getState().appConfig;

const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};
const getPostHeaders = () => {
  return {
    "Content-Type": "application/json",
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IAUTHAPI {
  Authentificate: (userName, userPassword, rememberMe, culture, isadmin, dbIdx) => any;
  RefreshToken: (username) => any;
  GetTenants: (username: string, userPassword: string) => Promise<Array<API_Types.Tennant>>;
  Logout: () => any;
  IsAuth: () => any;
}

const AUTHAPI: IAUTHAPI = {
  Authentificate: async (userName, userPassword, rememberMe, culture, isadmin, dbIdx) => {
    return await fetch(`${authUrl}Authentificate`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: getPostHeaders(),
      body: JSON.stringify({
        username: userName,
        password: userPassword,
        rememberMe: rememberMe,
        culture: culture,
        isadmin: isadmin,
        dbIdx: dbIdx,
      }),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  RefreshToken: async (username) => {
    return await fetch(`${authUrl}RefreshToken`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: useAppStore.getState().authToken,
        username: username,
      }),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  Logout: async () => {
    return await fetch(`${authUrl}Logout`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: getPostHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetTenants: async (username, userPassword) => {
    return await fetch(`${authUrl}GetTenants?userName=${username}&userPwd=${userPassword}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<API_Types.Tennant>) => {
        return data;
      });
  },
  IsAuth: async () => {
    return await fetch(`${authUrl}IsAuth`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
};

export { AUTHAPI };
