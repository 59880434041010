import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface ISYSTEM_STATUS_API {
  GetSystemAlarmInfo: (statusType: string) => Promise<Array<any>>;
  GetSystemAlarmStatus: (
    dbSuffix: string,
    mediumId: string,
    tipPrezenta: string,
    perspectiveCode: string,
    grupaAlarme: string,
    severityCode: string
  ) => Promise<Array<any>>;
  GetSystemAlarmsAges: (
    mediumId: string,
    perspectiveCode: string,
    grupaAlarme: string,
    severityCode: string
  ) => Promise<Array<any>>;
  GetSystemReadingStatus: (mediumId: string) => Promise<Array<any>>;
  GetSystemReadingsAges: (mediumId: string) => Promise<Array<any>>;
  GetMediumConsumptionEvolution: (
    mediumId: string,
    consumptionType: string,
    online: string,
    returnLists: string,
    returnListZile: string,
    returnListSaptamani: string,
    returnListLuni: string,
    returnListAni: string,
    idRegion: string,
    idCity: string,
    idCityZone: string,
    idBillingGroup: string
  ) => Promise<any>;
}

const SYSTEM_STATUS_API: ISYSTEM_STATUS_API = {
  GetSystemAlarmInfo: async (statusType) => {
    return await fetch(`${apiUrl}GetSystemAlarmInfo?statusType=${statusType}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetSystemAlarmStatus: async (
    dbSuffix,
    mediumId,
    tipPrezenta,
    perspectiveCode,
    grupaAlarme,
    severityCode
  ) => {
    return await fetch(
      `${apiUrl}GetSystemAlarmStatus?dbSuffix=${dbSuffix}&mediumId=${mediumId}&tipPrezenta=${tipPrezenta}&perspectiveCode=${perspectiveCode}&grupaAlarme=${grupaAlarme}&severityCode=${severityCode}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetSystemAlarmsAges: async (mediumId, perspectiveCode, grupaAlarme, severityCode) => {
    return await fetch(
      `${apiUrl}GetSystemAlarmsAges?mediumId=${mediumId}&perspectiveCode=${perspectiveCode}&grupaAlarme=${grupaAlarme}&severityCode=${severityCode}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetSystemReadingStatus: async (mediumId) => {
    return await fetch(`${apiUrl}GetSystemReadingStatus?mediumId=${mediumId}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetSystemReadingsAges: async (mediumId) => {
    return await fetch(`${apiUrl}GetSystemReadingsAges?mediumId=${mediumId}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetMediumConsumptionEvolution: async (
    mediumId,
    consumptionType: string,
    online,
    returnLists,
    returnListZile,
    returnListSaptamani,
    returnListLuni,
    returnListAni,
    idRegion,
    idCity,
    idCityZone,
    idBillingGroup
  ) => {
    return await fetch(
      `${apiUrl}GetMediumConsumptionEvolution?mediumId=${mediumId}&consumptionType=${consumptionType}&online=${online}&returnLists=${returnLists}&returnListZile=${returnListZile}&returnListSaptamani=${returnListSaptamani}&returnListLuni=${returnListLuni}&returnListAni=${returnListAni}&idRegion=${idRegion}&idCity=${idCity}&idCityZone=${idCityZone}&idBillingGroup=${idBillingGroup}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
};

export { SYSTEM_STATUS_API };
