import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IDCS_API {
  GetAdministrativeGroupEfficiency: (
    groupId: string,
    nodeType: string,
    start: string,
    stop: string,
    periodicity: number
  ) => Promise<Array<any>>;
  GetAnalyseReadingRates: (
    equipmentId: number | null,
    tipNod: number | null,
    equipmentType: number | null,
    clientName: string,
    ziStart: string,
    ziStop: string,
    dbSuffix: string,
    online: string
  ) => Promise<Array<any>>;
}

const DCS_API: IDCS_API = {
  GetAdministrativeGroupEfficiency: async (groupId, nodeType, start, stop, periodicity) => {
    return await fetch(
      `${apiUrl}GetAdministrativeGroupEfficiency?groupId=${groupId}&nodeType=${nodeType}&start=${start}&stop=${stop}&periodicity=${periodicity}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetAnalyseReadingRates: async (
    equipmentId,
    tipNod,
    equipmentType,
    clientName,
    ziStart,
    ziStop,
    dbSuffix,
    online
  ) => {
    return await fetch(
      `${apiUrl}GetAnalyseReadingRates?equipmentId=${equipmentId}&tipNod=${tipNod}&equipmentType=${equipmentType}&clientName=${clientName}&ziStart=${ziStart}&ziStop=${ziStop}&dbSuffix=${dbSuffix}&online=${online}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
};

export { DCS_API };
