import { DataGrid } from "@mui/x-data-grid";
import { memo, useEffect, useState } from "react";

interface CustomSimpleGridProps {
  dataSet: any;
  columns: any;
  maxHeight?: number;
}

const CustomSimpleGrid = memo(function CustomSimpleGrid(props: CustomSimpleGridProps) {
  const { dataSet, columns, maxHeight } = props;
  const [dataSetWithId, setDataSetWithId] = useState([]);

  useEffect(() => {
    if (dataSet !== undefined && dataSet.length > 0) {
      const dstmp = dataSet.map((item, index) => {
        if (item.id === undefined) item.id = index;
        return item;
      });

      setDataSetWithId(dstmp);
    } else {
      setDataSetWithId([]);
    }
  }, [dataSet]);

  return (
    <DataGrid
      rows={dataSetWithId}
      columns={columns}
      disableRowSelectionOnClick
      hideFooterPagination
      hideFooter
      sx={{
        maxWidth: { xs: 250, sm: 570, md: "100%", lg: "100%", xl: "100%" },
        backgroundColor: "white",
        minHeight: "300px",
        maxHeight: maxHeight ?? "500px",
        border: "none",
        "& .MuiTablePagination-spacer": { flex: "0" },
        "& .MuiInputBase-root.MuiInput-root:before": { borderBottom: "none" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#F5F5F5",
          fontWeight: "bold",
        },
      }}
    />
  );
});

export { CustomSimpleGrid };
