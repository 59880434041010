import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { memo, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import EmmsysReportViewer from "../../reporting/reportviewer";

const energyReports = [
  {
    id: 0,
    text: "Energy Supervisor",
    reportType3: "EMS_Building_EnergySupervisor.trdx",
    reportType2: "EMS_EndPoint_EnergySupervisor.trdx",
  },
  {
    id: 1,
    text: "Energy Events",
    reportType3: "EMS_Building_EnergyEvents.trdx",
    reportType2: "EMS_EndPoint_EnergyEvents.trdx",
  },
  {
    id: 2,
    text: "Energy Exploitation",
    reportType3: "EMS_Building_EnergyExploitation.trdx",
    reportType2: "EMS_EndPoint_EnergyExploitation.trdx",
  },
  {
    id: 3,
    text: "Data Sheet",
    reportType3: "Building_DataSheet2.trdx",
    reportType2: "EndPoint_DataSheet2.trdx",
  },
];

interface EmsCheckReportsProps {
  translate: (params: string) => string;
  language: string;
  globalRefresh: boolean;
  context: any;
}

const EmsCheckReports = memo(function EmsCheckReports({
  translate,
  context,
}: EmsCheckReportsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { nodeItem } = useContext<any>(context);

  const [selectedReport, setSelectedReport] = useState(0);

  const [reportConfig, setReportConfig] = useState({});

  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
  };

  useEffect(() => {
    if (nodeItem.nodeType !== undefined) {
      const rparameters: any = [];
      const findreport: any = energyReports.find((r) => r.id === selectedReport);
      let rname = "";
      switch (nodeItem.nodeType) {
        case "3":
          rparameters.push({
            Name: "idBuilding",
            Value: nodeItem.nodeId ?? 0,
            Visible: false,
          });
          rname = findreport.reportType3;
          break;
        case "2":
          rparameters.push(
            { Name: "idBuilding", Value: "", Visible: false },
            {
              Name: "idPunctConsum",
              Value: nodeItem.nodeId ?? 0,
              Visible: false,
            },
            {
              Name: "idEndPointExtern",
              Value: nodeItem.nodeId ?? 0,
              Visible: false,
            },
            { Name: "idEndPoint", Value: "", Visible: false }
          );
          rname = findreport.reportType2;
          break;
        default:
          rparameters.push({
            Name: "idBuilding",
            Value: nodeItem.nodeId ?? 0,
            Visible: false,
          });
          rname = findreport.reportType3;
          break;
      }

      setReportConfig({
        rName: rname,
        rParams: rparameters,
      });
    }
  }, [nodeItem, selectedReport]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              paddingBottom: isMobile ? "10px" : "inherit",
            }}
          >
            <Typography
              noWrap
              component="span"
              sx={{
                fontSize: "1.38rem",
                fontWeight: 700,
              }}
            >
              {translate("Reports")}
            </Typography>
            <Tooltip title={translate("Description:")} arrow>
              <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
            </Tooltip>
          </Box>

          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end", p: 0 }}>
            <FormControl sx={{ m: "0px 1em", minWidth: isMobile ? "auto" : 200 }}>
              <InputLabel id="select-report-label">{translate("Report")}</InputLabel>
              <Select
                labelId="select-report-label"
                id="select-report"
                value={selectedReport}
                onChange={handleReportChange}
                autoWidth
                label={translate("Report")}
                size="small"
              >
                {energyReports.map((b, index) => {
                  return (
                    <MenuItem key={index} value={b.id}>
                      {b.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            p: 0,
          }}
        >
          <EmmsysReportViewer width="100%" height="500px" reportOptions={reportConfig} />
        </Box>
      </CardContent>
    </Card>
  );
});

export { EmsCheckReports };
