import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MuiPagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  DataGrid,
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { memo, useContext, useEffect, useState } from "react";
import { config } from "../../../config/config";

import { DateValueFormatter } from "../../utils/utils";
import { isMobile } from "react-device-detect";
import { EMS_API } from "../../../api/emsapi";

const columns = [
  {
    field: "idCheck",
    headerName: "Id Check",
    editable: false,
  },
  {
    field: "beginMonth",
    headerName: "From Month",
    valueFormatter: (params) => DateValueFormatter(params.value, 3),
    editable: false,
  },
  {
    field: "endMonth",
    headerName: "To Month",
    valueFormatter: (params) => DateValueFormatter(params.value, 3),
    editable: false,
  },
  {
    field: "endPointCode",
    headerName: "End Point Code",
    editable: false,
  },
  {
    field: "endPointName",
    headerName: "End Point Name",
    editable: false,
  },
  {
    field: "meterUtility",
    headerName: "Meter Utility",
    editable: false,
  },
  {
    field: "meterName",
    headerName: "Meter Name",
    editable: false,
  },
  {
    field: "checkDate",
    headerName: "Check Date",
    valueFormatter: (params) => DateValueFormatter(params.value, 5),
    editable: false,
  },
  {
    field: "checkResult",
    headerName: "Check Result",
    editable: false,
    valueGetter: (params) => {
      if (params.value) {
        return "OK";
      }
      return "NOT OK";
    },
  },
  {
    field: "checkGenerator",
    headerName: "Check Generator",
    editable: false,
  },
];

const resultsItems = [
  {
    text: "meters with bad results",
    id: 1,
    value: 0,
  },
  {
    text: "meters with good results",
    id: 2,
    value: 1,
  },
  {
    text: "all meters",
    id: 3,
    value: null,
  },
];

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

interface CheckSheetsProps {
  translate: (params: string) => string;
  language: string;
  globalRefresh: boolean;
  context: any;
  onGeneralError: (error: any) => void;
}

const apifilesurl = config.WS_APIFILES_URL.startsWith("http")
  ? config.WS_APIFILES_URL
  : window.location.origin + config.WS_APIFILES_URL;

const CheckSheets = memo(function CheckSheets({
  translate,
  language,
  context,
  onGeneralError,
}: CheckSheetsProps) {
  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const { nodeItem } = useContext<any>(context);

  const [isCheckListLoaded, setIsCheckListLoaded] = useState(false);

  const [openReport, setOpenReport] = useState(false);

  const [gridColumns, setGridColumns] = useState<any>([]);
  const [checksResultsOptions, setChecksResultsOptions] = useState<any>([]);
  const [checkResult, setCheckResult] = useState(0);

  const [checkListItems, setCheckListItems] = useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);

  const [checksEvents, setChecksEvents] = useState<any>({});
  const [checksSupervisor, setChecksSupervisor] = useState<any>({});
  const [checksExploitation, setChecksExploitation] = useState<any>({});

  const [checkPdfFile, setCheckPdfFile] = useState("");

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleChangeCheckResult = (event) => {
    setCheckResult(event.target.value);
  };

  const handleGenerateChecksClick = () => {
    return 1;
  };

  const handleReportClose = () => {
    setOpenReport(false);
  };

  const handleOpenReport = (ev) => {
    let pdfurl = apifilesurl + "GetEMSCheckPdfFileV2?pdfFileName=";
    switch (ev.target.value) {
      case "1":
        pdfurl = pdfurl + checksEvents.checkReportFileName;
        break;
      case "2":
        pdfurl = pdfurl + checksSupervisor.checkReportFileName;
        break;
      case "3":
        pdfurl = pdfurl + checksExploitation.checkReportFileName;
        break;
    }
    setCheckPdfFile(pdfurl);
    setOpenReport(true);
  };

  useEffect(() => {
    const tmpcolumns = columns.map((col) => {
      col.headerName = translate(col.headerName);
      return col;
    });

    const checkresultoptionstmp = resultsItems.map((opt) => {
      opt.text = translate(opt.text);
      return opt;
    });

    setGridColumns(tmpcolumns);
    setChecksResultsOptions(checkresultoptionstmp);
  }, [language]);

  useEffect(() => {
    setIsCheckListLoaded(false);

    const idbuilding = nodeItem.nodeId ?? "";
    const nodetype = nodeItem.nodeType ?? "";
    const checkres = checkResult ?? "";

    EMS_API.GetEmsChecksList(idbuilding, nodetype, "", checkres.toString()).then(
      (result) => {
        if (result != undefined && result.length > 0) {
          const dstmp = result.map((item) => {
            if (item.id === undefined) item.id = item.idCheck;
            return item;
          });
          setCheckListItems(dstmp);
          setRowSelectionModel(dstmp[0].id);
        } else {
          setCheckListItems([]);
          setRowSelectionModel([]);
        }

        setIsCheckListLoaded(true);
      },
      (error) => {
        setCheckListItems([]);
        setRowSelectionModel([]);
        setIsCheckListLoaded(true);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [nodeItem, checkResult]);

  useEffect(() => {
    const idcheck = rowSelectionModel ?? "";

    EMS_API.GetEmsChecksEvents(idcheck).then(
      (result) => {
        if (result != undefined) {
          setChecksEvents(result);
        } else setChecksEvents({});
      },
      (error) => {
        setChecksEvents({});
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    EMS_API.GetEmsChecksSupervisor(idcheck).then(
      (result) => {
        if (result != undefined) {
          setChecksSupervisor(result);
        } else setChecksSupervisor({});
      },
      (error) => {
        setChecksSupervisor({});
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    EMS_API.GetEmsChecksExploitation(idcheck).then(
      (result) => {
        if (result != undefined) {
          setChecksExploitation(result);
        } else setChecksExploitation({});
      },
      (error) => {
        setChecksExploitation({});
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [rowSelectionModel]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          {!isCheckListLoaded && (
            <CircularProgress
              sx={{
                width: "1.2rem !important",
                height: "1.2rem !important",
                margin: "6px",
              }}
            />
          )}
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
              flex: "0 1 auto",
            }}
          >
            {translate("Check Sheets")}
          </Typography>
          <Box sx={{ p: 0, m: 0, flex: "0 1 auto" }}>
            <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0.5rem 1.5rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box sx={{ flex: "0 1 auto", minWidth: "200px" }}>
            <Select
              id="chackresultsoptions-select"
              value={checkResult}
              onChange={handleChangeCheckResult}
              sx={{ width: "100%" }}
            >
              {checksResultsOptions.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {translate(item.text)}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              flex: "0 1 auto",
              padding: "0rem 1rem",
              paddingTop: "0.7rem",
            }}
          >
            <Button
              endIcon={<RefreshOutlinedIcon />}
              onClick={handleGenerateChecksClick}
              sx={{
                backgroundColor: "#0051C1",
                color: "white",
                minWidth: "200px",
                borderRadius: "7px",
              }}
            >
              {translate("Generate New Checks")}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0.5rem 1.5rem",
          }}
        >
          <DataGrid
            rows={checkListItems}
            editMode="row"
            columns={gridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            slots={{
              pagination: CustomPagination,
            }}
            sx={{
              maxWidth: { xs: 250, sm: 570, md: "100%", lg: "100%", xl: "100%" },
              backgroundColor: "white",
              height: "400px",
              border: "none",
              "& .MuiTablePagination-spacer": { flex: "0" },
              "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
              "& .MuiInputBase-root.MuiInput-root:before": {
                borderBottom: "none",
              },
              "& .MuiTablePagination-root": { width: "100%" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-selectedRowCount": {
                flex: "1 0 auto",
              },
            }}
            localeText={{
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "1rem 2rem",
            display: "flex",
            backgroundColor: "#FAFAFA",
          }}
        >
          <Stack
            direction={matchessmbreackpoint ? "row" : "column"}
            spacing={1}
            sx={{ flexGrow: 1 }}
          >
            <Box sx={{ display: "flex", flex: "1 0 auto" }}>
              <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: 700,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Events")}
                  </Typography>
                  <Chip
                    label={
                      translate("Check Result") +
                      " - " +
                      (checksEvents.checkResult == null
                        ? "n/a"
                        : checksEvents.checkResult === true
                          ? "OK"
                          : " NOT OK")
                    }
                    variant="filled"
                    sx={{
                      backgroundColor:
                        checksEvents.checkResult == null
                          ? "#BDBDBD !important"
                          : checksEvents.checkResult === true
                            ? "#43A047 !important"
                            : "#E53935 !important",
                      color: "white !important",
                      flex: "0 1 auto",
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Min. Alarm Duration (minutes)")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksEvents.paramAlarmDuration ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. Metrological Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksEvents.paramThresholdMaxMetrologic ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Button variant="outlined" size="large" value="1" onClick={handleOpenReport}>
                    {translate("View Report")}
                  </Button>
                </Box>
              </Stack>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ margin: "0px 20px !important" }} />
            <Box sx={{ display: "flex", flex: "1 0 auto" }}>
              <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: 700,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Supervisor")}
                  </Typography>
                  <Chip
                    label={
                      translate("Check Result") +
                      " - " +
                      (checksSupervisor.checkResult == null
                        ? "n/a"
                        : checksSupervisor.checkResult === true
                          ? "OK"
                          : " NOT OK")
                    }
                    variant="filled"
                    sx={{
                      backgroundColor:
                        checksSupervisor.checkResult == null
                          ? "#BDBDBD !important"
                          : checksSupervisor.checkResult === true
                            ? "#43A047 !important"
                            : "#E53935 !important",
                      color: "white !important",
                      flex: "0 1 auto",
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Min. Ratio Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksSupervisor.paramThresholdMinRatio ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. Ratio Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksSupervisor.paramThresholdMaxRatio ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Min. Trend Ratio Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksSupervisor.paramThresholdMinTrendRatio ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. Trend Ratio Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksSupervisor.paramThresholdMaxTrendRatio ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Min. Slope Acceptance")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksSupervisor.paramThresholdMinSlope ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. Slope Acceptance")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksSupervisor.paramThresholdMaxSlope ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Button variant="outlined" size="large" value="2" onClick={handleOpenReport}>
                    {translate("View Report")}
                  </Button>
                </Box>
              </Stack>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ margin: "0px 20px !important" }} />
            <Box sx={{ display: "flex", flex: "1 0 auto" }}>
              <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: 700,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Exploitation")}
                  </Typography>
                  <Chip
                    label={
                      translate("Check Result") +
                      " - " +
                      (checksExploitation.checkResult == null
                        ? "n/a"
                        : checksExploitation.checkResult === true
                          ? "OK"
                          : " NOT OK")
                    }
                    variant="filled"
                    sx={{
                      backgroundColor:
                        checksExploitation.checkResult == null
                          ? "#BDBDBD !important"
                          : checksExploitation.checkResult === true
                            ? "#43A047 !important"
                            : "#E53935 !important",
                      color: "white !important",
                      flex: "0 1 auto",
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. Marginal Flows Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksExploitation.paramThresholdMaxExtremeFlows ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. dT < 1 °C Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksExploitation.paramThresholdMaxDeltaT1C ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "1 0 auto",
                    }}
                  >
                    {translate("Max. dT < 3 °C Acceptance %")}
                  </Typography>
                  <Typography
                    noWrap
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      flex: "0 1 auto",
                    }}
                  >
                    {checksExploitation.paramThresholdMaxDeltaT3C ?? "n/a"}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                  <Button variant="outlined" size="large" value="3" onClick={handleOpenReport}>
                    {translate("View Report")}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          scroll={"paper"}
          onClose={handleReportClose}
          aria-labelledby="customized-dialog-title"
          open={openReport}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {translate("Report")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleReportClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: 0, minHeight: "500px" }}>
            <object width="100%" height="500px" data={checkPdfFile} type="application/pdf">
              File not found
            </object>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleReportClose}>
              {translate("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
});

export { CheckSheets };
