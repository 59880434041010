import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DownloadIcon from "@mui/icons-material/Download";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Children, cloneElement, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { NavigationBar } from "./components/shared/NavigationBar";
import { MobileNavigationBar } from "./components/shared/MobileNavigationBar";
/*import { OldschoolNavigationBar } from "components/shared/OldschoolNavigationBar";*/
import { SettingsModal } from "./components/shared/SettingsModal";

import { config } from "./config/config";
import { EmmsysMenu } from "./emmsysmenu";
import { useAppStore } from "./store/store";
import { AUTHAPI } from "./api/auth";

const drawerWidth = 350;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
  // @ts-ignore
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function stringAvatar(name) {
  if (name === undefined || name === "") {
    return {
      sx: {
        bgcolor: "#0051C1",
        width: 24,
        height: 24,
      },
      children: `-`,
    };
  }

  return {
    sx: {
      bgcolor: "#0051C1",
      width: 24,
      height: 24,
      fontSize: "0.9rem",
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ")[1] == null || name.split(" ")[1] == "" ? "" : name.split(" ")[1][0]
    }`,
  };
}

// const flagsItemRender = (props) => {
//   const result = LoginFlags.find(({ icon }) => icon === props);

//   return (
//     <>
//       <Avatar
//         alt="En"
//         src={"/img/flags/" + result.icon + ".png"}
//         variant="rounded"
//         sx={{
//           "& .MuiAvatar-img": {
//             width: "16px",
//             height: "16px",
//           },
//         }}
//       />
//     </>
//   );
// };

export default function MiniDrawer(props) {
  const {
    translate,
    language,
    installationType,
    globalRefresh,
    notifications,
    popNotification,
    onGeneralError,
  } = props;

  const theme = useTheme();

  const cookies = new Cookies();
  const {
    setAuthToken,
    appConfig,
    authToken,
    setRefreshToken,
    toggleSettingsOpen,
    /*oldschoolNavigation,*/
    sidebarPosition,
    setSidebarPosition,
  } = useAppStore();

  const userFullName = cookies.get("loginUserFullName") ?? "Administrator";
  const tokenMin = cookies.get("tokenMin") ?? "1";

  const apiurl = config.WS_API_URL;
  const apifilesurl = config.WS_APIFILES_URL;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const [menuItems, setMenuItems] = useState<any>([]);
  const { sidebarOpen, toggleSidebarOpen } = useAppStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const openaccountmenu = Boolean(anchorEl);
  const [anchorNotifEl, setAnchorNotifEl] = useState(null);
  const opennotifmenu = Boolean(anchorNotifEl);

  const location = useLocation();
  const {
    breadcrumbs,
    setBreadcrumbs,
    sidebarExpandedItems,
    setSidebarExpandedItems,
    sidebarSelectedItem,
    setSidebarSelectedItem,
  } = useAppStore();

  const tokenTimer: any = [];

  const checkToken = () => {
    let ufname = cookies.get("loginUserFullName");
    let token = useAppStore.getState().authToken;
    if (token != null) {
      AUTHAPI.RefreshToken(ufname).then(
        (result) => {
          if (result.code != "0") {
            if (onGeneralError != undefined)
              onGeneralError({ cause: result.code, message: "Session expired!" });
            clearInterval(tokenTimer[0]);
          } else {
            if (result.token != null) {
              setAuthToken(result.token.accessToken);
            }
          }
        },
        (error) => {
          if (error.cause == "401") {
            if (onGeneralError != undefined)
              onGeneralError({ cause: error.cause, message: "Session expired!" });
            clearInterval(tokenTimer[0]);
          }
        }
      );
    }
  };

  const handleDrawerClose = () => {
    toggleSidebarOpen();
  };

  const handleExpandClick = (idx) => {
    handleSidebarSelection(idx, null);
  };

  const handleNotifMenuClick = (event) => {
    setAnchorNotifEl(event.currentTarget);
  };
  const handleNotifMenuClose = () => {
    setAnchorNotifEl(null);
  };

  const handleDownloadExportFile = (event, value) => {
    setAnchorNotifEl(null);

    if (value === null || value === undefined) return 1;

    if (popNotification !== undefined) popNotification(value.exportGuid);

    window.open(
      apifilesurl + "DownloadFile?fileName=" + value.exportDownloadFileName,
      "_blank",
      "rel=noopener noreferrer"
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    AUTHAPI.Logout().then(
      (result) => {
        if (result.code != "0") {
          if (onGeneralError != undefined)
            onGeneralError({ cause: result.code, message: result.message });
        } else {
          setAnchorEl(null);
          setAuthToken(null);
          setRefreshToken(null);
        }
      },
      (error) => {
        if (error.cause != "401") {
          if (onGeneralError != undefined)
            onGeneralError({ cause: error.cause, message: "Internal error" });
        } else {
          setAnchorEl(null);
          setAuthToken(null);
          setRefreshToken(null);
        }
      }
    );
  };

  const handleSidebarSelection = (idx, routePath) => {
    const newBreadcrumbs: any = [];
    let navigateTo = "";
    let selectedItemRoutePath = "";

    if (idx === null && sidebarSelectedItem !== undefined) {
      selectedItemRoutePath = sidebarSelectedItem.route;
    }

    EmmsysMenu.find((menuItem: any) => {
      if (menuItem.items) {
        // If parent item, toggle expanded state
        if (menuItem.id === idx) {
          if (sidebarExpandedItems.find((sidebarItem) => sidebarItem.id === menuItem.id)) {
            setSidebarExpandedItems(
              sidebarExpandedItems.filter((sidebarItem) => sidebarItem.id !== menuItem.id)
            );
          } else {
            setSidebarExpandedItems([...sidebarExpandedItems, menuItem]);
          }

          return;
        }

        menuItem.items.find((submenuItem) => {
          if (submenuItem.route === selectedItemRoutePath || submenuItem.id === idx) {
            // Ignore empty routes
            if (submenuItem.route === "") return;

            setSidebarSelectedItem(submenuItem);

            newBreadcrumbs.push(
              { text: menuItem.text, route: menuItem.route },
              { text: submenuItem.text, route: submenuItem.route }
            );
            if (routePath !== null && routePath.startsWith(submenuItem.route))
              navigateTo = routePath;
            else navigateTo = submenuItem.route;
          }
        });
      } else if (menuItem.route === routePath || menuItem.id === idx) {
        // Ignore empty routes
        if (menuItem.route === "") return;

        setSidebarSelectedItem(menuItem);
        newBreadcrumbs.push({ text: menuItem.text, route: menuItem.route });
        if (routePath !== null && routePath.startsWith(menuItem.route)) navigateTo = routePath;
        else navigateTo = menuItem.route;
      }
    });
    if (navigateTo === "") navigateTo = "/";
    navigateTo && navigate(navigateTo);
    newBreadcrumbs.length > 0 && setBreadcrumbs([breadcrumbs, ...newBreadcrumbs]);
  };

  const children = Children.map(props.children, (child) => {
    return cloneElement(child, {
      ...props,
    });
  });

  //useEffect(() => {
  //  if (authToken === null) {
  //    navigate("/login", { replace: true });
  //    return;
  //  }
  //}, [authToken]);

  useEffect(() => {
    if (apiurl === undefined) {
      setAuthToken(null);
      return;
    }

    // Set selected menu items based on URL at mount
    const currentRoute = location.pathname;

    handleSidebarSelection(null, currentRoute);
    setMenuItems(EmmsysMenu);
    let timerseconds = parseInt(tokenMin) * 60 * 1000 - 10000;
    if (timerseconds == undefined || timerseconds == null || isNaN(timerseconds))
      timerseconds = 50000;
    if (tokenTimer[0] === undefined) tokenTimer[0] = setInterval(checkToken, timerseconds);
    checkToken();
  }, []);

  //useEffect(() => {
  //  // refresh layout on oldschoolNavigation toggle
  //  setMenuItems(oldschoolNavigation ? OldschoolEmmsysMenu : EmmsysMenu);
  //}, [oldschoolNavigation]);

  //const renderDrawerSlot = (slotPosition) => {
  //  if (slotPosition === sidebarPosition) {
  //    return (
  //      <Drawer variant="permanent" open={sidebarOpen} anchor={slotPosition}>
  //        {oldschoolNavigation ? (
  //          <OldschoolNavigationBar
  //            translate={translate}
  //            menuItems={menuItems}
  //            sidebarSelectedItem={sidebarSelectedItem}
  //            sidebarOpen={sidebarOpen}
  //            handleExpandClick={handleExpandClick}
  //            handleDrawerClose={handleDrawerClose}
  //          />
  //        ) : (
  //          <NavigationBar
  //            translate={translate}
  //            menuItems={menuItems}
  //            sidebarSelectedItem={sidebarSelectedItem}
  //            sidebarOpen={sidebarOpen}
  //            handleExpandClick={handleExpandClick}
  //            handleDrawerClose={handleDrawerClose}
  //            sidebarExpandedItems={sidebarExpandedItems}
  //            installationType={installationType}
  //          />
  //        )}
  //      </Drawer>
  //    );
  //  }
  //};

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/*{renderDrawerSlot("left")}*/}
      <Drawer
        variant="permanent"
        open={sidebarOpen && !isMobile}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        {!isMobile && (
          <NavigationBar
            translate={translate}
            menuItems={menuItems}
            sidebarSelectedItem={sidebarSelectedItem}
            sidebarOpen={sidebarOpen}
            handleExpandClick={handleExpandClick}
            handleDrawerClose={handleDrawerClose}
            sidebarExpandedItems={sidebarExpandedItems}
            installationType={installationType}
          />
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundImage: "linear-gradient(#F5F5F9,#dfdfec)",
          display: "flex",
          //minWidth: "360px",
        }}
      >
        <Grid container spacing={2} sx={{ flex: 1 }}>
          <Grid
            item
            sx={{
              display: "flex",
              backgroundColor: "white",
              marginLeft: "-10px",
              marginTop: "-10px",
              marginRight: "-24px",
              marginBottom: "-10px",
              paddingBottom: "10px",
              paddingRight: "20px",
              justifyContent: isMobile ? "space-between" : "",
              flex: 1,
            }}
          >
            {isMobile && (
              <MobileNavigationBar
                translate={translate}
                menuItems={menuItems}
                sidebarSelectedItem={sidebarSelectedItem}
                handleExpandClick={handleExpandClick}
                handleDrawerClose={handleDrawerClose}
                sidebarExpandedItems={sidebarExpandedItems}
                installationType={installationType}
              />
            )}

            {!isMobile && (
              <Breadcrumbs separator="/" aria-label="breadcrumb" sx={{ flex: "1 0 auto" }}>
                {breadcrumbs.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      noWrap
                      component="div"
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.75rem",
                      }}
                    >
                      {translate(item.text)}
                    </Typography>
                  );
                })}
              </Breadcrumbs>
            )}

            {notifications.length > 0 && (
              <IconButton
                onClick={handleNotifMenuClick}
                aria-label="notifications"
                aria-controls={opennotifmenu ? "notifications-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={opennotifmenu ? "true" : undefined}
              >
                <Badge
                  badgeContent={notifications.length}
                  color="error"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Tooltip title="Notifications" followCursor placement="top">
                    <NotificationsActiveOutlinedIcon sx={{ color: "#0051C1" }} />
                  </Tooltip>
                </Badge>
              </IconButton>
            )}

            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, borderRadius: "unset" }}
              aria-controls={openaccountmenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openaccountmenu ? "true" : undefined}
            >
              <Avatar {...stringAvatar(userFullName)} />
              {!isMobile && (
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    padding: "0px 10px",
                  }}
                >
                  {userFullName}
                </Typography>
              )}
              <ArrowDropDownIcon />
            </IconButton>
          </Grid>
          {/* <Grid item xs={12} md={12} sx={{ "&.MuiGrid-root": { padding: "0px" } }}>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                margin: "1rem",
                flexGrow: 1,
                border: "2px solid white",
                marginTop: "5px",
              }}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            {children}
          </Grid>
        </Grid>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openaccountmenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem key="1" onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem key="2">
          <ListItemIcon>
            <SettingsRoundedIcon />
          </ListItemIcon>
          {translate("Settings")}
        </MenuItem>
        <MenuItem key="3" onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {translate("Logout")}
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorNotifEl}
        id="notifications-menu"
        open={opennotifmenu}
        onClose={handleNotifMenuClose}
        onClick={handleNotifMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {notifications.map((item, index) => {
          return (
            <MenuItem key={index} onClick={handleNotifMenuClose}>
              {item.type === 1 && (
                <>
                  <TaskAltIcon /> {item.name} - status: {item.status}{" "}
                  {item.exportDownloadFileName !== "" && (
                    <IconButton onClick={(event) => handleDownloadExportFile(event, item)}>
                      <DownloadIcon />
                    </IconButton>
                  )}
                </>
              )}
            </MenuItem>
          );
        })}
      </Menu>

      {/*<SettingsModal />*/}
      {/*{renderDrawerSlot("right")}*/}
    </Box>
  );
}
