import { animated, to } from "react-spring";

const MAX_THICKNESS = 50;

const getWidthAndX = (width, x) => {
  let resultWidth = width;
  let resultX = x;
  if (width > MAX_THICKNESS) {
    resultWidth = MAX_THICKNESS;
    resultX = x + (width - MAX_THICKNESS) / 2;
  }
  return [resultWidth, resultX];
};

const getHeightAndY = (height, y) => {
  let resultHeight = height;
  let resultY = y;
  if (height > MAX_THICKNESS) {
    resultHeight = MAX_THICKNESS;
    resultY = y + (height - MAX_THICKNESS) / 2;
  }
  return [resultHeight, resultY];
};

const AnimatedPath = animated("path");
export const RoundedBar = (props) => {
  const { orientation, data, dataKey, style, ownerState, ...rest } = props;
  const { x, y, height, width } = style;
  const clipId = `clip-${Math.random()}`;

  if (!data || !data[ownerState.dataIndex]) {
    return null;
  }

  let dataKeyName = ownerState.id.replace("serie-id-", "");
  let flip = data[ownerState.dataIndex][dataKeyName] < 0;

  const d = to([x, y, height, width], (x, y, height, width) => {
    // Clamp thickness of bar based on orientation and determine flipped bars
    if (orientation === "horizontal") {
      [height, y] = getHeightAndY(height, y);
    } else {
      // vertical
      [width, x] = getWidthAndX(width, x);
    }

    // Set radius to half of the width or height based on orientation
    const radius = (orientation === "horizontal" ? height : width) / 2;

    let pathX;
    let pathY;
    let leftSide;
    let rightSide;
    let rounding;
    if (orientation === "vertical") {
      if (flip) {
        pathX = x;
        pathY = y;
        leftSide = height - radius;
        rightSide = -height + radius;
        rounding = radius;
      } else {
        pathX = x;
        pathY = y + height;
        leftSide = -height + radius;
        rightSide = height - radius;
        rounding = radius;
      }
    } else if (orientation === "horizontal") {
      if (flip) {
        pathX = x + width;
        pathY = y;
        leftSide = -width + radius;
        rightSide = width - radius;
        rounding = radius;
      } else {
        pathX = x;
        pathY = y;
        leftSide = width - radius;
        rightSide = -width + radius;
        rounding = radius;
      }
    }

    return orientation === "horizontal"
      ? `M${pathX},${pathY} 
                h${leftSide}
                a${rounding},${rounding} 0 0 ${flip ? 0 : 1} 0 ${rounding * 2}
                h${rightSide}`
      : `M${pathX},${pathY} 
                v${leftSide}
                a${rounding},${rounding} 0 0 ${flip ? 0 : 1} ${rounding * 2} 0
                v${rightSide}`;
  });

  const clipPath = to([x, y, width, height], (x, y, width, height) => {
    if (orientation === "horizontal") {
      [height, y] = getHeightAndY(height, y);
    } else {
      // vertical
      [width, x] = getWidthAndX(width, x);
    }

    return orientation === "horizontal"
      ? `M${x},${y}v${height}h${width}v${-height}Z`
      : `M${x},${y}h${width}v${height}h${-width}Z`;
  });

  return (
    <>
      <defs>
        <clipPath id={clipId}>
          <AnimatedPath d={clipPath} />
        </clipPath>
      </defs>
      <AnimatedPath d={d} fill={ownerState.color} clipPath={`url(#${clipId})`} {...rest} />
    </>
  );
};
