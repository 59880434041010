//@ts-nocheck

import Box from "@mui/material/Box";
import TelerikReportViewer from "./telerikreportviewer";

import { config } from "../../config/config";

const apiUrl = config.WS_REPORTING_URL.startsWith("http")
  ? config.WS_REPORTING_URL
  : window.location.origin + config.WS_REPORTING_URL;

function EmmsysReportViewer(props) {
  const { reportOptions } = props;

  const pdfurl = reportOptions.pdfReportUrl ?? "";
  const rname = reportOptions.rName ?? "";

  return (
    <Box sx={{ flex: "1 0 auto", p: 0 }}>
      {pdfurl != undefined && pdfurl != "" && (
        <object width="100%" height="500px" data={apiUrl + pdfrepurl} type="application/pdf">
          File not found
        </object>
      )}
      {rname != undefined && rname != "" && <TelerikReportViewer options={reportOptions} />}
    </Box>
  );
}

export default EmmsysReportViewer;
