import CircleIcon from "@mui/icons-material/Circle";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { memo, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import { BasePropsType } from "../../../types/common/EmmsysTypes";
import { Allocation_API } from "../../../api/allocationapi";
import { DashboardContext } from "../../dashboard/DashboardContext";
import { CustomSimpleGrid } from "../../common/CustomSimpleGrid";
import { StatusPieChart } from "../../common/StatusPieChart";

const columns1 = [
  {
    field: "name",
    headerName: "Counters",
    flex: 0.3,
    editable: false,
  },
  {
    field: "count",
    headerName: "",
    flex: 0.3,
    editable: false,
  },
];

export const AllocationWidget = memo(function AllocationWidget(props: BasePropsType) {
  const { translate, language } = props;

  const { idRegion, month } = useContext<any>(DashboardContext);

  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const [isAllocationCountersLoaded, setIsAllocationCountersLoaded] = useState(false);
  const [allocationCounters, setAllocationCounters] = useState<any>([]);

  const [isAllocationMetersReadStatusLoaded, setIsAllocationMetersReadStatusLoaded] =
    useState(false);
  const [allocationMetersStatusItems, setAllocationeMetersStatusItems] = useState<any>([]);

  useEffect(() => {
    setIsAllocationCountersLoaded(false);
    setIsAllocationMetersReadStatusLoaded(false);

    const dataStart = new Date(month.year(), month.month(), 1);

    Allocation_API.GetAllocationSummaryCounters(6, idRegion == undefined ? "" : idRegion).then(
      (result) => {
        let rows: any = [];
        if (result != null) {
          rows = [
            { id: 1, name: "Franchisee", count: result.region },
            { id: 2, name: "Cities", count: result.cities },
            { id: 3, name: "Condominiums", count: result.cityZones },
            { id: 4, name: "Buildings", count: result.buildings },
            { id: 5, name: "Consumption Points", count: result.consumptionPoints },
            { id: 6, name: "Meters", count: result.meters },
          ];
        }
        setAllocationCounters(rows);
        setIsAllocationCountersLoaded(true);
      },
      () => {
        setIsAllocationCountersLoaded(true);
      }
    );

    Allocation_API.GetNodeAllocationDetails(
      idRegion == undefined ? "" : idRegion,
      6,
      11,
      dayjs(dataStart).format("YYYYMMDD")
    ).then(
      (result) => {
        const allocationmetersstatus: any = [];
        if (result != null) {
          let totalmeters = 0;

          let msname = "";
          if (result.metersNotRead != null) {
            totalmeters += result.metersNotRead;
            msname = "OK (" + result.metersReadOK.toString() + ")";

            allocationmetersstatus.push({
              value: Math.floor((result.metersReadOK / totalmeters) * 10000) / 100,
              color: "#43A047",
              text: msname,
              label: msname,
              percent: Math.floor((result.metersReadOK / totalmeters) * 10000) / 100,
            });
          }

          if (result.metersReadAlarm != null) {
            totalmeters += result.metersReadAlarm;
            msname = "Alarm (" + result.metersReadAlarm.toString() + ")";

            allocationmetersstatus.push({
              value: Math.floor((result.metersReadAlarm / totalmeters) * 10000) / 100,
              color: "#FB8C00",
              text: msname,
              label: msname,
              percent: Math.floor((result.metersReadAlarm / totalmeters) * 10000) / 100,
            });
          }

          if (result.metersReadOK != null) {
            totalmeters += result.metersReadOK;
            msname = "Not Read (" + result.metersNotRead.toString() + ")";

            allocationmetersstatus.push({
              value: Math.floor((result.metersNotRead / totalmeters) * 10000) / 100,
              color: "#E53935",
              text: msname,
              label: msname,
              percent: Math.floor((result.metersNotRead / totalmeters) * 10000) / 100,
            });
          }
        }

        setAllocationeMetersStatusItems(allocationmetersstatus);
        setIsAllocationMetersReadStatusLoaded(true);
      },
      () => {
        setIsAllocationMetersReadStatusLoaded(true);
      }
    );
  }, [idRegion, month]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
          <Grid item xs={12} sm={12} md={13} lg={13}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
              }}
            >
              {(!isAllocationCountersLoaded || !isAllocationMetersReadStatusLoaded) && (
                <CircularProgress
                  sx={{
                    width: "1.2rem !important",
                    height: "1.2rem !important",
                    margin: "6px",
                  }}
                />
              )}
              <Typography
                noWrap
                component="span"
                sx={{
                  fontSize: "1.38rem",
                  fontWeight: 700,
                }}
              >
                {translate("Allocation")}
              </Typography>
              <Tooltip title={translate("Description:")} arrow>
                <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CustomSimpleGrid dataSet={allocationCounters} columns={columns1} />
          </Grid>
          <Grid
            item
            md={1}
            lg={1}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              width: "1rem",
              justifyContent: "center",
            }}
          >
            <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "fnone",
              },
              justifyContent: "center",
            }}
          >
            <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StatusPieChart
              translate={translate}
              language={language}
              chartConfig={{
                dataset: allocationMetersStatusItems,
                title: translate("Meters Read Status"),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
