import React, { useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import dayOfYear from "dayjs/plugin/dayOfYear";

import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import EmmsysRoutes from "./components/providers/emmsysroutes";
import { ErrorDialog } from "./components/common/ErrorDialog";

import { useAppStore } from "./store/store";
import { COMMON_API } from "./api/commonapi";
import { AUTHAPI } from "./api/auth";

dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.updateLocale("en", {
  weekStart: 1,
});

let notificationsTempList: any = [];

function App() {
  const [dictionary, setDictionary] = useState();
  const [currentCulture, setCurrentCulture] = useState("en-GB");
  const [installationType, setInstallationType] = useState("eth");
  const [globalRefresh, setGlobalRefresh] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [customError, setCustomError] = useState<any>({});
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const { setAuthToken } = useAppStore();

  const timersList: any = [];

  function loadCulture(culture: string): any {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (culture === undefined) return null;

        fetch("/culture/" + culture + ".json", { mode: "cors" })
          .then((response) => response.json())
          .then((responseJson) => {
            resolve(responseJson);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 0);
    });
  }

  function changeGlobalRefresh() {
    setGlobalRefresh((prevState) => !prevState);
  }

  const handleGeneralError = (error) => {
    if (error instanceof Response) {
      error.json().then((json) => {
        if (json.status == "401") {
          setIsSessionExpired(true);
          setCustomError({ Code: json.cause, Message: "Session expired!" });
        } else {
          AUTHAPI.IsAuth().then(
            (result) => {
              if (result.code != "0") {
                setCustomError({ Code: "401", Message: result.message });
              } else {
                setCustomError({ Code: json.status, Message: json.title, Details: json.detail });
              }
            },
            (error) => {
              setCustomError({ Code: error.cause, Message: "Internal server error." });
            }
          );
        }
      });
    } else {
      if (error.cause == "401") {
        setIsSessionExpired(true);
        setCustomError({ Code: error.cause, Message: "Session expired!" });
      } else {
        if (customError.Code != "401" && !isSessionExpired) {
          setCustomError({ Code: error.cause, Message: error.message });
        }
      }
    }
  };

  const handleCloseErrWindow = () => {
    setShowErrorDialog(false);
    if (customError.Code == "401") {
      setAuthToken(null);
    }
  };

  const translate = useCallback(
    (text: string) => {
      if (dictionary === undefined) return text;
      return dictionary[text] ?? text;
    },
    [currentCulture]
  );

  function setCulture(flag: string) {
    if (flag === undefined) return;

    let active = true;

    (async () => {
      const newdictionary = await loadCulture(flag);

      if (!active) {
        return;
      }

      setDictionary(newdictionary);
      setCurrentCulture(flag);

      active = false;
    })();
  }

  function changeInstallationType(value: string) {
    if (value === undefined) return;

    setInstallationType(value);
  }

  function checkNotificationStatus(arg: any) {
    if (arg.type === 1) {
      COMMON_API.GetExportFileTaskProgress(arg.exportGuid).then(
        (result) => {
          if (result.code === 0) {
            clearInterval(timersList['"' + arg.exportTimer + '"']);
          }
          const n = notificationsTempList.map((item: any) => {
            if (item.exportGuid === arg.exportGuid) {
              if (result.code === 0) {
                item.status = "Done";
                item.exportDownloadFileName = result.message;
              } else {
                item.status = result.message;
              }
            }

            return item;
          });

          setNotificationsList(n);
        },
        (error) => {
          setCustomError({ Code: error.cause, Message: error.message });
        }
      );
    } else {
      //clearInterval(timer);
    }
  }

  function popNotification(value: any) {
    if (value === undefined) return;

    const n = notificationsTempList.flatMap((item: any) => {
      if (item.exportGuid === value) return [];

      return item;
    });
    notificationsTempList = n;
    setNotificationsList(n);
  }

  function pushNotification(value: any) {
    if (value === undefined) return;

    notificationsTempList.push(value);
    const n = notificationsTempList.map((item: any) => item);

    setNotificationsList(n);

    timersList['"' + value.exportTimer + '"'] = setInterval(checkNotificationStatus, 2000, value);
  }

  useEffect(() => {
    if (customError.Code != undefined && customError.Message != undefined) {
      setShowErrorDialog(true);
    }
  }, [customError]);

  return (
    <React.Fragment>
      <EmmsysRoutes
        notifications={notificationsList}
        pushNotification={pushNotification}
        popNotification={popNotification}
        culture={setCulture}
        translate={translate}
        language={currentCulture}
        installationType={installationType}
        onChangeInstallationType={changeInstallationType}
        onChangeGlobalRefresh={changeGlobalRefresh}
        globalRefresh={globalRefresh}
        onGeneralError={handleGeneralError}
      ></EmmsysRoutes>
      {showErrorDialog && (
        <ErrorDialog
          translate={translate}
          err={customError}
          show={showErrorDialog}
          onClose={handleCloseErrWindow}
        ></ErrorDialog>
      )}
    </React.Fragment>
  );
}

export default App;
