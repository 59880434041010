export default function FetchResult(res) {
  if (!res.ok) {
    if (res.status == 404) {
      throw new Error("Error: 404", { cause: res.status });
    }
    if (res.status == 401) {
      throw new Error("Unauthorized, session expired", { cause: res.status });
    }
    throw res;
  } else {
    return res.json();
  }
}
