import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { IconConverter } from "../utils/utils";
import { BasePropsType } from "../../types/common/EmmsysTypes";

interface NavigationBarProps extends Pick<BasePropsType, "translate"> {
  menuItems: any;
  sidebarSelectedItem: any;
  sidebarOpen: boolean;
  handleExpandClick: React.MouseEventHandler;
  handleDrawerClose: React.MouseEventHandler;
  sidebarExpandedItems: Array<any>;
  installationType: string;
}

export const NavigationBar = (props: NavigationBarProps) => {
  const {
    translate,
    menuItems,
    sidebarSelectedItem,
    sidebarOpen,
    handleExpandClick,
    handleDrawerClose,
    sidebarExpandedItems,
    installationType,
  } = props;

  const theme = useTheme();

  return (
    <>
      <List
        disablePadding
        id="ml1"
        sx={{
          padding: "0px 10px",
          "& .MuiListItemButton-root.Mui-selected": {
            backgroundColor: "#0051C1 !important",
            borderRadius: "1.2rem",
            color: "white",
          },
        }}
      >
        {menuItems != undefined &&
          menuItems.map((item, index) => {
            const isSelected =
              sidebarSelectedItem.id === item.id || sidebarSelectedItem.parentId === item.id;
            const isExpanded =
              sidebarExpandedItems.find((sidebarItem) => sidebarItem.id === item.id) !== undefined;

            if (item.type === "header") {
              return (
                <ListSubheader
                  key={index}
                  sx={{
                    minHeight: 48,
                    justifyContent: sidebarOpen ? "initial" : "center",
                    px: 2.5,
                    display: "flex",
                    alignItems: "center",
                    margin: "1rem 0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sidebarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    onClick={handleDrawerClose}
                  >
                    {sidebarOpen ? (
                      <img src="/img/itron1.png" height="24px" alt="missing" />
                    ) : (
                      <img src="/img/itron.png" height="24px" alt="missing" />
                    )}
                  </ListItemIcon>
                </ListSubheader>
              );
            } else {
              if (item.type === "divider") {
                return (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{
                      display: "block",
                    }}
                  >
                    <Divider sx={{ margin: "5px 0px" }} />
                  </ListItem>
                );
              } else {
                if (item.installationType && item.installationType !== installationType)
                  return null;

                return (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{
                      display: "block",
                      border: isExpanded && isSelected ? "1px solid #0051C1" : "",
                      borderRadius: isExpanded && isSelected ? "1.2rem" : "",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        justifyContent: sidebarOpen ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        handleExpandClick(item.id);
                      }}
                      selected={isSelected}
                    >
                      {!sidebarOpen && (
                        <Tooltip title={translate(item.text)} placement="top">
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: sidebarOpen ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {item.icon != "" && (
                              <IconConverter name={isSelected ? item.iconselected : item.icon} />
                            )}
                          </ListItemIcon>
                        </Tooltip>
                      )}
                      {sidebarOpen && (
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: sidebarOpen ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon != "" && (
                            <IconConverter name={isSelected ? item.iconselected : item.icon} />
                          )}
                        </ListItemIcon>
                      )}

                      <ListItemText
                        primary={translate(item.text)}
                        sx={{ opacity: sidebarOpen ? 1 : 0 }}
                      />
                      {!sidebarOpen || !item.items ? (
                        ""
                      ) : isExpanded ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                    {item.items != null && (
                      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <List disablePadding sx={{ margin: sidebarOpen ? "0px 20px" : "inherit" }}>
                          {item.items.map((subitem, index) => {
                            const isSelected = sidebarSelectedItem.id === subitem.id;
                            return (
                              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                                <ListItemButton
                                  sx={{
                                    justifyContent: sidebarOpen ? "initial" : "center",
                                    px: 2.5,
                                  }}
                                  onClick={() => {
                                    handleExpandClick(subitem.id);
                                  }}
                                >
                                  {sidebarOpen && (
                                    <CircleIcon
                                      sx={{
                                        width: "1rem",
                                        color: isSelected ? "#0051C1" : "transparent",
                                      }}
                                    ></CircleIcon>
                                  )}
                                  {!sidebarOpen && (
                                    <Tooltip title={translate(subitem.text)} placement="top">
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: sidebarOpen ? 3 : "auto",
                                          justifyContent: sidebarOpen ? "initial" : "center",
                                        }}
                                      >
                                        {subitem.icon != "" && (
                                          <IconConverter name={subitem.icon} color={"inherit"} />
                                        )}
                                      </ListItemIcon>
                                    </Tooltip>
                                  )}
                                  {sidebarOpen && (
                                    <ListItemText
                                      primary={translate(subitem.text)}
                                      sx={{
                                        margin: "0px 10px",
                                        "& .MuiTypography-root": {
                                          fontWeight: isSelected ? 700 : 400,
                                        },
                                      }}
                                    />
                                  )}
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    )}
                  </ListItem>
                );
              }
            }
          })}
      </List>
      <Stack
        id="ml2"
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        spacing={5}
        style={{ height: "100%" }}
      >
        <IconButton onClick={handleDrawerClose} sx={{ borderRadius: "10%" }}>
          {theme.direction === "rtl" || !sidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          {sidebarOpen && (
            <Typography sx={{ fontSize: "1rem", bordeRadius: "10%" }} noWrap component="div">
              {translate("Collapse")}
            </Typography>
          )}
        </IconButton>
      </Stack>
    </>
  );
};
