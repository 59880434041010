import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IEMS_API {
  GetEmsChecksListV1: (
    idNode: string,
    tipGrupare: number | null,
    idUtility: number | null,
    checkResult: string,
    dataStart: string,
    dataStop: string
  ) => Promise<any>;
  GetTreeNodeEquipments: (
    equipmentId: string,
    equipmentType: string,
    clientName: string,
    quickSearch: string
  ) => Promise<Array<any>>;
  GetBuildings: (regionIds: string, cityIds: string, cityZoneIds: string) => Promise<Array<any>>;
  GetEmsChecksList: (
    idReferinta: string,
    tipGrupareAdministrativa: string,
    idMediuFiltruContoare: string,
    checkResult: string
  ) => Promise<Array<any>>;
  GetEmsChecksEvents: (idCheck: string) => Promise<any>;
  GetEmsChecksSupervisor: (idCheck: string) => Promise<any>;
  GetEmsChecksExploitation: (idCheck: string) => Promise<any>;
}

const EMS_API: IEMS_API = {
  GetEmsChecksListV1: async (idNode, tipGrupare, idUtility, checkResult, dataStart, dataStop) => {
    return await fetch(
      `${apiUrl}GetEmsChecksListV1?idReferinta=${idNode}&tipGrupareAdministrativa=${tipGrupare}&idMediuFiltruContoare=${idUtility}&checkResult=${checkResult}&dataStart=${dataStart}&dataStop=${dataStop}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetTreeNodeEquipments: async (equipmentId, equipmentType, clientName, quickSearch) => {
    return await fetch(
      `${apiUrl}GetTreeNodeEquipments?equipmentId=${equipmentId}&equipmentType=${equipmentType}&clientName=${clientName}&quickSearch=${quickSearch}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetBuildings: async (regionIds, cityIds, cityZoneIds) => {
    return await fetch(
      `${apiUrl}GetBuildings?regionIds=${regionIds}&cityIds=${cityIds}&cityZoneIds=${cityZoneIds}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetEmsChecksList: async (
    idReferinta,
    tipGrupareAdministrativa,
    idMediuFiltruContoare,
    checkResult
  ) => {
    return await fetch(
      `${apiUrl}GetEmsChecksList?idReferinta=${idReferinta}&tipGrupareAdministrativa=${tipGrupareAdministrativa}&idMediuFiltruContoare=${idMediuFiltruContoare}&checkResult=${checkResult}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetEmsChecksEvents: async (idCheck) => {
    return await fetch(`${apiUrl}GetEmsChecksEvents?idCheck=${idCheck}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetEmsChecksSupervisor: async (idCheck) => {
    return await fetch(`${apiUrl}GetEmsChecksSupervisor?idCheck=${idCheck}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
  GetEmsChecksExploitation: async (idCheck) => {
    return await fetch(`${apiUrl}GetEmsChecksExploitation?idCheck=${idCheck}`, {
      mode: "cors",
      headers: getHeaders(),
    })
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
};

export { EMS_API };
