import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { BarChart, axisClasses, useDrawingArea } from "@mui/x-charts";
import { memo, useContext, useState } from "react";
import { RoundedBar } from "../../common/RoundedBar";

import { CustomSimpleGrid } from "../../common/CustomSimpleGrid";
import { DateValueFormatter, FormatLargeNumbers } from "../../utils/utils";
import { DetailsInputs } from "./DetailsInputs";
import { SummaryInputs } from "./SummaryInputs";
import { useAppStore } from "../../../store/store";
import { isMobile } from "react-device-detect";

const chartSetting = {
  height: 350,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-25px, 0)",
    },
    [`.${axisClasses.right} .${axisClasses.label}`]: {
      transform: "translate(5px, 30)",
    },
  },
  legend: { hidden: true },
};

const StyledTickLine = styled("line")(({ x1 }) => ({
  //stroke: theme.palette.text[color],
  stroke: "#757575",
  strokeOpacity: "0.3",
  strokeDasharray: "3px",
  shapeRendering: "crispEdges",
  strokeWidth: 1,
  x1: x1,
}));

function CustomYAxisTick(props) {
  const { width } = useDrawingArea();
  const { x2 } = props;

  return <StyledTickLine {...props} x1={x2 === undefined ? null : width} />;
}

const columns = [
  {
    field: "dataStart",
    headerName: "Date",
    flex: 0.3,
    minWidth: 150,
    editable: false,
    valueFormatter: (params) => DateValueFormatter(params.value, null),
  },
  {
    field: "valoare",
    headerName: "Consumption",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
];

const seriesValueFormatter = (value, unit) => `${value} ${unit}`;

interface EmsConsumptionChartProps {
  translate: (params: string) => string;
  language: string;
  context: any;
}

export const EmsConsumptionChart = memo(function EmsConsumptionChart({
  translate,
  context,
}: EmsConsumptionChartProps) {
  const { appConfig } = useAppStore();

  const mname = appConfig?.mname;
  const munit = appConfig?.munit;

  const [data, setData] = useState([]);
  const [isDetailsView, setIsDetailsView] = useState(false);
  const [chartType, setChartType] = useState(1);
  const [viewFormat, setViewFormat] = useState(1);

  const { nodeItem } = useContext<any>(context);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: isMobile ? "10px" : "inherit",
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    fontSize: "1.38rem",
                    fontWeight: 700,
                  }}
                >
                  {translate("Energy Supplied & Consumed")}
                </Typography>

                <Tooltip title={translate("Description:")} arrow>
                  <InfoIcon
                    sx={{
                      margin: "0.31rem 0.9rem",
                      color: "#0051C1",
                    }}
                  />
                </Tooltip>
              </Box>

              {/*<Divider orientation="vertical" flexItem sx={{ margin: "0px 20px !important" }} />*/}
              <Stack direction="row" flex={1} justifyContent={"flex-end"}>
                {/*<Stack direction="row" spacing={1} sx={{ margin: "0px 10px" }}>*/}
                {/*  <Chip*/}
                {/*    sx={{*/}
                {/*      padding: "4px 14px !important",*/}
                {/*      borderRadius: "8px",*/}
                {/*    }}*/}
                {/*    icon={*/}
                {/*      <WarningOutlinedIcon*/}
                {/*        sx={{*/}
                {/*          color: "gray !important",*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label={"0 " + translate("Critical Alarms")}*/}
                {/*    variant="outlined"*/}
                {/*  />*/}

                {/*  <Chip*/}
                {/*    sx={{*/}
                {/*      padding: "4px 14px !important",*/}
                {/*      borderRadius: "8px",*/}
                {/*    }}*/}
                {/*    icon={*/}
                {/*      <ErrorOutlinedIcon*/}
                {/*        sx={{*/}
                {/*          color: "gray !important",*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label={"0 " + translate("Alarm Warning")}*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</Stack>*/}

                <ToggleButtonGroup
                  value={isDetailsView}
                  exclusive
                  onChange={() => setIsDetailsView((isDetailsView) => !isDetailsView)}
                  aria-label="text alignment"
                  size={"small"}
                  sx={{
                    justifyContent: "center",
                    marginTop: "-3px",
                  }}
                >
                  <ToggleButton
                    value={false}
                    aria-label="left aligned"
                    sx={{
                      maxHeight: "40px",
                      backgroundColor: !isDetailsView ? "#0051C1 !important" : "inherit",
                      color: !isDetailsView ? "white !important" : "inherit",
                      borderRadius: "4rem 0 0 4rem !important",
                    }}
                  >
                    <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                      {translate("Summary")}
                    </Typography>
                  </ToggleButton>

                  <ToggleButton
                    value={true}
                    aria-label="right aligned"
                    sx={{
                      maxHeight: "40px",
                      backgroundColor: isDetailsView ? "#0051C1 !important" : "inherit",
                      color: isDetailsView ? "white !important" : "inherit",
                      borderRadius: "0 4rem 4rem 0 !important",
                    }}
                  >
                    <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                      {translate("Details")}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isDetailsView ? (
              <DetailsInputs
                translate={translate}
                chartConfig={nodeItem}
                chartType={chartType}
                setChartType={setChartType}
                setViewFormat={setViewFormat}
                setData={setData}
              />
            ) : (
              <SummaryInputs
                translate={translate}
                chartConfig={nodeItem}
                chartType={chartType}
                setChartType={setChartType}
                setViewFormat={setViewFormat}
                setData={setData}
              />
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
              {chartType === 1 && (
                <BarChart
                  dataset={data}
                  margin={{
                    top: 30,
                    left: 80,
                    right: 30,
                    bottom: 30,
                  }}
                  xAxis={
                    data.length === 0
                      ? []
                      : [
                          {
                            scaleType: "band",
                            dataKey: "dataStart",
                            valueFormatter: (params) => DateValueFormatter(params, viewFormat),
                            //categoryGapRatio: 0.2,
                            //barGapRatio: 0.2,
                          },
                        ]
                  }
                  yAxis={
                    data.length === 0
                      ? []
                      : [
                          {
                            tickSize: 6,
                            tickFontSize: 12,
                            valueFormatter: FormatLargeNumbers,
                            label: mname + " (" + munit + ")",
                          },
                        ]
                  }
                  series={
                    data.length === 0
                      ? []
                      : [
                          {
                            dataKey: "valoare",
                            valueFormatter: (value) => seriesValueFormatter(value, munit),
                            type: "bar",
                            color: "#1976D2",
                            label: "Consumed",
                          },
                        ]
                  }
                  {...chartSetting}
                  slots={{
                    bar: (props) =>
                      RoundedBar({
                        ...props,
                        data: data,
                        orientation: "vertical",
                      }),
                    axisTick: (props) => CustomYAxisTick(props),
                  }}
                ></BarChart>
              )}
              {chartType === 2 && (
                <CustomSimpleGrid dataSet={data} columns={columns} maxHeight={350} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
