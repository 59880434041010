import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { memo, useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack } from "@mui/material";

import * as Common_Types from "../../types/common/EmmsysTypes";
import { BasePropsType } from "../../types/common/EmmsysTypes";
import { CustomMultiselectDropDown } from "./CustomMultiselectDropDown";
import { COMMON_API } from "../../api/commonapi";

interface AddressFilterProps extends Pick<BasePropsType, "translate"> {
  onChange: (params: any) => void;
}

const AddressFilter = memo(function AddressFilter(props: AddressFilterProps) {
  const { translate, onChange } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isRegionsLoaded, setIsRegionsLoaded] = useState(false);
  const [isCitiesLoaded, setIsCitiesLoaded] = useState(false);
  const [isCityZonesLoaded, setIsCityZonesLoaded] = useState(false);

  const [regionsItems, setRegionsItems] = useState<Common_Types.CatalogItemType[]>([]);
  const [citiesItems, setCitiesItems] = useState<Common_Types.CatalogItemType[]>([]);
  const [cityZonesItems, setCityZonesItems] = useState<Common_Types.CatalogItemType[]>([]);

  const [regionsValues, setRegionsValues] = useState([]);
  const [citiesValues, setCitiesValues] = useState([]);

  const [addressParams, setAddressParams] = useState({});
  const [addressParamsTmp, setAddressParamsTmp] = useState({});

  const handleRegionValueChange = (value) => {
    setRegionsValues(value);
    if (value.join(",") != "") setAddressParams({ ...addressParams, regions: value.join(",") });
    else setAddressParams({ regions: "", cities: "", cityZones: "" });
  };

  const handleCitiesValueChange = (value) => {
    setCitiesValues(value);
    if (value.join(",") != "") setAddressParams({ ...addressParams, cities: value.join(",") });
    else setAddressParams({ ...addressParams, cities: "", cityZones: "" });
  };

  const handleCityZonesValueChange = (value) => {
    setAddressParams({ ...addressParams, cityZones: value.join(",") });
  };

  useEffect(() => {
    if (isEqual(addressParams, addressParamsTmp)) return;

    setAddressParamsTmp(addressParams);

    onChange(addressParams);
  }, [addressParams]);

  useEffect(() => {
    setIsCitiesLoaded(false);
    const regions = regionsValues.join(",");
    if (regions !== "") {
      COMMON_API.GetCatalog("Cities").then((cities) => {
        const citiesitems = cities.map((city) => {
          return {
            id: city.code,
            value: city.code,
            text: city.name,
          };
        });
        setCitiesItems(citiesitems);
        setCityZonesItems([]);
        setCitiesValues([]);
        setIsCitiesLoaded(true);
      });
    } else {
      setCitiesItems([]);
      setCityZonesItems([]);
      setCitiesValues([]);
      setIsCitiesLoaded(true);
    }
  }, [regionsValues]);

  useEffect(() => {
    setIsCityZonesLoaded(false);
    const cities = citiesValues.join(",");
    if (cities !== "") {
      COMMON_API.GetCatalog("CityZones").then((cityzones) => {
        const cityzonesitems = cityzones.map((cityzone) => {
          return {
            id: cityzone.code,
            value: cityzone.code,
            text: cityzone.name,
          };
        });
        setCityZonesItems(cityzonesitems);
        setIsCityZonesLoaded(true);
      });
    } else {
      setCityZonesItems([]);
      setIsCityZonesLoaded(true);
    }
  }, [citiesValues]);

  useEffect(() => {
    setIsRegionsLoaded(false);

    COMMON_API.GetCatalog("Regions").then((regions) => {
      const regionsitems = regions.map((reg) => {
        return {
          id: reg.code,
          value: reg.code,
          text: reg.name,
        };
      });
      setRegionsItems(regionsitems);
      setIsRegionsLoaded(true);
    });
  }, []);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={1}
      sx={{ display: "flex", flex: 1, alignItems: isMobile ? "flex-start" : "center", mt: 1 }}
    >
      <Grid item sx={{ flex: "1 1 max-content", width: isMobile ? "100%" : "auto" }}>
        <CustomMultiselectDropDown
          dataSet={regionsItems}
          dropDownLabel={translate("Region")}
          onValueChange={handleRegionValueChange}
        />
        {!isRegionsLoaded && (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </Grid>
      <Grid item sx={{ flex: "1 1 max-content", width: isMobile ? "100%" : "auto" }}>
        <CustomMultiselectDropDown
          dataSet={citiesItems}
          dropDownLabel={translate("Cities")}
          onValueChange={handleCitiesValueChange}
        />
        {!isCitiesLoaded && (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </Grid>
      <Grid item sx={{ flex: "1 1 max-content", width: isMobile ? "100%" : "auto" }}>
        <CustomMultiselectDropDown
          dataSet={cityZonesItems}
          dropDownLabel={translate("City Zones")}
          onValueChange={handleCityZonesValueChange}
        />
        {!isCityZonesLoaded && (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </Grid>
    </Stack>
  );
});

export { AddressFilter };
