import { Pagination, PaginationItem } from "@mui/material";
import {
  DataGrid,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import { useContext } from "react";
import { BuildingsContext } from "./TestPage";

const ROW_HEIGHT = 52;
const PAGE_SIZE = 5;
const TABLE_HEIGHT = ROW_HEIGHT * PAGE_SIZE + 111;

const COLUMNS = [
  {
    field: "name",
    headerName: "Name",
    flex: 0.2,
  },
  {
    field: "code",
    headerName: "Code",
    flex: 0.2,
  },
  {
    field: "consumptionPointsNo",
    headerName: "#Consumption Points",
    flex: 0.2,
  },
  {
    field: "metersNo",
    headerName: "#Meters",
    flex: 0.2,
  },
];

export const CustomPagination = ({ page, onPageChange, className }) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      count={pageCount}
      className={className}
      variant="outlined"
      shape="rounded"
      showFirstButton
      showLastButton
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          sx={{
            borderColor: "border",
            "&:hover": {
              color: "white",
              backgroundColor: "primary.dark",
            },
            ".MuiTouchRipple-child": {
              color: "white",
              backgroundColor: "primary.light",
              "&:hover": {
                color: "white",
              },
            },
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "primary.main",
              border: "none",
              "&:hover": {
                color: "white",
                backgroundColor: "primary.dark",
                border: "none",
              },
            },
          }}
        />
      )}
    />
  );
};

export const BuildingTableX = () => {
  const { buildings } = useContext(BuildingsContext);

  return (
    <DataGrid
      sx={{ height: TABLE_HEIGHT, width: "100%" }}
      columns={COLUMNS}
      rowHeight={ROW_HEIGHT}
      rows={buildings}
      // TODO: Update loading state logic
      loading={buildings.length === 0}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: PAGE_SIZE,
          },
        },
      }}
      pageSizeOptions={[PAGE_SIZE]}
      slots={{
        pagination: (props) => <GridPagination ActionsComponent={CustomPagination} {...props} />,
      }}
      checkboxSelection
      disableRowSelectionOnClick
    />
  );
};
