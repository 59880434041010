import { useAppStore } from "../store/store";
import { config } from "../config/config";
import FetchResult from "./fetchresult";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;
const appConfig = useAppStore.getState().appConfig;
const getHeaders = () => {
  return {
    mid: appConfig.mid,
    Authorization: "bearer " + useAppStore.getState().authToken,
    RefreshToken: useAppStore.getState().refreshToken,
  };
};

interface IVEE_API {
  GetVeeBillingGroups: (billingMonth: string, selectType: string) => Promise<Array<any>>;
  GetVeeStatistics: (
    billingMonth: string,
    idBillingGroup: string,
    startDate: string
  ) => Promise<any>;
}

const VEE_API: IVEE_API = {
  GetVeeBillingGroups: async (billingMonth, selectType) => {
    return await fetch(
      `${apiUrl}GetVeeBillingGroups?billingMonth=${billingMonth}&selectType=${selectType}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: Array<any>) => {
        return data;
      });
  },
  GetVeeStatistics: async (billingMonth, idBillingGroup, startDate) => {
    return await fetch(
      `${apiUrl}GetVeeStatistics?billingMonth=${billingMonth}&idBillingGroup=${idBillingGroup}&startDate=${startDate}`,
      {
        mode: "cors",
        headers: getHeaders(),
      }
    )
      .then((res) => FetchResult(res))
      .then((data: any) => {
        return data;
      });
  },
};

export { VEE_API };
