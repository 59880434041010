import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import BloodtypeOutlinedIcon from "@mui/icons-material/BloodtypeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import InfoIcon from "@mui/icons-material/Info";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import dayjs from "dayjs";

import { CustomAreaChart } from "../common/CustomAreaChart";
import { HorizontalBarChart } from "../common/horizontalbarchart";
import { StatusPieChart } from "../common/StatusPieChart";
import { VerticalBarChart } from "../common/verticalbarchart";
import { AlarmSubscription } from "../shared/alarmsubscription";
import { DateValueFormatter, FormatLargeNumbers } from "../utils/utils";
import { useAppStore } from "../../store/store";
import { SYSTEM_STATUS_API } from "../../api/systemstatusapi";
import { EMS_API } from "../../api/emsapi";
import { DCS_API } from "../../api/dcsapi";
import { COMMON_API } from "../../api/commonapi";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: "24px 0px" }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 32,
  borderRadius: "2rem",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "2rem",
    backgroundColor: theme.palette.mode === "light" ? "#66BB6A" : "#308fe8",
  },
}));

const valueFormatter = (value) => `${value}%`;
const yAxisValueFormatter1 = (value) => value.toString().split("_")[1];
const valueFormatter1 = (value, unit) => `${value} ${unit}`;

const weekDateValueFormatter = (value) => {
  if (value == null) return "n/a";
  else return "Week " + value;
};

const nullValueFormatter = (value) => {
  if (value == null) return "n/a";
  else return `Reading rate: ${value}%`;
};

function CustomAlarmIcon(iconCode) {
  switch (iconCode) {
    case 0:
      return <ErrorOutlinedIcon sx={{ color: "#1E88E5 !important" }} />;
    case 1:
      return <ErrorOutlinedIcon sx={{ color: "#FB8C00 !important" }} />;
    case 2:
      return <WarningOutlinedIcon sx={{ color: "#E53935 !important" }} />;
  }
}

export default function DistrictHeatAndCooling(props) {
  const { translate, language, globalRefresh, onGeneralError } = props;
  const { appConfig } = useAppStore();

  const theme = useTheme();
  //const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const yAxisValueFormatter = FormatLargeNumbers;
  const dateValueFormatter = DateValueFormatter;

  const [selectedTab, setSelectedTab] = useState(0);
  const [alarmStatusType, setAlarmStatusType] = useState("alarmdistribution");
  const [lastAlarmStatusType, setLastAlarmStatusType] = useState("");

  const [isMediumConsLoaded, setIsMediumConsLoaded] = useState(false);
  const [mediumConsEvolution, setMediumConsEvolution] = useState<any>({});

  const [isAlarmDistrLoaded, setIsAlarmDistrLoaded] = useState(true);
  const [alarmDistrItems, setAlarmDistrItems] = useState<any>([]);
  const [alarmInfoItems, setAlarmInfoItems] = useState<any>([]);
  const [alarmInfoCode, setAlarmInfoCode] = useState<any>();

  const [isPerformanceLoaded, setIsPerformanceLoaded] = useState(false);
  const [performanceItems, setPerformanceItems] = useState<any>({
    xAxisData: [],
    seriesData: [],
  });

  const [performanceAvg, setPerformanceAvg] = useState(0);

  const [isAlarmAgesLoaded, setIsAlarmAgesLoaded] = useState(true);
  const [alarmAgesItems, setAlarmAgesItems] = useState<any>([]);

  const [isReadingStatusLoaded, setIsReadingStatusLoaded] = useState(false);
  const [readingStatusItems, setReadingStatusItems] = useState<any>([]);

  const [isReadingsAgeLoaded, setIsReadingsAgeLoaded] = useState(false);
  const [readingsAgeItems, setReadingsAgeItems] = useState<any>([]);

  const [isEquipmentsLoaded, setIsEquipmentsLoaded] = useState(false);
  const [equipmentsItems, setEquipmentsItems] = useState<any>([]);
  const [selectedEquipment, setEquipment] = useState(0);

  const [usageData, setUsageData] = useState<any>([]);

  const [metersNo, setMetersNo] = useState(0);
  const [criticalAl, setCriticalAl] = useState(0);
  const [warningsAl, setWarningsAl] = useState(0);

  const [supplyPointsNo, setSupplyPointsNo] = useState(0);
  const [networkLength, setNetworkLength] = useState("");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEquipmentChange = (event) => {
    setEquipment(event.target.value);
  };

  const handleAlarmStatusType = (event, newAlarmStatusType) => {
    if (newAlarmStatusType != null) {
      setAlarmStatusType(newAlarmStatusType);
    }
  };

  const handleInfoCode = (event, newAlarmInfoCode) => {
    setAlarmInfoCode(newAlarmInfoCode);
  };

  const mname = appConfig?.mname ?? "Cooling";
  const munit = appConfig?.munit ?? "MWh";

  useEffect(() => {
    setIsReadingStatusLoaded(false);
    setIsReadingsAgeLoaded(false);
    setIsMediumConsLoaded(false);
    setIsEquipmentsLoaded(false);

    SYSTEM_STATUS_API.GetSystemReadingStatus("").then(
      (result) => {
        setIsReadingStatusLoaded(true);
        let metersno = 0;
        let criticalalarms = 0;
        let warnings = 0;
        const readingstat = result.flatMap((rs) => {
          if (rs.percent === 0) {
            return [];
          } else {
            const rsname = translate(rs.name) + " (" + rs.metersCount.toString() + ")";
            metersno = metersno + rs.metersCount;
            if (rs.status === 3) criticalalarms = rs.metersCount;
            if (rs.status === 5) warnings = rs.metersCount;
            return {
              value: Math.floor(rs.percent * 10000) / 100,
              color: rs.color,
              text: rsname,
              label: rsname,
              percent: Math.floor(rs.percent * 10000) / 100,
            };
          }
        });
        setReadingStatusItems(readingstat);
        setMetersNo(metersno);
        setCriticalAl(criticalalarms);
        setWarningsAl(warnings);
      },
      (error) => {
        setIsReadingStatusLoaded(true);
        setReadingStatusItems([]);
        setMetersNo(0);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    SYSTEM_STATUS_API.GetSystemReadingsAges("").then(
      (result) => {
        setIsReadingsAgeLoaded(true);

        const readingsage = result.flatMap((rs) => {
          if (rs.percent === 0) {
            return [];
          } else {
            const rsname = translate(rs.readingsAge) + " (" + rs.metersCount.toString() + ")";

            return {
              value: Math.floor(rs.percent * 10000) / 100,
              color: rs.color,
              text: rsname,
              label: rsname,
              percent: Math.floor(rs.percent * 10000) / 100,
            };
          }
        });
        setReadingsAgeItems(readingsage);
      },
      (error) => {
        setIsReadingsAgeLoaded(true);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    SYSTEM_STATUS_API.GetMediumConsumptionEvolution(
      "11",
      "1",
      "0",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ).then(
      (result) => {
        SYSTEM_STATUS_API.GetMediumConsumptionEvolution(
          "11",
          "2",
          "0",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        ).then(
          (result1) => {
            if (result.days.length > 0) {
              for (let i = 0; i < result.days.length; i++) {
                result.days[i].supplierSide =
                  Math.floor(result.days[i].valoare) === -2145185
                    ? null
                    : Math.floor(result.days[i].valoare * 1000) / 1000;
                result.days[i].consumerSide =
                  Math.floor(result1.days[i].valoare) === -2145185
                    ? null
                    : Math.floor(result1.days[i].valoare * 1000) / 1000;
              }
            }
            if (result.weeks.length > 0) {
              for (let i = 0; i < result.weeks.length; i++) {
                result.weeks[i].supplierSide =
                  Math.floor(result.weeks[i].valoare) === -2145185
                    ? null
                    : Math.floor(result.weeks[i].valoare * 1000) / 1000;
                result.weeks[i].consumerSide =
                  Math.floor(result1.weeks[i].valoare) === -2145185
                    ? null
                    : Math.floor(result1.weeks[i].valoare * 1000) / 1000;
              }
            }
            if (result.months.length > 0) {
              for (let i = 0; i < result.months.length; i++) {
                result.months[i].supplierSide =
                  Math.floor(result.months[i].valoare) === -2145185
                    ? null
                    : Math.floor(result.months[i].valoare * 1000) / 1000;
                result.months[i].consumerSide =
                  Math.floor(result1.months[i].valoare) === -2145185
                    ? null
                    : Math.floor(result1.months[i].valoare * 1000) / 1000;
              }
            }
            if (result.years.length > 0) {
              for (let i = 0; i < result.years.length; i++) {
                result.years[i].supplierSide =
                  Math.floor(result.years[i].valoare) === -2145185
                    ? null
                    : Math.floor(result.years[i].valoare * 1000) / 1000;
                result.years[i].consumerSide =
                  Math.floor(result1.years[i].valoare) === -2145185
                    ? null
                    : Math.floor(result1.years[i].valoare * 1000) / 1000;
              }
            }

            setIsMediumConsLoaded(true);

            setMediumConsEvolution(result);
          },
          (error) => {
            setIsMediumConsLoaded(true);
            setMediumConsEvolution({});
            if (onGeneralError != undefined) onGeneralError(error);
          }
        );
      },
      (error) => {
        setIsMediumConsLoaded(true);
        setMediumConsEvolution({});
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    EMS_API.GetTreeNodeEquipments("", "", "", "").then(
      (result) => {
        setEquipmentsItems(result);
        if (result.length > 0) {
          setEquipment(result[0].tipEchipament);
        }
        setIsEquipmentsLoaded(true);
      },
      (error) => {
        setEquipmentsItems([]);
        setEquipment(0);
        setIsEquipmentsLoaded(true);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    COMMON_API.GetNetworkLength().then(
      (result) => {
        setNetworkLength(result);
      },
      (error) => {
        setNetworkLength("");
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    COMMON_API.GetSupplyPointsNo().then(
      (result) => {
        setSupplyPointsNo(result);
      },
      (error) => {
        setSupplyPointsNo(0);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );

    COMMON_API.GetUsageData().then(
      (result) => {
        if (result.length > 0) {
          const newdata = result.map((item) => {
            return {
              value: Math.floor(item.percent * 100) / 100,
              text: item.consumptionPointActivityTypeName,
              label: item.consumptionPointActivityTypeName,
              percent: Math.floor(item.percent * 100) / 100,
              color: item.color,
            };
          });
          setUsageData(newdata);
        } else setUsageData([]);
      },
      (error) => {
        setUsageData([]);
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [globalRefresh]);

  useEffect(() => {
    setIsPerformanceLoaded(false);

    const currentDate = new Date();
    const dataStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    );
    //let dataStop = new Date(
    //  currentDate.getFullYear(),
    //  currentDate.getMonth() + 1,
    //  currentDate.getDate() - 1
    //);

    DCS_API.GetAnalyseReadingRates(
      0,
      1,
      selectedEquipment,
      "",
      dayjs(dataStart).format("YYYYMMDD"),
      dayjs(currentDate).format("YYYYMMDD"),
      "daily",
      "0"
    ).then(
      (result) => {
        setIsPerformanceLoaded(true);
        const rratesxaxisdata = result.flatMap((rs) => {
          return dayjs(rs.day).toDate();
        });
        let perfAvg = 0;
        const rratesseriesdata = result.flatMap((rs) => {
          perfAvg = perfAvg + rs.readingRate;
          return rs.readingRate;
        });
        perfAvg = Math.ceil((perfAvg / rratesseriesdata.length) * 100) / 100;

        setPerformanceItems({
          xAxisData: rratesxaxisdata,
          seriesData: rratesseriesdata,
        });
        setPerformanceAvg(perfAvg);
      },
      (error) => {
        setIsPerformanceLoaded(true);
        setPerformanceItems({ xAxisData: [], seriesData: [] });
        if (onGeneralError != undefined) onGeneralError(error);
      }
    );
  }, [globalRefresh, selectedEquipment]);

  useEffect(() => {
    if (alarmStatusType != lastAlarmStatusType) {
      setLastAlarmStatusType(alarmStatusType);
      SYSTEM_STATUS_API.GetSystemAlarmInfo(alarmStatusType == "alarmdistribution" ? "0" : "1").then(
        (result) => {
          const temparr = result.flatMap((item) => {
            if (item.metersCount == 0) {
              return [];
            } else {
              return item;
            }
          });
          setAlarmInfoItems(temparr);
        },
        (error) => {
          setAlarmInfoItems([]);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    }

    if (alarmStatusType == "alarmdistribution") {
      setIsAlarmDistrLoaded(false);
      SYSTEM_STATUS_API.GetSystemAlarmStatus("", "", "", "", alarmInfoCode ?? "", "").then(
        (result) => {
          const aldistr = result.flatMap((alarm, index) => {
            if (alarm.percent === 0) {
              return [];
            } else {
              const yAxislabel =
                translate(alarm.name) +
                " (" +
                alarm.metersCount.toString() +
                ")" +
                (Math.floor(alarm.percent * 10000) / 100).toString() +
                "%";

              const yAxisValue = index + "_" + Math.floor(alarm.percent * 10000) / 100 + "%";
              return {
                id: index,
                alarmName: alarm.name,
                alarmCount: alarm.metersCount,
                percent: Math.floor(alarm.percent * 10000) / 100,
                alarmNameFormatted: yAxisValue,
                alarmNameLabelFormatted: yAxislabel,
                color: alarm.color,
                code: alarm.code,
              };
            }
          });
          setAlarmDistrItems(aldistr);
          setIsAlarmDistrLoaded(true);
        },
        (error) => {
          setIsAlarmDistrLoaded(true);
          setAlarmDistrItems([]);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    } else {
      setIsAlarmAgesLoaded(false);
      SYSTEM_STATUS_API.GetSystemAlarmsAges("", "", alarmInfoCode ?? "", "").then(
        (result) => {
          const alages = result.flatMap((alarm, index) => {
            if (alarm.percent === 0) {
              return [];
            } else {
              const yAxislabel =
                translate(alarm.alarmsAge) +
                " (" +
                alarm.metersCount.toString() +
                ")" +
                (Math.floor(alarm.percent * 10000) / 100).toString() +
                "%";

              const yAxisValue = index + "_" + Math.floor(alarm.percent * 10000) / 100 + "%";
              return {
                id: index,
                alarmName: alarm.alarmsAge,
                alarmCount: alarm.metersCount,
                percent: Math.floor(alarm.percent * 10000) / 100,
                alarmNameFormatted: yAxisValue,
                alarmNameLabelFormatted: yAxislabel,
                color: alarm.color,
                code: alarm.ageCode,
              };
            }
          });

          setAlarmAgesItems(alages);
          setIsAlarmAgesLoaded(true);
        },
        (error) => {
          setIsAlarmAgesLoaded(true);
          setAlarmAgesItems([]);
          if (onGeneralError != undefined) onGeneralError(error);
        }
      );
    }
  }, [alarmStatusType, alarmInfoCode]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "white",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                scrollButtons
              >
                <Tab
                  icon={(isMobile && <DevicesOutlinedIcon />) || ""}
                  label={isMobile ? "" : translate("Metering / Sensing Analysis")}
                  {...a11yProps(0)}
                  sx={{ fontSize: "1rem" }}
                />
                <Tab
                  icon={(isMobile && <NotificationsIcon />) || ""}
                  label={isMobile ? "" : translate("Alarms & Event Management")}
                  {...a11yProps(1)}
                  sx={{ fontSize: "1rem" }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={selectedTab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} columns={isMobile ? 12 : 13}>
                        <Grid item xs={12} md={13} lg={13}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              flexFlow: 1,
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingBottom: "10px",
                              flexDirection: isMobile ? "column" : "row",
                            }}
                          >
                            <Box
                              sx={{
                                flexFlow: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              {(!isReadingStatusLoaded || !isReadingsAgeLoaded) && (
                                <CircularProgress
                                  sx={{
                                    width: "1.2rem !important",
                                    height: "1.2rem !important",
                                    margin: "6px",
                                  }}
                                />
                              )}
                              <Typography
                                noWrap
                                component="span"
                                sx={{
                                  fontSize: "1.38rem",
                                  fontWeight: 700,
                                }}
                              >
                                {translate("Reading Status")}
                              </Typography>
                              <Tooltip title={translate("Description:")} arrow>
                                <InfoIcon
                                  sx={{
                                    margin: "0.31rem 0.9rem",
                                    color: "#0051C1",
                                  }}
                                />
                              </Tooltip>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                  margin: "0px 20px !important",
                                  display: isMobile ? "none" : "flex",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                flexFlow: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "1rem", paddingTop: "5px" }}
                                noWrap
                                component="span"
                              >
                                {metersNo.toString() + " " + translate("Meters")}
                              </Typography>
                            </Box>
                            <Stack
                              direction={isMobile ? "column" : "row"}
                              spacing={1}
                              sx={{
                                margin: isMobile ? "10px 0px" : "0px 10px",
                                alignItems: "flex-start",
                              }}
                            >
                              <Chip
                                sx={{
                                  padding: "4px 14px !important",
                                  borderRadius: "8px",
                                }}
                                icon={<WarningOutlinedIcon sx={{ color: "#E53935 !important" }} />}
                                label={criticalAl + " " + translate("Critical Alarms")}
                                variant="outlined"
                              />
                              <Chip
                                sx={{
                                  padding: "4px 14px !important",
                                  borderRadius: "8px",
                                  ml: isMobile ? "0px !important" : "8px",
                                  mt: isMobile ? "8px !important" : "auto",
                                }}
                                icon={<ErrorOutlinedIcon sx={{ color: "#FB8C00 !important" }} />}
                                label={warningsAl + " " + translate("Alarm Warning")}
                                variant="outlined"
                              />
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <StatusPieChart
                            translate={translate}
                            language={language}
                            chartConfig={{
                              dataset: readingStatusItems,
                              title: translate("Reading Status"),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={1}
                          lg={1}
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                            width: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "fnone",
                            },
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <StatusPieChart
                            translate={translate}
                            language={language}
                            chartConfig={{
                              dataset: readingsAgeItems,
                              title: translate("Reading Ages"),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} columns={isMobile ? 12 : 13}>
                        <Grid item xs={12} md={13} lg={13}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingBottom: "10px",
                            }}
                          >
                            {!isMediumConsLoaded && (
                              <CircularProgress
                                sx={{
                                  width: "1.2rem !important",
                                  height: "1.2rem !important",
                                  margin: "6px",
                                }}
                              />
                            )}
                            <Typography
                              noWrap
                              component="span"
                              sx={{
                                fontSize: "1.38rem",
                                fontWeight: 700,
                              }}
                            >
                              {translate("Energy Supplied & Consumed")}
                            </Typography>
                            <Tooltip title={translate("Description:")} arrow>
                              <InfoIcon
                                sx={{
                                  margin: "0.31rem 0.9rem",
                                  color: "#0051C1",
                                }}
                              />
                            </Tooltip>
                            {/*<Divider*/}
                            {/*  orientation="vertical"*/}
                            {/*  flexItem*/}
                            {/*  sx={{ margin: "0px 20px !important" }}*/}
                            {/*/>*/}
                            {/*<Stack direction="row" spacing={1} sx={{ margin: "0px 10px" }}>*/}
                            {/*  <Chip*/}
                            {/*    sx={{*/}
                            {/*      padding: "4px 14px !important",*/}
                            {/*      borderRadius: "8px",*/}
                            {/*    }}*/}
                            {/*    icon={<WarningOutlinedIcon sx={{ color: "gray !important" }} />}*/}
                            {/*    label={"0 " + translate("Critical Alarms")}*/}
                            {/*    variant="outlined"*/}
                            {/*  />*/}
                            {/*  <Chip*/}
                            {/*    sx={{*/}
                            {/*      padding: "4px 14px !important",*/}
                            {/*      borderRadius: "8px",*/}
                            {/*    }}*/}
                            {/*    icon={<ErrorOutlinedIcon sx={{ color: "gray !important" }} />}*/}
                            {/*    label={"0 " + translate("Alarm Warning")}*/}
                            {/*    variant="outlined"*/}
                            {/*  />*/}
                            {/*</Stack>*/}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <VerticalBarChart
                            translate={translate}
                            language={language}
                            chartConfig={{
                              dataset: mediumConsEvolution.days,
                              tableColumns: [
                                {
                                  field: "zi",
                                  headerName: "Date",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                  valueFormatter: (params) =>
                                    dateValueFormatter(params.value, null),
                                },
                                {
                                  field: "supplierSide",
                                  headerName: "Supplied",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                },
                                {
                                  field: "consumerSide",
                                  headerName: "Consumed",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                },
                              ],
                              xAxisField: "zi",
                              yAxisValueFormatter: yAxisValueFormatter,
                              xAxisValueFormatter: (params) => dateValueFormatter(params, 1),
                              series: [
                                {
                                  dataKey: "supplierSide",
                                  valueFormatter: (value) => valueFormatter1(value, munit),
                                  type: "bar",
                                  color: "#1976D2",
                                  label: "Supplied",
                                },
                                {
                                  dataKey: "consumerSide",
                                  valueFormatter: (value) => valueFormatter1(value, munit),
                                  type: "bar",
                                  color: "#BBDEFB",
                                  label: "Consumed",
                                },
                              ],
                              yAxisLabel: mname + " (" + munit + ")",
                            }}
                            viewByMode={1}
                          />
                        </Grid>
                        <Grid
                          item
                          md={1}
                          lg={1}
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                            width: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "fnone",
                            },
                            justifyContent: "center",
                          }}
                        >
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ margin: "0 2rem", flexGrow: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <VerticalBarChart
                            translate={translate}
                            language={language}
                            chartConfig={{
                              dataset: mediumConsEvolution.weeks,
                              tableColumns: [
                                {
                                  field: "primaZiSaptamana",
                                  headerName: "Date",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                  valueFormatter: (params) =>
                                    dateValueFormatter(params.value, null),
                                },
                                {
                                  field: "ultimaZiSaptamana",
                                  headerName: "Date",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                  valueFormatter: (params) =>
                                    dateValueFormatter(params.value, null),
                                },
                                {
                                  field: "supplierSide",
                                  headerName: "Supplied",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                },
                                {
                                  field: "consumerSide",
                                  headerName: "Consumed",
                                  flex: 0.3,
                                  minWidth: isMobile ? 0 : 150,
                                  editable: false,
                                },
                              ],
                              xAxisField: "nrSaptamana",
                              yAxisValueFormatter: yAxisValueFormatter,
                              xAxisValueFormatter: weekDateValueFormatter,
                              series: [
                                {
                                  dataKey: "supplierSide",
                                  valueFormatter: (value) => valueFormatter1(value, munit),
                                  type: "bar",
                                  color: "#1976D2",
                                  label: "Supplied",
                                },
                                {
                                  dataKey: "consumerSide",
                                  valueFormatter: (value) => valueFormatter1(value, munit),
                                  type: "bar",
                                  color: "#BBDEFB",
                                  label: "Consumed",
                                },
                              ],
                              yAxisLabel: mname + " (" + munit + ")",
                            }}
                            viewByMode={2}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <StatusPieChart
                            translate={translate}
                            language={language}
                            chartConfig={{
                              dataset: usageData,
                              title: translate("Usage"),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={1}
                          lg={1}
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                            width: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "fnone",
                            },
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              flexGrow: 1,
                              display: "flex",
                              width: "100%",
                              overflowX: "auto",
                            }}
                          >
                            <Stack direction={isMobile ? "column" : "row"} spacing={1}>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: isMobile ? "flex-start" : "center",
                                  ml: isMobile ? "0px" : "8px",
                                }}
                              >
                                <Chip
                                  icon={<Groups2OutlinedIcon sx={{ color: "white !important" }} />}
                                  label={supplyPointsNo}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#1976D2 !important",
                                    color: "white !important",
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "1rem", padding: "5px 10px" }}
                                  component="span"
                                >
                                  {translate("Supply Points")}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: isMobile ? "flex-start" : "center",
                                  ml: isMobile ? "0px" : "8px",
                                }}
                              >
                                <Chip
                                  icon={
                                    <StraightenOutlinedIcon sx={{ color: "white !important" }} />
                                  }
                                  label={networkLength}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#1976D2 !important",
                                    color: "white !important",
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "1rem", padding: "5px 10px" }}
                                  component="span"
                                >
                                  {translate("Km. of Network")}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: isMobile ? "flex-start" : "center",
                                  ml: isMobile ? "0px" : "8px",
                                }}
                              >
                                <Chip
                                  icon={
                                    <BloodtypeOutlinedIcon sx={{ color: "white !important" }} />
                                  }
                                  label={metersNo}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#1976D2 !important",
                                    color: "white !important",
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "1rem", padding: "5px 10px" }}
                                  component="span"
                                >
                                  {translate("Connections")}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} columns={isMobile ? 12 : 13}>
                        <Grid item xs={12} md={13} lg={13}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingBottom: "10px",
                              flexDirection: isMobile ? "column" : "row",
                            }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingBottom: "10px",
                              }}
                            >
                              {(!isPerformanceLoaded || !isEquipmentsLoaded) && (
                                <CircularProgress
                                  sx={{
                                    width: "1.2rem !important",
                                    height: "1.2rem !important",
                                    margin: "6px",
                                  }}
                                />
                              )}
                              <Typography
                                variant="h5"
                                noWrap
                                component="span"
                                sx={{
                                  fontSize: "1.38rem",
                                  flex: "0 1 auto",
                                  fontWeight: 700,
                                }}
                              >
                                {translate("Data Collect Performance")}
                              </Typography>
                              <Tooltip title={translate("Description:")} arrow>
                                <InfoIcon
                                  sx={{
                                    margin: "0.31rem 0.9rem",
                                    color: "#0051C1",
                                  }}
                                />
                              </Tooltip>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                  margin: "0px 20px !important",
                                  display: isMobile ? "none" : "flex",
                                }}
                              />
                            </Box>

                            <Box
                              sx={{
                                flexGrow: 1,
                              }}
                            ></Box>
                            {/*<Stack*/}
                            {/*  direction="row"*/}
                            {/*  spacing={1}*/}
                            {/*  sx={{ margin: "0px 10px", flex: "1" }}*/}
                            {/*>*/}
                            {/*  <Chip*/}
                            {/*    sx={{*/}
                            {/*      padding: "4px 14px !important",*/}
                            {/*      borderRadius: "8px",*/}
                            {/*    }}*/}
                            {/*    icon={<WarningOutlinedIcon sx={{ color: "gray !important" }} />}*/}
                            {/*    label={"0 " + translate("Critical Alarms")}*/}
                            {/*    variant="outlined"*/}
                            {/*  />*/}
                            {/*  <Chip*/}
                            {/*    sx={{*/}
                            {/*      padding: "4px 14px !important",*/}
                            {/*      borderRadius: "8px",*/}
                            {/*    }}*/}
                            {/*    icon={<ErrorOutlinedIcon sx={{ color: "gray !important" }} />}*/}
                            {/*    label={"0 " + translate("Alarm Warning")}*/}
                            {/*    variant="outlined"*/}
                            {/*  />*/}
                            {/*</Stack>*/}
                            <FormControl sx={{ m: "0px 1em", minWidth: isMobile ? "auto" : 200 }}>
                              <InputLabel id="select-equipment-label">
                                {translate("Equipment")}
                              </InputLabel>
                              <Select
                                labelId="select-equipment-label"
                                id="select-equipment"
                                value={selectedEquipment}
                                onChange={handleEquipmentChange}
                                autoWidth
                                size="small"
                                label={translate("Equipment")}
                              >
                                {equipmentsItems.map((b, index) => {
                                  return (
                                    <MenuItem key={index} value={b.tipEchipament}>
                                      {b.descriere}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <CustomAreaChart
                            language={language}
                            chartConfig={{
                              lineColor: "#1976D2",
                              xAxis:
                                performanceItems.xAxisData.length > 0
                                  ? [
                                      {
                                        id: "yAxis_id",
                                        scaleType: "point",
                                        data: performanceItems.xAxisData,
                                        min:
                                          performanceItems.xAxisData.length > 0
                                            ? performanceItems.xAxisData[0].getTime()
                                            : null,
                                        max:
                                          performanceItems.xAxisData.length > 0
                                            ? performanceItems.xAxisData[
                                                performanceItems.xAxisData.length - 1
                                              ].getTime()
                                            : null,
                                        valueFormatter: (params) => dateValueFormatter(params, 1),
                                        tickNumber: 6,
                                        tickLabelStyle: {
                                          angle: 45,
                                          textAnchor: "start",
                                        },
                                      },
                                    ]
                                  : [],
                              yAxis: [
                                {
                                  valueFormatter: (value) => value + " %",
                                  label: translate("Performance"),
                                },
                              ],
                              series:
                                performanceItems.seriesData.length > 0
                                  ? [
                                      {
                                        id: "EqipmentReadingRates",
                                        data: performanceItems.seriesData,
                                        area: true,
                                        showMark: false,
                                        valueFormatter: nullValueFormatter,
                                      },
                                    ]
                                  : [],
                              gradientColors: ["#0051C17A", "#0051C10F"],
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={1}
                          lg={1}
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                            width: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "fnone",
                            },
                            justifyContent: "center",
                          }}
                        >
                          <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: "flex" }}>
                          <Box sx={{ flexGrow: 1, display: "flex", width: "100%" }}>
                            <Stack
                              direction="column"
                              spacing={1}
                              sx={{
                                width: "100%",
                                justifyContent: "center",
                                padding: "0px 3rem",
                                margin: 0,
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Chip
                                  icon={
                                    <InventoryOutlinedIcon sx={{ color: "white !important" }} />
                                  }
                                  label={performanceAvg.toString() + " %"}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#1976D2 !important",
                                    color: "white !important",
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "1rem", padding: "5px 10px" }}
                                  component="span"
                                >
                                  {translate("Performance Average")}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Chip
                                  icon={<SpeedOutlinedIcon sx={{ color: "white !important" }} />}
                                  label={metersNo}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#1976D2 !important",
                                    color: "white !important",
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "1rem", padding: "5px 10px" }}
                                  component="span"
                                >
                                  {translate("Meters")}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Chip
                                  icon={<SensorsOutlinedIcon sx={{ color: "white !important" }} />}
                                  label="0"
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#1976D2 !important",
                                    color: "white !important",
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "1rem", padding: "5px 10px" }}
                                  component="span"
                                >
                                  {translate("AMI Meters")}
                                </Typography>
                              </Box>

                              <Typography
                                sx={{ fontSize: "1rem", fontWeight: "bold" }}
                                component="span"
                              >
                                {translate("Deployment")}
                              </Typography>
                              <BorderLinearProgress variant="determinate" value={0} />
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "1rem", width: "50%" }}
                                  component="span"
                                >
                                  {translate("AMI") + "(0%)"}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "1rem",
                                    width: "50%",
                                    textAlign: "right",
                                  }}
                                  component="span"
                                >
                                  {translate("No AMI") + "(100%)"}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                          flexDirection: isMobile ? "column" : "row",
                        }}
                      >
                        <Box
                          sx={{
                            flexFlow: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {(!isAlarmDistrLoaded || !isAlarmAgesLoaded) && (
                            <CircularProgress
                              sx={{
                                width: "1.2rem !important",
                                height: "1.2rem !important",
                                margin: "6px",
                              }}
                            />
                          )}
                          <Typography
                            noWrap
                            component="span"
                            sx={{
                              fontSize: "1.38rem",
                              fontWeight: 700,
                            }}
                          >
                            {translate("Alarms")}
                          </Typography>
                          <Tooltip title={translate("Description:")} arrow>
                            <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
                          </Tooltip>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              margin: "0px 20px !important",
                              display: isMobile ? "none" : "flex",
                            }}
                          />
                        </Box>

                        <ToggleButtonGroup
                          value={alarmInfoCode}
                          exclusive
                          onChange={handleInfoCode}
                          aria-label="text alarminfo"
                        >
                          {alarmInfoItems.map((chip, index) => {
                            return (
                              <ToggleButton
                                key={index}
                                value={chip.code}
                                aria-label={chip.name}
                                sx={{
                                  border: "none !important",
                                  padding: "0px !important",
                                  margin: "0px 5px !important",
                                  textTransform: "none",
                                  borderRadius: "8px !important",
                                }}
                              >
                                <Chip
                                  sx={{
                                    padding: "4px 14px !important",
                                    borderRadius: "8px",
                                    fontSize: "0.8125rem",
                                    fontWeight: "normal",
                                  }}
                                  icon={CustomAlarmIcon(chip.code)}
                                  label={chip.metersCount + " " + chip.name}
                                  variant="outlined"
                                />
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <ToggleButtonGroup
                          value={alarmStatusType}
                          exclusive
                          onChange={handleAlarmStatusType}
                          aria-label="text alarmStatusType"
                        >
                          <ToggleButton
                            value="alarmdistribution"
                            aria-label="left aligned"
                            sx={{
                              maxHeight: "40px",
                              backgroundColor:
                                alarmStatusType == "alarmdistribution"
                                  ? "#0051C1 !important"
                                  : "inherit",
                              color:
                                alarmStatusType == "alarmdistribution"
                                  ? "white !important"
                                  : "inherit",
                              borderRadius: "4rem 0 0 4rem !important",
                            }}
                          >
                            {alarmStatusType == "alarmdistribution" && <DoneOutlinedIcon />}
                            <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                              {translate("Alarm Distribution")}
                            </Typography>
                          </ToggleButton>
                          <ToggleButton
                            value="alarmages"
                            aria-label="right aligned"
                            sx={{
                              maxHeight: "40px",
                              backgroundColor:
                                alarmStatusType == "alarmages" ? "#0051C1 !important" : "inherit",
                              color:
                                alarmStatusType == "alarmages" ? "white !important" : "inherit",
                              borderRadius: "0 4rem 4rem 0 !important",
                            }}
                          >
                            <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                              {translate("Alarm Ages")}
                            </Typography>
                            {alarmStatusType == "alarmages" && <DoneOutlinedIcon />}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>

                      <HorizontalBarChart
                        translate={translate}
                        language={language}
                        chartConfig={{
                          dataset:
                            alarmStatusType == "alarmdistribution"
                              ? alarmDistrItems
                              : alarmAgesItems,
                          seriesValueFormatter: valueFormatter,
                          yAxisValueFormatter: yAxisValueFormatter1,
                          xAxisField: "percent",
                          yAxisField: "alarmNameLabelFormatted",
                          yAxisLabelField: "alarmNameLabelFormatted",
                          yAxisExtField: "alarmCount",
                          colors: ["#1976D2"],
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12}>
                  <AlarmSubscription
                    translate={translate}
                    globalRefresh={globalRefresh}
                    language={language}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
