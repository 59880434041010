import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MuiPagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridActionsCellItem,
  GridPagination,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";

import { AlarmsForSubscription } from "./alarmsforsubscription";
import { config } from "../../config/config";
import { useAppStore } from "../../store/store";

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;

const frequencyValues = {
  0: "instant email",
  1: "hourly",
  2: "on each 2 hours",
  3: "on each 3 hours",
  4: "on each 4 hours",
  6: "on each 6 hours",
  8: "on each 8 hours",
  12: "on each 12 hours",
  24: "daily",
};

function QuickSearchToolbar() {
  return (
    <Box sx={{ flex: "1 0 auto", display: "flex", justifyContent: "center" }}>
      <GridToolbarQuickFilter
        sx={{
          p: 0.5,
          pb: 0,
          border: "1px solid rgb(224, 224, 224)",
          borderRadius: "5px",
          padding: "4px",
        }}
      />
    </Box>
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      sx={{
        "& .MuiPaginationItem-root": {
          borderRadius: "8px!important",
          border: "1px solid #BDBDBD !important",
        },
      }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar(props) {
  const { addButtonText, setRows, setRowModesModel, rows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = () => {
    if (rows.find((i) => i.id === -1) === undefined) {
      const id = -1;
      setRows((oldRows) => [
        ...oldRows,
        {
          id: id,
          isNew: true,
          description: "",
          emailTo: "",
          emailCC: "",
          emailSubject: "",
          emailFrequency: "",
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "description" },
      }));
    }
  };

  return (
    <GridToolbarContainer {...props}>
      <Box
        sx={{
          flexGrow: 1,
          paddingBottom: "20px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "space-between",
        }}
      >
        <div style={{ flex: "0 0 auto", display: isMobile ? "none" : "flex" }}></div>
        <Button
          endIcon={<AddIcon />}
          onClick={handleClick}
          sx={{
            maxHeight: "40px",
            backgroundColor: "#0051C1",
            color: "white",
            minWidth: "200px",
            borderRadius: "7px",
          }}
        >
          {addButtonText}
        </Button>
        <QuickSearchToolbar />
        <GridToolbarFilterButton sx={{ color: "#212121", flex: "0 0 auto" }} />
      </Box>
    </GridToolbarContainer>
  );
}

export function AlarmSubscription(props) {
  const { translate } = props;
  const { appConfig } = useAppStore();
  const cookies = new Cookies();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [rows, setRows] = useState<any>([]);
  const [rowModesModel, setRowModesModel] = useState<any>({});
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedDescr, setSelectedDescr] = useState("");
  const [selectedEmailTo, setSelectedEmailTo] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [alarmValues, setAlarmValues] = useState([]);

  //const [gridColumns, setGridColumns] = useState([]);
  const [isRowsLoaded, setIsRowsLoaded] = useState(false);
  // const [apiError, setApiError] = useState(null);

  const [snackbar, setSnackbar] = useState<any>(null);
  const [openAlarms, setOpenAlarms] = useState(false);

  const handleCloseSnackbar = () => setSnackbar(null);

  const tid = cookies.get("loginTenant") ?? appConfig?.tid;
  const uid = cookies.get("loginUserId") ?? appConfig?.uid;
  const mid = cookies.get("mid") ?? appConfig?.mid;

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    console.log("[CVT]", "handleRowEditStop", params, event, rowModesModel);
  };

  const handleEditClick = (id) => () => {
    console.log("[CVT]", "handleEditClick");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    console.log("[CVT]", "handleSaveClick");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  //Confirmation when deleting dialog
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);
  const noButtonRef = useRef(null);
  const handleNo = () => {
    //const { id } = promiseArguments;
    //resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { id } = promiseArguments;

    try {
      // Make the HTTP request to delete in the backend

      fetch(apiUrl + "DeleteUserEmailSubscription?idSubscription=" + id, {
        mode: "cors",
        method: "PUT",
        headers: {
          tid: tid,
          uid: uid,
          mid: mid,
          Authorization: "bearer " + useAppStore.getState().authToken,
          RefreshToken: useAppStore.getState().refreshToken,
        },
      })
        .then(
          (res) => res.json(),
          (error) => {
            console.log("[CVT]", "[API] UpdateUserEmailSubscription ERR1", error);
            setSnackbar({
              children: translate("Subscription deletion failed"),
              severity: "error",
            });
            setPromiseArguments(null);
          }
        )
        .then(
          (result) => {
            console.log("[CVT]", "[API] UpdateUserEmailSubscription", result);
            if (result.id === null) {
              console.log("[CVT]", "[API] UpdateUserEmailSubscription ERR2.1", result.error);
              setSnackbar({
                children: translate("Subscription deletion failed"),
                severity: "error",
              });
              setPromiseArguments(null);
            }

            setRows(rows.filter((row) => row.id !== id));
            setSnackbar({
              children: translate("Subscription successfully deleted"),
              severity: "success",
            });
            setPromiseArguments(null);
          },
          (error) => {
            console.log("[CVT]", "[API] UpdateUserEmailSubscription ERR2.2", error);
            setSnackbar({
              children: translate("Subscription deletion failed"),
              severity: "error",
            });
            setPromiseArguments(null);
          }
        );

      //const response = await mutateRow(newRow);
      //setSnackbar({ children: 'User successfully saved', severity: 'success' });
      //resolve(response);
      //setPromiseArguments(null);
    } catch (error) {
      setSnackbar({
        children: translate("Subscription deletion failed"),
        severity: "error",
      });
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };
  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    //const { id } = promiseArguments;
    //const mutation = (id > 0) ? translate("Are you sure you want to delete this subscription?") : null;

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>{translate("Warning!")}</DialogTitle>
        <DialogContent dividers>
          {translate("Are you sure you want to delete this subscription?")}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            {translate("No")}
          </Button>
          <Button onClick={handleYes}>{translate("Yes")}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDeleteClick = (id) => () => {
    console.log("[CVT]", "handleDeleteClick");

    setPromiseArguments({ id });
  };

  const handleAlarmsClick = (id) => () => {
    if (id > 0) {
      const currentRow = rows.find((row) => row.id === id);
      setSelectedId(id);
      setSelectedDescr(currentRow.description);
      setSelectedEmailTo(currentRow.emailTo);
      setSelectedFrequency(currentRow.emailFrequency);
      setOpenAlarms(true);
    } else {
      setSelectedId(-1);
      setSelectedDescr("");
      setSelectedEmailTo("");
      setSelectedFrequency("");
      setOpenAlarms(false);
    }

    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleAlarmsClose = () => {
    setSelectedId(-1);
    setSelectedDescr("");
    setSelectedEmailTo("");
    setSelectedFrequency("");
    setOpenAlarms(false);
  };

  const handleCancelClick = (id) => () => {
    console.log("[CVT]", "handleCancelClick");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow, originalRow) => {
    const newId = newRow.id;
    let newFrequency = newRow.emailFrequency;
    if (frequencyValues[newFrequency] === undefined) {
      for (const [key, val] of Object.entries(frequencyValues)) {
        if (translate(val) === newFrequency) {
          newFrequency = key;
          break;
        }
      }
    }
    if (newRow.isNew || newRow.id === -1) {
      //Este inregistrare noua; adaug in baza de date
      console.log("[CVT]", "processRowUpdate", "INSERT IN DATABASE");
      return fetch(
        apiUrl +
          "AddUserEmailSubscription?description=" +
          newRow.description +
          "&emailTo=" +
          newRow.emailTo +
          "&emailCC=" +
          newRow.emailCC +
          "&emailSubject=" +
          newRow.emailSubject +
          "&emailFrequency=" +
          newFrequency,
        {
          mode: "cors",
          method: "POST",
          headers: {
            tid: tid,
            uid: uid,
            mid: mid,
            Authorization: "bearer " + useAppStore.getState().authToken,
            RefreshToken: useAppStore.getState().refreshToken,
          },
        }
      )
        .then(
          (res) => res.json(),
          (error) => {
            console.log("[CVT]", "[API] AddUserEmailSubscription ERR1", error);
            setSnackbar({
              children: translate("Subscription creation failed"),
              severity: "error",
            });
            return originalRow;
          }
        )
        .then(
          (result) => {
            console.log("[CVT]", "[API] AddUserEmailSubscription", result);
            if (result.id === null) {
              console.log("[CVT]", "[API] AddUserEmailSubscription ERR2.1", result.error);
              setSnackbar({
                children: "Subscription creation failed",
                severity: "error",
              });
              return originalRow;
            }
            const updatedRow = { ...newRow, id: newId, isNew: false };
            setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            setSnackbar({
              children: translate("Subscription successfully created"),
              severity: "success",
            });
            return updatedRow;
          },
          (error) => {
            console.log("[CVT]", "[API] AddUserEmailSubscription ERR2.2", error);
            setSnackbar({
              children: translate("Subscription creation failed"),
              severity: "error",
            });
            return originalRow;
          }
        );
    } else {
      //Exista deja, ii fac update in baza de date, daca s-a schimbat ceva
      const editedRow = rows.find((row) => row.id === newId);
      let editedRowFreq = editedRow.emailFrequency;
      if (frequencyValues[editedRowFreq] === undefined) {
        for (const [key, val] of Object.entries(frequencyValues)) {
          if (translate(val) === editedRowFreq) {
            editedRowFreq = key;
            break;
          }
        }
      }
      if (
        editedRow.description !== newRow.description ||
        editedRow.emailTo !== newRow.emailTo ||
        editedRow.emailCC !== newRow.emailCC ||
        editedRow.emailSubject !== newRow.emailSubject ||
        editedRowFreq !== newFrequency
      ) {
        //s-a modificat; fac update in baza de date
        console.log("[CVT]", "processRowUpdate", "UPDATE IN DATABASE");
        return fetch(
          apiUrl +
            "UpdateUserEmailSubscription?idSubscription=" +
            newId +
            "&description=" +
            newRow.description +
            "&emailTo=" +
            newRow.emailTo +
            "&emailCC=" +
            newRow.emailCC +
            "&emailSubject=" +
            newRow.emailSubject +
            "&emailFrequency=" +
            newFrequency,
          {
            mode: "cors",
            method: "PUT",
            headers: {
              tid: tid,
              uid: uid,
              mid: mid,
              Authorization: "bearer " + useAppStore.getState().authToken,
              RefreshToken: useAppStore.getState().refreshToken,
            },
          }
        )
          .then(
            (res) => res.json(),
            (error) => {
              console.log("[CVT]", "[API] UpdateUserEmailSubscription ERR1", error);
              setSnackbar({
                children: translate("Subscription change failed"),
                severity: "error",
              });
              return originalRow;
            }
          )
          .then(
            (result) => {
              console.log("[CVT]", "[API] UpdateUserEmailSubscription", result);
              if (result.id === null) {
                console.log("[CVT]", "[API] UpdateUserEmailSubscription ERR2.1", result.error);
                setSnackbar({
                  children: translate("Subscription change failed"),
                  severity: "error",
                });
                return originalRow;
              }
              const updatedRow = { ...newRow, id: newId, isNew: false };
              setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
              setSnackbar({
                children: translate("Subscription successfully changed"),
                severity: "success",
              });
              return updatedRow;
            },
            (error) => {
              console.log("[CVT]", "[API] UpdateUserEmailSubscription ERR2.2", error);
              setSnackbar({
                children: translate("Subscription change failed"),
                severity: "error",
              });
              return originalRow;
            }
          );
      } else {
        //nu s-a modificat
        console.log("[CVT]", "processRowUpdate", "DO NOTHING");
        return originalRow;
      }
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function SelectEditInputCell(props) {
    const { id, value, field, translate } = props;
    const apiRef = useGridApiContext();
    const [myValue, setMyValue] = useState(0);

    const handleChange = async (event) => {
      //console.log("[CVT]","SelectEditInputCell/handleChange", id,value,field, event)
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    useEffect(() => {
      if (frequencyValues[value] === undefined) {
        for (const [key, val] of Object.entries(frequencyValues)) {
          if (translate(val) === value) {
            setMyValue(parseInt(key));
          }
        }
      } else setMyValue(value);
    }, [value, translate]);
    return (
      <Select
        value={myValue}
        onChange={handleChange}
        size="small"
        sx={{ height: 1, flexGrow: 1 }}
        native
        autoFocus
      >
        <option value="0">{translate("instant email")}</option>
        <option value="1">{translate("hourly")}</option>
        <option value="2">{translate("on each 2 hours")}</option>
        <option value="3">{translate("on each 3 hours")}</option>
        <option value="4">{translate("on each 4 hours")}</option>
        <option value="6">{translate("on each 6 hours")}</option>
        <option value="8">{translate("on each 8 hours")}</option>
        <option value="12">{translate("on each 12 hours")}</option>
        <option value="24">{translate("daily")}</option>
      </Select>
    );
  }

  const renderSelectEditInputCell = (params, translate) => {
    return <SelectEditInputCell {...params} translate={translate} />;
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      //flex: 0.16,
      minWidth: isMobile ? 0 : 150,
      editable: true,
    },
    {
      field: "emailTo",
      headerName: "Email To",
      //flex: 0.16,
      minWidth: isMobile ? 0 : 150,
      editable: true,
    },
    {
      field: "emailCC",
      headerName: "Email CC",
      //flex: 0.16,
      minWidth: isMobile ? 0 : 150,
      editable: true,
    },
    {
      field: "emailSubject",
      headerName: "Email Subject",
      //flex: 0.16,
      minWidth: isMobile ? 0 : 150,
      editable: true,
    },
    {
      field: "emailFrequency",
      headerName: "Email Frequency",
      //flex: 0.16,
      editable: true,
      type: "singleSelect",
      valueParser: (value) => {
        console.log("[CVT]", "valueParser", value);
        if (frequencyValues[value] !== undefined) return translate(frequencyValues[value]);
        else return value;
      },
      renderEditCell: (params) => renderSelectEditInputCell(params, translate),
    },
    {
      field: "actions",
      type: "actions",
      headerName: translate("Actions"),
      width: isMobile ? 0 : 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode || id === -1) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label={translate("Save")}
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
              key={0}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label={translate("Cancel")}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              key={1}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<NotificationsActiveOutlinedIcon />}
            label={translate("Alarms")}
            className="textPrimary"
            onClick={handleAlarmsClick(id)}
            color="inherit"
            key={0}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label={translate("Edit")}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            key={1}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={translate("Delete")}
            onClick={handleDeleteClick(id)}
            color="inherit"
            key={2}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    //console.log("[CVT]", "useEffect 2");
    setIsRowsLoaded(false);

    fetch(apiUrl + "GetUserEmailSubscriptions", {
      mode: "cors",
      headers: {
        tid: tid,
        uid: uid,
        mid: mid,
        Authorization: "bearer " + useAppStore.getState().authToken,
        RefreshToken: useAppStore.getState().refreshToken,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsRowsLoaded(true);
          if (result !== undefined && result.length > 0) {
            const dstmp = result.map((item, index) => {
              if (item.id === undefined) item.id = index;
              item.emailFrequency =
                frequencyValues[item.emailFrequency] !== undefined
                  ? translate(frequencyValues[item.emailFrequency])
                  : item.emailFrequency;
              return item;
            });

            setRows(dstmp);
          }
        },
        (error) => {
          console.log("[CVT]", "GetUserEmailSubscriptions ERROR:", error);
          setIsRowsLoaded(true);
          setRows([]);
        }
      );
    fetch(apiUrl + "GetAlarmTypes", {
      mode: "cors",
      headers: {
        tid: tid,
        uid: uid,
        mid: mid,
        Authorization: "bearer " + useAppStore.getState().authToken,
        RefreshToken: useAppStore.getState().refreshToken,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result !== undefined && result.length > 0) {
            const dstmp = result.map((item, index) => {
              if (item.id === undefined) item.id = index;
              item.name = translate(item.name);
              return item;
            });

            setAlarmValues(dstmp);
          }
        },
        (error) => {
          console.log("[CVT]", "GetAlarmTypes ERROR:", error);
          setAlarmValues([]);
        }
      );
  }, [apiUrl, mid, tid, uid, translate]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          {!isRowsLoaded && (
            <CircularProgress
              sx={{
                width: "1.2rem !important",
                height: "1.2rem !important",
                margin: "6px",
              }}
            />
          )}
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
            }}
          >
            {translate("Subscriptions")}
          </Typography>
          <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1.5rem",
          }}
        >
          {renderConfirmDialog()}
          <DataGrid
            sx={{
              maxWidth: { xs: 250, sm: 570, md: "100%", lg: "100%", xl: "100%" },
              backgroundColor: "white",
              height: "500px",
              border: "none",
              "& .MuiTablePagination-spacer": { flex: "0" },
              "& .MuiTablePagination-displayedRows": { flex: "1 1 100%" },
              "& .MuiInputBase-root.MuiInput-root:before": {
                borderBottom: "none",
              },
              "& .MuiTablePagination-root": { width: "100%" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-selectedRowCount": {
                flex: "1 0 auto",
              },
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            slots={{
              toolbar: (props) =>
                CustomToolbar({
                  addButtonText: translate("Add Subscription"),
                  setRows,
                  setRowModesModel,
                  rows,
                  props,
                }),
              pagination: CustomPagination,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            localeText={{
              toolbarFilters: translate("Filter List"),
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} out of ${count}`,
              },
            }}
          />
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Box>
        <AlarmsForSubscription
          apiurl={apiUrl}
          tid={tid}
          uid={uid}
          mid={mid}
          translate={translate}
          selectedId={selectedId}
          selectedDescr={selectedDescr}
          selectedEmailTo={selectedEmailTo}
          selectedFrequency={selectedFrequency}
          openAlarms={openAlarms}
          handleAlarmsClose={handleAlarmsClose}
          alarmValues={alarmValues}
        />
      </CardContent>
    </Card>
  );
}
