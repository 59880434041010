import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import CircleIcon from "@mui/icons-material/Circle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SaveIcon from "@mui/icons-material/Save";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import { Link } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Camelize } from "../utils/utils";
import Cookies from "universal-cookie";
import { config } from "../../config/config";
import { useAppStore } from "../../store/store";
import { NestedMenuItem } from "mui-nested-menu";

import { BasicFilters } from "../common/BasicFilters";

export default function Meters(props) {
  const { translate, language, pushNotification } = props;

  const cookies = new Cookies();
  const { appConfig } = useAppStore();
  const [columnsMetadata, setColumnsMetadata] = useState<any>([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [gridRowsCount, setGridRowsCount] = useState();
  const [gridRows, setGridRows] = useState([]);
  const [isMetersLoaded, setIsMetersLoaded] = useState(false);
  const [quickSearch, setQuickSearch] = useState("");
  const [anchorActionsEl, setAnchorActionsEl] = useState(null);
  const [anchorFiltersEl, setAnchorFiltersEl] = useState(null);
  const openactionsmenu = Boolean(anchorActionsEl);

  const openfiltermenu = Boolean(anchorFiltersEl);

  const [metersFilters, setMetersFilters] = useState({});

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const apiurl = config.WS_API_URL;
  const apifilesurl = config.WS_APIFILES_URL;

  const tid = cookies.get("loginTenant") ?? appConfig?.tid;
  const uid = cookies.get("loginUserId") ?? appConfig?.uid;
  const mid = cookies.get("mid") ?? appConfig?.mid;

  const camelize = Camelize;

  const handleActionsMenuClick = (event) => {
    setAnchorActionsEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setAnchorActionsEl(null);
  };

  const handleFiltersMenuClick = (event) => {
    if (anchorFiltersEl === null) setAnchorFiltersEl(event.currentTarget);
    else setAnchorFiltersEl(null);
  };

  const handleFiltersMenuClose = () => {
    setAnchorFiltersEl(null);
  };

  const onFiltersChange = (value) => {
    const filters = {} as any;
    if (value !== undefined) {
      value.forEach((filter) => {
        switch (filter.type) {
          case 1:
            filters.installationStatusCodeArray = filter.value;
            break;
          case 2:
            filters.readingStatusCodes = filter.value.join(",");
            break;
          case 3:
            filters.alarmCodes = filter.value.join(",");
            break;
          case 4:
            filters.alarmCategoryCodes = filter.value.join(",");
            break;
          case 5:
            filters.region = filter.value.join(",");
            break;
          case 6:
            filters.city = filter.value.join(",");
            break;
          case 7:
            filters.street = filter.value.join(",");
            break;
          case 8:
            filters.zipCode = filter.value;
            break;
          case 9:
            filters.workOrderId = filter.value;
            break;
          case 10:
            filters.medium = filter.value.join(",");
            break;
          case 11:
            filters.deviceCodes = filter.value.join(",");
            break;
          case 12:
            filters.dnCodes = filter.value.join(",");
            break;
        }
      });
    }

    setMetersFilters(filters);
  };

  const handleActionExport = () => {
    setAnchorActionsEl(null);

    const colsmetadata = columnsMetadata.map((c) => c);

    fetch(apifilesurl + "ExportData", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        tid: tid,
        uid: uid,
        mid: mid,
      },
      body: JSON.stringify({
        expParameters: {
          quickSearch: quickSearch,
          readingStatusCodes: metersFilters["readingStatusCodes"] ?? null,
          onlyUnassigned: null,
          alarmCodes: metersFilters["alarmCodes"] ?? null,
          alarmCategoryCodes: metersFilters["alarmCategoryCodes"] ?? null,
          medium: metersFilters["medium"] ?? null,
          submediumCodes: null,
          deviceCodes: metersFilters["deviceCodes"] ?? null,
          region: metersFilters["region"] ?? null,
          city: metersFilters["city"] ?? null,
          street: metersFilters["street"] ?? null,
          cityZone: null,
          tariff: null,
          activityType: null,
          customerType: null,
          manufacturerCodes: null,
          deviceModelCodes: null,
          dnCodes: metersFilters["dnCodes"] ?? null,
          accuracyLossRiskIds: null,
          workOrderAssignment: null,
          nrPagina: 1,
          nrRanduriPagina: gridRowsCount,
          colOrder: "MeterName",
          colOrderAsc: "1",
          metersIds: null,
          groupsIds: null,
          dmaInletMetersIds: null,
          dmaOutletMainMetersIds: null,
          dmaOutletEndMetersIds: null,
          routeNames: null,
          routeSequences: null,
          idDisReplaceRecommType: null,
          illegalUsage: null,
          collectionAreaIds: null,
          billingGroupIds: null,
          customerCustomerTypeIds: null,
          customerCustActivityTypeIds: null,
          soldToIds: null,
          billToIds: null,
          shipToIds: null,
          specificTaskStatus: null,
          quickSearchConnectionID: "1",
          quickSearchMeterName: "1",
          quickSearchMeterSN: "1",
          quickSearchElectronicSN: "1",
          quickSearchConsumptionPointCode: "1",
          quickSearchCustomerAccountNumber: "1",
          quickSearchCustomerName: "1",
          quickSearchCustomerAddress: "1",
          quickSearchMatchWholeWord: "0",
          installationStatusCodeArray: metersFilters["installationStatusCodeArray"] ?? null,
          quickSearchMethod: "1",
          quickSearchConsumptionPointName: "1",
          quickSearchConsumptionPointAddress: "1",
          quickSearchConsumptionPointAccountNumber: "1",
          meterTags: null,
          meterAttributeId: null,
          meterAttributeValue: null,
          zipCode: metersFilters["zipCode"] ?? null,
          workOrderId: metersFilters["workOrderId"] ?? null,
          workOrderIdTaskStatus: null,
          workOrderIdIncludeReassignment: null,
          compoundPartCodeArray: null,
          idWorkCenterArray: null,
          installationYear: null,
          isVirtualMeter: null,
          idMeterArrayDelegate: null,
        },
        metadata: colsmetadata,
        fileType: ".csv",
        exportRequestType: 1,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (pushNotification !== undefined)
          pushNotification({
            type: 1,
            name: "Meters export task",
            exportGuid: result.message,
            exportDownloadFileName: "",
            exportTimer: "timer_" + result.message.replaceAll("-", ""),
            status: "task started",
          });
      });
  };

  useEffect(() => {
    const visiblecols = columnsMetadata.flatMap((col) => {
      if (!col.isVisible) return [];
      return {
        field: camelize(col.path),
        headerName: translate(col.header),
        width: col.width,
        editable: false,
        sortable: false,
      };
    });
    visiblecols.splice(0, 0, {
      field: "readingStatusColor",
      headerName: "",
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => <CircleIcon sx={{ fill: params.value.replace("#FF", "#") }} />,
      renderHeader: () => (
        <Tooltip title="Reading status" placement="top" arrow>
          <PanoramaFishEyeIcon />
        </Tooltip>
      ),
    });
    visiblecols.splice(1, 0, {
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          params.row.workOrderId != undefined &&
          params.row.workOrderId != null && <AssignmentIndIcon />
        );
      },
      renderHeader: () => <AssignmentIndIcon />,
    });
    setGridColumns(visiblecols);
  }, [language, columnsMetadata]);

  const [rowCountState, setRowCountState] = useState(gridRowsCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      gridRowsCount !== undefined ? gridRowsCount : prevRowCountState
    );
  }, [gridRowsCount, setRowCountState]);

  useEffect(() => {
    fetch(apiurl + "GetMetersV5", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        tid: tid,
        uid: uid,
        mid: mid,
      },
      body: JSON.stringify({
        quickSearch: quickSearch,
        readingStatusCodes: metersFilters["readingStatusCodes"] ?? null,
        onlyUnassigned: null,
        alarmCodes: metersFilters["alarmCodes"] ?? null,
        alarmCategoryCodes: metersFilters["alarmCategoryCodes"] ?? null,
        medium: metersFilters["medium"] ?? null,
        submediumCodes: null,
        deviceCodes: metersFilters["deviceCodes"] ?? null,
        region: metersFilters["region"] ?? null,
        city: metersFilters["city"] ?? null,
        street: metersFilters["street"] ?? null,
        cityZone: null,
        tariff: null,
        activityType: null,
        customerType: null,
        manufacturerCodes: null,
        deviceModelCodes: null,
        dnCodes: metersFilters["dnCodes"] ?? null,
        accuracyLossRiskIds: null,
        workOrderAssignment: null,
        nrPagina: paginationModel.page + 1,
        nrRanduriPagina: 100,
        colOrder: "MeterName",
        colOrderAsc: "1",
        metersIds: null,
        groupsIds: null,
        dmaInletMetersIds: null,
        dmaOutletMainMetersIds: null,
        dmaOutletEndMetersIds: null,
        routeNames: null,
        routeSequences: null,
        idDisReplaceRecommType: null,
        illegalUsage: null,
        collectionAreaIds: null,
        billingGroupIds: null,
        customerCustomerTypeIds: null,
        customerCustActivityTypeIds: null,
        soldToIds: null,
        billToIds: null,
        shipToIds: null,
        specificTaskStatus: null,
        quickSearchConnectionID: "1",
        quickSearchMeterName: "1",
        quickSearchMeterSN: "1",
        quickSearchElectronicSN: "1",
        quickSearchConsumptionPointCode: "1",
        quickSearchCustomerAccountNumber: "1",
        quickSearchCustomerName: "1",
        quickSearchCustomerAddress: "1",
        quickSearchMatchWholeWord: "0",
        installationStatusCodeArray: metersFilters["installationStatusCodeArray"] ?? null,
        quickSearchMethod: "1",
        quickSearchConsumptionPointName: "1",
        quickSearchConsumptionPointAddress: "1",
        quickSearchConsumptionPointAccountNumber: "1",
        meterTags: null,
        meterAttributeId: null,
        meterAttributeValue: null,
        zipCode: metersFilters["zipCode"] ?? null,
        workOrderId: metersFilters["workOrderId"] ?? null,
        workOrderIdTaskStatus: null,
        workOrderIdIncludeReassignment: null,
        compoundPartCodeArray: null,
        idWorkCenterArray: null,
        installationYear: null,
        isVirtualMeter: null,
        idMeterArrayDelegate: null,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        const tmprows = result.map((row) => {
          row.id = row.meterId;
          return row;
        });

        setGridRows(tmprows);
        setIsMetersLoaded(true);
      });
  }, [rowCountState, paginationModel]);

  useEffect(() => {
    fetch(apiurl + "GetMetersPagedCountV3", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        tid: tid,
        uid: uid,
        mid: mid,
      },
      body: JSON.stringify({
        quickSearch: quickSearch,
        readingStatusCodes: metersFilters["readingStatusCodes"] ?? null,
        onlyUnassigned: null,
        alarmCodes: metersFilters["alarmCodes"] ?? null,
        alarmCategoryCodes: metersFilters["alarmCategoryCodes"] ?? null,
        medium: metersFilters["medium"] ?? null,
        submediumCodes: null,
        deviceCodes: metersFilters["deviceCodes"] ?? null,
        region: metersFilters["region"] ?? null,
        city: metersFilters["city"] ?? null,
        street: metersFilters["street"] ?? null,
        cityZone: null,
        tariff: null,
        activityType: null,
        customerType: null,
        manufacturerCodes: null,
        deviceModelCodes: null,
        dnCodes: metersFilters["dnCodes"] ?? null,
        accuracyLossRiskIds: null,
        workOrderAssignment: null,
        metersIds: null,
        groupsIds: null,
        dmaInletMetersIds: null,
        dmaOutletMainMetersIds: null,
        dmaOutletEndMetersIds: null,
        routeNames: null,
        routeSequences: null,
        idDisReplaceRecommType: null,
        illegalUsage: null,
        collectionAreaIds: null,
        billingGroupIds: null,
        customerCustomerTypeIds: null,
        customerCustActivityTypeIds: null,
        soldToIds: null,
        billToIds: null,
        shipToIds: null,
        specificTaskStatus: null,
        quickSearchConnectionID: "1",
        quickSearchMeterName: "1",
        quickSearchMeterSN: "1",
        quickSearchElectronicSN: "1",
        quickSearchConsumptionPointCode: "1",
        quickSearchCustomerAccountNumber: "1",
        quickSearchCustomerName: "1",
        quickSearchCustomerAddress: "1",
        quickSearchMatchWholeWord: "0",
        installationStatusCodeArray: metersFilters["installationStatusCodeArray"] ?? null,
        quickSearchMethod: "1",
        quickSearchConsumptionPointName: "1",
        quickSearchConsumptionPointAddress: "1",
        quickSearchConsumptionPointAccountNumber: "1",
        meterTags: null,
        meterAttributeId: null,
        meterAttributeValue: null,
        zipCode: metersFilters["zipCode"] ?? null,
        workOrderId: metersFilters["workOrderId"] ?? null,
        workOrderIdTaskStatus: null,
        workOrderIdIncludeReassignment: null,
        compoundPartCodeArray: null,
        idWorkCenterArray: null,
        installationYear: null,
        isVirtualMeter: null,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setGridRowsCount(result);
      });
  }, [quickSearch, metersFilters]);

  useEffect(() => {
    fetch(apifilesurl + "GetMetadataV2?key=AdminMeters&customClientId=", {
      mode: "cors",
      headers: {
        tid: tid,
        uid: uid,
        mid: mid,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setColumnsMetadata(result);
      });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper component="form" sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={quickSearch}
              placeholder="quick search"
              inputProps={{ "aria-label": "quick search" }}
              onChange={(event) => {
                setQuickSearch(event.target.value);
              }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="quick search">
              <SearchIcon />
            </IconButton>
            <Tooltip title={translate("Filters")} arrow>
              <IconButton
                sx={{
                  color:
                    Object.keys(metersFilters).length !== 0
                      ? "red"
                      : openfiltermenu
                        ? "#0051C1"
                        : "black",
                }}
                aria-haspopup="true"
                aria-label="filters-menu"
                onClick={handleFiltersMenuClick}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Paper>
          {openfiltermenu && (
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "flex-start", flexGrow: 1, marginTop: "3px" }}
            >
              <BasicFilters onFiltersChange={onFiltersChange} translate={translate} />
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <CardHeader
                avatar={
                  !isMetersLoaded && (
                    <CircularProgress
                      sx={{
                        width: "1.2rem !important",
                        height: "1.2rem !important",
                        margin: "6px",
                      }}
                    />
                  )
                }
                action={
                  <IconButton aria-label="actions">
                    <MoreVertIcon
                      onClick={handleActionsMenuClick}
                      aria-controls={openactionsmenu ? "actions-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openactionsmenu ? "true" : undefined}
                    />
                  </IconButton>
                }
                title={translate("Meters") + ": " + gridRowsCount}
                sx={{
                  "& .MuiCardHeader-title": {
                    fontSize: "1rem",
                    fontWeight: 700,
                  },
                }}
              />
              <Box sx={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={gridRows}
                  columns={gridColumns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 100,
                      },
                    },
                  }}
                  rowCount={rowCountState}
                  pageSizeOptions={[100]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={setPaginationModel}
                />
              </Box>
            </CardContent>
            <Menu
              anchorEl={anchorActionsEl}
              id="actions-menu"
              open={openactionsmenu}
              onClose={handleActionsMenuClose}
              onClick={handleActionsMenuClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem key="1" onClick={handleActionExport}>
                <FileDownloadIcon /> Export (.csv)
              </MenuItem>
              <Divider />
              <MenuItem key="2" onClick={handleActionsMenuClose}>
                <AssignmentIndIcon /> Assign Work Order
              </MenuItem>
              <MenuItem key="3" onClick={handleActionsMenuClose}>
                <AssignmentIndIcon /> Assign to Reading Plan
              </MenuItem>
              <Divider />
              <MenuItem key="4" onClick={handleActionsMenuClose}>
                Record Customer Feedback
              </MenuItem>
              <MenuItem key="5" onClick={handleActionsMenuClose}>
                Record Non-Customer Feedback
              </MenuItem>
              <Divider />
              <MenuItem key="6" onClick={handleActionsMenuClose}>
                Set Meter Installation Status
              </MenuItem>
              <Divider />
              <NestedMenuItem
                parentMenuOpen={true}
                key="7"
                label="Export to RMS"
                onClick={handleActionsMenuClose}
              >
                <SaveIcon /> Export to RMS
                <MenuItem key="71" onClick={handleActionsMenuClose}>
                  <SaveIcon /> By cities
                </MenuItem>
                <MenuItem key="72" onClick={handleActionsMenuClose}>
                  <SaveIcon /> By city zones
                </MenuItem>
                <MenuItem key="73" onClick={handleActionsMenuClose}>
                  <SaveIcon /> By streets
                </MenuItem>
              </NestedMenuItem>
              <MenuItem key="8" onClick={handleActionsMenuClose}>
                <SaveIcon /> Export to TMT Scheduler
              </MenuItem>
              <Divider />
              <MenuItem key="9" onClick={handleActionsMenuClose}>
                <ShortcutIcon /> Go to Analyse
              </MenuItem>
              <MenuItem key="10" onClick={handleActionsMenuClose}>
                <ShortcutIcon /> Go to Comparison
              </MenuItem>
            </Menu>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
