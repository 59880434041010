import { Autocomplete } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useContext } from "react";
import { BuildingsContext } from "./TestPage";

export const BuildingFilters = () => {
  const { buildings, filters } = useContext<any>(BuildingsContext);

  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Autocomplete
        sx={{ flex: "1", mr: "64px" }}
        autoComplete
        size="small"
        options={buildings}
        loading={buildings.length === 0}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params) => <TextField {...params} label="Search" />}
      />
      <Stack spacing={"16px"} direction="row" sx={{ flex: "2" }}>
        <Autocomplete
          sx={{ flex: "1" }}
          autoComplete
          size="small"
          options={filters.regions}
          getOptionLabel={(option: any) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Region"
              SelectProps={{
                multiple: true,
              }}
            />
          )}
        />
        <Autocomplete
          sx={{ flex: "1" }}
          autoComplete
          size="small"
          options={filters.cities}
          getOptionLabel={(option: any) => option.name}
          renderInput={(params) => <TextField {...params} label="City" />}
        />
        <Autocomplete
          sx={{ flex: "1" }}
          autoComplete
          size="small"
          options={filters.cityZones}
          getOptionLabel={(option: any) => option.name}
          renderInput={(params) => <TextField {...params} label="City Zone" />}
        />
      </Stack>
    </Stack>
  );
};
