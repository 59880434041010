import { create } from "zustand";
import { persist } from "zustand/middleware";

// const widgets = [
//   "emgbuildings",
//   "emgmap",
//   "emgbuildingmeters",
//   "emgconsumption",
//   "emgefficiency",
//   "emgsynoptic",
//   "emgcounters",
//   "emgchecksheets",
//   "emgcheckreports",
// ];

const enabledWidgets = [
  // "emgbuildings",
  // "emgmap",
  // "emgbuildingmeters",
  "emgconsumption",
  // "emgefficiency",
  // "emgsynoptic",
  // "emgcounters",
  "emgchecksheets",
  "emgcheckreports",
];

const appSettingsSlice = (set, get) => ({
  settingsOpen: false,
  toggleSettingsOpen: () => {
    set(() => ({ settingsOpen: !get().settingsOpen }));
  },
  oldschoolNavigation: true,
  toggleOldschoolNavigation: () => {
    set(() => ({ oldschoolNavigation: !get().oldschoolNavigation }));
  },
  sidebarPosition: "left",
  setSidebarPosition: (value) => {
    set(() => ({ sidebarPosition: value }));
  },
});

const appGeneralSlice = (set, get) => ({
  authToken: null,
  setAuthToken: (value) => {
    set(() => ({ authToken: value }));
  },
  refreshToken: null,
  setRefreshToken: (value) => {
    set(() => ({ refreshToken: value }));
  },

  appConfig: null,
  setAppConfig: (value) => {
    set(() => ({ appConfig: value }));
  },

  sidebarOpen: true,
  setSidebarOpen: (value) => {
    set(() => ({ sidebarOpen: value }));
  },
  toggleSidebarOpen: () => {
    set(() => ({ sidebarOpen: !get().sidebarOpen }));
  },
  sidebarSelectedItem: {},
  setSidebarSelectedItem: (object) => {
    set(() => ({ sidebarSelectedItem: object }));
  },
  sidebarExpandedItems: [],
  setSidebarExpandedItems: (value) => {
    set(() => ({ sidebarExpandedItems: value }));
  },
  breadcrumbs: [],
  setBreadcrumbs: (value) => {
    set(() => ({ breadcrumbs: value }));
  },

  energyManagementViews: enabledWidgets,
  setEnergyManagementViews: (value) => {
    set(() => ({ energyManagementViews: value }));
  },

  buildingSelectorOpen: false,
  setBuildingSelectorOpen: (value) => {
    set(() => ({ buildingSelectorOpen: value }));
  },
});

// persistent hook for useAppStore
export const useAppStore = create(
  persist(
    (set, get) => ({
      ...appGeneralSlice(set, get),
      ...appSettingsSlice(set, get),
    }),
    {
      name: "app-storage",
    }
  )
) as any;
