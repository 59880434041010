import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import BloodtypeOutlinedIcon from "@mui/icons-material/BloodtypeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { config } from "../../config/config";
import { CustomAreaChart } from "../common/CustomAreaChart";
import { CustomGrid } from "../common/customgrid";
import { HorizontalBarChart } from "../common/horizontalbarchart";
import { StatusPieChart } from "../common/StatusPieChart";
import { VerticalBarChart } from "../common/verticalbarchart";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: "24px 0px" }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 32,
  borderRadius: "2rem",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "2rem",
    backgroundColor: theme.palette.mode === "light" ? "#66BB6A" : "#308fe8",
  },
}));

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "Description",
    headerName: "Description",
    flex: 0.3,
    minWidth: 150,
    editable: true,
  },
  {
    field: "EmailTo",
    headerName: "Email To",
    flex: 0.3,
    minWidth: 150,
    editable: true,
  },
  {
    field: "EmailFrom",
    headerName: "Email From",
    flex: 0.3,
    minWidth: 150,
    editable: true,
  },
];

const rows = [
  {
    id: 1,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 2,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 3,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 4,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 5,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 6,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 7,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 8,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
  {
    id: 9,
    Description: "Low Power Supply Alarm",
    EmailTo: "john.doe@itron.com",
    EmailFrom: "marketing@itron.com",
  },
];

const months = [
  new Date(2023, 3, 5),
  new Date(2023, 3, 6),
  new Date(2023, 3, 7),
  new Date(2023, 3, 8),
  new Date(2023, 3, 9),
];

const FranceGDPperCapita = [26.3, 62.1, 49.02, 38.2, 80.2];

const dataset1 = [
  {
    london: 7100,
    month: "Mon",
    color: "#1976D2",
  },
  {
    london: 6024,
    month: "Tue",
    color: "#1976D2",
  },
  {
    london: 5800,
    month: "Wed",
    color: "#1976D2",
  },
  {
    london: 6200,
    month: "Thu",
    color: "#1976D2",
  },
  {
    london: 4250,
    month: "Fri",
    color: "#1976D2",
  },
  {
    london: null,
    month: "Sat",
    color: "#1976D2",
  },
  {
    london: -3780,
    month: "Sun",
    color: "#1976D2",
  },
];

const dataset1columns = [
  {
    field: "month",
    headerName: "Month",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
  {
    field: "london",
    headerName: "Consumption",
    flex: 0.3,
    minWidth: 150,
    editable: false,
  },
];

const valueFormatter = (value) => `${value}%`;

const yAxisValueFormatter1 = (value) => value.toString().split("_")[1];

const valueFormatter1 = (value) => `${value} m2`;

function yAxisValueFormatter(value) {
  const xval = Math.abs(value / 1000);
  return `${xval}K`;
}

export default function OperationalVisibility(props) {
  const { translate, language } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const [alignment, setAlignment] = useState("alarmdistribution");
  const [alarmDistrItems, setAlarmDistrItems] = useState([]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const apiurl = config.WS_API_URL;

  useEffect(() => {
    fetch(apiurl + "GetSystemAlarmStatus", { mode: "cors" })
      .then((res) => res.json())
      .then((result) => {
        const aldistr = result.map((alarm, index) => {
          const yAxislabel =
            alarm.name +
            " (" +
            alarm.metersCount +
            ") " +
            Math.floor(alarm.percent * 10000) / 100 +
            "%";
          const yAxisValue = index + "_" + Math.floor(alarm.percent * 10000) / 100 + "%";
          return {
            id: index,
            alarmName: alarm.name,
            alarmCount: alarm.metersCount,
            percent: Math.floor(alarm.percent * 10000) / 100,
            alarmNameFormatted: yAxisValue,
            alarmNameLabelFormatted: yAxislabel,
            color: alarm.color,
          };
        });
        setAlarmDistrItems(aldistr);
      });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography
            noWrap
            component="div"
            sx={{
              fontWeight: 700,
              fontSize: "1.75rem",
            }}
          >
            {translate("Water Outcomes") + " / " + translate("Operational Visibility")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "white",
              }}
            >
              <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab
                  label={translate("Metering / Sensing Analysis")}
                  {...a11yProps(0)}
                  sx={{ fontSize: "14px" }}
                />
                <Tab
                  label={translate("Alarms & Event Management")}
                  {...a11yProps(1)}
                  sx={{ fontSize: "14px" }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={selectedTab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          noWrap
                          component="span"
                          sx={{
                            fontSize: "1.38rem",
                            fontWeight: 700,
                          }}
                        >
                          {translate("Section Title")}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "0px 40px !important" }}
                        />
                        <Typography
                          sx={{ fontSize: "1rem", paddingTop: "5px" }}
                          noWrap
                          component="span"
                        >
                          {"22 " + translate("Meters")}
                        </Typography>
                        <Stack direction="row" spacing={1} sx={{ margin: "0px 10px" }}>
                          <Chip
                            icon={<WarningOutlinedIcon sx={{ color: "#E53935 !important" }} />}
                            label={"8 " + translate("Critical Alarms")}
                            variant="outlined"
                          />
                          <Chip
                            icon={<ErrorOutlinedIcon sx={{ color: "#FB8C00 !important" }} />}
                            label={"2 " + translate("Alarm Warning")}
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                      <Stack direction="row" spacing={1}>
                        <StatusPieChart
                          translate={translate}
                          language={language}
                          chartConfig={{
                            dataset: [
                              {
                                value: 9.09,
                                color: "#FB8C00",
                                text: "Alarms (2)",
                                percent: "9.09%",
                              },
                              {
                                value: 4.55,
                                color: "#64B5F6",
                                text: "No Alarms (1)",
                                percent: "4.55%",
                              },
                              {
                                value: 86.36,
                                color: "#E0E0E0",
                                text: "Blank (19)",
                                percent: "86.36%",
                              },
                            ],
                            title: translate("Reading Status"),
                          }}
                        />
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "3rem 0px !important" }}
                        />
                        <StatusPieChart
                          translate={translate}
                          language={language}
                          chartConfig={{
                            dataset: [
                              {
                                value: 9.09,
                                color: "#43A047",
                                text: "Yesterday (2)",
                                percent: "9.09%",
                              },
                              {
                                value: 4.55,
                                color: "#90CAF9",
                                text: "Last 7 days (1)",
                                percent: "4.55%",
                              },
                              {
                                value: 86.36,
                                color: "#E0E0E0",
                                text: "Never (19)",
                                percent: "86.36%",
                              },
                            ],
                            title: translate("Reading Ages"),
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          noWrap
                          component="span"
                          sx={{
                            fontSize: "1.38rem",
                            fontWeight: 700,
                          }}
                        >
                          {translate("Water Produced & Consumed")}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "0px 40px !important" }}
                        />
                        <Stack direction="row" spacing={1} sx={{ margin: "0px 10px" }}>
                          <Chip
                            icon={<WarningOutlinedIcon sx={{ color: "gray !important" }} />}
                            label={"0 " + translate("Critical Alarms")}
                            variant="outlined"
                          />
                          <Chip
                            icon={<ErrorOutlinedIcon sx={{ color: "gray !important" }} />}
                            label={"0 " + translate("Alarm Warning")}
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                      <Stack direction="row" spacing={1}>
                        <VerticalBarChart
                          translate={translate}
                          language={language}
                          chartConfig={{
                            dataset: dataset1,
                            tableColumns: dataset1columns,
                            xAxisField: "month",
                            yAxisValueFormatter: yAxisValueFormatter,
                            seriesValueFormatter: valueFormatter1,
                            seriesDataKey: "london",
                            colors: ["#1976D2"],
                          }}
                          viewByMode={1}
                        />
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "3rem 0px !important" }}
                        />
                        <VerticalBarChart
                          translate={translate}
                          language={language}
                          chartConfig={{
                            dataset: dataset1,
                            tableColumns: dataset1columns,
                            xAxisField: "month",
                            yAxisValueFormatter: yAxisValueFormatter,
                            seriesValueFormatter: valueFormatter1,
                            seriesDataKey: "london",
                            colors: ["#1976D2"],
                          }}
                          viewByMode={2}
                        />
                      </Stack>
                      <Stack direction="row" spacing={1} sx={{ backgroundColor: "#FAFAFA" }}>
                        <Stack direction="row" spacing={1} sx={{ width: "50%" }}>
                          <StatusPieChart
                            translate={translate}
                            language={language}
                            chartConfig={{
                              dataset: [
                                {
                                  value: 9.09,
                                  color: "#BBDEFB",
                                  text: "Domestic (2)",
                                  percent: "9.09%",
                                },
                                {
                                  value: 4.55,
                                  color: "#64B5F6",
                                  text: "Industrial (1)",
                                  percent: "4.55%",
                                },
                                {
                                  value: 86.36,
                                  color: "#2196F3",
                                  text: "Public Service (19)",
                                  percent: "86.36%",
                                },
                                {
                                  value: 86.36,
                                  color: "#1976D2",
                                  text: "Registered (19)",
                                  percent: "86.36%",
                                },
                                {
                                  value: 86.36,
                                  color: "#0D47A1",
                                  text: "Agriculture (19)",
                                  percent: "86.36%",
                                },
                              ],
                              title: translate("Usage"),
                            }}
                          />
                        </Stack>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "3rem 0px !important" }}
                        />
                        <Stack direction="row" spacing={1} sx={{ width: "50%" }}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              icon={<Groups2OutlinedIcon sx={{ color: "white !important" }} />}
                              label="15.545"
                              variant="filled"
                              sx={{
                                backgroundColor: "#1976D2 !important",
                                color: "white !important",
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", padding: "5px 10px" }}
                              component="span"
                            >
                              {translate("Supply Points")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              icon={<StraightenOutlinedIcon sx={{ color: "white !important" }} />}
                              label="23"
                              variant="filled"
                              sx={{
                                backgroundColor: "#1976D2 !important",
                                color: "white !important",
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", padding: "5px 10px" }}
                              component="span"
                            >
                              {translate("Km. of Network")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              icon={<BloodtypeOutlinedIcon sx={{ color: "white !important" }} />}
                              label="3213"
                              variant="filled"
                              sx={{
                                backgroundColor: "#1976D2 !important",
                                color: "white !important",
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", padding: "5px 10px" }}
                              component="span"
                            >
                              {translate("Connections")}
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          noWrap
                          component="span"
                          sx={{
                            fontSize: "1.38rem",
                            fontWeight: 700,
                          }}
                        >
                          {translate("Performance")}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "0px 40px !important" }}
                        />
                        <Stack direction="row" spacing={1} sx={{ margin: "0px 10px" }}>
                          <Chip
                            icon={<WarningOutlinedIcon sx={{ color: "gray !important" }} />}
                            label={"0 " + translate("Critical Alarms")}
                            variant="outlined"
                          />
                          <Chip
                            icon={<ErrorOutlinedIcon sx={{ color: "gray !important" }} />}
                            label={"0 " + translate("Alarm Warning")}
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                      <Stack direction="row" spacing={1}>
                        <CustomAreaChart
                          language={language}
                          chartConfig={{
                            lineColor: "#1976D2",
                            xAxis: [
                              {
                                id: "months",
                                data: months,
                                scaleType: "time",
                                valueFormatter: (date) => date.getDate() + " Mar",
                              },
                            ],
                            yAxis: [
                              {
                                valueFormatter: (value) => value + " %",
                                label: "Performance",
                              },
                            ],
                            series: [
                              {
                                id: "Performance",
                                data: FranceGDPperCapita,
                                stack: "total",
                                area: true,
                                showMark: false,
                              },
                            ],
                            gradientColors: ["#0051C17A", "#0051C10F"],
                          }}
                        />

                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "3rem 0px !important" }}
                        />
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{
                            width: "50%",
                            justifyContent: "center",
                            padding: "0px 3rem",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              icon={<InventoryOutlinedIcon sx={{ color: "white !important" }} />}
                              label="87%"
                              variant="filled"
                              sx={{
                                backgroundColor: "#1976D2 !important",
                                color: "white !important",
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", padding: "5px 10px" }}
                              component="span"
                            >
                              {translate("Performance Average")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              icon={<SpeedOutlinedIcon sx={{ color: "white !important" }} />}
                              label="90.155"
                              variant="filled"
                              sx={{
                                backgroundColor: "#1976D2 !important",
                                color: "white !important",
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", padding: "5px 10px" }}
                              component="span"
                            >
                              {translate("Meters")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              icon={<SensorsOutlinedIcon sx={{ color: "white !important" }} />}
                              label="86.825"
                              variant="filled"
                              sx={{
                                backgroundColor: "#1976D2 !important",
                                color: "white !important",
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", padding: "5px 10px" }}
                              component="span"
                            >
                              {translate("AMI Meters")}
                            </Typography>
                          </Box>

                          <Typography
                            sx={{ fontSize: "1rem", fontWeight: "bold" }}
                            component="span"
                          >
                            {translate("Deployment")}
                          </Typography>
                          <BorderLinearProgress variant="determinate" value={50} />
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "1rem", width: "50%" }} component="span">
                              {translate("AMI") + "(58%)"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                width: "50%",
                                textAlign: "right",
                              }}
                              component="span"
                            >
                              {translate("No AMI") + "(42%)"}
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          noWrap
                          component="span"
                          sx={{
                            fontSize: "1.38rem",
                            fontWeight: 700,
                          }}
                        >
                          {translate("Alarms")}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: "0px 40px !important" }}
                        />
                        <Stack direction="row" spacing={1} sx={{ margin: "0px 10px" }}>
                          <Chip
                            icon={<WarningOutlinedIcon sx={{ color: "#E53935 !important" }} />}
                            label="8 Critical Alarms"
                            variant="outlined"
                          />
                          <Chip
                            icon={<ErrorOutlinedIcon sx={{ color: "#FB8C00 !important" }} />}
                            label="2 Alarm Warning"
                            variant="outlined"
                          />
                          <Chip
                            icon={<ErrorOutlinedIcon sx={{ color: "#1E88E5 !important" }} />}
                            label="2 Alarm Info"
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          aria-label="text alignment"
                        >
                          <ToggleButton
                            value="alarmdistribution"
                            aria-label="left aligned"
                            sx={{
                              maxHeight: "40px",
                              backgroundColor:
                                alignment == "alarmdistribution" ? "#0051C1 !important" : "inherit",
                              color:
                                alignment == "alarmdistribution" ? "white !important" : "inherit",
                              borderRadius: "4rem 0 0 4rem !important",
                            }}
                          >
                            {alignment == "alarmdistribution" && <DoneOutlinedIcon />}
                            <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                              {translate("Alarm Distribution")}
                            </Typography>
                          </ToggleButton>
                          <ToggleButton
                            value="alarmages"
                            aria-label="right aligned"
                            sx={{
                              maxHeight: "40px",
                              backgroundColor:
                                alignment == "alarmages" ? "#0051C1 !important" : "inherit",
                              color: alignment == "alarmages" ? "white !important" : "inherit",
                              borderRadius: "0 4rem 4rem 0 !important",
                            }}
                          >
                            <Typography noWrap component="span" sx={{ fontSize: "14px" }}>
                              {translate("Alarm Ages")}
                            </Typography>
                            {alignment == "alarmages" && <DoneOutlinedIcon />}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>

                      <HorizontalBarChart
                        translate={translate}
                        language={language}
                        chartConfig={{
                          dataset: alarmDistrItems,
                          seriesValueFormatter: valueFormatter,
                          yAxisValueFormatter: yAxisValueFormatter1,
                          xAxisField: "percent",
                          yAxisField: "alarmNameLabelFormatted",
                          yAxisLabelField: "alarmNameLabelFormatted",
                          yAxisExtField: "alarmCount",
                          colors: ["#1976D2"],
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          noWrap
                          component="span"
                          sx={{
                            fontSize: "1.38rem",
                            fontWeight: 700,
                          }}
                        >
                          {translate("Subscriptions")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          padding: "1.5rem",
                        }}
                      >
                        <CustomGrid
                          translate={translate}
                          dataSet={rows}
                          columns={columns}
                          allowAdd={true}
                          filterable={true}
                          addButtonText={translate("Add Subscription")}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
