import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import ConstructionIcon from "@mui/icons-material/Construction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DeviceThermostatOutlinedIcon from "@mui/icons-material/DeviceThermostatOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";

//import page_home from "../../assets/icons/page_home.png";
//import page_analyse from "../../assets/icons/page_device.png";
//import page_compare from "../../assets/icons/page_chart.png";
//import page_alarm from "../../assets/icons/page_alarm.png";
//import page_meters from "../../assets/icons/page_analyse.png";
//import page_diagrams from "../../assets/icons/page_grid.png";
//import page_workorders from "../../assets/icons/page_work_order.png";
//import page_map from "../../assets/icons/page_map.png";
//import page_custom_reports from "../../assets/icons/page_custom_reports.png";
//import page_balance from "../../assets/icons/page_balance.png";
//import page_energy_management from "../../assets/icons/page_energy_management.png";
//import page_meterspark from "../../assets/icons/page_meterspark.png";
//import page_datacollect from "../../assets/icons/page_datacollect.png";
//import page_userdashboards from "../../assets/icons/page_userdashboards.png";
//import page_scheduled_reports from "../../assets/icons/page_scheduled_reports.png";

function MenuIconConverter(props) {
  const { name, tooltipText } = props;

  switch (name) {
    case "page_home":
      if (tooltipText === undefined || tooltipText === "")
        return <HomeIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <HomeIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_analyse":
      if (tooltipText === undefined || tooltipText === "")
        return <DevicesOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <DevicesOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_compare":
      if (tooltipText === undefined || tooltipText === "")
        return <StackedBarChartIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <StackedBarChartIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_alarm":
      if (tooltipText === undefined || tooltipText === "")
        return <NotificationsIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <NotificationsIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_meters":
      if (tooltipText === undefined || tooltipText === "")
        return (
          <MonitorHeartOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
        );
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <MonitorHeartOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_diagrams":
      if (tooltipText === undefined || tooltipText === "")
        return <AccountTreeIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <AccountTreeIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_workorders":
      if (tooltipText === undefined || tooltipText === "")
        return <FeedOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <FeedOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_map":
      if (tooltipText === undefined || tooltipText === "")
        return <LocationOnIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <LocationOnIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_custom_reports":
      if (tooltipText === undefined || tooltipText === "")
        return <ArticleOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <ArticleOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_balance":
      if (tooltipText === undefined || tooltipText === "")
        return <BalanceOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <BalanceOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_energy_management":
      if (tooltipText === undefined || tooltipText === "")
        return <ElectricBoltIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <ElectricBoltIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_meterspark":
      if (tooltipText === undefined || tooltipText === "")
        return <WarehouseIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <WarehouseIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_datacollect":
      if (tooltipText === undefined || tooltipText === "")
        return <BrowserUpdatedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <BrowserUpdatedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_userdashboards":
      if (tooltipText === undefined || tooltipText === "")
        return <DashboardIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <DashboardIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />
          </Tooltip>
        );
    case "page_scheduled_reports":
      return <ArticleOutlinedIcon sx={{ color: "1976D2", fontSize: "1.7rem", width: "auto" }} />;
    case "tool_fill":
      if (tooltipText === undefined || tooltipText === "") return <ConstructionIcon />;
      else
        return (
          <Tooltip title={tooltipText} followCursor placement="top">
            <ConstructionIcon />
          </Tooltip>
        );
    default:
      return <></>;
  }
}

function IconConverter(props) {
  const { name, color } = props;

  switch (name) {
    case "home_fill":
      return <HomeIcon sx={{ color: "white" }} />;
    case "home_line":
      return <HomeOutlinedIcon />;
    case "dashboard_line":
      return <DashboardOutlinedIcon />;
    case "dashboard_full":
      return <DashboardIcon sx={{ color: "white" }} />;
    case "water_line":
      return <WaterDropOutlinedIcon />;
    case "water_fill":
      return <WaterDropIcon sx={{ color: "white" }} />;
    case "chart_line":
      return <InsertChartOutlinedOutlinedIcon />;
    case "chart_fill":
      return <InsertChartIcon sx={{ color: "white" }} />;
    case "notifications_line":
      return <NotificationsActiveOutlinedIcon sx={{ color: "white" }} />;
    case "replace_line":
      return <LoopOutlinedIcon sx={{ color: "white" }} />;
    case "meter_line":
      return <SpeedOutlinedIcon sx={{ color: "white" }} />;
    case "energy_line":
      return <EmojiObjectsOutlinedIcon sx={{ color: color ?? "white" }} />;
    case "temperature_line":
      return <DeviceThermostatOutlinedIcon />;
    case "temperature_fill":
      return <DeviceThermostatOutlinedIcon sx={{ color: "white" }} />;
    case "devices_line":
      return <DevicesOutlinedIcon sx={{ color: "white" }} />;
    case "data_validation_line":
      return <AnalyticsOutlinedIcon sx={{ color: "white" }} />;
    case "validation_line":
      return <TaskOutlinedIcon sx={{ color: "white" }} />;
    case "engineer_line":
      return <EngineeringOutlinedIcon sx={{ color: "white" }} />;
    case "android_line":
      return <AndroidOutlinedIcon sx={{ color: "white" }} />;
    case "management_line":
      return <AccountTreeOutlinedIcon sx={{ color: "white" }} />;
    case "tool_line":
      return <ConstructionIcon sx={{ color: "white" }} />;
    case "tool_fill":
      return <ConstructionIcon />;
    default:
      return <></>;
  }
}

function GetFirstDateOfWeek(d) {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

function GenerateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function WordWrap(text, max): [] {
  const regex = new RegExp(".{0," + max + "}(?:\\s|$)", "g");
  const lines: any = [];

  let line: any;
  while ((line = regex.exec(text)?.[0]) !== "") {
    lines.push(line);
  }

  return lines;
}

function FormatLargeNumbers(value) {
  if (value == undefined || value == null) return "n/a";

  if (value >= 0) {
    if (value >= 100000000)
      return (
        (value / 1000000).toLocaleString(undefined, {
          maximumFractionDigits: 1,
        }) + "M"
      );

    if (value >= 10000000)
      return (
        (value / 1000000).toLocaleString(undefined, {
          maximumFractionDigits: 1,
        }) + "M"
      );

    if (value >= 100000)
      return (value / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + "K";

    if (value >= 1000)
      return (value / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + "K";

    if (value <= 10) return value.toLocaleString(undefined, { maximumFractionDigits: 2 });

    if (value <= 100) return value.toLocaleString(undefined, { maximumFractionDigits: 2 });

    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
  value = value * -1;
  if (value >= 100000000)
    return (
      ((value * -1) / 1000000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "M"
    );

  if (value >= 10000000)
    return (
      ((value * -1) / 1000000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "M"
    );

  if (value >= 100000)
    return (
      ((value * -1) / 1000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "K"
    );

  if (value >= 1000)
    return (
      ((value * -1) / 1000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "K"
    );

  if (value <= 10) return (value * -1).toLocaleString(undefined, { maximumFractionDigits: 2 });

  if (value <= 100) return (value * -1).toLocaleString(undefined, { maximumFractionDigits: 2 });

  return (value * -1).toLocaleString(undefined, { maximumFractionDigits: 2 });
}

function DateValueFormatter(dateValue, viewBy): string {
  if (dateValue == null) return "n/a";
  else {
    if (viewBy == null) return dayjs(dateValue).format("YYYY-MM-DD");
    else {
      const dayjsDate = dayjs(dateValue);
      if (!dayjsDate.isValid()) {
        const dt = new Date(dateValue);
        return dayjs(dt).toString();
      }

      switch (viewBy) {
        case 1: {
          return dayjsDate.format("ddd DD/MM");
        }
        case 2: {
          return "Week " + dayjsDate.week();
        }
        case 3: {
          return dayjsDate.format("MMM");
        }
        case 4: {
          return dayjsDate.format("YYYY");
        }
        case 5: {
          return dayjsDate.format("DD/MM/YYYY HH:mm");
        }
        case 6: {
          return dayjsDate.format("DD/MM/YYYY HH:mm");
        }
        default: {
          return "Format N/A";
        }
      }
    }
  }
}

const CustomClusterIcon = (count) => {
  const iconMarkup = renderToStaticMarkup(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="42"
      style={{ position: "absolute", top: "-42px", left: "-14px" }}
    >
      <path
        d="m14.00004,0.38638c-7.69776,0 -13.95854,6.61934 -13.95854,14.75077c0,7.63819 10.44808,15.90284 13.46881,26.10751c0.0649,0.22037 0.25976,0.37052 0.47824,0.36896c0.21926,-0.00322 0.41099,-0.15502 0.47292,-0.37705c2.93056,-10.45571 13.49633,-18.45238 13.49704,-26.10098c-0.0007,-8.12987 -6.26227,-14.7492 -13.95846,-14.7492l0,-0.00001l0,-0.00001l-0.00001,0.00001zm0,25.94034c-6.03535,0 -10.94618,-5.1903 -10.94618,-11.56745c0,-6.37789 4.91084,-11.56745 10.94618,-11.56745c6.03386,0 10.9454,5.18955 10.9454,11.56745c0,6.37714 -4.91162,11.56745 -10.9454,11.56745z"
        fill="#5693C9"
      ></path>
      <path
        d="m2.61129,14.87791c0,-6.55743 5.02777,-11.86894 11.2349,-11.86894c6.20712,0 11.2349,5.31151 11.2349,11.86894c0,6.55743 -5.02777,11.86894 -11.2349,11.86894c-6.20712,0 -11.2349,-5.31151 -11.2349,-11.86894z"
        fill="#ffffff"
      ></path>
      <text
        fontWeight="bold"
        textAnchor="middle"
        transform="matrix(0.12575133698300078, 0, 0, 0.09119301284000736, 13.51915579414451, 17.357595662477369)"
        fontFamily="Monospace"
        fontSize="95"
        fill="black"
      >
        {count}
      </text>
    </svg>
  );
  const customIcon = divIcon({
    html: iconMarkup,
    className: "custom-marker-cluster",
  });
  return customIcon;
};

function Camelize(str) {
  if (str === undefined) return str;
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export {
  MenuIconConverter,
  CustomClusterIcon,
  DateValueFormatter,
  FormatLargeNumbers,
  GenerateUUID,
  GetFirstDateOfWeek,
  IconConverter,
  WordWrap,
  Camelize,
};
