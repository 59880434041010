export const PROP_NODE_TYPE = {
  BUILDING: 3,
  APARTMENT: 2,
  MEDIUM: 1,
  METER: 0,
};

export const NODE_TYPE = {
  BUILDING: "4",
  APARTMENT: "3",
  MEDIUM: "2",
  METER: "1",
};

export const MEDIUM_TYPE = {
  COOLING: 11,
};

export const CATALOG_TYPE = {
  REGIONS: "Regions",
  CITIES: "Cities",
  CITY_ZONES: "CityZones",
};
