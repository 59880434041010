import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { memo, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import { BasePropsType } from "../../../types/common/EmmsysTypes";
import { WO_API } from "../../../api/woapi";
import { DashboardContext } from "../../dashboard/DashboardContext";
import { CustomAreaChart } from "../../common/CustomAreaChart";
import { HorizontalBarChart } from "../../common/horizontalbarchart";

const valueFormatter = (value) => `${value}%`;
const nullValueFormatter = (value) => {
  if (value == null) return "n/a";
  else return `Efficiency: ${value}%`;
};

const dateValueFormatter = (value) => {
  if (value == null) return "n/a";
  else return dayjs(value).format("DD/MM");
};

export const WorkOrdersWidget = memo(function WorkOrdersWidget(props: BasePropsType) {
  const { translate, language } = props;

  const { idRegion, month } = useContext<any>(DashboardContext);

  const theme = useTheme();
  const matchessmbreackpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const [isWOStatusLoaded, setIsWOStatusLoaded] = useState(false);
  const [woStatusItems, setWoStatusItems] = useState<any>([]);

  const [isWOEfficiencyLoaded, setIsWOEfficiencyLoaded] = useState(false);
  const [woEfficiencyItems, setWoEfficiencyItems] = useState<any>({
    xAxisData: [],
    seriesData: [],
  });

  useEffect(() => {
    setIsWOStatusLoaded(false);

    setIsWOEfficiencyLoaded(false);

    const dataStart = new Date(month.year(), month.month(), 1);
    const dataStop = new Date(dataStart.getFullYear(), dataStart.getMonth() + 1, 1);

    WO_API.GetWorkOrdersStatus(
      dayjs(dataStart).format("YYYYMMDD"),
      dayjs(dataStop).format("YYYYMMDD"),
      idRegion == undefined ? "" : idRegion
    ).then(
      (result) => {
        setIsWOStatusLoaded(true);

        const wostatus = result.map((wo, index) => {
          const yAxislabel =
            wo.name +
            " (" +
            wo.woCount.toString() +
            ") " +
            (Math.floor(wo.percent * 10000) / 100).toString() +
            "%";

          const yAxisValue = index + "_" + Math.floor(wo.percent * 10000) / 100 + "%";
          return {
            id: index,
            name: wo.name,
            woCount: wo.woCount,
            percent: Math.floor(wo.percent * 10000) / 100,
            nameFormatted: yAxisValue,
            woLabelFormatted: yAxislabel,
          };
        });
        setWoStatusItems(wostatus);
      },
      () => {
        setIsWOStatusLoaded(true);
        setWoStatusItems([]);
      }
    );

    WO_API.GetFieldWorkerEfficiencyOverall(
      dayjs(dataStart).format("YYYYMMDD"),
      dayjs(dataStop).format("YYYYMMDD"),
      "",
      "weekly",
      0,
      0,
      "37,131,121,141,133,16,136,134,135,20,18,19"
    ).then(
      (result) => {
        setIsWOEfficiencyLoaded(true);
        const woeffxaxisdata = result.flatMap((rs) => {
          return dayjs(rs.date).toDate();
        });
        const woeffseriesdata = result.flatMap((rs) => {
          return rs.efficency;
        });
        setWoEfficiencyItems({
          xAxisData: woeffxaxisdata,
          seriesData: woeffseriesdata,
        });
      },
      () => {
        setIsWOEfficiencyLoaded(true);
        setWoEfficiencyItems({ xAxisData: [], seriesData: [] });
      }
    );
  }, [idRegion, month]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} columns={matchessmbreackpoint ? 13 : 12}>
          <Grid item xs={12} md={13} lg={13}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
              }}
            >
              <Typography
                noWrap
                component="span"
                sx={{
                  fontSize: "1.38rem",
                  fontWeight: 700,
                }}
              >
                {translate("Work Orders")}
              </Typography>
              <Tooltip title={translate("Description:")} arrow>
                <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {!isWOStatusLoaded && (
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress />
              </Box>
            )}
            <HorizontalBarChart
              translate={translate}
              language={language}
              chartConfig={{
                dataset: woStatusItems,
                seriesValueFormatter: valueFormatter,
                title: translate("Work Order Status"),
                xAxisField: "percent",
                yAxisField: "woLabelFormatted",
                yAxisLabelField: "woLabelFormatted",
                yAxisExtField: "woCount",
                colors: ["#1976D2"],
                height: 250,
              }}
            />
          </Grid>
          <Grid
            item
            md={1}
            lg={1}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              width: "1rem",
              justifyContent: "center",
            }}
          >
            <Divider orientation="vertical" flexItem sx={{ margin: "2rem 0" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "fnone",
              },
              justifyContent: "center",
            }}
          >
            <Divider orientation="horizontal" flexItem sx={{ flexGrow: 1 }} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {!isWOEfficiencyLoaded && (
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress />
              </Box>
            )}
            <CustomAreaChart
              language={language}
              chartConfig={{
                title: "Efficiency",
                lineColor: "#1976D2",
                height: 250,
                xAxis:
                  woEfficiencyItems.xAxisData.length > 0
                    ? [
                        {
                          id: "yAxis_id",
                          scaleType: "time",
                          data: woEfficiencyItems.xAxisData,
                          min: woEfficiencyItems.xAxisData[0].getTime(),
                          max: woEfficiencyItems.xAxisData[
                            woEfficiencyItems.xAxisData.length - 1
                          ].getTime(),
                          valueFormatter: dateValueFormatter,
                          tickNumber: 6,
                        },
                      ]
                    : [],
                yAxis: [
                  {
                    valueFormatter: (value) => value + " %",
                    label: translate("Efficiency"),
                  },
                ],
                series:
                  woEfficiencyItems.xAxisData.length > 0
                    ? [
                        {
                          id: "Efficiency",
                          data: woEfficiencyItems.seriesData,
                          area: true,
                          showMark: false,
                          valueFormatter: nullValueFormatter,
                        },
                      ]
                    : [],
                gradientColors: ["#0051C17A", "#0051C10F"],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
