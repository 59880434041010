import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BarChart, useDrawingArea, axisClasses } from "@mui/x-charts";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { memo, useState } from "react";

import { CustomSimpleGrid } from "./CustomSimpleGrid";
import { GetFirstDateOfWeek } from "../utils/utils";
import { RoundedBar } from "./RoundedBar";

const chartSetting = {
  height: 350,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-30px, 0px)",
    },
    [`.${axisClasses.right} .${axisClasses.label}`]: {
      transform: "translate(30px, 0px)",
    },
  },
  legend: { hidden: true },
};

const viewbylist = [
  {
    text: "Week",
    id: 1,
  },
  {
    text: "Month",
    id: 2,
  },
  {
    text: "Year",
    id: 3,
  },
];

const typelist = [
  {
    text: "Chart",
    id: 1,
  },
  {
    text: "Table",
    id: 2,
  },
];

const monthslist = [
  {
    text: "January",
    id: 1,
  },
  {
    text: "February",
    id: 2,
  },
  {
    text: "March",
    id: 3,
  },
  {
    text: "April",
    id: 4,
  },
  {
    text: "May",
    id: 5,
  },
  {
    text: "June",
    id: 6,
  },
  {
    text: "July",
    id: 7,
  },
  {
    text: "August",
    id: 8,
  },
  {
    text: "September",
    id: 9,
  },
  {
    text: "October",
    id: 10,
  },
  {
    text: "November",
    id: 11,
  },
  {
    text: "December",
    id: 12,
  },
];

const StyledTickLine = styled("line")(({ x1 }) => ({
  //stroke: theme.palette.text[color],
  stroke: "#757575",
  strokeOpacity: "0.3",
  strokeDasharray: "3px",
  shapeRendering: "crispEdges",
  strokeWidth: 1,
  x1: x1,
}));

function CustomYAxisTick(props) {
  const { width } = useDrawingArea();
  const { x2 } = props;

  return <StyledTickLine {...props} x1={x2 == undefined ? null : width} />;
}

const defaultValueFormatter = (value) => `${value}`;

interface VerticalBarChartProps {
  translate: (params: string) => string;
  language: string;
  chartConfig: any;
  viewByMode: number;
}

const VerticalBarChart = memo(function VerticalBarChart({
  translate,
  chartConfig,
  viewByMode,
}: VerticalBarChartProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const useGetFirstDateOfWeek = GetFirstDateOfWeek;

  const [viewby, setViewby] = useState(viewByMode);

  const currentDate = new Date();

  const firstdayofcurrentweek = useGetFirstDateOfWeek(currentDate);
  const lastdayofcurrentweek = new Date(
    firstdayofcurrentweek.getFullYear(),
    firstdayofcurrentweek.getMonth(),
    firstdayofcurrentweek.getDate() + 6
  );

  const [type, setType] = useState(1);

  const [month, setMonth] = useState(currentDate.getMonth() + 1);

  const [year, setYear] = useState(dayjs(currentDate));

  const [weekvalue, setWeekvalue] = useState<any>([
    dayjs(firstdayofcurrentweek),
    dayjs(lastdayofcurrentweek),
  ]);

  const handleChangeViewby = (event) => {
    setViewby(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (value) => {
    setYear(dayjs(value));
  };

  const handleChangeWeekValue = (value) => {
    setWeekvalue(value);
  };

  return (
    <Grid container spacing={2} sx={{ width: "100%", margin: "0 !important" }}>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          <Stack direction={isMobile ? "column" : "row"} spacing={1} sx={{ width: "100%" }}>
            <Box sx={{ width: isMobile ? "100%" : "33%", ml: isMobile ? "0px !important" : "8px" }}>
              <InputLabel id="viewby-label" disableAnimation={true}>
                {translate("View by")}
              </InputLabel>
              <Select
                labelId="viewby-label"
                id="viewby-select"
                value={viewby}
                onChange={handleChangeViewby}
                disabled
                sx={{ width: "100%" }}
              >
                {viewbylist.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {translate(item.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ width: isMobile ? "100%" : "33%", ml: isMobile ? "0px !important" : "8px" }}>
              {viewby == 1 && (
                <>
                  <InputLabel id="interval-label" disableAnimation={true}>
                    {translate("Select week")}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      slots={{ field: SingleInputDateRangeField }}
                      sx={{ width: "100%" }}
                      displayWeekNumber={true}
                      format={"MM/DD"}
                      value={weekvalue}
                      onAccept={handleChangeWeekValue}
                      disabled
                    />
                  </LocalizationProvider>
                </>
              )}
              {viewby == 2 && (
                <>
                  <InputLabel id="month-label" disableAnimation={true}>
                    {translate("Select month")}
                  </InputLabel>
                  <Select
                    labelId="month-label"
                    id="month-select"
                    value={month}
                    onChange={handleChangeMonth}
                    disabled
                    sx={{ width: "100%" }}
                  >
                    {monthslist.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {translate(item.text)}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {viewby == 3 && (
                <>
                  <InputLabel id="year-label" disableAnimation={true}>
                    {translate("Select year")}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      sx={{ width: "100%" }}
                      format={"YYYY"}
                      value={year}
                      onAccept={handleChangeYear}
                      disabled
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "33%", ml: isMobile ? "0px !important" : "8px" }}>
              <InputLabel id="type-label" disableAnimation={true}>
                {translate("Type")}
              </InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                value={type}
                onChange={handleChangeType}
                sx={{ width: "100%" }}
              >
                {typelist.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {translate(item.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          {type === 1 && (
            <BarChart
              dataset={chartConfig.dataset}
              margin={{ top: 30, left: 80, right: 30, bottom: 30 }}
              xAxis={
                chartConfig.dataset === undefined || chartConfig.dataset.length === 0
                  ? []
                  : [
                      {
                        scaleType: "band",
                        dataKey: chartConfig.xAxisField,
                        valueFormatter: chartConfig.xAxisValueFormatter ?? defaultValueFormatter,
                        //categoryGapRatio: 0.2,
                        //barGapRatio: 0.2,
                      },
                    ]
              }
              yAxis={
                chartConfig.dataset === undefined || chartConfig.dataset.length === 0
                  ? []
                  : [
                      {
                        tickSize: 6,
                        tickFontSize: 12,
                        valueFormatter: chartConfig.yAxisValueFormatter,
                        label: chartConfig.yAxisLabel,
                      },
                    ]
              }
              series={
                chartConfig.dataset === undefined || chartConfig.dataset.length === 0
                  ? []
                  : chartConfig.series.map((s) => {
                      if (s.dataKey != undefined) {
                        s.id = "serie-id-" + s.dataKey;
                        return s;
                      } else {
                        return s;
                      }
                    })
              }
              {...chartSetting}
              slots={{
                bar: (props) =>
                  RoundedBar({
                    ...props,
                    data: chartConfig.dataset,
                    dataKey: chartConfig.xAxisField,
                    orientation: "vertical",
                  }),
                axisTick: (props) => CustomYAxisTick(props),
              }}
              colors={chartConfig.colors}
            ></BarChart>
          )}
          {type === 2 && (
            <CustomSimpleGrid
              dataSet={chartConfig.dataset}
              columns={chartConfig.tableColumns}
              maxHeight={350}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
});

export { VerticalBarChart };
