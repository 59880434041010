import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { memo, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Cookies from "universal-cookie";
import { config } from "../../../config/config";

import { GetFirstDateOfWeek } from "../../utils/utils";
import { useAppStore } from "../../../store/store";

const viewbylist = [
  {
    text: "Week",
    id: 1,
  },
  {
    text: "Month",
    id: 2,
  },
  {
    text: "Year",
    id: 3,
  },
];

const monthslist = [
  {
    text: "January",
    id: 1,
  },
  {
    text: "February",
    id: 2,
  },
  {
    text: "March",
    id: 3,
  },
  {
    text: "April",
    id: 4,
  },
  {
    text: "May",
    id: 5,
  },
  {
    text: "June",
    id: 6,
  },
  {
    text: "July",
    id: 7,
  },
  {
    text: "August",
    id: 8,
  },
  {
    text: "September",
    id: 9,
  },
  {
    text: "October",
    id: 10,
  },
  {
    text: "November",
    id: 11,
  },
  {
    text: "December",
    id: 12,
  },
];

export const data = [
  ["From", "To", "Weight"],
  ["Thermal Solar", "Solar Energy", 1],
  ["Electricity Solar", "Solare Coverage", 1],
  ["Gas", "Conventional Energy", 1],
  ["Electricity", "Conventional Energy", 0.5],
  ["Electricity", "Conventional Coverage", 0.5],
  ["Steam", "Conventional Coverage", 1],
  ["Solar Energy", "Inlet", 1],
  ["Solare Coverage", "Inlet", 1],
  ["Conventional Energy", "Inlet", 1.5],
  ["Conventional Coverage", "Inlet", 1.5],
  ["Inlet", "Production", 5],
  ["Production", "Distribution", 5],
  ["Steam", "Inlet", 0],
  ["Inlet", "Production Efficiency", 1],
  ["Production Efficiency", "Distribution Efficiency", 1],
];

const options = {
  sankey: {
    link: {},
    node: {
      colors: [
        "#F57C00",
        "#F57C00",
        "#F57C00",
        "#F57C00",
        "#F57C00",
        "#1976D2",
        "#D32F2F",
        "#388E3C",
      ],
    },
  },
};

const apiUrl = config.WS_API_URL.startsWith("http")
  ? config.WS_API_URL
  : window.location.origin + config.WS_API_URL;

interface SynopticDiagramProps {
  translate: (params: string) => string;
  language: string;
  globalRefresh: boolean;
  viewByMode: number;
}

const SynopticDiagram = memo(function SynopticDiagram({
  translate,
  globalRefresh,
  viewByMode,
}: SynopticDiagramProps) {
  const { appConfig } = useAppStore();
  const cookies = new Cookies();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // const [synopticData, setSynopticData] = useState({});

  const useGetFirstDateOfWeek = GetFirstDateOfWeek;

  const [viewby, setViewby] = useState(viewByMode);

  const currentDate = new Date();

  const firstdayofcurrentweek = useGetFirstDateOfWeek(currentDate);
  const lastdayofcurrentweek = new Date(
    firstdayofcurrentweek.getFullYear(),
    firstdayofcurrentweek.getMonth(),
    firstdayofcurrentweek.getDate() + 6
  );

  const [month, setMonth] = useState(currentDate.getMonth() + 1);

  const [year, setYear] = useState(dayjs(currentDate));

  const [weekvalue, setWeekvalue] = useState<any>([
    dayjs(firstdayofcurrentweek),
    dayjs(lastdayofcurrentweek),
  ]);

  const handleChangeViewby = (event) => {
    setViewby(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (value) => {
    setYear(dayjs(value));
  };

  const handleChangeWeekValue = (value) => {
    setWeekvalue(value);
  };

  const tid = cookies.get("loginTenant") ?? appConfig?.tid;
  const uid = cookies.get("loginUserId") ?? appConfig?.uid;
  const mid = cookies.get("mid") ?? appConfig?.mid;

  useEffect(() => {
    setIsDataLoaded(false);

    const currentDate = new Date();
    const dataStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const dataStop = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate() - 1
    );

    fetch(
      apiUrl +
        "GetAdministrativeGroupSynopticValues?groupId=96&nodeType=3&start=" +
        dayjs(dataStart).format("YYYYMMDD") +
        "&stop=" +
        dayjs(dataStop).format("YYYYMMDD"),
      {
        mode: "cors",
        headers: {
          tid: tid,
          uid: uid,
          mid: mid,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsDataLoaded(true);

          if (result != undefined && result.length > 0) {
            const syndatatmp = {
              thermalSolar: "n/a",
              electricitySolar: "n/a",
              gas: "n/a",
              electricity: "n/a",
              steam: "n/a",
              production: "n/a",
              distribution: "n/a",
              solarEnergy: "n/a",
              conventionalEnergy: "n/a",
              inlet: "n/a",
              solareCoverage: "n/a",
              conventionalCoverage: "n/a",
              productionEfficiency: "n/a",
              distributionEfficiency: "n/a",
            };
            for (let i = 0, leni = result.length; i < leni; i++) {
              const itemi = result[i];
              switch (itemi.descriere) {
                case "Thermal Solar":
                  syndatatmp.thermalSolar =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Electricity Solar":
                  syndatatmp.electricitySolar =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Gas":
                  syndatatmp.gas = itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Electricity":
                  syndatatmp.electricity =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Steam":
                  syndatatmp.steam =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Production":
                  syndatatmp.production =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Distribution":
                  syndatatmp.distribution =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Solar Energy":
                  syndatatmp.solarEnergy =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Conventional Energy":
                  syndatatmp.conventionalEnergy =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Inlet":
                  syndatatmp.inlet =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Solare Coverage":
                  syndatatmp.solareCoverage =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Conventional Coverage":
                  syndatatmp.conventionalCoverage =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Distribution Efficiency":
                  syndatatmp.distributionEfficiency =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
                case "Production Efficiency":
                  syndatatmp.productionEfficiency =
                    itemi.valoare == null ? "n/a" : itemi.valoare + " " + itemi.unit;
                  break;
              }
            }
            // setSynopticData(syndatatmp);
          }
        },
        () => {
          // setIsDataLoaded(true);
          // setSynopticData({
          //   thermalSolar: "n/a",
          //   electricitySolar: "n/a",
          //   gas: "n/a",
          //   electricity: "n/a",
          //   steam: "n/a",
          //   production: "n/a",
          //   distribution: "n/a",
          //   solarEnergy: "n/a",
          //   conventionalEnergy: "n/a",
          //   inlet: "n/a",
          //   solareCoverage: "n/a",
          //   conventionalCoverage: "n/a",
          //   productionEfficiency: "n/a",
          //   distributionEfficiency: "n/a",
          // });
          //setApiError(error);
        }
      );
  }, [globalRefresh]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          {!isDataLoaded && (
            <CircularProgress
              sx={{
                width: "1.2rem !important",
                height: "1.2rem !important",
                margin: "6px",
              }}
            />
          )}
          <Typography
            noWrap
            component="span"
            sx={{
              fontSize: "1.38rem",
              fontWeight: 700,
            }}
          >
            {translate("Synoptic")}
          </Typography>
          <Tooltip title={translate("Description:")} arrow>
            <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
          </Tooltip>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0.5rem 1.5rem",
          }}
        >
          <Stack direction="row" spacing={1} sx={{ flex: "0.4" }}>
            <Box sx={{ flex: "1 0 auto" }}>
              <InputLabel id="viewby-label" disableAnimation={true}>
                {translate("View by")}
              </InputLabel>
              <Select
                labelId="viewby-label"
                id="viewby-select"
                value={viewby}
                onChange={handleChangeViewby}
                disabled
                sx={{ width: "100%" }}
                size="small"
              >
                {viewbylist.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {translate(item.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ flex: "1 0 auto" }}>
              {viewby == 1 && (
                <>
                  <InputLabel id="interval-label" disableAnimation={true}>
                    {translate("Select week")}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      slots={{ field: SingleInputDateRangeField }}
                      sx={{ width: "100%" }}
                      displayWeekNumber={true}
                      format={"MM/DD"}
                      value={weekvalue}
                      onAccept={handleChangeWeekValue}
                      disabled
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </>
              )}
              {viewby == 2 && (
                <>
                  <InputLabel id="month-label" disableAnimation={true}>
                    {translate("Select month")}
                  </InputLabel>
                  <Select
                    labelId="month-label"
                    id="month-select"
                    value={month}
                    onChange={handleChangeMonth}
                    disabled
                    sx={{ width: "100%" }}
                    size="small"
                  >
                    {monthslist.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {translate(item.text)}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {viewby == 3 && (
                <>
                  <InputLabel id="year-label" disableAnimation={true}>
                    {translate("Select year")}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="year-label"
                      views={["year"]}
                      sx={{ width: "100%" }}
                      format={"YYYY"}
                      value={year}
                      onAccept={handleChangeYear}
                      disabled
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
          </Stack>
        </Box>
        <Divider orientation="horizontal" flexItem sx={{ margin: "0.5rem 0" }} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1.5rem",
          }}
        >
          <Chart chartType="Sankey" width="100%" height="400px" data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
});

export { SynopticDiagram };
