import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { LineChart, useDrawingArea, axisClasses } from "@mui/x-charts";
import { memo } from "react";

const chartSetting = {
  legend: { hidden: true },
};

const StyledTickLine = styled("line")(({ x1, y1 }) => ({
  stroke: "#757575",
  strokeOpacity: "0.3",
  strokeDasharray: "3px",
  shapeRendering: "crispEdges",
  strokeWidth: 1,
  x1: x1,
  y1: y1,
}));

function CustomXYAxisTick(props) {
  const { width, height } = useDrawingArea();
  const { x2, y2 } = props;

  return (
    <StyledTickLine
      {...props}
      x1={x2 == undefined ? null : width}
      y1={y2 == undefined ? null : -1 * height}
    />
  );
}

interface CustomAreaChartProps {
  language: string;
  chartConfig: any;
}

const CustomAreaChart = memo(function CustomAreaChart(props: CustomAreaChartProps) {
  const { chartConfig } = props;
  const title = chartConfig.title ?? "";
  const serieId =
    chartConfig.series.length > 0 ? chartConfig.series[0].id ?? "seriesId" : "seriesId";

  return (
    <Box sx={{ flexGrow: 1 }}>
      {title != "" && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "5px",
          }}
        >
          <Typography noWrap component="span" sx={{ fontSize: "1rem", fontWeight: 400 }}>
            {title}
          </Typography>
        </Box>
      )}
      {chartConfig.xAxis.length > 0 && (
        <LineChart
          sx={{
            "& .MuiLineElement-root": {
              strokeWidth: 2,
              stroke: chartConfig.lineColor,
            },
            ["& .MuiAreaElement-series-" + serieId]: {
              fill: "url('#customAreaChartGradient')",
            },
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translate(-20px, 0px)",
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              transform: "translate(20px, 0px)",
            },
          }}
          margin={{ top: 30, left: 60, right: 30, bottom: 80 }}
          xAxis={chartConfig.xAxis}
          yAxis={chartConfig.yAxis}
          series={chartConfig.series}
          height={chartConfig.height ?? 300}
          slots={{
            axisTick: (props) => CustomXYAxisTick(props),
          }}
          {...chartSetting}
        >
          {chartConfig.gradientColors != undefined && chartConfig.gradientColors.length === 2 && (
            <defs>
              <linearGradient
                id="customAreaChartGradient"
                gradientTransform="rotate(90)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="6%" stopColor={chartConfig.gradientColors[0]} />
                <stop offset="48%" stopColor={chartConfig.gradientColors[1]} />
              </linearGradient>
            </defs>
          )}
        </LineChart>
      )}
      {chartConfig.xAxis.length == 0 && (
        <LineChart
          sx={{
            "& .MuiLineElement-root": {
              strokeWidth: 2,
              stroke: chartConfig.lineColor,
            },
          }}
          xAxis={[]}
          series={[]}
          height={chartConfig.height ?? 300}
        ></LineChart>
      )}
    </Box>
  );
});

export { CustomAreaChart };
