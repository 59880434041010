import CircleIcon from "@mui/icons-material/Circle";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { memo, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import { BasePropsType } from "../../../types/common/EmmsysTypes";
import { EMS_API } from "../../../api/emsapi";
import { DCS_API } from "../../../api/dcsapi";
import { DashboardContext } from "../../dashboard/DashboardContext";

export const EnergyManagementWidget = memo(function EnergyManagementWidget(
  props: Pick<BasePropsType, "translate">
) {
  const { translate } = props;

  const { idRegion, month } = useContext<any>(DashboardContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isDataset1Loaded, setIsDataset1Loaded] = useState(false);
  const [isDataset2Loaded, setIsDataset2Loaded] = useState(false);

  const [energyEfficiency, setEnergyEfficiency] = useState<any>({
    solarEfficiency: [],
    productionEfficiency: [],
    distributionEfficiency: [],
  });
  const [supervisionReports, setSupervisionReports] = useState<any>({});

  useEffect(() => {
    //if (
    //  idRegion === null ||
    //  idRegion === "" ||
    //  idRegion === undefined ||
    //  month === null ||
    //  month === undefined
    //)
    //  return;

    setIsDataset1Loaded(false);
    setIsDataset2Loaded(false);

    const dataStart = new Date(month.year(), month.month(), 1);
    const dataStop = new Date(dataStart.getFullYear(), dataStart.getMonth() + 1, 1);

    DCS_API.GetAdministrativeGroupEfficiency(
      idRegion == undefined ? "" : idRegion,
      "6",
      dayjs(dataStart).format("YYYYMMDD"),
      dayjs(dataStop).format("YYYYMMDD"),
      16
    ).then(
      (result) => {
        setEnergyEfficiency(result);
        setIsDataset1Loaded(true);
      },
      () => {
        setIsDataset1Loaded(true);
      }
    );

    EMS_API.GetEmsChecksListV1(
      idRegion == undefined ? "" : idRegion,
      6,
      11,
      "",
      dayjs(dataStart).format("YYYYMMDD"),
      dayjs(dataStop).format("YYYYMMDD")
    ).then(
      (result) => {
        setSupervisionReports(result);
        setIsDataset2Loaded(true);
      },
      () => {
        setSupervisionReports({});
        setIsDataset2Loaded(true);
      }
    );
  }, [idRegion, month]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
              }}
            >
              {!isDataset1Loaded && !isDataset2Loaded && (
                <CircularProgress
                  sx={{
                    width: "1.2rem !important",
                    height: "1.2rem !important",
                    margin: "6px",
                  }}
                />
              )}
              <Typography
                noWrap
                component="span"
                sx={{
                  fontSize: "1.38rem",
                  fontWeight: 700,
                }}
              >
                {translate("Energy Management")}
              </Typography>
              <Tooltip title={translate("Description:")} arrow>
                <InfoIcon sx={{ margin: "0.31rem 0.9rem", color: "#0051C1" }} />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                paddingBottom: "0.8rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  padding: "5px 10px",
                  fontWeight: 700,
                }}
                component="span"
                noWrap
              >
                {translate("Supervisor Reports")}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "3px 10px !important", display: isMobile ? "none" : "flex" }}
              />
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={1}
                sx={{ justifyContent: "flex-start" }}
              >
                <Chip
                  label={
                    translate("Events") +
                    " " +
                    (supervisionReports.checkEvents !== undefined
                      ? supervisionReports.checkEvents + " %"
                      : " - n/a")
                  }
                  variant="outlined"
                  sx={{ margin: "0px 8px", justifyContent: "flex-start" }}
                  icon={
                    <CircleIcon
                      sx={{
                        color: "#E53935 !important",
                        boxShadow: "0 0 0 2px #FFEBEE",
                        borderRadius: "50%",
                      }}
                    />
                  }
                />
                <Chip
                  label={
                    translate("Supervisor") +
                    " " +
                    (supervisionReports.checkSupervisor !== undefined
                      ? supervisionReports.checkSupervisor + " %"
                      : " - n/a")
                  }
                  variant="outlined"
                  sx={{ margin: "0px 8px", justifyContent: "flex-start" }}
                  icon={
                    <CircleIcon
                      sx={{
                        color: "#43A047 !important",
                        boxShadow: "0 0 0 2px #E8F5E9",
                        borderRadius: "50%",
                      }}
                    />
                  }
                />
                <Chip
                  label={
                    translate("Exploitation") +
                    " " +
                    (supervisionReports.checkExploitation !== undefined
                      ? supervisionReports.checkExploitation + " %"
                      : " - n/a")
                  }
                  variant="outlined"
                  sx={{ margin: "0px 8px", justifyContent: "flex-start" }}
                  icon={
                    <CircleIcon
                      sx={{
                        color: "#E53935 !important",
                        boxShadow: "0 0 0 2px #FFEBEE",
                        borderRadius: "50%",
                      }}
                    />
                  }
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ display: isMobile ? "flex" : "none" }}>
            <Divider orientation="horizontal" flexItem sx={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  padding: "5px 10px",
                  fontWeight: 700,
                }}
                component="span"
                noWrap
              >
                {translate("Energy Efficiency")}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "3px 10px !important", display: isMobile ? "none" : "flex" }}
              />
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={1}
                sx={{ justifyContent: "flex-start" }}
              >
                <Chip
                  label={
                    translate("Solar Coverage") +
                    " - " +
                    (energyEfficiency.solarEfficiency.length > 0
                      ? energyEfficiency.solarEfficiency[0].valoare + " %"
                      : "n/a")
                  }
                  variant="outlined"
                  sx={{ margin: "0px 8px", justifyContent: "flex-start" }}
                  icon={
                    <CircleIcon
                      sx={{
                        color: "#E53935 !important",
                        boxShadow: "0 0 0 2px #FFEBEE",
                        borderRadius: "50%",
                      }}
                    />
                  }
                />
                <Chip
                  label={
                    translate("Production Efficiency") +
                    " - " +
                    (energyEfficiency.productionEfficiency.length > 0
                      ? energyEfficiency.productionEfficiency[0].valoare + " %"
                      : "n/a")
                  }
                  variant="outlined"
                  sx={{ margin: "0px 8px", justifyContent: "flex-start" }}
                  icon={
                    <CircleIcon
                      sx={{
                        color: "#FB8C00 !important",
                        boxShadow: "0 0 0 2px #FFF3E0",
                        borderRadius: "50%",
                      }}
                    />
                  }
                />
                <Chip
                  label={
                    translate("Distribution Efficiency") +
                    " - " +
                    (energyEfficiency.distributionEfficiency.length > 0
                      ? energyEfficiency.distributionEfficiency[0].valoare + " %"
                      : "n/a")
                  }
                  variant="outlined"
                  sx={{ margin: "0px 8px", justifyContent: "flex-start" }}
                  icon={
                    <CircleIcon
                      sx={{
                        color: "#E53935 !important",
                        boxShadow: "0 0 0 2px #FFEBEE",
                        borderRadius: "50%",
                      }}
                    />
                  }
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
