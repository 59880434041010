import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { API } from "../../../api/api";
import { useAppStore } from "../../../store/store";

const viewByList = [
  {
    text: "Days",
    id: 1,
  },
  {
    text: "Weeks",
    id: 2,
  },
  {
    text: "Months",
    id: 3,
  },
  {
    text: "Years",
    id: 4,
  },
];

const typelist = [
  {
    text: "Chart",
    id: 1,
  },
  {
    text: "Table",
    id: 2,
  },
];

export const SummaryInputs = (props) => {
  const { appConfig } = useAppStore();
  const mid = appConfig?.mid;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { translate, chartConfig, chartType, setChartType, setViewFormat, setData } = props;

  const [viewBy, setViewby] = useState(1);

  const [nodePerspectives, setNodePerspectives] = useState<any>([]);
  const [nodeMediums, setNodeMediums] = useState<any>([]);

  const [perspectiveValue, setPerspectiveValue] = useState(-1);
  const [mediumValue, setMediumValue] = useState(-1);

  const handleChangeViewby = (event) => {
    const value = event.target.value;

    setViewby(value);
  };

  const handleNodePerspectiveChange = (event) => {
    setPerspectiveValue(event.target.value);
  };

  const handleNodeMediumChange = (event) => {
    setMediumValue(event.target.value);
  };

  useEffect(() => {
    const nodeId = chartConfig.nodeId;
    const nodeType = chartConfig.nodeType;
    if (nodeId === undefined || nodeId === "") return;

    API.GetEmsNodeMeterPerspectives(nodeId, nodeType, mid).then((result) => {
      setNodePerspectives(result);
      if (result.length > 0) setPerspectiveValue(result[0].code);
    });

    API.GetEmsNodeMediums(nodeId, nodeType).then((result) => {
      setNodeMediums(result);
      if (result.length > 0) setMediumValue(result[0].idMedium);
    });
  }, [chartConfig]);

  useEffect(() => {
    const nodeId = chartConfig.nodeId;
    const nodeType = chartConfig.nodeType;
    if (nodeId === undefined || nodeId === "" || mediumValue === -1 || perspectiveValue === -1)
      return;

    let timeline = "";
    switch (viewBy) {
      case 1:
        timeline = "Days";
        break;
      case 2:
        timeline = "Weeks";
        break;
      case 3:
        timeline = "Months";
        break;
      case 4:
        timeline = "Years";
        break;
      default:
        console.warn("Undefined time unit");
    }

    API.GetAdministrativeGroupConsumptionEvolution(
      timeline,
      nodeId,
      mid,
      nodeType,
      perspectiveValue,
      mediumValue
    ).then(
      (result) => {
        result = result.map((item) => {
          let dataKey;
          switch (viewBy) {
            case 1:
              dataKey = "zi";
              break;
            case 2:
              dataKey = "primaZiSaptamana";
              break;
            case 3:
              dataKey = "luna";
              break;
            case 4:
              dataKey = "an";
              break;
            default:
              console.warn("Undefined time unit");
              break;
          }
          return { ...item, dataStart: item[dataKey].toString() };
        });
        setData(result);
        setViewFormat(viewBy);
      },
      () => {
        setData({});
        setViewFormat(viewBy);
      }
    );
  }, [chartConfig, viewBy, mediumValue, perspectiveValue]);

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: "10px",
      }}
    >
      <Stack direction={isMobile ? "column" : "row"} spacing={1} sx={{ width: "100%" }}>
        <Box sx={{ width: isMobile ? "100%" : "20%", ml: isMobile ? "0px !important" : "8px" }}>
          <InputLabel id="viewby-label" disableAnimation={true}>
            {translate("View by")}
          </InputLabel>
          <Select
            labelId="viewby-label"
            id="viewby-select"
            value={viewBy}
            onChange={handleChangeViewby}
            sx={{ width: "100%" }}
            size="small"
          >
            {viewByList.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {translate(item.text)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ width: isMobile ? "100%" : "20%", ml: isMobile ? "0px !important" : "8px" }}>
          <InputLabel id="type-label" disableAnimation={true}>
            {translate("Type")}
          </InputLabel>
          <Select
            labelId="type-label"
            id="type-select"
            value={chartType}
            onChange={(event) => {
              setChartType(event.target.value);
            }}
            sx={{ width: "100%" }}
            size="small"
          >
            {typelist.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {translate(item.text)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {perspectiveValue !== -1 && (
          <Box sx={{ width: isMobile ? "100%" : "20%", ml: isMobile ? "0px !important" : "8px" }}>
            <InputLabel id="perspective-label" disableAnimation={true}>
              {translate("Perspective")}
            </InputLabel>
            <Select
              labelId="perspective-label"
              id="perspective-select"
              value={perspectiveValue}
              onChange={handleNodePerspectiveChange}
              sx={{ width: "100%" }}
              size="small"
            >
              {nodePerspectives.map((item, index) => (
                <MenuItem key={index} value={item.code}>
                  {translate(item.name)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {mediumValue !== -1 && (
          <Box sx={{ width: isMobile ? "100%" : "20%", ml: isMobile ? "0px !important" : "8px" }}>
            <InputLabel id="medium-label" disableAnimation={true}>
              {translate("Medium")}
            </InputLabel>
            <Select
              labelId="medium-label"
              id="medium-select"
              value={mediumValue}
              onChange={handleNodeMediumChange}
              sx={{ width: "100%" }}
              size="small"
            >
              {nodeMediums.map((item, index) => (
                <MenuItem key={index} value={item.idMedium}>
                  {translate(item.name)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
